import React, { useEffect } from "react";
import './toast.css';

const Success = ({ message }) => {

    // useEffect(() => {
    //     console.log('mounted');
    //     return () => {
    //         console.log('unmounted');
    //     }
    // }, [])
    return (
        <React.Fragment>
            <div className="toast-parent">
                <p>{message}</p>
            </div>
        </React.Fragment>
    )
}

export default Success;