import React, { useEffect, useState } from 'react'
import './ProfileProgress.css'
import Header from '../../layout/header/Header'
import alert from '../../assets/images/alert-triangle.svg'

import { NavLink, Outlet, useParams } from 'react-router-dom'
import { updateCaller } from 'services/api'
import { doctorApprovalStatus, _approvalStatus } from 'utils/constants'
import useMasters from 'hooks/useMasters'
import { MenuItem, Select } from '@mui/material'

import DoneIcon from '@mui/icons-material/Done';

const MODAL_INPUT_SIZE = 'small';

const ProfileInProgress = () => {
    const { doctorId } = useParams();
    const { approvalStatus } = useMasters();

    const setActiveInactiveClass = ({ isActive }) => { return isActive ? 'doctor-list-tab doctor-list-active-tab' : 'doctor-list-tab' }
    const isPC = useState(false);

    const [isProfileCompleted, setIsPC] = isPC;
    console.log("🚀 ~ file: ProfileInProgress.jsx:22 ~ ProfileInProgress ~ isProfileCompleted", isProfileCompleted)

    const approveDoctor = async () => {
        const response = await updateCaller(`admin/users/doctor/profile/approval/${doctorId}`, { status: doctorApprovalStatus['accepted'] });
        response?.error === false && setIsPC(doctorApprovalStatus['accepted'])
    }

    const rejectDoctor = async () => {
        const response = await updateCaller(`admin/users/doctor/profile/approval/${doctorId}`, { status: doctorApprovalStatus['rejected'] });
        response?.error === false && setIsPC(doctorApprovalStatus['rejected'])
    }

    const pendingDoctor = async () => {
        const response = await updateCaller(`admin/users/doctor/profile/approval/${doctorId}`, { status: doctorApprovalStatus['pending'] });
        response?.error === false && setIsPC(doctorApprovalStatus['pending'])
    }

    const handleChange = (e) => {
        if (window.confirm('Are you sure you want to update the status?')) {
            const value = e.target.value;
            console.log("🚀 ~ file: ProfileInProgress.jsx:36 ~ handleChange ~ value", value)
            if (value == _approvalStatus.accepted) {
                approveDoctor()
            } else if (value === _approvalStatus.rejected) {
                rejectDoctor()
            } else {
                pendingDoctor()
            }
        }
    }

    const whatToRender = () => {
        switch (isProfileCompleted) {
            case _approvalStatus.accepted:
                return 'Doctor has been approved'

            case _approvalStatus.rejected:
                return 'Doctor approval has been rejected'

            default:
                return 'Doctor approval is pending'
        }
    }

    return (
        <>
            <Header />
            Back
            <div className="d-container">
                <div className={`doctor-approvel-container doctor-approvel-container-${isProfileCompleted}`}>
                    <div className="approvel-body">
                        {/* <DoneIcon /> */}
                        {/* <img src={alert} alt="" /> */}
                        <div className='approvel-para'>
                            <h4>{whatToRender()}</h4>
                            <p>Click on the joining info link at the time of your appointment and meet the doctor</p>
                        </div>
                    </div>
                    <div className="approvel-btn-container">

                        <Select
                            className="width-full text-capitalize"
                            size={MODAL_INPUT_SIZE}
                            name='approvalStatus'
                            onChange={handleChange}
                            value={isProfileCompleted || ''}
                        >
                            <MenuItem
                                className='text-capitalize'
                                value={0}
                            >
                                ---select---
                            </MenuItem>
                            {
                                Object.keys(approvalStatus).length > 0
                                    ? Object.keys(approvalStatus).map(key => {
                                        return (
                                            <MenuItem
                                                className='text-capitalize'
                                                key={key}
                                                value={key}
                                            >
                                                {approvalStatus[key].UI}
                                            </MenuItem>
                                        );
                                    })
                                    : <NoItemFound />
                            }
                        </Select>
                        {/* <button className='reject-btn'> Reject </button>
                        <button className='approve-btn' onClick={approveDoctor}> Approve </button> */}
                    </div>
                </div>
                {/* 
                {
                    (isProfileCompleted === doctorApprovalStatus['pending']) && <>
                        <div className="doctor-approvel-container">
                            <div className="approvel-body">
                                <img src={alert} alt="" />
                                <div className='approvel-para'>
                                    <h4>Doctor profile approval pending</h4>
                                    <p>Click on the joining info link at the time of your appointment and meet the doctor</p>
                                </div>
                            </div>
                            <div className="approvel-btn-container">
                                <button className='reject-btn'> Reject </button>
                                <button className='approve-btn' onClick={approveDoctor}> Approve </button>
                            </div>
                        </div>
                    </>
                }
                {
                    isProfileCompleted === doctorApprovalStatus['rejected'] && <>Doctor Rejected</>
                } */}
                <div className="doctor-tab-container">
                    <ul>
                        <NavLink
                            className={setActiveInactiveClass}
                            to={''}
                        >
                            <li>
                                Profile
                            </li>
                        </NavLink>

                        <NavLink
                            className={setActiveInactiveClass}
                            to={'appointments'}
                        >
                            <li>
                                Appointment
                            </li>
                        </NavLink>


                        <NavLink
                            className={setActiveInactiveClass}
                            to={'reviews'}
                        >
                            <li>
                                Reviews
                            </li>
                        </NavLink>

                    </ul>
                    <div>
                        <Outlet
                            context={{ isPC }}
                        />
                        {/* <div className="" hidden={tabIndex !== 0}>
                            <DoctorAccount />
                        </div>
                        <div className="" hidden={tabIndex !== 1}>
                            <DoctorAppointment />
                        </div>
                        <div className="" hidden={tabIndex !== 2}>
                            <ReviewShowScreen />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

function NoItemFound() {
    return <MenuItem>No Items</MenuItem>
}

export default ProfileInProgress