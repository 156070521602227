import React, { useState } from "react";
import Header from "../../../layout/header/Header";
import "../couponCodes/coupon.css";
import { useFormik } from "formik";
import { DateRangePicker } from 'rsuite';
import format from "date-fns/format";
import "rsuite/dist/rsuite.css";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { postCaller } from "services/api";
import { toast } from 'react-toastify';
import { responseMessages } from "utils/responseMessages";

import TOAST from 'components/common/toast/Success'
import { getOnlyNumber } from "utils";

const CreateCoupon = () => {
    const [value, setValue] = useState();
    const navigate = useNavigate();

    const createCouponSchema = Yup.object().shape({
        couponTitle: Yup.string()
            .min(2, "Coupon Title is too small")
            .max(10, "Title is too long")
            .required("Title is required"),
        code: Yup.string().required('Code is required'),
        perUser: Yup.number().min(1).required('Per User is required'),
        discount: Yup.number().min(2, "Discount can not be less then 1")
            .max(99, "Discount can not be greater then 99").required('Disscount is required'),

    });


    const formik = useFormik({
        initialValues: {
            couponTitle: "",
            code: "",
            perUser: "",
            description: "",
            status: "",
            discount: ""

        },
        validationSchema: createCouponSchema,
        onSubmit: async (values, { resetForm }) => {

            const [valid_from, valid_to] = calenderValue;

            // values['perUser'] = values['perUser'].toString()
            values['valid_from'] = valid_from;
            values['valid_to'] = valid_to;

            const response = await postCaller('createCoupon', values);

            if (response) {
                toast.success(<TOAST message={'Create Coupon Successfully'} />)
                navigate('/dashboard/configurations/settings-coupon');
            } else {
                toast.info(response?.message || responseMessages.UNKNOWN_ERROR)
            }

            resetForm();
        },
    });

    const [calenderValue, setCalenderValue] = useState([null, null])

    const handleMyChange = ({ target: { value } }) => {
        const onlyNumber = getOnlyNumber(formik.values.perUser, value, 200)
        formik.setFieldValue('perUser', onlyNumber)
    }

    console.log('formik.errors', formik.errors)

    return (
        <>
            <Header />
            <div className="edit-home-container">
                <p> Create Coupon </p>
                <div className="home-wrapper">
                    <form action="" onSubmit={formik.handleSubmit}>
                        <div className="coupon-container">
                            <div className="create-coupon-form">
                                <div className="create-input-content">
                                    <label htmlFor="home"> Coupon Title </label> <br />
                                    <input
                                        type="text"
                                        className="coupon-input-field"
                                        name="couponTitle"
                                        placeholder="Homepage"
                                        onChange={formik.handleChange}
                                        value={formik.values.couponTitle}
                                    />
                                    {formik.touched.couponTitle && formik.errors.couponTitle && (
                                        <span className="error">{formik.errors.couponTitle}</span>
                                    )}
                                </div>
                                <div
                                    className="create-input-content"
                                    style={{ marginLeft: "2rem" }}
                                >
                                    <label htmlFor="home"> Coupon Code </label> <br />
                                    <input
                                        type="text"
                                        className="coupon-generate-field"
                                        name="code"
                                        placeholder="23UFHY46"
                                        onChange={formik.handleChange}
                                        value={formik.values.code}
                                    />
                                    {formik.touched.code && formik.errors.code && (
                                        <span className="error">{formik.errors.code}</span>
                                    )}
                                </div>
                                <div className="create-input-content">
                                    <label htmlFor="home"> Valid From - TO </label> <br />
                                    <DateRangePicker
                                        showOneCalendar
                                        value={value}
                                        onChange={newValue => setCalenderValue(newValue || [null, null])}
                                        name="valid"
                                        format="yyyy-MM-dd"
                                        placeholder="Select Date Range"
                                        placement='auto'
                                        ranges={[]}
                                    />
                                    {formik.touched.valid && formik.errors.valid && (
                                        <span className="error">{formik.errors.valid}</span>
                                    )}

                                </div>
                                <div
                                    className="create-input-content"
                                    style={{ marginLeft: "2rem" }}
                                >
                                    <label htmlFor="home"> Usage Per User </label> <br />
                                    <input
                                        type="text"
                                        className="coupon-input-field"
                                        name="perUser"
                                        placeholder="Enter"
                                        onChange={handleMyChange}
                                        value={formik.values.perUser}
                                    />
                                    {formik.touched.perUser && formik.errors.perUser && (
                                        <span className="error">{formik.errors.perUser}</span>
                                    )}
                                </div>
                            </div>

                            <div className="desc-input-content">
                                <label htmlFor="home"> Description </label> <br />
                                <input
                                    type="text"
                                    className="coupon-input-field"
                                    name="description"
                                    placeholder="Describe Your Bio Here"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                />
                                {formik.touched.description && formik.errors.description && (
                                    <span className="error">{formik.errors.description}</span>
                                )}
                            </div>
                            <div className="create-input-content">
                                <label htmlFor="home"> Status </label> <br />
                                <select name="status" id=""
                                    onChange={formik.handleChange}
                                    value={formik.values.status}
                                >
                                    <option value="0"> choose status </option>
                                    <option > Active </option>
                                    <option > Inactive </option>
                                </select>
                            </div>

                            <div className="create-input-content">
                                <label htmlFor="home"> Discount Percent(%) </label> <br />
                                <input
                                    className="coupon-input-field"
                                    type="number"
                                    name="discount"
                                    id=""
                                    onChange={formik.handleChange}
                                    value={formik.values.discount}
                                />
                                {formik.touched.discount && formik.errors.discount && (
                                    <span className="error">{formik.errors.discount}</span>
                                )}

                            </div>

                        </div>
                        <div className="coupon-btn-content">
                            <button onClick={() => navigate('/dashboard/configurations/settings-coupon')} className="home-cancel">
                                {" "}
                                Cancel
                            </button>
                            <button type="submit" className="home-save">
                                {" "}
                                Save{" "}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CreateCoupon;
