import { getCaller, postCaller, updateCaller } from "services/api"
const LISTING = 'admin/teams/listing';


export const addTeamMember = async formdata => {
    const response = await postCaller('admin/team', formdata, true);
    return (response?.error == false) ? response : false;
}

export const fetchTeamMemberByIdRQ = async id => {
    const response = await getCaller(`admin/team/${id}`);
    return (response?.error == false) ? response : false;
}

export const updateTeamMember = async (formdata, id) => {
    const response = await updateCaller(`admin/team/${id}`, formdata, true);
    return (response?.error == false) ? response : false;
}




export const fetchTeamsListingRQ = async () => {
    const response = await getCaller(LISTING);

    return (response?.error == false) ? response?.data : false;
}