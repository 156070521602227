import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import eye from '../../assets/images/eye.png'
import Typography from '@mui/material/Typography';
import '../modal/modal.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { updateCaller } from 'services/api';
const CreateCouponModal = props => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const queryData = ['1', '2', '3', '4', '5']

    /* Coupon Active or InActive */
    const couponActiveOrInactive = async (id, status) => {
        let data = {}
        if (status === 'Active') {
            data.status = 'Active';
        } else if (status === 'Inactive') {
            data.status = 'Inactive';
        }
        const response = await updateCaller(`updateCoupon/status/${id}`, data);
        console.log(response);
        props.refreshData()
    }

    return (
        <>
            <img src={eye} alt="" onClick={handleOpen} style={{ cursor: "pointer" }} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="query-pop-up">
                            <h4> View Coupon</h4>
                            <span onClick={handleClose}> <FaRegTimesCircle style={{ width: "2rem", height: "1.5rem" }} /> </span>
                        </div>
                        <Typography id="transition-modal-description" className='query-para' >

                            <div className='query-body'>
                                <div className="query-details">
                                    <p>Coupon Title</p>
                                    <h4>{props?.currentCoupon?.couponTitle}</h4>
                                </div>
                                <div className="query-details">
                                    <p>Coupon Code</p>
                                    <h4>{props?.currentCoupon?.code}</h4>
                                </div>
                                <div className="query-details">
                                    <p>Valid From-To</p>
                                    <h4>
                                        {moment(props?.currentCoupon?.valid_from).format("DD MMM YYYY")} - {moment(props?.currentCoupon?.valid_to).format("DD MMM YYYY")}
                                    </h4>
                                </div>
                                <div className="query-details">
                                    <p>Created On</p>
                                    <h4>{moment(props?.currentCoupon?.createdAt).format("MM-DD-YYYY") ?? "N/A"}</h4>
                                </div>
                                <div className="query-details">
                                    <p>Status</p>
                                    <h4 style={{ color: "green", fontWeight: "bold" }} >{props?.currentCoupon?.status}</h4>
                                </div>
                            </div>
                            <div style={{ padding: "1rem" }}>
                                <p>Description</p>
                                <h4>{props?.currentCoupon?.description}</h4>
                            </div>
                        </Typography>
                        <div className="query-btn-content">
                            <button className='home-cancel'>Share</button>
                            <div className="query-mark-btn">
                                <button className='mark-query' onClick={() => couponActiveOrInactive(props?.currentCoupon?.couponId, props?.currentCoupon?.status === "Inactive" ? "Active" : "Inactive")}>
                                    {props?.currentCoupon?.status === "Inactive" ? "Active" : "Inactive"}
                                </button>

                                {/* <button className='mark-query'> Mark as Inactive </button> */}
                                <button className='mark-query' onClick={() => navigate(`/editCoupon/${props?.currentCoupon?.couponId}`)}> Edit Coupon </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>

    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height:450,
    borderRadius: "1rem",
    bgcolor: 'white',

    // boxShadow: 24,
    // paddingTop:"1rem"
};


export default CreateCouponModal;