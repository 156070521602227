import { getCaller, updateCaller } from "../../services/api";

const GET_DOCTOR = 'admin/config/settings/doctor';
const GET_USER = 'admin/config/settings/user';
const UPDATEDOCTOR = id =>`admin/config/settings/${id}`;

export const fetchDoctorSettingsRQ = async () => {
    const response = await getCaller(GET_DOCTOR);
    return (response?.error == false) ? response : false;
}

export const fetchUserSettingsRQ = async () => {
    const response = await getCaller(GET_USER);
    return (response?.error == false) ? response : false;
}

export const updateDoctoreSettingsRQ = async (id, data) => {
    const response = await updateCaller(UPDATEDOCTOR(id), data,false);
    return (response?.error == false) ? response : false;

}

