import { Slide } from "@mui/material"
import React, { useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { Dialog, Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";
import { ThemeButton } from './../../../../components/common/button/Button';
import {updateGeneralFooterRQ,fetchGeneralFooterRQ} from './Calls'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

function LinkDialogBox({
    openLinkDialog,
    setOpenLink,
    setOpenDialog,
    parentData,
}) {
    /* Button State */
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();


    /* Button States */
    const [loader, setLoader] = useState(false);

    const handleClose = () => {
        setOpenLink(false);
        parentData(null);
    }

    
    /* ID Use State */
    // const { id } = useParams();

    const handleLinksSubmit =  async  data => {
        const payload = {content: data}
        setLoader(true);
        const response = await updateGeneralFooterRQ(parentData?.id, payload);
        if (response.errors === false) {
            fetchGeneralFooterRQ()
            setOpenDialog(true);
            setLoader(false);
        } else {
            setLoader(false);
            alert(response.msg)
        }
    }
    useEffect(()=>{
        // setValue('url','');
        reset();
        if(parentData){
            setValue('twitter',parentData?.content?.twitter)
            setValue('linkedin',parentData?.content?.linkedin)
            setValue('facebook',parentData?.content?.facebook)
            setValue('instagram',parentData?.content?.instagram)
        }
    },[parentData])

    return (
        <div>
            <Dialog
                open={openLinkDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <Container>
                    <Header>
                        Edit Links
                        <IconButton onClick={handleClose}>
                            <CloseIcon className='pointer' />
                        </IconButton>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit(handleLinksSubmit)} >
                            <div className="">
                                <div className="">
                                    <div className='home-input-content-full'>
                                        <label htmlFor="home"> Twiter </label> <br />
                                        <input
                                            {...register("twitter", { required: true })}
                                            type="text"
                                            className='home-input-field'
                                            placeholder='Enter your link'
                                        />
                                        <div className={'input-invalid-message'}>
                                            {errors.twitter && 'link is required'}
                                        </div>
                                    </div>
                                    <div className='home-input-content-full'>
                                        <label htmlFor="home"> Linkdin </label> <br />
                                        <input
                                            {...register("linkedin", { required: true })}
                                            type="text"
                                            className='home-input-field'
                                            placeholder='Enter your link'
                                        />
                                        <div className={'input-invalid-message'}>
                                            {errors.linkedin && 'link is required'}
                                        </div>
                                    </div>
                                    <div className='home-input-content-full'>
                                        <label htmlFor="home"> Facebook </label> <br />
                                        <input
                                            {...register("facebook", { required: true })}
                                            type="text"
                                            className='home-input-field'
                                            placeholder='Enter your link'
                                        />
                                        <div className={'input-invalid-message'}>
                                            {errors.facebook && 'link is required'}
                                        </div>
                                    </div>
                                    <div className='home-input-content-full'>
                                        <label htmlFor="home"> Instagarm </label> <br />
                                        <input
                                            {...register("instagram", { required: true })}
                                            type="text"
                                            className='home-input-field'
                                            placeholder='Enter your link'
                                        />
                                        <div className={'input-invalid-message'}>
                                            {errors.instagram && 'link is required'}
                                        </div>
                                    </div>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{
                                            marginTop: '1rem'
                                        }}
                                    >
                                        <Grid item xs={8}></Grid>
                                        <Grid item xs={4}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-end'
                                            }}>
                                            <ThemeButton
                                                style={{
                                                    color: loader ? 'grey' : '#2f77b6'
                                                }}
                                                loading={loader}
                                                text={'Update'}
                                            />

                                        </Grid>
                                    </Grid>

                                    <div className={'input-invalid-message'}>
                                        {errors.name && 'Name is required'}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Body>
                </Container>
            </Dialog>
        </div>
    )

}


const Container = styled("div")`
    padding: 1rem;
    min-width: 400px;
    width: 40vw;
`;

const Header = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Body = styled("div")`
    width: 97%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 0.2rem;
    padding-left: 1rem;
    border-radius: 5px;
    input{
        border: none;
        outline: none;
        border: 1px solid #d2d2d2;
        width: 100%;
        color: var(--text-light);
    }
`;

export default LinkDialogBox;
