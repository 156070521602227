import React from 'react';
import { dateFormatWW } from 'utils';
import '../view.css';
import { dateFormatWW_TIME } from 'utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';




const AppointmentDetails = (props) => {
    
    const {t} = useTranslation()



    const getColor = (status) => {
       switch (status) {
        case "payment_pending":
            return "rgb(67, 175, 233)";
        case "inReview":
            return "rgb(239, 213, 17)";
        case "completed":
            return "rgb(19, 188, 52)";
        case "rescheduled":
            return "rgb(67, 175, 233)";
        case  "cancelled":
            return "rgb(67, 175, 233)";
        default:
            return  "rgb(19, 188, 52)";
       }
    }
    return (
        <>
            <Link to='/dashboard'>

            </Link>
            <div className="report-view-child">
                <div className="report-view-section-parent">
                    <div className="section-parent-header">
                        <h5 className="appointment-heading">Appointment Details</h5>
                    </div>
                    <div className="section-childrens">
                        <div className="section-parent-app-dtails">
                            {t("Appointment ID")}
                            <p className="section-app">{props.View.data?.appointmentId}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                            {t("Appointment Date & Time")}
                            <p className="section-app">{dateFormatWW_TIME(props.View.data?.createdAt)}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                            {t("Booking Date")}
                            <p className="section-app">{dateFormatWW(props.View.data?.slot[0].date)}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                            {t("Payment Amount")}
                            <p className="section-app">{props.View.data?.fees}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                            {t("Payment Method")}
                            <p className="section-app">{props.View.data?.transaction?.type}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                           {t("Appointment Status")}
                            <p className="section-app" style={{ color: getColor(props.View.data?.status), fontWeight: "bold" }} >{props.View.data?.status}</p>
                        </div>
                    </div>
                    <div className="section-parent-childs">

                    </div>
                </div>
            </div>
        </>
    )
}

export default AppointmentDetails;