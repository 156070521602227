import React from 'react'
import { useEffect, useState } from 'react'
import { json, useNavigate, useParams } from 'react-router-dom'
import Header from '../../../layout/header/Header'
import { getCaller, postCaller } from '../../../services/api';
import '../TermsConditions/Sections/TermsCondition.css'
import Section from '../TermsConditions/Sections/Section'
import { ThemeButton } from 'components/common/button/Button';


const ViewTermsConditions = () => {
    const { id } = useParams();
    /* All inputs states */
    const [pageName, setPageName] = useState("");
    const [pageUrl, setPageUrl] = useState("");
    const [title,] = useState("");
    // const [subtile, setSubTile] = useState("");

    const [sectionData, setSectionData] = useState([])
    const [sectionsData, setSectionsData] = useState([{}, {}, {}, {}, {}, {}])
    // console.log('sectionsData===>>>>', sectionsData.length)
    const navigate = useNavigate();

    const handleSaveTerm = async () => {
        const data = {
            id: id,
            page_name: pageName,
            page_url: pageUrl,
            // page_title: title,
            content: sectionsData
        }
        const response = await postCaller('admin/createTermsCondition', data)
        console.log('response', response)
        alert('Terms & Conditions Created Successfully')
        navigate('/dashboard')
    }

    const getTermCondById = async () => {
        const response = await getCaller(`admin/getTermsCondition/${id}`)
        console.log('response', response)
        setPageName(response.page_name)
        setPageUrl(response.page_url)
        setSectionsData(response.content)
        // setTitle(response.page_title)
        // setSubTile(response.content)
    }

    useEffect(() => {
        getTermCondById()
    }, [])
    return (
        <>
            <Header />
            <div className='edit-terms-container'>
                <p> Edit Terms & Condition </p>
                <div className="terms-wrapper">
                    <div className="terms-form">
                        <div className='terms-input-content'>
                            <label htmlFor="terms"> Page Name </label> <br />
                            <input
                                defaultValue={pageName}
                                type="text"
                                className='terms-input-field'
                                placeholder='Enter your Page Name'
                                disabled
                            />
                        </div>
                        <div className='terms-input-content' style={{ marginLeft: "2rem" }}>
                            <label htmlFor="terms"> Page URL </label> <br />
                            <input type="text"
                                defaultValue={pageUrl}
                                className='terms-input-field'
                                placeholder='https://varclinic.com/terms'
                                disabled
                            />
                        </div>
                    </div>
                    {
                        Array(6).fill(0).map((nodata, index) => {
                            return (
                                <Section
                                    sectionsData={sectionsData}
                                    setSectionsData={setSectionsData}
                                    key={index}
                                    index={index}
                                    sectionData={sectionData}
                                    setSectionData={setSectionData}
                                    disable={true}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className="terms-mark">
                    {/* <button className='terms-preview'> Preview </button> */}
                    <ThemeButton
                        text='Back'
                        className='terms-save'
                        onClick={() => navigate('/dashboard/webiste-contents')}
                    />
                </div>
            {/* <div className="terms-btn-content">
                <button onClick={() => navigate('/dashboard/webiste-contents')}> Back </button>

            </div> */}
        </>
    )
}

export default ViewTermsConditions