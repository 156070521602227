import { Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import '../../TermsConditions/Edit';
export const Section = ({
    index,
    sectionsData,
    setSectionsData,
    disable
}) => {
    console.log("🚀 ~ file: Section.jsx:93 ~ sectionsData", sectionsData)

    const {t} =useTranslation()
    
    return (
        <>
            <div className="terms-section-one">
                <Typography>
                    Section {index + 1}
                </Typography>
                <div className='section-one-input'>
                    <label htmlFor="terms">{t("Title (EN)")}</label> <br />
                    <input type="text"
                        value={sectionsData[index]?.title?.en}
                        className='terms-input-field'
                        placeholder='Title in EN'
                        disabled={disable}
                        onChange={(e) => {
                            let temp = sectionsData;
                            if (temp[index]) {
                                temp[index].title = {
                                    'en': e.target.value,
                                    'ar': temp[index].title?.ar
                                }
                            }
                            setSectionsData([...temp])
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="terms">{t("Title (AR)")}</label> <br />
                    <input type="text"
                        value={sectionsData[index]?.title?.ar}
                        className='terms-input-field'
                        placeholder='Title in AR'
                        disabled={disable}
                        onChange={(e) => {
                            let temp = sectionsData;
                            if (temp[index]) {
                                temp[index].title = {
                                    'ar': e.target.value,
                                    'en': temp[index].title?.en
                                }
                            }
                            setSectionsData([...temp])
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="terms"> SubTitle (EN)</label> <br />
                    <textarea type="text"
                        value={sectionsData[index]?.content?.en}
                        className='terms-input-field home-input-field-textarea'
                        placeholder='Sub-Title in EN'
                        disabled={disable}
                        onChange={(e) => {
                            const temp = sectionsData;
                            temp[index].content = {
                                'en': e.target.value,
                                'ar': sectionsData[index].content?.ar
                            }
                            setSectionsData([...sectionsData])
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="terms"> SubTitle (AR)</label> <br />
                    <textarea type="text"
                        value={sectionsData[index]?.content?.ar}
                        className='terms-input-field home-input-field-textarea'
                        placeholder='Sub-Title in AR'
                        disabled={disable}
                        onChange={({ target: { value } }) => {
                            const temp = sectionsData;
                            temp[index].content = {
                                'ar': value,
                                'en': sectionsData[index].content?.en
                            }
                            setSectionsData([...sectionsData])
                        }}
                    />
                </div>
            </div>
        </>
    )
}
export default Section

