import React, { useEffect, useState } from 'react'
import { getCaller } from '../../../services/api'
import UserInreviewAppointments from './UserInreviewAppointments'
import UserPastAppointments from './UserPastAppointments'
import UserUpcomingAppointments from './UserUpcomingAppointments'

const UserAppointment = ({
    upcomingAppointments,
    inReviewAppointments,
    pastAppointments,
    setPastAppointment,
    setUpcomingAppointments,
    setinReviewAppointments
}) => {

    let aptData = {
        upcomingApt: upcomingAppointments,
        reviewApt: inReviewAppointments,
        pastApt: pastAppointments
    }
    return (
        <>
            <div className="user-appointment-container">
                <UserUpcomingAppointments />
                <UserInreviewAppointments data={aptData} />
            </div>
            <div>
                <UserPastAppointments />
            </div>
        </>
    )
}

export default UserAppointment