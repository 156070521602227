import React, { useEffect, useState } from 'react'
import '../../../websitecontent/homePage/edithome.css';
import Header from '../../../../layout/header/Header'
import '../HomePage'
import { checkAvailability } from '../../../../utils';
import { postCaller } from '../../../../services/api';
import { MdImage } from 'react-icons/md';
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { URLS } from 'utils/constants';

const INITIAL__LANGUAGE = { en: '', ar: '' };

const SectionSix = ({
    sectionSevenData,
    setSectionSevenData,
    disabled
}) => {
    const [title, setTitle] = useState(INITIAL__LANGUAGE);
    const [subtitle, setSubtitle] = useState(INITIAL__LANGUAGE);
    const [image, setImage] = useState("");

    const { t } = useTranslation()

    const uploadAndDelete = async (file, old_image_url) => {
        /* Generating FormData for background image*/
        const formData = new FormData();
        formData.append('file', file);
        formData.append('old_file', old_image_url);

        /* Passing form to API */
        const response = await postCaller('homepage/uploads', formData, true);
        if (response.error == false) {
            return response?.data?.Key;
        }
        return false;
    }


    const handleImage = async (e) => {
        const current = e.target;
        try {
            if (current.files && current.files.length !== 0) {
                let file = current.files[0];
                // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
                if (!checkAvailability(file.type)) { return alert("Unsupported File") }

                const newKey = await uploadAndDelete(file, image);
                setSectionSevenData(prev => {
                    return {
                        ...prev,
                        image: newKey
                    }
                })
                setImage(newKey)
            }
        } catch (e) {
            return false;
        }
    }

    const handleParentStates = e => {
        setSectionSevenData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        setTitle(sectionSevenData?.title)
        setSubtitle(sectionSevenData?.subtitle)
        setImage(sectionSevenData?.image)
    }, [sectionSevenData])

    return (
        <>
            <Header />
            {/* section 6 */}
            <div className="home-section-one">
                <p> Section 7 </p>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (EN)")}</label> <br />
                    <input
                        type="text"
                        disabled={disabled}
                        name='title'
                        value={title?.en}
                        className='home-input-field'
                        placeholder='Title (en)'
                        onChange={e => {
                            const temp = sectionSevenData;
                            temp.title = {
                                'en': e.target.value,
                                'ar': temp?.title?.ar
                            };
                            setSectionSevenData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (AR)")}</label> <br />
                    <input
                        disabled={disabled}
                        type="text"
                        name='title'
                        value={title?.ar}
                        className='home-input-field'
                        placeholder='Title (ar)'
                        onChange={e => {
                            const temp = sectionSevenData;
                            temp.title = {
                                'ar': e.target.value,
                                'en': temp?.title?.en
                            };
                            setSectionSevenData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (EN) </label> <br />
                    <input
                        disabled={disabled}
                        type="text"
                        name='subtitle'
                        value={subtitle?.en}
                        className='home-input-field'
                        placeholder='Sub-Title (en)'
                        onChange={e => {
                            const temp = sectionSevenData;
                            temp.subtitle = {
                                'en': e.target.value,
                                'ar': temp?.subtitle?.ar
                            };
                            setSectionSevenData({ ...temp })
                        }}
                    />
                </div>

                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (AR)</label> <br />
                    <input
                        disabled={disabled}
                        type="text"
                        name='subtitle'
                        value={subtitle?.ar}
                        className='home-input-field'
                        placeholder='Sub-Title (ar)'
                        onChange={e => {
                            const temp = sectionSevenData;
                            temp.subtitle = {
                                'ar': e.target.value,
                                'en': temp?.subtitle?.en
                            };
                            setSectionSevenData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-bg-input'>
                    <label htmlFor="home"> Image </label> <br />
                    <Grid container spacing={2} className="my-5 align-items-center">
                        <Grid item xs={8}>
                            <div>
                                <input
                                    disabled={disabled}
                                    type="file"
                                    className='home-input-field-file'
                                    placeholder='Upload'
                                    onChange={handleImage}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            {

                                !(image) ?
                                    <></>
                                    : <>
                                        <MdImage className="delete-icon"
                                            onClick={() => window.open(`${URLS.API_BASE_URL}/common/awsObject?key=${image}`)
                                            }
                                        />
                                    </>
                            }
                        </Grid>
                    </Grid>

                </div>
            </div>

        </>
    )
}

export default SectionSix