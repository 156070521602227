import React, { useState } from 'react'
import '../invoice/invoice.css'
import { FaAngleRight } from 'react-icons/fa'
import { DateRangePicker } from 'rsuite'
import { getCaller } from 'services/api'
import { format } from 'date-fns';
import { URLS } from 'utils/constants'
import { useTranslation } from 'react-i18next'

const GenerateInvoice = () => {
    /*Date Range State  */

    const {t} = useTranslation()

    const [value, setValue] = useState([null, null]);
    console.log("🚀 ~ file: GenerateInvoice.jsx:12 ~ GenerateInvoice ~ value", value)

    const handleDatePicker = event => setValue(event || [null, null])

    const submitDateRange = async () => {
        const fromDate = format(value[0], 'yyyy-MM-dd')
        const toDate = format(value[1], 'yyyy-MM-dd')
        const url = `${URLS.API_BASE_URL}/admin/dashboard/doctorProfileDataExport?appointmentDateFrom=${fromDate}&appointmentDateTo=${toDate}`;
        window.open(url, '_blank');
    }

    return (
        <>
            <div className='border'>
                <div className='invoice-body'>
                    <p>{t("Generate invoice of all the doctors")}</p>
                    <div className='invoice-input-container'>
                        <label htmlFor="home"> {t("Select Date Range")}  </label> <br />
                        <DateRangePicker
                            value={value}
                            showOneCalendar // to show only one calender
                            placement='auto'
                            name='Date Range'
                            format='yyyy-MM-dd'
                            onChange={handleDatePicker}
                            placeholder='DD/MM/YY — DD/MM/YY'
                        />
                    </div>
                    <button
                        className='theme-button'
                        style={{ marginTop: "1rem", width: "100%", color: 'white' }}
                        onClick={submitDateRange}
                        disabled={value[0] === null || value[1] === null}
                    >
                        {t("Download")} <FaAngleRight />
                    </button>
                </div>
            </div>
        </>
    )
}

export default GenerateInvoice