import { Backdrop, Box, Fade, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import "rsuite/dist/rsuite.css";
import { useState } from 'react';
import { useFormik } from 'formik';
import React from 'react'
import { dateFormat } from 'utils/index';
import { DateRangePicker } from 'rsuite';
import useMasters from 'hooks/useMasters';
import { FaRegTimesCircle } from 'react-icons/fa';
import * as Yup from 'yup';


const MODAL_INPUT_SIZE = 'small';
const FilterModal = ({
    open,
    setQuery,
    refreshData,
    handleClose,
}) => {
    const {
        reviews,
        reviewsRange,
    } = useMasters();


    const filterSchema = Yup.object().shape({
        givenBy: Yup.string(),
        givenTo: Yup.string(),
        rating: Yup.number(),
        message: Yup.string(),
        status: Yup.string(),
    })

    const formikIntialValue = {
        givenBy: '',
        givenTo: '',
        rating: '',
        message: '',
        status: ''
    }

    const formik = useFormik({
        initialValues: formikIntialValue,
        validationSchema:filterSchema,
        onSubmit: data => {
            const [from, to] = calenderValue;
            const _data = {
                ...data,
                dateGivenFrom: from && dateFormat(from),
                dateGivenTo: to && dateFormat(to)
            }
            setQuery(_data)
            refreshData(_data)
            handleClose();
        }

    })
    const { errors } = formik;

    const [calenderValue, setCalenderValue] = useState([null, null])

    const handleRestFormAndClose = () => {
        formik.resetForm();
        setCalenderValue([null, null])
        refreshData({ page: 1 })
        handleClose();
    }


    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="query-pop-up">
                                <h4> Filter Doctors </h4>
                                <span onClick={handleClose}>
                                    <FaRegTimesCircle
                                        style={{
                                            width: "2rem",
                                            height: "1.5rem"
                                        }}
                                    />
                                </span>
                            </div>

                            {/* Content */}
                            <Typography id="transition-modal-description" className='query-para' >
                                <div className='query-body'>
                                    <div className="query-details">
                                        <p>Given By</p>
                                        <TextField
                                            type={'text'}
                                            value={formik.values.givenBy}
                                            className="width-full"
                                            size='small'
                                            name='givenBy'
                                            onChange={formik.handleChange}
                                            error = {errors?.givenBy}
                                            helperText = {errors?.givenBy && ""}
                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Date Given</p>
                                        <DateRangePicker
                                            ranges={[]}
                                            className="width-full text-capitalize"
                                            showOneCalendar // to show only one calender
                                            value={calenderValue}
                                            onChange={newValue => setCalenderValue(newValue || [null, null])}
                                            name="valid"
                                            format="dd-MM-yyyy"
                                            placeholder="Select Date Range"
                                            placement='auto'
                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Given To</p>
                                        <TextField
                                            type={'text'}
                                            className="width-full"
                                            size='small'
                                            value={formik.values.givenTo}
                                            onChange={formik.handleChange}
                                            name="givenTo"
                                            error = {errors?.givenTo}
                                            helperText = {errors?.givenTo && ""}

                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Rating</p>
                                        <Select
                                            className="width-full text-capitalize"
                                            size={MODAL_INPUT_SIZE}
                                            name='rating'
                                            value={formik.values.rating}
                                            onChange={formik.handleChange}
                                            error = {errors?.rating}
                                            helperText = {errors?.rating && ""}
                                        >
                                            {
                                                Object.keys(reviewsRange).length > 0
                                                    ? Object.keys(reviewsRange).map(key => {
                                                        return (
                                                            <MenuItem
                                                                className='text-capitalize'
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {reviewsRange[key].UI}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    : <NoItemFound />
                                            }
                                        </Select>
                                    </div>

                                    <div className="query-details">
                                        <p>Review Content</p>
                                        <TextField
                                            type={'text'}
                                            className="width-full"
                                            size='small'
                                            name='reviewContent'
                                            value={formik.values.reviewContent}
                                            onChange={formik.handleChange}
                                            error = {errors?.reviewContent}
                                            helperText = {errors?.reviewContent && ""}

                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Status</p>
                                        <Select
                                            className="width-full text-capitalize"
                                            size={MODAL_INPUT_SIZE}
                                            name='status'
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                            error = {errors?.status}
                                            helperText = {errors?.status && ""}
                                        >
                                            {
                                                Object.keys(reviews).length > 0
                                                    ? Object.keys(reviews).map(key => {
                                                        return (
                                                            <MenuItem
                                                                className='text-capitalize'
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {reviews[key].UI}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    : <NoItemFound />
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </Typography>

                            {/*  */}
                            <div className="query-btn-content">
                                <div className="query-mark-btn">
                                    <button
                                        className='mark-query text-white'
                                        // onClick={handleFilter}
                                        type='submit'
                                        style={{
                                            backgroundColor: '#2f77b6'
                                        }}
                                    >
                                        Filter
                                    </button>

                                    <div
                                        className='mark-query text-white'
                                        style={{
                                            backgroundColor: 'var(--steel-blue)'
                                        }}
                                        onClick={handleRestFormAndClose}
                                    >
                                        Reset
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

function NoItemFound() {
    return <MenuItem>No Items</MenuItem>
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height:450,
    borderRadius: "1rem",
    bgcolor: 'white',

    // boxShadow: 24,
    // paddingTop:"1rem"
};


export default FilterModal