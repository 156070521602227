import './downloadInvoice.css'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { getCaller } from 'services/api';
import { BsDownload } from 'react-icons/bs'
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import AptTable from 'pages/appointment/AptTable';
import Header from '../../../layout/header/Header'
import { appointmentStatus, URLS } from 'utils/constants';
import React, { useEffect, useRef, useState } from 'react'
import Pagination from 'components/pagination/Pagination';
import { useLocation, useParams } from 'react-router-dom';
import { ThemeButton } from 'components/common/button/Button';
import { dateFormat, formatTimeSlot, generateQuery } from 'utils';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TableBody, TableContainer, TableHead, Stack } from '@mui/material';
import FilterModal from './FilterModal';
import { DateRangePicker } from 'rsuite';
import useGlobal from 'hooks/useGlobal';

// import TableBody from '@mui/material/TableBody';
// import TableHead from '@mui/material/TableHead';
// import { useNavigate } from 'react-router-dom';
// import TableContainer from '@mui/material/TableContainer';
// import Pagination from '../../../components/pagination/Pagination'
// import FilterInput from '../../../components/common/filterfield/FilterInput';


const sizes = {
    "sm": 200,
    "xl": 500,
    "xxl": 600
}


const InvoiceDownloadScreen = () => {
    const { doctorId } = useParams();
    const {
        state: {
            totalAppointments,
            totalEarnings,
            name
        }
    } = useLocation();

    const [appointments, setAppointment] = useState([])
    const [calenderValue, setCalenderValue] = useState([null, null])
    const { tKey: [tKey] } = useGlobal();


    const { t } = useTranslation();

    const [pagina, setPagina] = useState(1);
    const [porPagina] = useState(5);
    const searchTextFieldRef = useRef(null);
    const [modal, setModal] = useState(false)
    const [query, setQuery] = useState({
        status: appointmentStatus['Completed'],
        doctorId: doctorId
    })


    const maximo = appointments?.count / porPagina;


    const appointmentsData = async payload => {
        /* force fully setting status to completed and doctor id  */
        payload.status = appointmentStatus['Completed']
        payload.doctorId = doctorId;
        let URL = `admin/dashboard/appointments?`;
        URL = generateQuery(URL, payload)
        const response = await getCaller(URL)
        setAppointment(response?.data?.allAppointments || {})
    }



    const formateTimeSlotAndDate = e => {
        if (Array.isArray(e.slot)) {
            const [_slot] = e.slot;
            const { start, end } = _slot.time;
            return `${dateFormat(e.slotDate)}, ${formatTimeSlot(start)} - ${formatTimeSlot(end)}`
        } else {
            return "n/a"
        }
    }

    const nextData = async page => {
        const payload = {
            ...query,
            page
        }
        appointmentsData(payload)
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        setQuery(_prev => ({ ..._prev, searchText: value }))
        appointmentsData({ page: 1, searchText: value })
    }


    /* one liners */
    const handleModalOpen = () => setModal(true)
    const handleModalClose = () => setModal(false)


    const handleDownloadWithCurrentQuery = () => {
        const _query = generateQuery("", query)
        const url = `${URLS.API_BASE_URL}/admin/dashboard/doctorProfileDataExport?${_query}`;
        window.open(url, '_blank');
    }

    const handleCalender = newValue => {
        const [from, to] = newValue;
        setQuery(_prev => ({
            ..._prev,
            appointmentDateFrom: from && dateFormat(from),
            appointmentDateTo: to && dateFormat(to)
        }))

        setCalenderValue(newValue || [null, null])
    }

    const handleTableFromCalender = () => {
        appointmentsData(query)
    }

    const generateDownloadableQuery = () => {
        const _query = generateQuery("", query)
        return `${URLS.API_BASE_URL}/admin/dashboard/doctorProfileDataExport?${window.encodeURIComponent(_query)}`;
    }

    const handleShareEmail = () => {
        let url = `https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto`;

        const payload = {
            su: 'VAR+Clinic+Application',
            to: 'sagargupta@staqo.com',
            body: `Auto-generated+link++${generateDownloadableQuery()}`,
        }
        url = generateQuery(url, payload);
        window.open(url, "samewindow", "toolbar=no, scrollbars=no, resizable=no");
    }

    /* when ever page changes */
    useEffect(() => {
        nextData(pagina)
    }, [pagina])


    useEffect(() => {
        appointmentsData({})
    }, [])

    return (
        <>
            <Header />
            <div className="invoice-download-container">
                <div className="earn-body-content">
                    <div className="earn-head">
                        <p>Earning By Dr. {name}</p>
                        <div className="earn-btn-container">
                            <button
                                onClick={handleShareEmail}
                                className='home-cancel'
                            > Share via email </button>
                            <ThemeButton
                                className='home-save'
                                sx={{ width: '40%' }}
                                text='Download'
                                onClick={handleDownloadWithCurrentQuery}
                            />
                            {/* <BsDownload /> */}
                            {/* <button className='home-save' >
                                Download
                            </button> */}
                        </div>
                    </div>
                    <div className="total-earning-content">
                        <div className="total-date-select">
                            <label htmlFor=""> Select Date Range </label> <br />
                            <Stack direction='row' spacing={3}>
                                <DateRangePicker
                                    ranges={[]}
                                    className="width-full text-capitalize"
                                    showOneCalendar // to show only one calender
                                    value={calenderValue}
                                    onChange={handleCalender}
                                    name="valid"
                                    format="dd-MM-yyyy"
                                    placeholder="Select Date Range"
                                    placement='auto'
                                />
                                <button
                                    style={{
                                        color: 'white',
                                        borderRadius: '1rem',
                                        width: '5rem'
                                    }}
                                    onClick={handleTableFromCalender}
                                >Go</button>
                            </Stack>
                        </div>

                        <div className="total-earn-body">
                            <div className='total-earn-card' >
                                <p> Total Appointments </p>
                                <h3> {totalAppointments || 0} </h3>
                            </div>
                            <div >
                                <p> Total Earnings(SAR) </p>
                                <h3 style={{ color: "#13BC34" }}> {totalEarnings || 0} </h3>
                            </div>
                        </div>
                    </div>

                    <>
                        <FilterModal
                            open={modal}
                            handleClose={handleModalClose}
                            refreshData={appointmentsData}
                            setQuery={setQuery}
                        />
                        <div>
                            <div className="user-past">
                                <h4> Past Appointments </h4>
                            </div>
                            <div className="filter-field">
                                <p> {appointments?.count} Items </p>
                                <div className="filter-search">
                                    <div className="filter-search-field">
                                        <input
                                            type="text"
                                            id="search-box"
                                            placeholder="Search "
                                            ref={searchTextFieldRef}
                                        />
                                        <label
                                            htmlFor="search-box"
                                            className="fas fa-search"
                                            onClick={handleTextFieldButton}
                                        ></label>
                                    </div>
                                    <div className="filter-button">
                                        <button onClick={handleModalOpen}> Filter </button>
                                    </div>
                                </div>
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700, minHeight: sizes['sm'] }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell> Appointment ID </StyledTableCell>
                                            <StyledTableCell> Appointment Date & Time </StyledTableCell>
                                            <StyledTableCell> Specialisation Chosen </StyledTableCell>
                                            <StyledTableCell> Patient Name </StyledTableCell>
                                            <StyledTableCell> Gender, Age </StyledTableCell>
                                            {/* <StyledTableCell>
                                                Status <img onClick={filterSortOrder} src={sortarrow} alt="" />
                                            </StyledTableCell> */}
                                            <StyledTableCell> Amount Paid </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            appointments && appointments.rows && appointments.rows.map((e, i) => {
                                                return (
                                                    <StyledTableRow key={`APT_Table_${i}`} >
                                                        <StyledTableCell> {e?.appointmentId ?? "n/a"} </StyledTableCell>
                                                        <StyledTableCell> {formateTimeSlotAndDate(e)} </StyledTableCell>
                                                        <StyledTableCell> {e?.service?.service_master?.name?.[tKey] ?? "n/a"} </StyledTableCell>
                                                        <StyledTableCell> {e?.patientName ?? "n/a"} </StyledTableCell>
                                                        <StyledTableCell> {e?.gender ?? "n/a"}, {e?.age ?? "n/a"} </StyledTableCell>
                                                        <StyledTableCell> {e?.transaction?.amount ?? "n/a"} </StyledTableCell>
                                                        {/* <StyledTableCell style={{ color: e?.status === 'inReview' ? '#EFD511' : e?.status === 'Completed' ? '#13BC34' : "#43AFE9" }} > <p style={{ fontWeight: "bold" }} >{e?.status}</p> </StyledTableCell> */}
                                                        {/* <StyledTableCell className='table-edit-one' >
                                                            <OptionsToShow data={e} />
                                                        </StyledTableCell> */}
                                                    </StyledTableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: "top",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        height: '50px',
        verticalAlign: "top",
        userSelect: "none"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


export default InvoiceDownloadScreen