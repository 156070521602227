import React, { useState, useEffect } from 'react'
import Header from '../../../layout/header/Header';
import '../homePage/edithome.css'

import SectionOne from '../homePage/Sections/SectionOne'
import SectionTwo from '../homePage/Sections/SectionTwo'
import SectionSix from '../homePage/Sections/SectionSix'
import SectionSeven from '../homePage/Sections/SectionSeven'
import SectionFive from '../homePage/Sections/SectionFive'
import SectionFour from '../homePage/Sections/SectionFour'
import { useLocation, useNavigate, useParams, } from 'react-router-dom'
import SectionThree from '../homePage/Sections/SectionThree'
import { getCaller, updateCaller } from '../../../services/api';
import { ThemeButton, UnstyledButton } from '../../../components/common/button/Button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const INITIAL__LANGUAGE = { en: '', ar: '' };


const HomePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state: { disabled } } = useLocation();

    const { t } = useTranslation()

    const [pageName, setPageName] = useState(INITIAL__LANGUAGE);
    const [pageUrl, setPageUrl] = useState("");
    const [section1, setSection1] = useState();
    const [section2, setSectionTwo] = useState();
    const [section3, setSectionThree] = useState();
    const [section4, setSectionFour] = useState();
    const [section5, setSectionFive] = useState();
    const [section6, setSectionSix] = useState();
    const [section7, setSectionSeven] = useState();

    const [loader, setLoader] = useState(false);
    const [disable, setDisable] = useState(false);




    const handleSaveHome = async () => {
        setLoader(true);
        try {
            const data = {
                page_name: pageName,
                page_url: pageUrl,
                page_type: "HOMEPAGE",
                content: {
                    'section-1': section1,
                    'section-2': section2,
                    'section-3': section3,
                    'section-4': section4,
                    'section-5': section5,
                    'section-6': section6,
                    'section-7': section7,
                }
            }
            const response = await updateCaller(
                `admin/website-content/${id}`,
                data
            )
            if (response?.error === false) {
                toast.success(response?.msg)
                navigate('/dashboard/webiste-contents')
            } else {
                toast.info(response?.msg)
            }
        } catch (error) {
            console.error(error.message);
        }
        setLoader(false);
    }

    const getHomePageId = async () => {
        const _response = await getCaller(`admin/website-content/${id}`)

        if (_response?.error === false) {
            const {
                page_name,
                page_url,
                content
            } = _response.data;

            page_name && setPageName(page_name)
            page_url && setPageUrl(page_url)

            if (content) {
                setSection1(content['section-1']);
                setSectionTwo(content['section-2']);
                setSectionThree(content['section-3']);
                setSectionFour(content['section-4']);
                setSectionFive(content['section-5']);
                setSectionSix(content['section-6']);
                setSectionSeven(content['section-7']);
            } /* no else condiiton because, handling from initial state value */
        }
    }

    useEffect(() => {
        getHomePageId();
        return () => {
            setLoader(false)
        }
    }, [])

    return (
        <>
            <Header />
            <div className='edit-home-container'>
                <p> Edit Home Page </p>
                <div className="home-wrapper">
                    <div>
                        <div className="home-form">
                            <div className='home-input-content'>
                                <label htmlFor="home"> {t("Page Name")} </label> <br />
                                <input
                                    value={pageName?.en}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Homepage'
                                    disabled
                                    onChange={({ target: { value } }) => {
                                        setPageName(_prev => ({
                                            ..._prev,
                                            en: value
                                        }))
                                    }}
                                />
                            </div>
                            <div className='home-input-content' style={{ marginLeft: "2rem" }}>
                                <label htmlFor="home"> {t("Page URL")} </label> <br />
                                <input
                                    value={pageUrl}
                                    type="text"
                                    className='home-input-field'
                                    disabled
                                    placeholder='https://varclinic.com/home'
                                    onChange={(e) => {
                                        setPageUrl(e.target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <SectionOne
                            sectionOneData={section1}
                            setSectionOneData={setSection1}
                            disabled={disabled}
                        />
                        <SectionTwo
                            sectionTwoData={section2}
                            setSectionTwoData={setSectionTwo}
                            disabled={disabled}
                        />
                        <SectionThree
                            sectionThreeData={section3}
                            setSectionThreeData={setSectionThree}
                            disabled={disabled}
                        />
                        <SectionFour
                            sectionFourData={section4}
                            setSectionFourData={setSectionFour}
                            disabled={disabled}
                        />
                        <SectionFive
                            sectionFiveData={section5}
                            setSectionFiveData={setSectionFive}
                            disabled={disabled}
                        />
                        <SectionSix
                            sectionSixData={section6}
                            setSectionSixData={setSectionSix}
                            disabled={disabled}
                        />
                        <SectionSeven
                            sectionSevenData={section7}
                            setSectionSevenData={setSectionSeven}
                            disabled={disabled}
                        />
                        {/* button container  */}
                    </div>
                </div>
            </div>
            <div className="home-btn-content">
                <UnstyledButton
                    sx={{
                        width: '10rem'
                    }}
                    text="Back"
                    onClick={() => navigate('/dashboard/webiste-contents')}
                />
                {
                    !disabled
                    && <div className="home-mark">
                        <div
                            className='theme-button home-save'
                            style={{
                                color: disable ? 'black' : 'white'
                            }}
                        >
                            <ThemeButton
                                text="Save"
                                loading={loader}
                                onClick={handleSaveHome}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default HomePage