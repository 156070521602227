import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import './userAppointment.css'
import arrowleft from "../../../assets/images/Path -33.png";
import arrowright from "../../../assets/images/Path -34.png";
import { useTranslation } from "react-i18next";

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { dateFormatWW, destructureSlot, formatFromToTimeSlot, formatTime, showDob } from '../../../utils';
import { appointmentStatus, constants } from 'utils/constants';
import { getCaller } from 'services/api';

const UserUpcomingAppointments = (props) => {
    const { three_dots } = constants;
    const [appointments, setAppointments] = useState("")
    const { patientId } = useParams();

    const appointmentsData = async payload => {
        const {
            page
        } = payload;

        let URL = `admin/users/patient/appointments/${appointmentStatus.Upcoming}/${patientId}?`;

        if (page) {
            URL += 'page=' + page;
        }
        const res = await getCaller(URL)

        setAppointments(res?.data || [])
    }


    useEffect(() => {
        appointmentsData({})
    }, [])


    const [sliderRef, setSliderRef] = useState(null)

    const { t } = useTranslation();
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },

        ]
    };
    return (
        <div className="user-app-content">
            {/* upcoming Card */}
            <div className="user-upcoming">
                <h5>
                    {t("upcomingAppointments")} <span> {appointments?.count} items </span>
                </h5>
                <div className="appointment-arrow">
                    <img
                        src={arrowleft}
                        alt=""
                        style={{ marginRight: "1rem", cursor: "pointer" }}
                        onClick={sliderRef?.slickPrev}
                    />
                    <img src={arrowright} alt="" style={{ cursor: "pointer" }} onClick={sliderRef?.slickNext} />
                </div>
            </div>
            <Slider ref={setSliderRef} {...sliderSettings}>
                {
                    Array.isArray(appointments?.rows)
                        && appointments?.rows?.length > 0
                        ? appointments?.rows?.map((e, i) => {
                            const { start, end, date } = destructureSlot(e)?.slot;

                            return (
                                <div className="user-up-content">
                                    <div className="user-up-body">
                                        <button className="user-upcoming-btn">
                                            {e?.status.toLowerCase() ?? three_dots}
                                        </button>
                                        <div className="user-app-id">
                                            <div className="user-app-body">
                                                <p> {t("appointmentID")} </p>
                                                <h4> {e?.appointmentId ?? three_dots} </h4>
                                            </div>
                                            <div className="user-app-body">
                                                <p> {t("appointmentTime")} </p>
                                                <h4>
                                                    {dateFormatWW(date)} {formatFromToTimeSlot(start, end, 'to', false) || three_dots}
                                                </h4>
                                            </div>
                                            <div className="user-app-body">
                                                <p> {t("doctorName")} </p>
                                                <h4> {e?.doctor_profile?.name ?? three_dots} </h4>
                                            </div>
                                            <div className="user-app-body">
                                                <p> {t("specialtyChosen")} </p>
                                                <h4> {e?.serviceName ?? three_dots} </h4>
                                            </div>
                                            <div className="user-app-body">
                                                <p> {t("patientName")} </p>
                                                <h4> {e?.patientName ?? three_dots} </h4>
                                            </div>
                                            <div className="user-gender">
                                                <div>
                                                    <p> {t("gender")} </p>
                                                    <h4> {e?.gender ?? three_dots} </h4>
                                                </div>
                                                <div className="user-age">
                                                    <p> {t("age")}</p>
                                                    <h4> {e?.age ?? three_dots} </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-up-btn">
                                            <div className="user-attend">
                                                <div
                                                    to="#"
                                                    style={{ color: "#2F77B6", cursor: "pointer" }}

                                                >
                                                    {t("attend")}
                                                </div>
                                            </div>
                                            <div className="user-cancel"></div>
                                            <div className="user-attend">
                                                <Link to="#" style={{ color: "#C4C1C1" }} >
                                                    {t("cancel")}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <>
                            <div className='no-upcoming-content'>
                                <div>
                                    No Appointments Found
                                </div>
                            </div>
                        </>
                }




            </Slider>
        </div>
    )
}

export default UserUpcomingAppointments