import React from 'react';
import { Grid } from "@mui/material";
import { MdDelete, MdImage } from "react-icons/md";
import { checkAvailability, uploadAndDelete } from "../../../../utils";
import { URLS } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const Two = ({ register, errors, setValue, getValues, getFieldState, setDisable, disabled }) => {
    const INITIAL_VALUE = [{}, {}, {}, {}, {}, {}, {}, {}]

    const [sectionTwoContent, setSectionTwoContent] = React.useState(INITIAL_VALUE);

    const { t } = useTranslation()


    const handleImages = async (e, index) => {
        let _temp = sectionTwoContent;
        const current = e.target;
        if (current.files && current.files.length !== 0) {
            let file = current.files[0];
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
            if (!checkAvailability(file.type)) {
                current.value = "";
                return alert("Unsupported File")
            }
            setDisable(true)
            const newKey = await uploadAndDelete(file, _temp[index].image);
            _temp[index].image = newKey;
            setValue(`two_content_image_hidden-${index}`, newKey);
            setSectionTwoContent([..._temp || []])
            setDisable(false)
        }
    };

    return (
        <>
            <div className="home-section-one">
                <p> Section 2 </p>
                <div className='section-one-input'>
                    <label htmlFor="home"> Title (EN) </label> <br />
                    <input
                        disabled={disabled}
                        {...register("two_title.en", { required: true })}
                        type="text"
                        className='home-input-field'
                        placeholder='Enter Your Title'
                    />
                    <div className={'input-invalid-message'}>
                        {errors.two_title?.en && 'English Title is required'}
                    </div>
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (AR)")}</label> <br />
                    <input
                        disabled={disabled}
                        {...register("two_title.ar", { required: true })}
                        type="text"
                        className='home-input-field'
                        placeholder='Enter Your Title'
                    />
                    <div className={'input-invalid-message'}>
                        {errors.two_title?.ar && 'Arabic Title is required'}
                    </div>
                </div>

                {/* Sub Title */}
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Sub-Title (EN)")}</label> <br />
                    <textarea
                        disabled={disabled}
                        {...register("two_subtitle.en", { required: true })}
                        type="text"
                        className='home-input-field home-input-field-textarea'
                        placeholder='Enter Your Subtitle'

                    />
                    <div className={'input-invalid-message'}>
                        {errors.two_subtitle?.en && 'English Sub-Title is required'}
                    </div>
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Sub-Title (AR)")} </label> <br />
                    <textarea
                        disabled={disabled}
                        {...register("two_subtitle.ar", { required: true })}
                        type="text"
                        className='home-input-field home-input-field-textarea'
                        placeholder='Enter Your Subtitle'

                    />
                    <div className={'input-invalid-message'}>
                        {errors.two_subtitle?.ar && 'Arabic Sub-Title is required'}
                    </div>
                </div>
                {
                    sectionTwoContent && sectionTwoContent.map((nodata, index) => {
                        return (
                            <>
                                <Grid container spacing={5} className="my-5 align-items-center">
                                    <Grid item>
                                        {/* Title */}
                                        <div style={{ padding: '1rem 0' }}>
                                            <label htmlFor="home"> Title {index + 1} (EN)</label> <br />
                                            <input type="text"
                                                disabled={disabled}
                                                {...register(`two_content_title-${index}.en`, { required: true })}
                                                className='home-input-field'
                                                placeholder='Enter Your Title (en)'
                                            />
                                            <div className={'input-invalid-message'}>
                                                {errors[`two_content_title-${index}`]?.en && 'This field is required'}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item >
                                        <div style={{ padding: '1rem 0' }}>
                                            <label htmlFor="home"> Title {index + 1} (AR)</label> <br />
                                            <input type="text"
                                                disabled={disabled}
                                                {...register(`two_content_title-${index}.ar`, { required: true })}
                                                className='home-input-field'
                                                placeholder='Enter Your Title (ar)'
                                            />
                                            <div className={'input-invalid-message'}>
                                                {errors[`two_content_title-${index}`]?.ar && 'This field is required'}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item >
                                        <div style={{ padding: '1rem 0' }}>
                                            <label htmlFor="home"> Upload Image </label> <br />
                                            <input
                                                type="hidden"
                                                {...register(`two_content_image_hidden-${index}`)}
                                                name=""
                                            />

                                            <div className='title-input-container home-title-container'>
                                                <input
                                                    disabled={disabled}
                                                    {
                                                    ...register(`two_content_image-${index}`,
                                                        {
                                                            // required: true,
                                                            onChange: (e) => handleImages(e, index)
                                                        })}
                                                    type="file"
                                                    className='home-input-field-file'
                                                    placeholder='Upload Images'
                                                />
                                                <div className={'input-invalid-message'}>
                                                    {errors[`two_content_image-${index}`] && 'This field is required'}
                                                </div>
                                                {
                                                    !(getFieldState(`two_content_image_hidden-${index}`)) ?
                                                        <></>
                                                        : <>
                                                            <MdImage className="delete-icon"
                                                                onClick={() => window.open(`${URLS.API_BASE_URL}/common/awsObject?key=${getValues(`two_content_image_hidden-${index}`)}`)
                                                                }
                                                            />
                                                            {/* <MdDelete className="delete-icon" /> */}
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    })
                }


            </div></>
    )
}

export default Two;