import React, { useState, useEffect } from 'react'
import '../serviceOverview/service.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { getCaller } from '../../../services/api'
import { Grid } from '@mui/material'
import useGlobal from 'hooks/useGlobal'

const Doctors = () => {
    const { tKey: [tKey] } = useGlobal();

    const [serviceIndex, setServiceIndex] = useState(0);
    const [sliderRef, setSliderRef] = useState(null)
    const [service, setService] = useState()

    const serviceAppointmentOverview = async () => {
        const resp = await getCaller('appoinmentServiceOverview')
        setService(resp)
    }

    useEffect(() => {
        serviceAppointmentOverview()
    }, [])

    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    // dots: true
                }
            },

        ]
    };

    return (
        <>
            <div className='service-body-container'>

                <div className="service-body-card">
                    <Slider ref={setSliderRef} {...sliderSettings}>
                        {
                            service?.map((e, i) => (
                                <>

                                    <div
                                        className={`service-body-content ${serviceIndex === i ? 'service-active' : null}`}
                                        onClick={() => { setServiceIndex(i) }}
                                        key={`Service_Over_${i}`}
                                    >
                                        <div className="service-border">
                                            <p> {e?.service_master?.[tKey] ?? "N/a"} </p>
                                            <div className="service-cat">
                                                <h3> {e?.categories ?? "N/a"}  </h3>
                                                <button> {e?.categories ?? "N/a"} Categories |{e?.doctors ?? "N/a"} Doctors </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='service-special-cat' hidden={serviceIndex !== i}>
                                        <Grid container spacing={2}>
                                            {e?.list?.map((el, insideIndex) => (
                                                <Grid item xs={6}>
                                                    <div className="tab-service" key={`Service_Tab_${insideIndex}`}>
                                                        <p>{el?.sub_services?.[tKey] ?? "N/a"}</p>
                                                        <h3>{el?.doctors ?? "N/a"}</h3>
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </>
    )

}

export default Doctors

