import { updateCaller, getCaller } from "../../../../services/api";

const UPDATE = id => `admin/config/general/footer/${id}`;
const BYID = id => `admin/config/general/footer/${id}`;
const UPDATELOGO = id =>`admin/config/general/footer/logo/${id}`;


export const updateGeneralFooterRQ = async (id, data) => {
    const response = await updateCaller(UPDATE(id), data, false);
    return (response?.error == false) ? response : false;
}


export const fetchGeneralFooterRQ = async (id, formData) => {
    const response = await getCaller(BYID(id));
    return (response?.error == false) ? response : false;
}

export const  fetchGeneralFooterLogoRQ = async(id, data) =>{
    const response = await updateCaller(UPDATELOGO(id), data, true);
    return (response?.error == false) ? response : false;
}