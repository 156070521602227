import React, { useEffect, useState } from 'react'
import '../../../websitecontent/homePage/edithome.css';
import Header from '../../../../layout/header/Header'
import '../HomePage'
import { checkAvailability, uploadAndDelete } from '../../../../utils';
import { postCaller } from '../../../../services/api';
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { URLS } from 'utils/constants';
import { MdDelete, MdImage } from 'react-icons/md'


const INITIAL__LANGUAGE = { en: '', ar: '' };
const IMAGES_INITIAL_VALUE = [
    ...Array(6).fill(undefined).map(noData => (
        {
            title: INITIAL__LANGUAGE,
            subTitle: INITIAL__LANGUAGE,
            description: INITIAL__LANGUAGE
        }
    ))
];

const SectionSix = ({
    sectionSixData,
    setSectionSixData,
    disabled
}) => {

    const [title, setTitle] = useState(INITIAL__LANGUAGE);
    const [content, setContent] = useState(IMAGES_INITIAL_VALUE);

    const { t } = useTranslation()


    const handleImages = async (e, index) => {
        let _temp = content;
        const current = e.target;
        if (current.files && current.files.length !== 0) {
            let file = current.files[0];
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
            if (!checkAvailability(file.type)) {
                return alert("Unsupported File")
            }

            const newKey = await uploadAndDelete(file, _temp[index].image);

            _temp[index].image = newKey;
            setSectionSixData(prev => ({ ...prev, ..._temp }))
        }
    };
    useEffect(() => {
        setTitle(sectionSixData?.title)
        setContent((Array.isArray(sectionSixData?.content) && sectionSixData?.content?.length > 0 && sectionSixData?.content) || IMAGES_INITIAL_VALUE)
        console.log("🚀 ~ file: SectionSix.jsx:52 ~ useEffect ~ sectionSixData", sectionSixData)
    }, [sectionSixData])

    return (
        <div className="home-section-one">
            <p> Section 6 </p>
            <div className="title-input-container">
                {/* FOR ENGLISH */}
                <div className='home-input-content'>
                    <label htmlFor="home"> {t("Title (EN)")}</label> <br />
                    <input type="text"
                        value={title?.en || ""}
                        disabled={disabled}
                        className='home-input-field'
                        placeholder='Image title (en)'
                        onChange={e => {
                            const temp = sectionSixData;
                            temp.title = {
                                'en': e.target.value,
                                'ar': temp?.title?.ar
                            };
                            setTitle({ ...temp?.title })
                            setSectionSixData({ ...temp })
                        }}
                    />
                </div>
                <div className='home-input-content'>
                    <label htmlFor="home"> {t("Title (AR)")}</label> <br />
                    <input type="text"
                        value={title?.ar || ""}
                        disabled={disabled}

                        className='home-input-field'
                        placeholder='Image title (en)'
                        onChange={e => {
                            const temp = sectionSixData;
                            temp.title = {
                                'ar': e.target.value,
                                'en': temp?.title?.en
                            };
                            setTitle({ ...temp?.title })
                            setSectionSixData({ ...temp })
                        }}
                    />
                </div>
            </div>

            {
                content.map((nodata, index) => {
                    return (
                        <>
                            <div className="home-section-one">
                                <p> Testimonial - {index + 1} </p>
                            </div>
                            <div className="title-input-container">
                                {/* FOR ENGLISH */}
                                <div className='home-input-content'>
                                    <label htmlFor="home"> {t("Title (EN)")}</label> <br />
                                    <input type="text"
                                        value={content[index]?.title?.en || ""}
                                        disabled={disabled}

                                        className='home-input-field'
                                        placeholder='Image title (en)'
                                        onChange={e => {
                                            let _images = content;
                                            _images[index].title = {
                                                'en': e.target.value,
                                                'ar': _images[index].title?.ar
                                            };

                                            setContent([..._images]);
                                            setSectionSixData(prev => ({ ...prev, content: _images }))
                                        }}
                                    />
                                </div>
                                {/* FOR ARABIC */}
                                <div className='home-input-content'>
                                    <label htmlFor="home"> {t("Title (AR)")}</label> <br />
                                    <input type="text"
                                        value={content[index]?.title?.ar || ""}
                                        disabled={disabled}
                                        className='home-input-field'
                                        placeholder='Image title (ar)'
                                        onChange={e => {
                                            let _images = content;
                                            _images[index].title = {
                                                'ar': e.target.value,
                                                'en': _images[index].title?.en
                                            };
                                            setContent([..._images]);
                                            setSectionSixData(prev => ({ ...prev, content: _images }))
                                        }}
                                    />
                                </div>

                                <div className='home-input-content'>
                                    <label htmlFor="home"> {'Subtitle (EN)'}</label> <br />
                                    <input type="text"
                                        value={content[index]?.subTitle?.en || ""}
                                        disabled={disabled}
                                        className='home-input-field'
                                        placeholder='Image title (en)'
                                        onChange={e => {
                                            let _images = content;
                                            _images[index].subTitle = {
                                                'en': e.target.value,
                                                'ar': _images[index].subTitle?.ar
                                            };
                                            setContent([..._images]);
                                            setSectionSixData(prev => ({ ...prev, content: _images }))

                                        }}
                                    />
                                </div>

                                {/* FOR ARABIC */}
                                <div className='home-input-content'>
                                    <label htmlFor="home"> {'Subtitle (AR)'}</label> <br />
                                    <input type="text"
                                        disabled={disabled}
                                        value={content[index]?.subTitle?.ar || ""}
                                        className='home-input-field'
                                        placeholder='Image title (ar)'
                                        onChange={e => {
                                            let _images = content;
                                            _images[index].subTitle = {
                                                'ar': e.target.value,
                                                'en': _images[index].subTitle?.en
                                            };
                                            setContent([..._images]);
                                            setSectionSixData(prev => ({ ...prev, content: _images }))

                                        }}
                                    />
                                </div>

                                <div className='home-input-content home-web-content'>
                                    <label htmlFor="home"> Upload content </label> <br />
                                    <div className='title-input-container home-title-container'>
                                        <input
                                            disabled={disabled}
                                            type="file"
                                            className='home-input-field-file'
                                            placeholder='Upload'
                                            onChange={(e) => handleImages(e, index)}
                                        />
                                        {
                                            !(content[index]?.image) ?
                                                <></>
                                                : <>
                                                    <MdImage className="delete-icon"
                                                        onClick={() => window.open(`${URLS.API_BASE_URL}/common/awsObject?key=${content[index]?.image}`)
                                                        }
                                                    />
                                                    <MdDelete className="delete-icon" />
                                                </>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="title-input-container">
                                <div className='home-input-content home-input-content-full'>
                                    <label htmlFor="home"> Description (EN)</label> <br />
                                    <textarea
                                        disabled={disabled}
                                        value={content[index]?.description?.en || ""}
                                        className='home-input-field not-resizable-x'
                                        placeholder='Description (en)'
                                        onChange={e => {
                                            let _images = content;
                                            _images[index].description = {
                                                'en': e.target.value,
                                                'ar': _images[index].description?.ar
                                            };
                                            setContent([..._images]);
                                            setSectionSixData(prev => ({ ...prev, content: _images }))

                                        }}
                                        style={{
                                            height: '10rem'
                                        }}
                                    />
                                </div>
                                <div className='home-input-content home-input-content-full'>
                                    <label htmlFor="home"> Description (AR)</label> <br />
                                    <textarea
                                        disabled={disabled}
                                        value={content[index]?.description?.ar || ""}
                                        className='home-input-field not-resizable-x'
                                        placeholder='Description (en)'
                                        onChange={e => {
                                            let _images = content;
                                            _images[index].description = {
                                                'ar': e.target.value,
                                                'en': _images[index].description?.en
                                            };
                                            setContent([..._images]);
                                            setSectionSixData(prev => ({ ...prev, content: _images }))

                                        }}
                                        style={{
                                            height: '10rem'
                                        }}
                                    />
                                </div>
                            </div>

                        </>
                    )
                })
            }
        </div>

    )
}

export default SectionSix