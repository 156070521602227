import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import './userWallet.css'
import FilterInput from '../../../components/common/filterfield/FilterInput';
import Pagination from '../../../components/pagination/Pagination';
import { getCaller } from 'services/api';
import { constants } from 'utils/constants';
import { dateFormatWW } from 'utils';
const UserWallet = () => {
    const { three_dots } = constants;
    const { patientId } = useParams();
    const navigate = useNavigate();

    const [pagina, setPagina] = useState(1);
    const [porPagina] = useState(5);
    const [walletData, setWalletData] = useState("")
    const [walletAmount, setWalletAmount] = useState(0);

    const maximo = walletData?.transactions?.count / porPagina;

    const fetchWalletRecords = async payload => {
        const {
            page
        } = payload;

        let URL = `admin/users/patient/wallet/${patientId}?`;

        if (page) {
            URL += 'page=' + page;
        }
        const res = await getCaller(URL)

        if(page === 1){
            setWalletAmount(res?.data?.walletAmount)
        }

        
        setWalletData(res?.data || [])
    }

    useEffect(() => {
        fetchWalletRecords({ page: pagina })
    }, [pagina])

    useEffect(() => {
        fetchWalletRecords({})
    }, [])

    return (
        <>
            <div className='user-wallet-container'>
                <div className="user-wallet">
                    <div className="user-wallet-content">
                        <div className="user-wallet-content1">
                            <p>{t('walletBalance')}</p>
                            <h4>SAR {walletAmount} <small> As on {dateFormatWW()} </small> </h4>
                        </div>
                        <button className='resch-button' onClick={() => navigate('/addfunds')}>{t('addFunds')}</button>
                    </div>
                </div>
                <div className="user-past-content">
                    <FilterInput />
                    <div className="user-table-past">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell> Appointment ID </StyledTableCell>
                                        <StyledTableCell> Transaction ID </StyledTableCell>
                                        <StyledTableCell> Remarks </StyledTableCell>
                                        <StyledTableCell> Date & TIme </StyledTableCell>
                                        <StyledTableCell> Type </StyledTableCell>
                                        <StyledTableCell> Amount(SAR) </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(walletData?.transactions?.rows)
                                        && walletData?.transactions?.rows?.length > 0
                                        ? walletData?.transactions?.rows?.map((_item, _index) => {
                                            return (
                                                <StyledTableRow key={`User-Medical-Records-${_index}`} >
                                                    <StyledTableCell component="th" scope="row">
                                                        {_item?.transactionId || three_dots}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {_item?.transactionId || three_dots}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{three_dots}</StyledTableCell>
                                                    <StyledTableCell>{dateFormatWW(_item?.createdAt) || three_dots} </StyledTableCell>
                                                    <StyledTableCell> {_item?.type || three_dots} </StyledTableCell>
                                                    <StyledTableCell> {_item?.amount || three_dots} </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                        : <>
                                            <div className='no-upcoming-content'>
                                                <StyledTableRow>
                                                    No Data Found
                                                </StyledTableRow>
                                            </div>
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
                </div>
            </div>
        </>
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "#707070",
        border: 0,
        fontSize: 12,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        border: 0,
        fontWeight: 600,
        color: "#1A1919"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default UserWallet