import React from 'react'
import { useEffect, useState } from 'react'
import Header from '../../layout/header/Header'

import '../websitecontent/PrivacyPolicy/Policy.css'
import Policy from '../websitecontent/PrivacyPolicy/Policy'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCaller, postCaller, updateCaller } from '../../services/api'
import { ThemeButton, UnstyledButton } from '../../components/common/button/Button'
import { Typography } from '@mui/material'
import { responseMessages } from 'utils/responseMessages'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'



const INITIAL__LANGUAGE = { en: '', ar: '' };
const INITIAL_SECTIONS = [
    {
        title: INITIAL__LANGUAGE,
        content: INITIAL__LANGUAGE
    },
    {
        title: INITIAL__LANGUAGE,
        content: INITIAL__LANGUAGE
    },
];

const EditPrivacyPolicy = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state: { disabled } } = useLocation();


    const { t } = useTranslation()

    const [pageName, setPageName] = useState(INITIAL__LANGUAGE);
    const [pageUrl, setPageUrl] = useState("");
    const [policiesData, setPoliciesData] = useState(INITIAL_SECTIONS)

    const handleSavePolicy = async () => {
        try {
            const data = {
                id: id,
                page_name: pageName,
                // page_name_en: pageName?.en,
                // page_name_ar: pageName?.ar,
                page_url: pageUrl,
                content: policiesData
            }

            const response = await updateCaller(`admin/website-content/${id}`, data)
            if (response?.error === false) {
                toast.success(response?.msg || responseMessages.UNKNOWN_ERROR)
                return navigate('/dashboard/webiste-contents')
            } else {
                toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
            }
        } catch {
            toast.info(responseMessages.UNKNOWN_ERROR)
        }
    }

    const getPrivacyPolicyId = async () => {
        try {
            const response = await getCaller(`admin/website-content/${id}`)

            if (response?.error === false && response?.data) {
                const {
                    content,
                    page_url,
                    page_name
                    // page_name_en,
                    // page_name_ar,
                } = response?.data;

                if (Array.isArray(content) && content.length > 0) {
                    setPoliciesData(content)
                } else { setPoliciesData(INITIAL_SECTIONS) }

                setPageName(page_name)
                setPageUrl(page_url)
            }
        } catch { }
    }


    useEffect(() => {
        getPrivacyPolicyId()
    }, [])
    return (
        <>
            <Header />
            <div className='edit-policy-container'>
                <Typography
                    variant='h5'
                    component={'h1'}
                    sx={{
                        fontWeight: 'bold',
                        mb: 2
                    }}
                >
                    Edit Privacy & Policy
                </Typography>
                <div className="policy-wrapper">
                    <div className="policy-form">
                        <div className='policy-input-content'>
                            <label htmlFor="policy"> Page Name (EN)</label> <br />
                            <input
                                value={pageName?.en}
                                type="text"
                                disabled={disabled}
                                className='policy-input-field'
                                placeholder='Enter your Page Name'
                                onChange={({ target: { value } }) => {
                                    setPageName(
                                        _prev => ({
                                            ..._prev,
                                            en: value
                                        })
                                    )
                                }}
                            />
                        </div>
                        <div className='policy-input-content'>
                            <label htmlFor="policy"> Page Name (AR) </label> <br />
                            <input
                                disabled={disabled}

                                value={pageName?.ar}
                                type="text"
                                className='policy-input-field'
                                placeholder='Enter your Page Name'
                                onChange={({ target: { value } }) => {
                                    setPageName(
                                        _prev => ({
                                            ..._prev,
                                            ar: value
                                        })
                                    )
                                }}
                            />
                        </div>
                        <div className='policy-input-content' style={{ marginLeft: "2rem" }}>
                            <label htmlFor="policy"> {t("Page URL")} </label> <br />
                            <input
                                type="text"
                                disabled={disabled}
                                value={pageUrl}
                                className='policy-input-field'
                                placeholder='page url for '
                                onChange={(e) => {
                                    setPageUrl(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    {
                        Array(policiesData?.length || INITIAL_SECTIONS.length).fill(0).map((nodata, index) => {
                            return (
                                <Policy
                                    disabled={disabled}
                                    policyData={policiesData}
                                    setPoliciesData={setPoliciesData}
                                    key={index}
                                    index={index}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className="terms-btn-content">
                <UnstyledButton
                    sx={{
                        width: '10rem',
                    }}
                    text="Back"
                    onClick={() => navigate('/dashboard/webiste-contents')}
                />
                {
                    !disabled
                    && <div className="terms-mark">
                        <div className='terms-save'>
                            <ThemeButton
                                onClick={handleSavePolicy}
                                text="Save"
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )

}









export default EditPrivacyPolicy