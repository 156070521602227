import moment from "moment";
import { toast } from "react-toastify";
import { getCaller, postCaller } from "../services/api";
import { appointmentStatus, constants, profiles } from "./constants";

const BASE_URL = process.env.REACT_APP_BASE_URL


export function showDob(date) {
    if (date) {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let data = date.split("-")
        data = new Date(data[0], data[1], data[2]);
        date = `${data.getDate()} ${month[data.getMonth() - 1]} ${data.getFullYear()}`
        return date;
    } else {
        return;
    }

}

export function formatTime(time) {
    let formattedTime;
    console.log(time)
    if (time.includes('a')) {
        console.log(formattedTime)
        formattedTime = time.split("am");
        return `${formattedTime[0]} AM`
    } else if (time.includes('p')) {
        formattedTime = time.split("pm");
        return `${formattedTime[0]} PM`
    }
}

export function getDiscountedAmount(amount, discount) {
    console.log(amount)
    console.log(discount)
    let discountedAmount = 0;
    if (!discount) {
        return discountedAmount = amount
    } else {
        discountedAmount = parseFloat(amount) - ((parseFloat(amount) * parseFloat(discount)) / 100);
        console.log(discountedAmount)
        return discountedAmount
    }
}



export function parseTime(s) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
}

function convertHours(mins) {
    var hour = Math.floor(mins / 60);
    var mins = mins % 60;
    var converted = pad(hour, 2) + ':' + pad(mins, 2);
    return converted;
}

function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

export function calculate_time_slot(start_time, end_time, interval = "30") {
    var i, formatted_time;
    var time_slots = new Array();
    for (var i = start_time; i <= end_time; i = i + interval) {
        formatted_time = convertHours(i);
        time_slots.push(formatted_time);
    }
    return time_slots;
}

export function convertTime12To24(time12h) {
    console.log(time12h)
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
}
export function dateFilter(date) {
    const data = new Date([])
    data.sort();
    console.log(data)
}

export const checkAvailability = (formate) => {
    const availFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/bmp", "image/svg", "image/svg+xml", "application/pdf"];
    if (!availFileTypes.includes(formate)) {
        return false;
    }
    return true;
}

export const uploadAndDelete = async (file, old_image_url) => {
    // return "testimage";
    /* Generating FormData for background image*/
    const formData = new FormData();
    formData.append('file', file);
    formData.append('old_file', old_image_url);

    /* Passing form to API */
    const response = await postCaller('homepage/uploads', formData, true);
    if (response.error == false) {
        return response?.data?.Key;
    }
    return false;
};

export const handleResponse = (response, _successToast = false, _errorToast = false) => {
    if (response.error === false) {
        _successToast && toast.success(response.msg);
        return response.data;
    } else {
        _errorToast && toast.info(response.msg);
        return response.data;
    }
}

export const checkIfLogin = async () => {
    if (localStorage.getItem('token')) {
        const response = await getCaller('admin/profile/details');
        if (response?.status === 'failed') {
            return false;
        }
        return response?.data;
    }
}

export const destructureProfile = payload => {
    try {
        if (!payload) return constants.N_A;
        const {
            type
        } = payload;

        if (
            type === profiles.doctor
            || payload.doctor_profile
            || payload.doctor_profiles?.length > 0
        ) {
            if (
                !(Array.isArray(payload.doctor_profiles))
            ) {
                const { doctor_profile: { ...rest } } = payload;
                return formatDestructuredProfile(rest)
            }
            const { doctor_profiles: [{ ...rest }] } = payload;
            return rest

        } else {
            if (
                !(Array.isArray(payload.user_profiles))
            ) {
                const { user_profile: { ...rest } } = payload;
                return formatDestructuredProfile(rest)
            }

            const { user_profiles: [{ ...rest }] } = payload;
            return rest
        }
    }
    catch (e) {
        /* returning basic structure */
        return {
            name: constants.N_A,
            email: constants.N_A,
            mobile: constants.N_A
        }
    }
}


export const destructureSlot = appointment => {
    if (!appointment) return undefined;

    if (Array.isArray(appointment.slot)) {
        const [slot] = appointment.slot;
        return slot?.slotDetails || undefined;
    } else {
        return undefined;
    }
}


export const getFormData = object => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

/* HELPERS THAT ARE BEING USED IN THIS FILE ONLY */

export const generateQuery = (URL, options) => {
    let keys = Object.keys(options);
    keys.length > 0 && keys.forEach(e => {
        if (options[e]) {
            URL += `&${e}=${options[e]}`
        }
    })
    return URL;
}


const removeSplCharsForNumbers = (str) => {
    return str.replace(/[^0-9.]/gi, '');
}

export const getOnlyNumber = (oldVal, _val, maxVal = 1000000, fixedValue = 2) => {
    let val = _val;

    if (isNaN(val)) {
        return oldVal
    }
    if (val > maxVal) {
        return oldVal;
    }

    let parsedString = removeSplCharsForNumbers(val);

    /* If parsed string is in float */
    if (parsedString.includes('.')) {
        /* splitting parsedString with and slicing last index with fixed value */
        let splited = parsedString.split('.');
        if (splited.length == 2) {
            splited[splited.length - 1] = splited[splited.length - 1].slice(0, fixedValue);
        }

        /* then joining back to parsedString */
        parsedString = splited.join('.')
    }

    return parsedString;
}

export const appointmentStatusColor = status => {
    switch (status) {
        case appointmentStatus['inReview']:
            return '#F28519';
        case appointmentStatus['Upcoming']:
            return '#3A9CD8';
        case appointmentStatus['payment_pending']:
            return '#43AFE9';
        case appointmentStatus['Completed']:
            return '#16CB0E';
        case appointmentStatus['Cancelled']:
            return '#E80707';
        default:
            return '#000000';
    }
}

const formatDestructuredProfile = (params) => params
export const contact_us_target_url = roomId => (`/window/popup/${roomId}`);
export const dateFormat = date => moment(date).format(constants.USER_DATE_FORMAT)
export const fetchAWSObject = key => key ? `${BASE_URL}/common/awsObject?key=${key}` : null;
export const dateFormatWW_TIME = date => moment(date).format(constants.USER_DATE_FORMAT_WW_TIME); /* WW_TIME is for With Words and TIME  */
export const dateFormatWW = (date) => moment(date).format(constants.USER_DATE_FORMAT_WW); /* WW is for With Words */
export const calculateAgeFromDate = date => moment().diff(moment(date, constants.DATABASE_DATE_FORMAT), 'years')
export const formatTimeSlot = (time, prepand = true) => moment(time, 'HH:mm:ss').format(`HH:mm ${prepand ? 'A' : ''}`);
export const formatFromToTimeSlot = (start, end, seperator = 'to', prepand = true) => `${formatTimeSlot(start, prepand)} ${seperator} ${formatTimeSlot(end, prepand)}`;