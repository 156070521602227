import { getCaller } from "../../../services/api"

export const getAllCategories = async () => {
    const response = await getCaller('homepage/categories');
    if (response.error == false) {
        return response.data;
    } else {
        return false;
    }

}