import useGlobal from 'hooks/useGlobal';
import React from 'react';
import '../view.css';

const DoctorDetails = (props) => {
    const { tKey: [tKey] } = useGlobal();
    return (
        <>
            <div className="report-view-child">
                <div className="report-view-section-parent">
                    <div className="section-parent-header">
                        <h5 className="appointment-heading">Doctor Details</h5>
                    </div>
                    <div className="section-childrens">
                        <div className="section-parent-app-dtails">
                            Name
                            <p className="section-app">{props.View.data?.doctor_profile.name}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                            Year of Experience
                            <p className="section-app">{props.View.data?.doctor_profile.experience}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                            Specialisation
                            <p className="section-app">{props.View.data?.service?.service_master?.name?.[tKey] ??  "n.a"} , {props.View.data?.service?.name?.[tKey]}</p>
                        </div>
                    </div>
                    <div className="section-parent-childs">

                    </div>
                </div>
            </div>
        </>
    )
}
export default DoctorDetails;