import React, { useEffect, useState } from 'react'
import '../addPatient/addpatient.css'
import { getCaller, postCaller, updateCaller } from '../../../services/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Header from '../../../layout/header/Header';
import { calculateAgeFromDate, checkAvailability, fetchAWSObject, getFormData } from 'utils';
import { constants, useAvailability, profiles } from 'utils/constants';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { toast } from 'react-toastify';
import { responseMessages } from 'utils/responseMessages';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { TextField, Tooltip } from '@mui/material';
import { useRef } from 'react';
import { ThemeButton, UnstyledButton } from 'components/common/button/Button';
import useGlobal from 'hooks/useGlobal';


const EditDoctorProfile = () => {
    const initialAvilability = useAvailability();
    let doctorId = null;
    const { state } = useLocation();
    const { tKey: [tKey] } = useGlobal();

    if (state && state?.doctorId) {
        doctorId = state.doctorId;
    }

    const navigate = useNavigate();
    const checkAllRef = useRef(null);

    const [profileImg, setProfileImg] = useState(null);
    const [serviceData, setServiceData] = useState();
    const [subServiceData, setSubServiceData] = useState([]);
    const [subSelectedCat, setSubSelectedCat] = useState([]);
    const [user, setUser] = useState('');
    const [profileSettingsMaster, setProfileSettingsMaster] = useState({})
    const [masterService, setMasterService] = useState(0);
    const [profilePreview, setProfilePreview] = useState(null)

    if (typeof window !== 'undefined') {
        injectStyle();
    }
    const serviceApi = async () => {
        const serviceRes = await getCaller('getallService');
        setServiceData(serviceRes.data);
    };
    const categoryApi = async (id, previous = false) => {
        if (!id) {
            return;
        }
        const subServiceRes = await getCaller(`getallSubService/${id}`);
        previous && setSubSelectedCat(previous)
        setSubServiceData(subServiceRes.data);
    };

    const handleDropdown = (event) => {
        const { value } = event.target;
        setMasterService(value)
        categoryApi(value);
    };

    /* Alphanumeric value get */

    const { t } = useTranslation();
    const editPatientSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Name is too small')
            .max(30, 'Name is too long')
            .required('Name is required'),
        gender: Yup.string().required('Gender is required'),
        age: Yup.number().required('Age is required').min(1).max(110),
        email: Yup.string()
            .email('Invalid email')
            .required('Email is Required')
            .max(60, 'More than 60 character is not allowed'),
        mobile: Yup.string()
            .min(10, 'Incorrect mobile number')
            .max(10, 'Mobile Number is too long')
            .required('Mobile number is required'),
        location: Yup.string()
            .min(2, 'Location is too small')
            .max(30, 'Location is too large')
            .required('Location is required'),
        dob: Yup.string(),
        description: Yup.string()
            .required('About is required')
            .max(500, 'Description is allowed only 500 characters'),
        experience: Yup.number().required('Experience is required').min(0).max(99),
        license_no: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/)
            .required('License number is required')
            .max(10, 'Licence no. more than 10 characters is not allowed'),
        fees: Yup.number()
            .required('Appointment Fees is Required')
            .min(0)
            .max(999999999, 'More than 999999999 Fees amount is not allowed'),
        password: Yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/, 'Password must contain, one uppercase, one special character, one number'),
        availability: Yup.array()
            .of(
                Yup.object().shape({
                    "day": Yup.string().required('This field is required'),
                    "day_index": Yup.string().required('This field is required'),
                    "start": Yup.string().required('This field is required'),
                    "end": Yup.string().required('This field is required'),
                    "status": Yup.boolean().required('This field is required')
                },)
            ).required()
    });

    const loadUser = async () => {
        const result = await getCaller(`showDoctorProfile?doctorId=${doctorId}`);

        delete result.data.id;
        delete result.data.allAppointment;
        delete result.data.allpatient;
        delete result.data.call;
        delete result.data.videoCall;
        delete result.data.allAppointment;
        delete result.data.loginId;
        delete result.data.reviews;
        delete result.data.license_url;
        delete result.data.isActive;
        delete result.data.createdAt;
        delete result.data.updatedAt;

        let avail = null;
        if (result?.data?.availability?.length > 0) {
            avail = result?.data?.availability?.map(
                _avail => {
                    return {
                        ..._avail,
                        start: moment(_avail?.start, 'HH:mm:ss'),
                        end: moment(_avail?.end, 'HH:mm:ss'),
                    }
                }
            )
        }

        formik.setValues((values) => ({
            ...values,
            mobile: result?.data?.mobile ?? '',
            name: result?.data?.name ?? '',
            email: result?.data?.email ?? '',
            dob: result?.data?.dob ?? '',
            age: result?.data?.age ?? '',
            gender: result?.data?.gender ?? '',
            description: result?.data?.description ?? '',
            license_no: result?.data?.license_no ?? '',
            experience: result?.data?.experience ?? '',
            location: result?.data?.location ?? '',
            specialization: result?.data?.specialization ?? '',
            subServiceId: result?.data?.subServiceId ?? '',
            fees: result?.data?.fees ?? '',
            password: '',
            // schedule: result?.data?.schedule,
            availability: avail || initialAvilability,
        }));

        previousMasterService(result);
        setUser(result.data);
        setProfilePreview(fetchAWSObject(result?.data?.url))
    };

    const previousMasterService = result => {
        const { serviceMasterId, serviceId } = result.data?.subService || {};
        setMasterService(serviceMasterId)
        categoryApi(serviceMasterId, serviceId)
    }

    const getProfileMaster = async () => {
        const response = await getCaller(`master/profile-settings?type=${profiles.doctor}`)
        setProfileSettingsMaster(response.data || {})
    }

    const uploadDoctorLicense = (values) => {
        const formData = new FormData();
        formData.append('doc', values.doc)
        updateCaller(`uploads/doctor/license/${doctorId}`, formData, true);
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            gender: '',
            age: '',
            mobile: '',
            email: '',
            location: '',
            dob: undefined,
            description: '',
            experience: '',
            license_no: '',
            doc: '',
            fees: '',
            specialization: '',
            subServiceId: '',
            schedule: [],
            availability: initialAvilability
        },
        validationSchema: editPatientSchema,
        onSubmit: async (values, { setFieldError }) => {
            try {
                const _availability = [...values.availability.map(
                    avail => {
                        return {
                            ...avail,
                            start: avail.start?.format("HH:mm"),
                            end: avail.end?.format("HH:mm")
                        }
                    }
                )]

                const { isPassed, index } = validate_time_slots(_availability)

                if (!isPassed) {
                    setFieldError(`availability[${index}].start`, 'Slot Should be on interval of 20 minutes')
                    setFieldError(`availability[${index}].end`, 'Slot Should be on interval of 20 minutes')
                    return;
                };


                /* deleting availability */
                delete values.availability;

                /* modifying existing values */
                values.experience = values.experience;
                values.specialization = subSelectedCat.toString();
                values.subServiceId = Number(subSelectedCat) || null;

                const formData = getFormData(values);

                /* setting manually  */
                formData.append('schedule', `[{${values.schedule}}]`);

                if (doctorId) {
                    const originalValues = Object.assign({}, values);
                    delete values.password;
                    delete values.doc;
                    const response = await updateCaller(`updateDoctorProfile?doctorId=${doctorId}`, values);
                    const _response = await updateCaller(`profile/availability?doctorId=${doctorId}`, { availability: _availability })
                    uploadDoctorLicense(originalValues)
                    await handleProfileUpload({ loginId: doctorId });
                    if (response?.error === false) {
                        toast.success(response?.msg)
                        return navigate('/dashboard/users');
                    } else {
                        return toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
                    }
                }

                const response = await postCaller('admin/createDoctorProfile', formData, true);

                if (response) {
                    const _response = await updateCaller(`profile/availability?doctorId=${response?.data?.loginId}`, { availability: _availability })
                    await handleProfileUpload(response?.data);
                    if (_response?.error === false) {
                        toast.success(response?.msg)
                        navigate('/dashboard/users');
                    } else {
                        toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
                    }
                }
                else {
                    toast.info(responseMessages.UNKNOWN_ERROR)
                }
            } catch (err) {
                console.error(err);
            }
        }
    });



    const validate_time_slots = slots => {
        const slots_interval = 20;

        let isPassed = true;
        let index = '';
        slots.forEach((slot, _index) => {
            if (isPassed === false) return;

            if (!(slot.start) || !(slot.end)) {
                isPassed = false;
                return;
            }

            const start = moment(slot.start, 'HH:mm');
            const end = moment(slot.end, 'HH:mm');

            if (end && start) {
                const duration = moment.duration(end.diff(start));
                const minutes = duration.asMinutes();

                if (
                    minutes &&
                    (minutes % slots_interval !== 0)
                    || minutes === 0
                ) {
                    index = _index
                    isPassed = false;
                } else {
                    isPassed = true;
                }
            }
        })

        return { isPassed, index };
    }

    const handleProfileUpload = async (data) => {
        let formData = new FormData();
        formData.append('doc', profileImg);
        await updateCaller(`profile/image?type=doctor&userId=${data?.loginId}`, formData, true)
    };

    const dicardBtn = () => {
        navigate('/dashboard');
    };


    const handleStartTime = (element, index) => {
        // const value = ;
        const _temp = formik.values.availability;
        _temp[index].start = element;

        formik.setFieldValue('availability', [..._temp])
    }

    const handleEndTime = (element, index) => {
        // const value = element.target.value;
        const _temp = formik.values.availability;
        _temp[index].end = element;

        formik.setFieldValue('availability', [..._temp])
    }

    const handleSlotCheck = (element, index) => {
        const value = element.target.checked;

        const _temp = formik.values.availability;
        _temp[index].status = value;

        formik.setFieldValue('availability', [..._temp])
    }

    const formatSlot = slot => {
        return moment(slot, 'HH:mm:ss').format('HH:mm')
    }

    const handleImageRender = e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setProfileImg(file)
            // if (!checkAvailability(file.type)) { return toast.error('Unsupported File') }

            if (!checkAvailability(file.type)) { return alert('Unsupported File') }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setProfilePreview(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }

    const handleAllSelectDay = ({ target: { checked } }) => {
        const _oldValues = formik.values.availability;

        formik.setFieldValue(
            'availability',
            [
                ..._oldValues.map(
                    item => ({ ...item, status: checked })
                )
            ]
        )
    }


    const handleAgeCalculation = (value) => {
        if (value?.isValid()) {
            const _value = value.format(constants.DATABASE_DATE_FORMAT);
            const age = calculateAgeFromDate(_value) || 0

            if (age > 18 && age < 110) {
                formik.setFieldValue('dob', _value)
                formik.setFieldValue('age', age)
                formik.setFieldError('dob', '', false)
                return;
            } else {
                formik.setFieldValue('dob', _value, false)
                formik.setFieldError('dob', 'Date of birth mush be 18 year of old', false)
                return;
            }
        } else {
            formik.setFieldValue('dob', '', false)
            formik.setFieldError('dob', 'Date of birth is required')
        }
    }

    const handleAttachments = ({ target: { files } }) => {
        const [file] = files
        if (file) {
            const isPassed = checkAvailability(file?.type)
            if (isPassed) {
                return formik.setFieldValue('doc', file);
            }
            formik.setFieldError('doc', 'Only PDF, Image', false)
        }
    }


    useEffect(() => {
        doctorId && loadUser();
        getProfileMaster();
        serviceApi();
    }, []);

    useEffect(() => {
        let isPassed = false;
        const avail = formik?.values?.availability;

        for (let i = 0; i < avail?.length; i++) {
            if (avail[i]?.status) {
                isPassed = true;
            } else {
                isPassed = false;
                break;
            }
        }

        if (isPassed) {
            checkAllRef.current.checked = true;
        } else {
            checkAllRef.current.checked = false;
        }
    }, [formik.values.availability])

    return (
        <>
            <Header></Header>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <div className='edit-container'>
                    <div className='edit-content'>
                        <div className='edit-body'>
                            <h3>{t("Edit Profile Details")}</h3>
                            <div className='edit-doctor-img'>
                                <img
                                    src={profilePreview}
                                    alt=''
                                    height='220'
                                    style={{ borderRadius: '8px' }}
                                />
                                <input
                                    type='file'
                                    id='uploadImage'
                                    hidden
                                    onChange={handleImageRender}
                                    accept='image/*'
                                />
                                <label style={{
                                    cursor: 'pointer',
                                    color: '#2f77b6'
                                }} htmlFor='uploadImage'>{t('changePhoto')}</label>
                            </div>
                        </div>
                        <div className='edit-body-right'>
                            <div className='edit-form'>
                                <div className='edit-name'>
                                    <label htmlFor='name'>{t("Name")}</label>
                                    <input type='text' id='name' name='name' placeholder='Enter name'
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    <div className='error'>
                                        {formik.touched.name && formik.errors.name && (
                                            <span >{formik.errors.name}</span>
                                        )}
                                    </div>
                                </div>

                                <div className='edit-select'>
                                    <label htmlFor='gender'>{t("Gender")}</label>
                                    <select
                                        id='gender'
                                        name='gender'
                                        className='edit-select-field'
                                        onChange={formik.handleChange}
                                        value={formik.values.gender}
                                    >
                                        <option value=''> {t("Select")} </option>
                                        <option value='male'>{t("Male")}</option>
                                        <option value='female'>{t("Female")}</option>
                                    </select>
                                    <div className='error'>
                                        {formik.touched.gender && formik.errors.gender && (
                                            <span >{formik.errors.gender}</span>
                                        )}
                                    </div>
                                </div>
                                <div className='edit-age'>
                                    <label htmlFor='age'> {t("Age")} </label>
                                    <input
                                        type='number'
                                        id='age'
                                        name='age'
                                        placeholder='Enter'
                                        // onChange={formik.handleChange}
                                        disabled
                                        value={formik.values.age}
                                    />
                                    {/* <div className='error'>
                                        {formik.touched.age && formik.errors.age && (
                                            <span>{formik.errors.age}</span>
                                        )}
                                    </div> */}
                                    {/* <div className='error'>
                                        {formik.touched.age && formik.errors.age && (
                                            <span>{formik.errors.age}</span>
                                        )}
                                    </div> */}
                                </div>
                            </div>

                            <div className='edit-form'>
                                <div className='edit-mobile'>
                                    <label htmlFor='mnumber'> {t("Mobile Number")} </label>
                                    <input type='text' id='mnumber' name='mobile' placeholder='Enter Mobile Number'
                                        onChange={formik.handleChange}
                                        value={formik.values.mobile}
                                    />
                                    <div className='error'>
                                        {formik.touched.mobile && formik.errors.mobile && (
                                            <span>{formik.errors.mobile}</span>
                                        )}
                                    </div>
                                </div>
                                <div className='edit-email'>
                                    <label htmlFor='email'> {t("Email Id")} </label>
                                    <input type='text' id='email' name='email' placeholder='Enter Email'
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    <div className='error'>
                                        {formik.touched.email && formik.errors.email && (
                                            <span>{formik.errors.email}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='edit-form'>
                                <div className='edit-mobile'>
                                    <label htmlFor='location'> {t("Location")} </label> <br />
                                    <input type='text' id='location' name='location' placeholder='Enter Location'
                                        onChange={formik.handleChange}
                                        value={formik.values.location}
                                    />
                                    <div className='error'>
                                        {formik.touched.location && formik.errors.location && (
                                            <span>{formik.errors.location}</span>
                                        )}
                                    </div>

                                </div>
                                <div className='edit-email'>
                                    <label htmlFor='dob'> {t("Date of Birth")} </label> <br />
                                    <DatePicker
                                        value={formik?.values?.dob}
                                        onChange={handleAgeCalculation}
                                        renderInput={
                                            (params) => (
                                                <TextField
                                                    name='dob'
                                                    size='small'
                                                    helperText={formik?.errors?.dob !== undefined && formik.errors.dob}
                                                    {...params}
                                                />
                                            )
                                        }
                                    />
                                    {/* <div className='error'>
                                        {formik.touched.dob && formik.errors.dob && (
                                            <span>{formik.errors.dob}</span>
                                        )}
                                    </div> */}
                                </div>

                            </div>

                            <div className='edit-textarea'>
                                <label htmlFor='desc'> {t("Description")} </label> <br />
                                <textarea name='description' id='desc' placeholder='Describe your profile bio here'
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                >
                                </textarea>
                                <div className='error'>
                                    {formik.touched.description && formik.errors.description && (
                                        <span>{formik.errors.description}</span>
                                    )}
                                </div>
                            </div>
                            <div className='edit-form'>
                                <div className='edit-mobile'>
                                    <label htmlFor='special'> {t("Specialization")} </label> <br />
                                    <select
                                        name='specialization'
                                        className='edit-select-field'
                                        onChange={(e) => {
                                            handleDropdown(e);
                                        }}
                                        defaultValue={masterService}
                                    >
                                        <option value={0}> {t("Choose Services")} </option>
                                        {
                                            serviceData &&
                                            serviceData.length > 0 &&
                                            serviceData.map((value, index) => {
                                                return (
                                                    <option
                                                        key={`service-data-${index}`}
                                                        value={value.serviceMasterId}
                                                        selected={value.serviceMasterId === user?.subService?.service_master?.serviceMasterId}
                                                    >
                                                        {value.services?.[tKey]}
                                                    </option>
                                                );
                                            })}
                                    </select>

                                    {
                                        subServiceData.length > 0 && (
                                            <select
                                                className='edit-select-field'
                                                value={subSelectedCat}
                                                onChange={
                                                    element => {
                                                        const { value } = element.target;
                                                        setSubSelectedCat(value)
                                                        formik.setFieldValue('subServiceId', value)
                                                    }
                                                }
                                            >
                                                <option value=''>{t("Choose Subcategory")} </option>
                                                {subServiceData?.map(
                                                    (el, index) => (
                                                        <option value={el.serviceId} key={`sub_service_${index}`}>
                                                            {el.sub_services?.[tKey]}
                                                        </option>
                                                    ))}
                                            </select>
                                        )}

                                    <div className='error'>
                                        {
                                            formik.touched.specialization && formik.errors.specialization && (
                                                <span>{formik.errors.specialization}</span>
                                            )
                                        }
                                    </div>

                                </div>
                                <div className='edit-email'>
                                    <label htmlFor='year'> {t("Year of Experience")} </label>
                                    <input type='number' name='experience' id='year' placeholder='Enter experience'
                                        onChange={formik.handleChange}
                                        value={formik.values.experience}
                                    />
                                    <div className='error'>
                                        {formik.touched.experience && formik.errors.experience && (
                                            <span>{formik.errors.experience}</span>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className='edit-form'>
                                <div className='edit-file'>
                                    <label htmlFor='fees'> {t("Appointment Fees")} </label> <br />

                                    <input type='text' name='fees' id='fees' placeholder='Appointment Fees'
                                        onChange={formik.handleChange}
                                        value={formik.values.fees}
                                    />
                                    <div className='error'>
                                        <span>

                                            {formik.touched.fees && formik.errors.fees && (
                                                formik.errors.fees
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className='edit-email'>
                                    <label htmlFor='upload' style={{ margin: '0' }}> {t("Attached File")} </label>
                                    <input
                                        type='file'
                                        name='doc'
                                        id='upload'
                                        placeholder='Upload'
                                        className='file-input'
                                        onChange={handleAttachments}
                                    />

                                    <div className='error'>
                                        {formik.errors.doc && (
                                            <span>{formik.errors.doc}</span>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className='edit-form'>
                                <div className='edit-mobile'>
                                    <label htmlFor='saudi'> {t("Saudi Commision License")}</label> <br />
                                    <input type='text' id='saudi' name='license_no' placeholder='Enter License'
                                        onChange={formik.handleChange}
                                        value={formik.values.license_no}
                                    />
                                    <div className='error'>
                                        <span>
                                            {formik.touched.license_no && formik.errors.license_no && (
                                                formik.errors.license_no
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className='edit-email'>
                                    <label htmlFor='password'> {t("Password")} </label>
                                    <input type='text' id='saudi' name='password' placeholder='*******'
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                    <div>
                                        <span className='error'>
                                            {
                                                formik?.touched?.password
                                                && formik?.errors?.password
                                                && formik?.errors?.password
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='edit-table'>
                                <div style={{ overflowX: 'auto' }}>
                                    <table className='table-content-edit' style={{ marginTop: '2rem' }} >

                                        <thead>
                                            <tr>
                                                <th className='check-day'>
                                                    <input
                                                        id='select-all'
                                                        type='checkbox'
                                                        name='schedule'
                                                        onClick={handleAllSelectDay}
                                                        ref={checkAllRef}
                                                    />
                                                    <label
                                                        className={'not-selectable'}
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            paddingLeft: '.5rem'
                                                        }}
                                                        htmlFor={`select-all`}
                                                    >
                                                        {t("Day")}
                                                    </label>
                                                </th>
                                                <th> {t("From")} </th>
                                                <th> {t("To")} </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Array.isArray(formik?.values?.availability)
                                                && formik?.values?.availability
                                                && formik?.values?.availability?.map(
                                                    (item, i) => {
                                                        return (
                                                            <React.Fragment key={`availability_slots_${i}`}>
                                                                <tr>
                                                                    <td className='check-day'>
                                                                        <input
                                                                            type='checkbox'
                                                                            checked={item.status || false}
                                                                            onChange={element => handleSlotCheck(element, i)}
                                                                            name={`schedule.${i}.enabled`}
                                                                            id={`availability.label.${i}`}
                                                                        />
                                                                        <label
                                                                            className={'not-selectable'}
                                                                            style={{
                                                                                textTransform: 'capitalize',
                                                                                paddingLeft: '.5rem'
                                                                            }}
                                                                            htmlFor={`availability.label.${i}`}
                                                                        >
                                                                            {item?.day}
                                                                        </label>
                                                                    </td>

                                                                    <td>
                                                                        <TimePicker
                                                                            ampm={true}
                                                                            name={`availability-${i}-start`}
                                                                            value={formik.values.availability?.[i]?.start}
                                                                            onChange={element => handleStartTime(element, i)}
                                                                            renderInput={
                                                                                (params) => (
                                                                                    <TextField
                                                                                        size='small'
                                                                                        // disabled
                                                                                        // onKeyDown={e => e.preventDefault()}
                                                                                        {...params}
                                                                                    />
                                                                                )
                                                                            }
                                                                        />
                                                                        <div className='error'>
                                                                            <span>
                                                                                {
                                                                                    formik?.touched?.availability?.[i]?.start
                                                                                    && formik?.errors?.availability?.[i]?.start
                                                                                    && formik?.errors?.availability?.[i]?.start
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <TimePicker
                                                                            ampm={true}
                                                                            name={`availability-${i}-end`}
                                                                            value={formik.values.availability?.[i]?.end}
                                                                            onChange={element => handleEndTime(element, i)}
                                                                            disabled={!(formik?.values?.availability?.[i]?.start)}
                                                                            renderInput={
                                                                                (params) => (
                                                                                    <TextField
                                                                                        size='small'
                                                                                        error={false}
                                                                                        // disabled
                                                                                        // onKeyDown={e => e.preventDefault()}
                                                                                        {...params}
                                                                                    />
                                                                                )
                                                                            }
                                                                        />
                                                                        <div className='error'>
                                                                            <span>
                                                                                {
                                                                                    formik?.values?.availability?.[i]?.start
                                                                                    && formik?.touched?.availability?.[i]?.end
                                                                                    && formik?.errors?.availability?.[i]?.end
                                                                                    && formik?.errors?.availability?.[i]?.end
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='edit-footer'>
                        <div className='edit-button-content'>
                            {/* <button type='button'  >Discard</button> */}
                            <UnstyledButton
                                className='discard-btn'
                                sx={{
                                    mr: 2
                                }}
                                onClick={() => navigate('/dashboard/users')}
                                text={'Discard'}
                            />
                            {/* <button className='save-btn' type='submit'> Save Changes </button> */}
                            <ThemeButton
                                text={'Save Changes'}
                                className='save-btn'
                            />

                        </div>
                    </div>
                </div>

            </form>


        </>
    )
}

export default EditDoctorProfile