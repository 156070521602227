import React, { useContext, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilterInput from '../../components/common/filterfield/FilterInput'
import Pagination from '../../components/pagination/Pagination'
import '../../components/common/filterfield/filter.css'
import sortarrow from '../../assets/images/sort-arrow.svg'
import message from '../../assets/images/message-square.svg'
import check from '../../assets/images/check-circle.png';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import eye from '../../assets/images/eye.png'
import '../../components/modal/modal.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import trick from '../../assets/images/Group 5571.png';
import { getCaller, updateCaller } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { contact_us_target_url, dateFormatWW } from "utils/index";
import GlobalContext from 'context/GlobalContext';
import moment from 'moment';
import { queryStatus } from 'utils/constants';
import { Tooltip } from '@mui/material';
import { useRef } from 'react';
import FilterModal from './FilterModal';
import { useTranslation } from 'react-i18next';
let orderStatus = false;
const CustomerQueryTable = ({
    customerData,
    setCustomerData,
    refreshData
}) => {
    const navigate = useNavigate();

    const { t } = useTranslation()

    const context = useContext(GlobalContext);
    const socket = context.socket;
    const searchTextFieldRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [queryOpen, setQueryOpen] = useState(false);
    const [queryProcess, setQueryProcess] = useState(false);
    const [data, setData] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [porPagina, setPorPagina] = useState(5);
    const [queryData, setQueryData] = useState();
    const [query, setQuery] = useState({});
    const [modal, setModal] = useState(false);

    const maximo = customerData?.count / porPagina;


    const handleClose = () => setOpen(false);


    const handleView = async payload => {
        const {
            queryId
        } = payload?.data;

        const res = await getCaller(`getOneQuery/${queryId}`);
        setQueryData(res);
        setOpen(true)
    }



    const handleQueryInprocess = async () => {
        setOpen(false);
        setQueryProcess(true);
        const resQuery = await updateCaller(`updateQuery/${customerData.data[0].queryId}?status=In-Process`);
        if (resQuery) {
            // window.location.reload(false);
            navigate('/dashboard');
            localStorage.setItem('index', "6")
        }
        console.log(resQuery);
        setData(data)
    };

    const handleOpenChatWindow = async _data => {
        const data = _data.data;
        const id = data.queryId;

        const doUpdate = data.status === queryStatus['pending'];

        if (doUpdate) {
            const payload = {
                status: queryStatus['process'],
            }
            const response = await updateCaller(`updateQuery/${id}`, payload);
            refreshData()
        }
        openChatWindow(
            contact_us_target_url(data?.roomId),
            data
        )
    };

    const openChatWindow = (url, data) => {
        console.log("🚀 ~ file: CustomerQueryTable.jsx:105 ~ openChatWindow ~ data", data)
        let _url = url;
        const name = data.user?.name || data.doctor?.name || data?.name;

        _url += `?email=admin@gmail.com&user_id=${data.userId}&name=${name}`
        try {
            window.open(
                _url,
                data.userId,
                'height=500,width=400,left=100,top=100,resizable=0,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no'
            )

            // var timer = setInterval(function () {
            //     if (popupWindow.closed) {
            //         clearInterval(timer);
            //         socket.emit('forceCloseChatAction', data)
            //     }
            // }, 1000);

        } catch (err) {
            console.warn('Error while opening popup window', err.msg)
        }
    }

    const handleResolveQuery = async (_payload) => {
        if (
            window.confirm('Do you want to Mark it as resolved ?')
        ) {
            const { queryId } = _payload?.data;
            const payload = {
                status: queryStatus['resolved'],
            }
            const response = await updateCaller(`updateQuery/${queryId}`, payload);
            if (response?.error === false) {
                socket.emit('forceCloseChatAction', data)
                refreshData()
                handleClose()
            }
        }
    }

    const DecideWhatToShow = props => {
        const {
            status
        } = props?.data;

        switch (status) {
            case queryStatus['resolved']:
                return (
                    <div className="tooltip" onClick={() => handleView(props)}>
                        <img src={eye} alt=""
                            style={{ cursor: "pointer" }} />
                        <p className="tooltiptext">  </p>
                    </div>
                )

            default:
                return (
                    <>
                        <Tooltip title="View">
                            <div className="tooltip" onClick={() => handleView(props)}>
                                <img src={eye} alt=""
                                    style={{ cursor: "pointer" }} />
                            </div>
                            {/* <p className="tooltiptext"> View </p> */}
                        </Tooltip>

                        <Tooltip title="Mark As Resolved">
                            <div className="tooltip" onClick={() => handleResolveQuery(props)}>
                                <img src={check} alt="query-resolved" />
                                {/* <p className="tooltiptext"> MarkResolved </p> */}
                            </div>
                        </Tooltip>

                        <Tooltip title="Chat">
                            <div className="tooltip" onClick={() => handleOpenChatWindow(props)}>
                                <img src={message} alt="chat" />
                                {/* <p className="tooltiptext"> Chat </p> */}
                            </div>
                        </Tooltip>
                    </>
                )
        }
    }

    const nextData = async page => {
        const payload = {
            ...query,
            page
        }
        refreshData(payload)
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        refreshData({ page: 1, searchText: value })
    }

    const filterSortOrder = () => {
        orderStatus = !orderStatus;
        refreshData({ page: pagina, orderStatus: orderStatus ? "ASC" : "DESC" })
    }

    const handleModalClose = () => setModal(false)
    const handleModalOpen = () => setModal(true)

    useEffect(() => {
        nextData(pagina)
    }, [pagina])

    return (
        <>
            <div>
                <FilterModal
                    open={modal}
                    handleClose={handleModalClose}
                    refreshData={refreshData}
                    setQuery={setQuery}
                />
                <div className="filter-field">

                    <p> {customerData?.count ?? "0"} {t("Query")}</p>
                    <div className="filter-search">

                        <div className="filter-search-field">
                            <input
                                type="text"
                                id="search-box"
                                placeholder={t("Search")}
                                ref={searchTextFieldRef}
                                onKeyUp={event => event.key === "Enter" && handleTextFieldButton()}
                            />
                            <label onClick={handleTextFieldButton} htmlFor="search-box" className="fas fa-search"></label>
                        </div>
                        <div className="filter-button">
                            <button onClick={handleModalOpen}> {t("Filter")}</button>
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700, minHeight: 500 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell> {t("Type")} </StyledTableCell>
                                <StyledTableCell> {t("Name")} </StyledTableCell>
                                <StyledTableCell> {t("Mobile Number")} </StyledTableCell>
                                <StyledTableCell> {t("emailId")} </StyledTableCell>
                                <StyledTableCell> {t("Sent Date")} </StyledTableCell>
                                <StyledTableCell> {t("Message")} </StyledTableCell>
                                <StyledTableCell>{t("Status")} <img onClick={filterSortOrder} src={sortarrow} alt="" /></StyledTableCell>
                                <StyledTableCell> {t("Action")} </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {customerData &&
                                customerData?.rows?.map((el, i) => (
                                    <>
                                        <StyledTableRow key={i}>
                                            <StyledTableCell component="th" scope="row">
                                                {el?.type ?? "n/a"}
                                            </StyledTableCell>
                                            <StyledTableCell> {el?.name ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell> {el?.mobile ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell>  {el?.email ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell> {dateFormatWW(el?.createdAt) ?? "n/a"}</StyledTableCell>
                                            <StyledTableCell>  {el?.message ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell
                                                style={{ color: el?.status === 'Pending' ? '#EFE611' : el?.status === 'Resolved' ? '#13BC34' : "#43AFE9" }}>
                                                {/* rowInfo.row.status ? 'green' : 'red' */}
                                                <p style={{ fontWeight: "bold" }}>{el?.status}</p>
                                            </StyledTableCell>
                                            <StyledTableCell className='table-edit-one'>
                                                <DecideWhatToShow data={el} />
                                            </StyledTableCell>

                                        </StyledTableRow>
                                        <Modal
                                            aria-labelledby="transition-modal-title"
                                            aria-describedby="transition-modal-description"
                                            open={open}
                                            onClose={handleClose}
                                            closeAfterTransition
                                            BackdropComponent={Backdrop}
                                            BackdropProps={{
                                                timeout: 500,
                                            }}
                                        >
                                            <Fade in={open}>
                                                <Box sx={style}>
                                                    <div className="query-pop-up">
                                                        <h4> {t("Query Details")} </h4>
                                                        <span onClick={handleClose}> <FaRegTimesCircle
                                                            style={{ width: "2rem", height: "1.5rem" }} /> </span>
                                                    </div>
                                                    <div className='query-para'>

                                                        <div className='query-body'>

                                                            <div className="query-details">
                                                                <p>{t("Type")}</p>
                                                                <h4> {queryData?.data?.type} </h4>
                                                            </div>
                                                            <div className="query-details">
                                                                <p>{t("Type")}</p>
                                                                <h4> {queryData?.data?.name} </h4>
                                                            </div>
                                                            <div className="query-details">
                                                                <p>{t("Type")}</p>
                                                                <h4> {queryData?.data?.email} </h4>
                                                            </div>
                                                            <div className="query-details">
                                                                <p>{t("Type")}</p>
                                                                <h4> {queryData?.data?.mobile} </h4>
                                                            </div>
                                                            <div className="query-details">
                                                                <p>{t("Type")}</p>
                                                                <h4> {queryData?.data?.createdAt} </h4>
                                                            </div>
                                                            <div className="query-details">
                                                                <p>{t("Type")}</p>
                                                                <h4 style={{ color: queryData?.data?.status === 'Pending' ? '#EFE611' : queryData?.data?.status === 'Resolved' ? '#13BC34' : "#43AFE9" }}>
                                                                    {queryData?.data?.status}
                                                                </h4>
                                                            </div>

                                                        </div>
                                                        <div style={{ padding: "1rem" }}>
                                                            <p>{t("Problem Description")}</p>
                                                            <h4> {queryData?.data?.message} </h4>
                                                        </div>
                                                    </div>
                                                    <div className="query-btn-content">
                                                        <button className='home-cancel'
                                                            onClick={() => {
                                                                let url = `https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=VAR+Clinic+Query+Details&body=I+saw+this+and+thought+of+you`;
                                                                window.open(url, "samewindow", "toolbar=no, scrollbars=no, resizable=no");
                                                            }}
                                                        >{t("Share Via Email")}</button>
                                                        <div className="query-mark-btn">
                                                            {/* <button className='mark-query'
                                                                style={{ display: queryData?.data?.status === 'In-Process' ? 'none' : queryData?.data?.status === 'Resolved' ? 'none' : '' }}
                                                                onClick={handleQueryInprocess}> Mark as In-Process
                                                            </button> */}
                                                            <button
                                                                onClick={() => handleResolveQuery(queryData)}

                                                                className='mark-query'
                                                                style={{ display: queryData?.data?.status === 'Resolved' ? 'none' : '' }}> {t("Mark as Resolved")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </Fade>
                                        </Modal>
                                    </>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* modal open */}
                <div className={`query-open ${queryOpen ? "query-active" : ""}`}>
                    <div className="query-flex">
                        <div className="query-flex-1"><img src={trick} alt="" /> <p>Query Resolved</p></div>
                        <p className='query-para-1' onClick={() => setQueryOpen(false)}> Dismiss </p>
                    </div>

                </div>
                {/* query inprocess  */}
                <div style={{ border: "1px solid #43AFE9" }}
                    className={`query-open ${queryProcess ? "query-active" : ""}`}>
                    <div className="query-flex">
                        <div className="query-flex-1"><img src={trick} alt="" /> <p style={{ color: "#43AFE9" }}>Query
                            marked as in-process</p></div>
                        <p className='query-para-1' onClick={() => setQueryProcess(false)}> Dismiss </p>
                    </div>

                </div>
                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    )
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height:450,
    borderRadius: "1rem",
    bgcolor: 'white',
    border: "none"
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0, verticalAlign: 'top'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0, verticalAlign: 'top',
        userSelect: 'none'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default CustomerQueryTable
