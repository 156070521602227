import React from 'react'
import Header from '../../layout/header/Header'
import SideBar from '../../sidebar/SideBar'

const Dashboard = () => {
    return (
        <>
            <Header />
            <SideBar />
        </>
    )
}

export default Dashboard