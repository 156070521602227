import React from 'react'

import '../filterfield/filter.css'
import PropTypes from 'prop-types';



FilterInput.propTypes = {
    filter: PropTypes.bool,
    search: PropTypes.bool,
    searchRef: PropTypes.any,
    searchHandler: PropTypes.func
}

function FilterInput(props) {
    const filter = props.filter ?? true;
    const search = props.search ?? true;
    return (
        <>


            <div className="filter-field">
                <h2 style={{ fontWeight: "bolder" }}> {props.heading} </h2>
                <div className="filter-search">
                    {
                        search ? <>
                            <div className="filter-search-field">
                                <input
                                    type="text"
                                    id="search-box"
                                    placeholder="Search "
                                    ref={props.searchRef}
                                />
                                <label
                                    htmlFor="search-box"
                                    className="fas fa-search"
                                    onClick={props.searchHandler}
                                ></label>
                            </div>
                        </> : (
                            <div style={{ display: 'flex', width: '100%' }}></div>
                        )
                    }
                    {
                        filter &&
                        <>
                            <div className="filter-button">
                                <button onClick={props.addOnClick}> Add </button>
                            </div>
                            <div className="filter-button">
                                <button onClick={props.handleModalOpen}> Filter </button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default FilterInput