import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{
            position: 'relative',
            display: 'inline-flex',
            backgroundColor: 'white',
            boxShadow: '0 0 4px 1px #ada9a9',
            borderRadius: "50%"
        }}>
            <CircularProgress
                {...props}
                variant="determinate"
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography style={{ fontSize: '1rem', fontWeight: 'bold' }} variant="caption" component="h2" color="text.secondary">
                    {props.value}%
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
    return <CircularProgressWithLabel {...props} />;
}