import React, { useState, useEffect } from 'react'
import AptCard from '../../../components/common/appointmenCard/AptCard'

import GenerateInvoice from '../invoice/GenerateInvoice'
import Revenue from '../revenue/Revenue'
import ServiceOver from '../serviceOverview/ServiceOver'
import '../useroverview/userover.css'
import { getCaller, } from '../../../services/api'
import { useTranslation } from 'react-i18next'
const UseroverView = () => {
    // const [state, setstate] = useState('')
    const {t} = useTranslation()

    /* Users State */
    const [dashboardData, setDashboardData] = useState([])
    /* Appointments State */
    // const [appointment, setAppointment] = useState([])


    /* Dashboard Start */
    const fetchDashboardData = async () => {
        const res = await getCaller('admin/dashboard/all')
        console.log("🚀 ~ file: UseroverView.jsx ~ line 24 ~ fetchDashboardData ~ res", res.data)
        setDashboardData(res?.data || [])
    }

    useEffect(() => {
        fetchDashboardData()
    }, [])
    /* Dashboard End */

    /* User And Doctor Data feacting */
    const [data, setData] = useState([])
    const fetchDashboardDataRQ = async () => {
        const res = await getCaller(`userListing`);
        console.log('response', res)
        setData(res?.data?.rows || [])

    }
    useEffect(() => {
        fetchDashboardDataRQ();
    }, [])


    return (
        <>
            <section>
                <div className="user-over-view">
                    <p>{t('Users Overview')}</p>
                    <div className="border">
                        <div className="over-view-container">
                            <div className='over-view-body-1'>
                                <p>{t('Total Users')}</p>
                                <h3>{dashboardData?.userOverviews?.totalReviews}</h3>
                            </div>
                            <div className='over-view-body'>
                                <div style={{ marginLeft: "1rem" }}>
                                    <p> {t('Doctors')} </p>
                                    <h3> {dashboardData?.userOverviews?.totalDoctors} </h3>
                                </div>
                            </div>
                            <div className='over-view-body'>
                                <div style={{ marginLeft: "1rem" }}>
                                    <p> {t('Patients')} </p>
                                    <h3> {dashboardData?.userOverviews?.totalUsers} </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ServiceOver
                            dashboardData={dashboardData}
                        />
                    </div>
                    <div>
                        <Revenue state={dashboardData} setstate={setDashboardData} />
                    </div>
                </div>
                <div className="appointment-sec">
                    <div >
                        <p>{t('Appointments')}</p>
                        <AptCard
                            type={"APPOINTMENTS"}
                            all={t("All Appointments")}
                            new={t("New Appointments")}
                            res={t("Reschedule Appointments")}
                            doc={t("Doctor for Approvals")}
                            dashboardData={dashboardData}
                        />
                    </div>
                    <div className='mobile-app'>
                        <p> Mobile App </p>
                        <AptCard
                            type={"MOBILE"}
                            all={t("Android Users")}
                            new={t("iOS Users")}
                            res={t("Active Users")}
                            doc={t("Inactive Users")}
                            dashboardData={dashboardData}
                        />
                    </div>
                    <div className="generate-invoice">
                        <p> {t("Generate Invoice")} </p>
                        <GenerateInvoice />
                    </div>
                </div>
            </section>
        </>
    )
}

export default UseroverView