import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import './doctorAppointment.css'
import arrowleft from '../../../assets/images/Path -33.png'
import arrowright from '../../../assets/images/Path -34.png'
import DoctorAllAppointment from './DoctorAllAppointment';
import { getCaller } from 'services/api';
import { constants } from 'utils/constants';
import { dateFormatWW, destructureSlot, formatFromToTimeSlot, formatTimeSlot } from 'utils';

const DoctorAppointment = () => {
    const { three_dots } = constants;
    const { doctorId } = useParams();
    const [tAppointments, sTAppointments] = useState([]); //t --> Today's Appointment

    const fetchTodaysAppointment = async () => {
        const response = await getCaller(`admin/users/doctor/appointments/today/${doctorId}`)
        response?.error === false && sTAppointments(response?.data)
    }

    useEffect(() => {
        fetchTodaysAppointment();
    }, [])
    return (
        <>
            <Box sx={{ flexGrow: 1, border: '1px solid #F0F0F0', borderRadius: "0.625rem", marginTop: "1rem", backgroundColor: "white" }}>
                <Grid item xs={12} md={12} >
                    <div className='doctor-upcoming-head'>
                        <p> Today’s Appointments {tAppointments?.count > 0 && <span className="text-light"> {tAppointments?.count || 0} {tAppointments?.count > 1 ? "items" : "item"} </span>} </p>
                        <div >
                            <img src={arrowleft} alt="" style={{ marginRight: "1rem" }} />
                            <img src={arrowright} alt="" />
                        </div>
                    </div>
                    <div className='upcoming-container'>
                        {
                            Array.isArray(tAppointments?.rows)
                                && tAppointments?.rows?.length > 0
                                ? tAppointments?.rows?.map((_item, index) => {
                                    const { start, end, date } = destructureSlot(_item)?.slot;
                                    return (
                                        <>
                                            <div className='upcoming-content' key={`Todays-Appointment-${index}`}>
                                                <div className='upcoming text-uppercase'> Upcoming </div>
                                                <div className="appointment">
                                                    <div className="appointment-content">
                                                        <p> Appointment ID </p>
                                                        <p>Appointment Time </p>
                                                        <p> Patient Name </p>
                                                        <p> Gender </p>
                                                        <p> Age </p>
                                                        <p> Specialisation Chosen </p>

                                                    </div>
                                                    <div className="appointment-id">
                                                        <p> {_item?.appointmentId || three_dots}  </p>
                                                        <p> {dateFormatWW(date)} {formatFromToTimeSlot(start, end, 'to', false) || three_dots} </p>
                                                        <p> {_item?.patientName || three_dots} </p>
                                                        <p> {_item?.gender || three_dots} </p>
                                                        <p> {_item?.age || three_dots} </p>
                                                        <p> {_item?.serviceName || three_dots} </p>
                                                    </div>
                                                </div>
                                                <div className='view-resh-content'>
                                                    <button className='view-button' > View Details </button>
                                                    <button className='resch-button' > Reschedule </button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                : <>
                                    <div className='no-upcoming-content'>
                                        <div>
                                            No Appointments Found
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </Grid>
            </Box>
            <DoctorAllAppointment />
        </>
    )
}

export default DoctorAppointment