import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UnstyledButton } from '../../../components/common/button/Button';
import Header from '../../../layout/header/Header';
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { checkAvailability, uploadAndDelete } from '../../../utils';
import { MdDelete, MdImage } from 'react-icons/md'
import { getCaller, updateCaller, postCaller } from '../../../services/api';
import Top from './Sections/Top';
import One from './Sections/One';
import Two from './Sections/Two';
import { toast } from 'react-toastify';
import { responseMessages } from 'utils/responseMessages';
import { useTranslation } from 'react-i18next';

const About = props => {
    /* ISOLATEDS STATES */

    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const { state: { disabled } } = useLocation();


    /* Button controlling states */
    const [disable, setDisable] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    /* DESTRUCTRING Form States */
    const { register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        getFieldState,
    } = useForm();

    const onSubmit = async (d) => {
        try {


            setLoader(true);
            /* Parsing content for section 2 */
            let arr = [];
            let counter = 0;
            for (const key in d) {
                let current_key_title = `two_content_title-${counter}`
                let current_key_image = `two_content_image_hidden-${counter}`
                if (!d[current_key_title]) { break; }
                arr.push({
                    title: d[current_key_title],
                    image: d[current_key_image]
                })
                counter++;
            }
            const data = {
                page_url: d.page_url,
                page_name: d.page_name,
                content: {
                    main_heading: d.main_heading,
                    'section-1': {
                        title: d.one_title,
                        subtitle: d.one_subtitle,
                        background_image: d.one_backround_image_hidden
                    },
                    'section-2': {
                        title: d.two_title,
                        subtitle: d.two_subtitle,
                        content: arr
                    }
                }
            }
            const response = await updateCaller(
                `admin/website-content/${id}`, data)

            if (response?.error === false) {
                toast.success(response?.msg)
                return navigate('/dashboard/webiste-contents')
                // return navigate('/dashboard')
            } else {
                toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
            }
            setLoader(false);
        } catch {
            setLoader(false);
        }
    }

    /* Form Submittion handler */
    const getAboutId = async (data) => {
        const response = await getCaller(`admin/website-content/${id}`);
        if (response?.error === false && response?.data) {
            const {
                page_name,
                page_url,
                content
            } = response?.data;


            /* Setting form values */
            /* TOP SECTION */

            /* setting page name languages specified */
            page_name?.en && setValue('page_name.en', page_name?.en)
            page_name?.ar && setValue('page_name.ar', page_name?.ar)


            /* setting page url */
            page_url && setValue('page_url', page_url)

            /* setting main_heading */
            content?.main_heading?.en && setValue('main_heading.en', content?.main_heading?.en)
            content?.main_heading?.ar && setValue('main_heading.ar', content?.main_heading?.ar)

            /* SECTION ONE */
            const one = content?.['section-1']
            setValue('one_title', one.title);
            setValue('one_subtitle', one.subtitle);
            setValue('one_backround_image_hidden', one.background_image);

            /* SECTION TWO */
            const two = content?.['section-2'];
            setValue('two_title', two.title);
            setValue('two_subtitle', one.subtitle);

            two.content && two.content.map(((c, i) => {
                setValue(`two_content_title-${i}`, c.title);
                setValue(`two_content_image_hidden-${i}`, c.image);
            }))
        }
    }

    useEffect(() => {
        getAboutId()
    }, [])

    return (

        <>
            <Header />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='edit-policy-container'>
                    <h3>{t("Edit About Us")}</h3>

                    <div className="home-wrapper">
                        <Top
                            disabled={disabled}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                        />

                        {/* Section One */}
                        <One
                            disabled={disabled}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                            getFieldState={getFieldState}
                            setDisable={setDisable}
                        />

                        {/* Section Two */}
                        <Two
                            disabled={disabled}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                            getFieldState={getFieldState}
                            setDisable={setDisable}
                        />
                    </div>
                </div>


                <div className="home-btn-content">
                    <UnstyledButton
                        sx={{
                            width: '10rem',
                        }}
                        text="Back"
                        onClick={() => navigate('/dashboard/webiste-contents')}
                    />
                    {/* <button onClick={() => navigate('/dashboard')}> Cancel </button> */}

                    {
                        !disabled
                        && <div className="home-mark">
                            <div
                                className='theme-button home-save'
                            >
                                <UnstyledButton
                                    sx={{
                                        color: disable ? 'white' : 'white'
                                    }}
                                    text="Save"
                                    loading={loader}
                                />
                            </div>
                        </div>
                    }
                </div>
            </form>

        </>
    )

}


export default About;