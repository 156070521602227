import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import '../../../components/common/appointmenCard/card.css'
import Grid from "@mui/material/Grid";
import star from '../../../assets/images/Group 5279.png';
import '../../DoctorProfile/DoctorAccount/doctoraccount.css'
import { useTranslation } from "react-i18next";
import { getCaller } from "../../../services/api";
import { constants, profiles } from "utils/constants";
import { calculateAgeFromDate, fetchAWSObject } from "utils";

const UserAccount = () => {
    const { setProfilePec } = useOutletContext();
    const [patientProfileData, setPatientProfileData] = useState({});
    const navigate = useNavigate()

    const { patientId } = useParams();
    const { three_dots } = constants;

    const fetchPp /* fetch patient profile */ = async () => {
        const response = await getCaller(`admin/users/patient/profile/${patientId}`);
        if (response?.error === false) {
            setPatientProfileData(response?.data);
            const { profile } = response?.data;
            setProfilePec(Number(response?.data?.profile_percentage) || 0)
        }
    }


    useEffect(() => {
        fetchPp();
    }, [])

    const { t } = useTranslation();

    const editData = [
        {
            title: `${t('mobileNumber')}`,
            desc: patientProfileData?.profile?.mobile || three_dots
        },
        {
            title: `${t('emailId')}`,
            desc: patientProfileData?.profile?.email || three_dots,
        },
        {
            title: `${t('gender')}`,
            desc: patientProfileData?.profile?.gender || three_dots,
        },
        {
            title: `${t('location')}`,
            desc: patientProfileData?.profile?.location || three_dots,
        },
        {
            title: `${t('dob')}`,
            desc: patientProfileData?.profile?.dob ?? three_dots,
        },
        {
            title: 'Appointment Taken',
            desc: patientProfileData?.appointments?.completed || three_dots
        },
        {
            title: 'Health Condition',
            desc: patientProfileData?.profile?.condition || three_dots
        },
        {
            title: 'Family Member',
            desc: patientProfileData?.profile?.members?.length || three_dots
        },
        {
            title: `${t('age')}`,
            desc: calculateAgeFromDate(patientProfileData?.profile?.dob) || three_dots
        },
    ];

    const handleNavigate = () => {
        navigate('/addNewPatient', {
            state: {
                patientId: patientProfileData?.profile?.loginId
            }
        })
    }

    return (
        <>

            <div className="profile-attend">
                <div className="profile-attend-body">
                    <div>
                        <p> Doctors Interacted </p>
                        <h3> {patientProfileData?.appointments?.total_appointments ?? three_dots} </h3>
                    </div>

                    <div>
                        <p> Total Spendings(SAR) </p>
                        <h3> {patientProfileData?.total_earnings ?? three_dots} </h3>
                    </div>
                </div>
                <div className="profile-attend-body-1">
                    <div className="apt-body-content">
                        <div className='apt-para1'>
                            <p>Total Appointments</p>
                            <h3> {patientProfileData?.appointments?.total_appointments ?? three_dots}  </h3>
                        </div>
                        <div className='apt-para'>
                            <div className='apt-para-head'>
                                <p> Completed </p>
                                <h3 style={{ color: "#13BC34" }}> {patientProfileData?.appointments?.completed ?? three_dots} </h3>
                            </div>
                        </div>
                        <div className='apt-para'>
                            <div className='apt-para-head'>
                                <p> In Review </p>
                                <h3 style={{ color: "#EFD511" }}>{patientProfileData?.appointments?.in_reviews ?? three_dots}   </h3>
                            </div>
                        </div>
                        <div className='apt-para'>
                            <div className='apt-para-head'>
                                <p>Rescheduled</p>
                                <h3 style={{ color: "#E80707" }}> {patientProfileData?.appointments?.in_reschedule ?? three_dots} </h3>
                            </div>
                        </div>
                        <div className='apt-para'>
                            <div className='apt-para-head'>
                                <p>Cancelled</p>
                                <h3 style={{ color: "#989898" }}>  {patientProfileData?.appointments?.cancelled ?? three_dots} </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Box sx={{ flexGrow: 1, marginTop: 5, border: "1px solid #F0F0F0", backgroundColor: "white", borderRadius: ".625rem" }}>

                <Grid item xs={12} md={12} className="">
                    <div className="profile-head">
                        <p> Patient Profile </p>
                        <div className="">
                            <div
                                style={{
                                    cursor: 'pointer',
                                    padding: '0.5rem 0rem'
                                }}
                                onClick={handleNavigate}
                            >
                                Edit Profile </div>
                        </div>
                    </div>

                    <Grid item xs={12} md={12}>
                        <div className="profile">
                            <div className="profile-img">
                                <img src={fetchAWSObject(patientProfileData?.profile?.url)} alt="" width="175"
                                    height="175"
                                    style={{ borderRadius: '8px' }}
                                />
                                <img src={star} alt="" className="star-img" />
                                <br />
                                <input type="file" id="uploadImage" hidden accept="image/*" />
                                <label
                                    style={{
                                        cursor: 'pointer',
                                        padding: '0.5rem 0rem'
                                    }}
                                    for="uploadImage"
                                    onClick={handleNavigate}
                                >
                                    {t('changePhoto')}
                                </label>
                            </div>
                            <div className="profile-content">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h3> Patient </h3>
                                </div>

                                <Box sx={{ flexGrow: 1, marginTop: 0 }}>
                                    <Grid
                                        container
                                        spacing={{ xs: 2, md: 2 }}
                                        columns={{ xs: 4, sm: 8, md: 12 }}
                                    >
                                        {
                                            editData.map((e, i) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} sm={4} md={4} key={i}>
                                                            <p> {e.title} </p>
                                                            <h5> {e.desc} </h5>
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                    </Grid>
                                </Box>
                                <div className="profile-about">
                                    <p> About </p>
                                    <h5>
                                        {patientProfileData?.profile?.about ?? three_dots}
                                    </h5>
                                </div>

                            </div>
                        </div>
                    </Grid>
                </Grid>

            </Box>
        </>
    );
};

export default UserAccount;
