import React, { useState } from 'react';
import UseroverView from '../pages/dashboard/useroverview/UseroverView';
import './sidebar.css'
import dash from '../assets/images/dashboard.svg'
import apt from '../assets/images/Group 25.svg'
import docreview from '../assets/images/Group 117.svg'
import payment from '../assets/images/payment.svg'
import support from '../assets/images/support.svg'
import setting from '../assets/images/settings.svg'
import AptOverView from '../pages/appointment/AptOverView';
import UserTotal from '../pages/user/UserTotal';
import DoctorReview from '../pages/doctorReview/DoctorReview';
import PaymentOverView from '../pages/payment/PaymentOverView';
import WebsiteContent from '../pages/websitecontent/WebsiteContent';
import CustomerSupportQuery from '../pages/customerSupport/CustomerSupportQuery';
import Configuration from '../pages/configuration/Configuration';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { TbLogout } from 'react-icons/tb';
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';



const SideBar = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [isActive, setIsActive] = useState(false);
    const [index, setIndex] = useState(0);


    // const setTabIndex = index => {
    //     setIndex(index);
    //     localStorage.setItem('dashboardIndex', index);
    // }

    const setActiveInactiveClass = ({ isActive }) => { return isActive ? 'tablink active-side' : 'tablink' }

    // React.useEffect(() => {
    //     console.log("🚀 ~ file: SideBar.jsx ~ line 26 ~ SideBar ~ pathname", pathname)
    //     const dashboardIndex = localStorage.getItem("dashboardIndex");
    //     if (dashboardIndex) {
    //         setIndex(parseInt(dashboardIndex));
    //     }
    // }, [])
    return (
        <>
            <div className='dash-container'>

                <div className="side-left">
                    <ul>
                        <NavLink
                            className={pathname === '/dashboard' ? 'tablink active-side' : 'tablink'}
                            to={''}
                        >
                            <li>
                                <img src={dash} alt="" /> <span> {t("Dashboard")} </span>
                            </li>
                        </NavLink>


                        <NavLink
                            className={setActiveInactiveClass}
                            to={'appointments'}
                        >
                            <li>
                                <img src={apt} alt="" /> <span>{t("Appointments")}</span>
                            </li>
                        </NavLink>

                        <NavLink
                            className={setActiveInactiveClass}
                            to={'/dashboard/users'}
                        >
                            <li>
                                <img src={dash} alt="" /><span> {t("Users")}</span>
                            </li>
                        </NavLink>


                        <NavLink
                            className={setActiveInactiveClass}
                            to={'/dashboard/doctors'}
                        >
                            <li >
                                <img src={docreview} alt="" /> <span>{t("Doctor Reviews")}</span>
                            </li>
                        </NavLink>

                        <NavLink
                            className={setActiveInactiveClass}
                            to={'/dashboard/payments'}
                        >
                            <li >
                                <img src={payment} alt="" /> <span> {t("Payments")}</span>
                            </li>
                        </NavLink>

                        <NavLink
                            className={setActiveInactiveClass}
                            to={'/dashboard/webiste-contents'}
                        >
                            <li>
                                <img src={dash} alt="" /> <span>{t("Website Content")}</span>
                            </li>
                        </NavLink>

                        <NavLink
                            className={setActiveInactiveClass}
                            to={'/dashboard/contact-support'}
                        >
                            <li >
                                <img src={support} alt="" /> <span> {t("Customer Support")}</span>
                            </li>
                        </NavLink>


                        <NavLink
                            className={setActiveInactiveClass}
                            to={'/dashboard/configurations'}
                        >
                            <li>
                                <div>
                                    <div className="accordian-tab" onClick={() => setIsActive(!isActive)}>
                                        <img src={setting} alt="" />   <span > {t("Configuration")} </span>
                                        <p className='plus'>{isActive ? '-' : '+'}</p>
                                    </div>
                                    <ul className='accordian-list'>
                                        {
                                            isActive &&
                                            <>
                                                <NavLink
                                                    to={'/dashboard/configurations'}
                                                >
                                                    <li >
                                                        <span> {t("General")} </span>
                                                    </li>
                                                </NavLink>

                                                <NavLink
                                                    to={'/dashboard/configurations/settings-doctor'}
                                                >
                                                    <li >
                                                        <span>{t("Doctor Settings")} </span>
                                                    </li>
                                                </NavLink>


                                                <NavLink
                                                    to={'/dashboard/configurations/settings-patient'}
                                                >
                                                    <li >
                                                        <span>   {t("Patient Settings")}</span>
                                                    </li>
                                                </NavLink>


                                                <NavLink
                                                    to={'/dashboard/configurations/settings-appointment'}
                                                >
                                                    <li >
                                                        <span> {t("Appointment")} </span>
                                                    </li>
                                                </NavLink>


                                                <NavLink
                                                    to={'/dashboard/configurations/settings-coupon'}
                                                >
                                                    <li >
                                                        <span>{t("Coupon Codes")}</span>
                                                    </li>
                                                </NavLink>


                                                <NavLink
                                                    to={'/dashboard/configurations/settings-teams'}
                                                >
                                                    <li >
                                                        <span>{t("Teams")}</span>
                                                    </li>
                                                </NavLink>
                                            </>
                                        }
                                    </ul>
                                </div>
                            </li>
                        </NavLink>

                        <li className={`tablink ${index === 8 ? 'active-side' : null}`} onClick={
                            () => { localStorage.clear(); navigate('/') }}>
                            <span> <TbLogout /> {t("Logout")} </span>
                        </li>
                    </ul>

                </div>
                <div className="side-right">
                    <Outlet />
                    {/* 
                    <div hidden={index !== 0}>
                        <UseroverView />
                    </div>
                    <div hidden={index !== 1}>
                        <AptOverView />
                    </div>
                    <div hidden={index !== 2}>
                        <UserTotal />
                    </div>
                    <div hidden={index !== 3}>
                        <DoctorReview />
                    </div>
                    <div hidden={index !== 4}>
                        <PaymentOverView />
                    </div>
                    <div hidden={index !== 5}>
                        <WebsiteContent />
                    </div>
                    <div hidden={index !== 6}>
                        <CustomerSupportQuery />
                    </div>
                    <div hidden={index !== 7}>
                        <Configuration />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default SideBar