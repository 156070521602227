import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { getCaller } from 'services/api';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { Download } from '@mui/icons-material';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import eye from '../../../assets/images/eye.png'
import React, { useEffect, useState } from 'react'
import { constants, profiles } from 'utils/constants';
import { dateFormatWW, destructureProfile } from 'utils';
import TableContainer from '@mui/material/TableContainer';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../components/pagination/Pagination'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FilterInput from '../../../components/common/filterfield/FilterInput';


const UserMedical = () => {
    const { three_dots } = constants;
    const { patientId } = useParams();
    const navigate = useNavigate();

    const [pagina, setPagina] = useState(1);
    const [mRecords, setMRecords] = useState("")
    const [porPagina] = useState(5);

    const maximo = mRecords?.count / porPagina;

    const fetchMedicalRecords = async payload => {
        const {
            page
        } = payload;

        let URL = `admin/users/patient/medical/${patientId}?`;

        if (page) {
            URL += 'page=' + page;
        }
        const res = await getCaller(URL)

        setMRecords(res?.data || [])
    }

    useEffect(() => {
        fetchMedicalRecords({ page: pagina })
    }, [pagina])

    useEffect(() => {
        fetchMedicalRecords({})
    }, [])

    return (
        <>
            <div>
                <FilterInput heading={`${mRecords?.count} ${mRecords?.count > 1 ? 'Items' : 'Item'}`} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell> File Name </StyledTableCell>
                                <StyledTableCell > Date Added </StyledTableCell>
                                <StyledTableCell > Reported For </StyledTableCell>
                                <StyledTableCell > Appointment ID </StyledTableCell>
                                <StyledTableCell > Doctor Name</StyledTableCell>
                                <StyledTableCell > Patient Name</StyledTableCell>
                                <StyledTableCell > Action </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {Array.isArray(mRecords?.rows)
                                && mRecords?.rows?.length > 0
                                ? mRecords?.rows?.map((_item, _index) => {
                                    return (
                                        <StyledTableRow key={`User-Medical-Records-${_index}`} >
                                            <StyledTableCell component="th" scope="row">
                                                {_item?.fileName && _item.fileName.slice(0, 10) || three_dots}
                                            </StyledTableCell>
                                            <StyledTableCell>{dateFormatWW(_item?.createdAt) || three_dots} </StyledTableCell>
                                            <StyledTableCell> {_item?.appointment?.serviceName || three_dots} </StyledTableCell>
                                            <StyledTableCell>{_item?.appointment?.appointmentId || three_dots}</StyledTableCell>
                                            <StyledTableCell> {_item?.appointment?.doctor_profile?.name || three_dots} </StyledTableCell>
                                            <StyledTableCell> {_item?.appointment?.user_profile?.name || three_dots} </StyledTableCell>
                                            <StyledTableCell className='table-edit-one' >
                                                <img src={eye} alt="View" onClick={() => navigate('/inreviewAppointment')} />
                                                <Download />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                                : <>
                                    <div className='no-upcoming-content'>
                                        <div>
                                            No Appointments Found
                                        </div>
                                    </div>
                                </>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    )
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default UserMedical;