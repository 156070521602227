import React, { useState } from "react";
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import edit from '../../../../../assets/images/edit-2.svg'
import Pagination from '../../../../../components/pagination/Pagination';

import { useParams } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";
import { dateFormatWW, fetchAWSObject, generateQuery } from "../../../../../utils";
import moment from 'moment';
import FilterInput from "../../../../../components/common/filterfield/FilterInput";
import DialogBox from './Dialog_Modal';
import SubServiceFilterModal from "../../SubServiceFilterModal";
import { deleteCaller, getCaller } from "services/api";
import { constants } from "utils/constants";
import useGlobal from "hooks/useGlobal";

// const DialogBox = React.lazy(() => import("./Dialog_Modal"));

/* ICONS */
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const SubServiceListing = () => {
    const { tKey: [tKey] } = useGlobal();

    /* ISOLATED STATES */
    const { subMasterId } = useParams();

    /* Working states */
    const [list, setList] = React.useState([]);

    /* Modal State */
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({});
    // const [triggerRefresh, setTriggerRefresh] = React.useState(false);


    /* Filter Modal Inculde */
    const [pagina, setPagina] = useState(1);
    const maximo = list?.count / 5;
    const [serviceFilter, setServiceFilter] = React.useState(false)
    const [query, setQuery] = useState("")



    const nextData = async page => {
        const payload = {
            ...query,
            page
        }
        fetchTeamListing(payload)
    }


    /* HANDLING DATA FROM API CALL */
    const fetchTeamListing = async (payload) => {
        let URL = `admin/subServices/${subMasterId}?`;
        URL = generateQuery(URL, payload);
        const res = await getCaller(URL)
        setList(res?.data || {})
    }

    const handleDelete = async ({ serviceId }) => {
        try {
            if (!window.confirm('Do you want to delete this Service ?')) { return }
            await deleteCaller(`admin/deleteSubService/${serviceId}`)
            fetchTeamListing({ ...query, page: pagina })
        } catch (error) {
        }
    }


    React.useEffect(() => {
        fetchTeamListing({})
    }, [])

    React.useEffect(() => {
        nextData(pagina)
    }, [pagina])

    return (
        <>
            <>
                {/* <Header /> */}
                <DialogBox
                    openDialog={open}
                    setOpenDialog={setOpen}
                    parentData={data}
                    fetchTeamListing={fetchTeamListing}
                />
                <SubServiceFilterModal
                    openFilter={serviceFilter}
                    setOpenFilter={setServiceFilter}
                    setQuery={setQuery}
                    refreshData={fetchTeamListing}
                />
                <div>
                    <FilterInput
                        heading="Sub Services"
                        addOnClick={() => {
                            setOpen(true);
                            setData({
                                id: null,
                                masterId: subMasterId
                            })
                        }}
                        handleModalOpen={() =>
                            setServiceFilter(true)
                        }
                        search={false}
                    />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <Cell>Image</Cell>
                                    <Cell>Name</Cell>
                                    <Cell>Created At</Cell>
                                    <Cell>Actions</Cell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    list?.rows?.length > 0 ? list?.rows?.map(item => {
                                        return (
                                            <StyledTableRow>
                                                <Cell>
                                                    <Avatar alt={item.image} src={item.image && fetchAWSObject(item.image)} />
                                                </Cell>

                                                <Cell>
                                                    {item.sub_services?.[tKey] || constants.three_dots}
                                                </Cell>

                                                <Cell style={{ color: "#13BC34", fontWeight: "bold" }} >
                                                    {dateFormatWW(item.createdAt)}
                                                </Cell>

                                                <Cell className='table-edit-one' >
                                                    {/* <img
                                                        src={edit}
                                                        alt="Edit"
                                                    /> */}

                                                    <IconButton color="primary" >
                                                        <EditIcon
                                                            onClick={() => {
                                                                setOpen(true);
                                                                setData({
                                                                    id: item.serviceId,
                                                                    masterId: subMasterId,
                                                                    name: item?.sub_services,
                                                                    image: fetchAWSObject(item.image)
                                                                })
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton color="error">
                                                        <DeleteIcon onClick={() => handleDelete(item)} />
                                                    </IconButton>

                                                </Cell>
                                            </StyledTableRow>
                                        )
                                    }) : <>

                                        <Cell
                                            style={{
                                                color: "#13BC34",
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }}
                                        >
                                            No data found
                                        </Cell>
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
                </div>
            </>


        </>
    )
}

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default SubServiceListing