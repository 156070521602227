import React from 'react';
import Header from '../../../layout/header/Header';
import { useForm } from "react-hook-form";
import { ThemeButton, UnstyledButton } from '../../../components/common/button/Button';
import { Avatar, Grid, IconButton } from '@mui/material';
import { checkAvailability } from '../../../utils';
import { addTeamMember } from '../Call';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TOAST from 'components/common/toast/Success'

const Add = () => {
    /* ISOLATED STATES */
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [loader, setLoader] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [proiflePreview, setProfileView] = React.useState(null);


    const handleImageRender = async e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setValue('file', file)

            if (!checkAvailability(file.type)) { return toast.info(<TOAST message={'Unsupported File'} />) }

            // if (!checkAvailability(file.type)) { return alert("Unsupported File") }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setProfileView(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }

    const onSubmit = async data => {
        const {
            name,
            designation,
            about,
            file
        } = data;

        setLoader(true);
        const formData = new FormData();

        formData.append('name', JSON.stringify(name))

        formData.append('designation', JSON.stringify(designation))

        formData.append('about', JSON.stringify(about))

        formData.append('file', file)

        const response = await addTeamMember(formData);
        console.log("🚀 ~ file: index.jsx:59 ~ onSubmit ~ response", response)
        if (response) {
            toast.success(<TOAST message={'Team Added Successfully'} />)
            navigate('/dashboard/configurations/settings-teams');
        } else {
            alert(response.msg)
        }
        setLoader(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* FILLING SECTION */}
                <div
                    className='edit-home-container'
                    style={{
                        margin: '1rem',
                        width: '100%'
                    }}
                >
                    <h3>Create Team Member</h3>
                    <div className="home-wrapper">
                        <div className="home-form">
                            <div className='home-input-content'>
                                <label htmlFor="home"> Name (EN)</label> <br />
                                <input
                                    {...register("name.en", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member name (en)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.name?.en && 'Name (en) is required'}
                                </div>
                            </div>
                            <div className='home-input-content'>
                                <label htmlFor="home"> Name (AR)</label> <br />
                                <input
                                    {...register("name.ar", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member name (ar)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.name?.ar && 'Name (ar) is required'}
                                </div>
                            </div>

                            <div className='home-input-content' style={{ marginLeft: "2rem" }}>
                                <label htmlFor="home"> Designation (EN)</label> <br />
                                <input
                                    {...register("designation.en", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member designation (en)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.designation?.en && 'Designation (en) is required'}
                                </div>
                            </div>
                            <div className='home-input-content' style={{ marginLeft: "2rem" }}>
                                <label htmlFor="home"> Designation (AR)</label> <br />
                                <input
                                    {...register("designation.ar", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member designation (ar)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.designation?.ar && 'Designation (ar) is required'}
                                </div>
                            </div>

                            <div className='home-input-content home-input-content-20' style={{ marginLeft: "2rem" }}>
                                <label > Profile </label> <br />

                                <Grid container spacing={2}>
                                    <Grid item>
                                        <label
                                            htmlFor='profile_photo'
                                            // className='home-input-field-file'
                                            placeholder='Upload'
                                            style={{ border: 'none', display: 'block' }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                margin: "auto",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "100%"
                                            }}
                                        >
                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                <input
                                                    {...register("file")}
                                                    hidden
                                                    type="file"
                                                    name="file"
                                                    id="profile_photo"
                                                    className='hidden'
                                                    accept="image/*"
                                                    onChange={handleImageRender}
                                                />
                                                <Avatar id='profile_photo' alt="P" src={proiflePreview} />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className={'input-invalid-message'}>
                                </div>
                            </div>
                        </div>

                        <div className="home-form">
                            <div className='home-input-content home-input-content-full'>
                                <label htmlFor="home"> About (EN)</label> <br />
                                <textarea
                                    {...register("about.en", { required: true })}
                                    type="text"
                                    className='home-input-field home-input-content-full home-input-field-textarea'
                                    placeholder='Member about (en)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.about?.en && 'About (en) is required'}
                                </div>
                            </div>
                            <div className='home-input-content home-input-content-full'>
                                <label htmlFor="home"> About (AR)</label> <br />
                                <textarea
                                    {...register("about.ar", { required: true })}
                                    type="text"
                                    className='home-input-field home-input-content-full home-input-field-textarea'
                                    placeholder='Member about (ar)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.about?.ar && 'About (ar) is required'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* SUBMIT SECTION */}
                <div
                    className="home-btn-content"
                    style={{
                        position: 'unset',
                        backgroundColor: 'unset',
                        boxShadow: 'unset'
                    }}
                >
                    <UnstyledButton
                        onClick={() => navigate('/dashboard/configurations/settings-teams')}
                        text={'Cancel'}
                    />
                    <div className="home-mark">
                        <div
                            className=''
                            style={{
                                color: disable ? 'black' : 'white'
                            }}
                        >
                            <ThemeButton
                                text="Save"
                                loading={loader}
                            />
                        </div>
                    </div>
                </div>

            </form>
        </>
    )
}


export default Add;