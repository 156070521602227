import { React, useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../../../layout/header/Header'
import './push.css'
import { getCaller, postCaller } from '../../../../services/api'
import { Multiselect } from "multiselect-react-dropdown";
import { generateQuery } from '../../../../utils/index';

import * as Yup from 'yup'
import { useFormik } from 'formik'

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import useMasters from 'hooks/useMasters'
import { toast } from 'react-toastify'
import { responseMessages } from 'utils/responseMessages';
import { ThemeButton, UnstyledButton } from 'components/common/button/Button'
import { Autocomplete, TextField } from '@mui/material'
import { constants } from 'utils/constants'
import AvatarChip from './AvatorChip'



const INITIAL_USERS = [{ name: 'All', loginId: -1 }]

const validationSchema = Yup.object().shape({
    message: Yup.string().required('Message Can not be blank!'),
    selected_users: Yup.string().required('Please select atleast 1 user')
})

const initialValues = {
    message: '',
    selected_users: ''
}

const PushNotification = () => {
    const { user } = useMasters();
    const navigate = useNavigate();

    const usersRef = useRef();
    const userTypeRef = useRef();
    const autoCompleteInputRef = useRef(null)

    const [users, setUsers] = useState([]);
    const [usersState, setUsersState] = useState({})
    const [selectedUsers, setSelectedUsers] = useState([])

    const [selectedUserText, setSelectedUserText] = useState('')

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            console.log("🚀 ~ file: PushNotification.jsx:56 ~ onSubmit: ~ values", values)
            try {
                // const users = usersRef.current.getSelectedItems(); /* Retreving the selected values from multi select */

                /* Generating payload for API call */
                const payload = {
                    message: values.message,
                    type: userTypeRef.current?.value,
                    ids: [...selectedUsers?.map(user => user.loginId)]
                }

                const response = await postCaller('admin/push-notifications', payload)

                /* Handling request */
                if (response?.error == false) {
                    toast.success(response?.msg || responseMessages.UNKNOWN_ERROR)
                } else {
                    toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
                }
            } catch (error) {
                console.error("🚀 ~ file: PushNotification.jsx:66 ~ onSubmit: ~ error", error)
            } finally {
                setSubmitting(false)
            }
        }
    })

    const { values, errors, touched, handleChange, setFieldValue, submitForm, isSubmitting } = formik;
    console.log("🚀 ~ file: PushNotification.jsx:82 ~ PushNotification ~ errors", errors)


    const onTypeChangeHandler = value => {
        fillDropDownUserwise({ type: value === '-1' ? '' : value })
    };

    const fillDropDownUserwise = async (payload) => {
        setSelectedUsers([])

        let URL = 'admin/users-listing?';
        URL = generateQuery(URL, payload);
        const response = await getCaller(URL);
        if (response?.error === false) {
            setUsers([...INITIAL_USERS, ...response?.data])
            // let filtered = response?.data?.map(
            //     user => {
            //         if (user?.doctor_profile?.name || user?.user_profile?.name) {
            //             return {
            //                 id: user?.loginId,
            //                 name: (user?.doctor_profile ? user?.doctor_profile?.name : user?.user_profile?.name) || ''
            //             }
            //         }
            //     }
            // )
            // filtered = filtered?.filter(x => x) /* just to remove undefined values, please do not get mad. I'm low on time. have to do! */
            // setUsers([...INITIAL_USERS, ...filtered])
        }
    }

    const handleUserSelect = (selectedList, selectedItem) => {
        if (selectedItem?.loginId === -1) {
            console.log("------------------------Im-HERE------------------------")
            usersRef.current?.resetSelectedValues() /* Removing selected users, before selecting new category */
            // setUsers([...INITIAL_USERS])
            // setFieldValue('selected_users', 'xyz')
        } else {
            // setValue('selected_users', 'hellllo')
            if (Array.isArray(selectedList)) {
                if (selectedList.length === 0) {
                    setFieldValue('selected_users', '')
                    // setValue('selected_users', 'true') /* adding random value from my side */
                } else {
                    setFieldValue('selected_users', 'xyz')
                    // setValue('selected_users', null)
                }
            }
        }
    }

    const handleUserRemove = (mailList, userRemoved) => {
        if (userRemoved?.loginId === -1) {
            const { value } = userTypeRef.current
            value && fillDropDownUserwise({ type: value === '-1' ? '' : value })
            setFieldValue('selected_users', '')
        }
    }

    const handleOptionLabels = option => {
        if (!option) { return '' }
        if (option.loginId === -1) {
            return option.name
        }

        const formated = {
            name: (option?.doctor_profile ? option?.doctor_profile?.name : option?.user_profile?.name) || constants.N_A,
            mobile: option?.mobile,
            id: option?.loginId
        }

        return `${formated.mobile} - ${formated.name}`;
    }

    const handleUsersOnChange = (event, newValue) => {
        if (newValue?.loginId === -1) {
            setUsers([])
            setSelectedUsers([{ ...newValue }])
            setSelectedUserText('')
            return;
        }

        setSelectedUsers(prev => ([...prev, { ...newValue }]))

        setUsers([...users.filter(user => user.loginId !== newValue.loginId)])
        setSelectedUserText('')

        // console.log("🚀 ~ file: PushNotification.jsx:155 ~ handleUsersOnChange ~ indexOfOld", indexOfOld)
        // const temp = [...users];
        // temp[indexOfOld].isSelected = true
        // setUsers([...temp])
    }


    const handleChipDelete = unselectedUser => {
        setSelectedUsers(prev => {
            return [...selectedUsers.filter(user => user.loginId !== unselectedUser.loginId)]
        })

        if (unselectedUser?.loginId === -1) {
            fillDropDownUserwise({ value: userTypeRef.current?.value })
            setSelectedUserText('')
            return;
        }

        setUsers(prev => [...prev, { ...unselectedUser }])
        // const indexOfOld = users.findIndex(user => user.loginId === loginId)
        // console.log("🚀 ~ file: PushNotification.jsx:155 ~ handleUsersOnChange ~ indexOfOld", indexOfOld)
        // const temp = [...users];
        // temp[indexOfOld].isSelected = false
        // setUsers([...temp])
    }

    useEffect(() => {
        console.log("🚀 ~ file: PushNotification.jsx:179 ~ useEffect ~ selectedUsers", selectedUsers)
    }, [selectedUsers])

    useEffect(() => {
        if (selectedUsers?.length > 0) {
            setFieldValue('selected_users', 'xyz')
        } else {
            setFieldValue('selected_users', '')
        }
    }, [selectedUsers])

    useEffect(() => {
        fillDropDownUserwise({})
    }, []);

    return (
        <>
            <Header />
            <div className='push-container'>
                <p>Push Notification</p>
                <form onSubmit={e => e.preventDefault()} className='form'>
                    {/* Form */}
                    <div className='form-body'>
                        <div className="push-input-content">
                            <label className='mb-1' htmlFor=""> Message </label>
                            <input
                                name="message"
                                placeholder='Write your message here'
                                value={values.message}
                                onChange={handleChange}
                                aria-invalid={errors.msg ? "true" : "false"}
                            />
                            <small className='error'>
                                {
                                    touched.message
                                    && errors?.message
                                }
                            </small>
                        </div>
                        <div className="push-input-content">
                            <label htmlFor="" className='mb-1'> Select Type </label>
                            <Select
                                inputRef={userTypeRef}
                                style={{
                                    width: '100%'
                                }}
                                size={'small'}
                                name="userType"
                                defaultValue={'-1'}
                                onChange={(e) => onTypeChangeHandler(e.target.value)}
                            >
                                <MenuItem value={'-1'}>All</MenuItem>
                                {
                                    Object.keys(user).length > 0
                                        ? Object.keys(user).map(key => {
                                            return (
                                                <MenuItem
                                                    className='text-capitalize'
                                                    key={key}
                                                    value={user?.[key]?.DB}
                                                >
                                                    {user[key].UI}
                                                </MenuItem>
                                            );
                                        })
                                        : <NoItemFound />
                                }
                            </Select>
                            <small className='error'></small>
                        </div>

                        <div className="">
                            <label htmlFor="" className='mb-1'> Select user(s) to send notification </label>
                            {/* <input type="text" placeholder='Write your message here'/> */}
                            {/* <Multiselect
                                required
                                ref={usersRef}
                                options={users}
                                displayValue={'name'}
                                onSelect={handleUserSelect}
                                onRemove={handleUserRemove}
                                name='selected_users'
                            />
                             */}


                            <Autocomplete
                                value={selectedUserText}
                                options={users}
                                getOptionLabel={handleOptionLabels}
                                id="include-input-in-list"
                                blurOnSelect
                                onChange={handleUsersOnChange}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField {...params} inputRef={autoCompleteInputRef} variant="outlined" size='small' />
                                )}
                            />
                            <small className='error'>
                                {
                                    touched.selected_users
                                    && errors?.selected_users
                                }
                            </small>
                        </div>
                        <div className='my-2'>
                            {
                                selectedUsers.map(
                                    (user, index) => {
                                        console.log("🚀 ~ file: PushNotification.jsx:294 ~ PushNotification ~ user", user)
                                        if (user?.loginId === -1) {
                                            return <AvatarChip
                                                label={`All`}
                                                onDelete={() => handleChipDelete(user)}
                                            />
                                        }
                                        const name = (user?.doctor_profile ? user?.doctor_profile?.name : user?.user_profile?.name) || constants.N_A;
                                        return <AvatarChip
                                            label={`${name} - ${user.mobile}`}
                                            onDelete={() => handleChipDelete(user)}
                                        />
                                    }
                                )
                            }
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="form-btn">
                        <UnstyledButton
                            text={'Cancel'}
                            className='home-cancel'
                            onClick={() => navigate('/dashboard/configurations')}
                        />
                        <ThemeButton
                            loading={isSubmitting}
                            text={'Send Notifications'}
                            onClick={submitForm}
                            className='home-save'
                        />
                    </div>
                </form>

            </div>

        </>
    )
}

function NoItemFound() {
    return <MenuItem>No Items</MenuItem>
}

export default PushNotification