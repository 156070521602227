import { Backdrop, Box, Fade, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import useMasters from 'hooks/useMasters';
import React, { useMemo } from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaRegTimesCircle } from 'react-icons/fa';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { dateFormat } from 'utils';



const MODAL_INPUT_SIZE = 'small';
const FilterModal = ({
    open,
    handleClose,
    setQuery,
    refreshData
}) => {
    const {
        appointment,
        gender,
        services
    } = useMasters();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const [calenderValue, setCalenderValue] = useState([null, null])


    const onSubmit = data => {
        const [from, to] = calenderValue;
        const _data = {
            ...data,
            appointmentDateFrom: from && dateFormat(from),
            appointmentDateTo: to && dateFormat(to)
        }
        setQuery(_data)
        refreshData(_data)
        handleClose()
    }


    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="query-pop-up">
                                <h4> Filter Appointments </h4>
                                <span onClick={handleClose}>
                                    <FaRegTimesCircle
                                        style={{
                                            width: "2rem",
                                            height: "1.5rem"
                                        }}
                                    />
                                </span>
                            </div>

                            {/* Content */}
                            <Typography id="transition-modal-description" className='query-para' >
                                <div className='query-body'>
                                    <div className="query-details">
                                        <p>Appointment ID</p>
                                        <TextField
                                            type={'number'}
                                            className="width-full"
                                            size='small'
                                            name='age'
                                            {...register("appointmentId")}
                                        />
                                    </div>


                                    <div className="query-details">
                                        <p>Appointment Date</p>
                                        <DateRangePicker
                                            ranges={[]}
                                            className="width-full text-capitalize"
                                            showOneCalendar // to show only one calender
                                            value={calenderValue}
                                            onChange={newValue => setCalenderValue(newValue)}
                                            name="valid"
                                            format="dd-MM-yyyy"
                                            placeholder="Select Date Range"
                                            placement='auto'
                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Speciality Choosen</p>
                                        <Select
                                            className="width-full text-capitalize"
                                            size={MODAL_INPUT_SIZE}
                                            {...register("serviceName")}
                                        >
                                            {
                                                services.length > 0
                                                    ? services.map(key => {
                                                        return (
                                                            <MenuItem
                                                                className='text-capitalize'
                                                                key={key.UI}
                                                                value={key.UI}
                                                            >
                                                                {key.UI}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    : <NoItemFound />
                                            }
                                        </Select>
                                    </div>

                                    <div className="query-details">
                                        <p>Patient Name</p>
                                        <TextField
                                            type={'number'}
                                            className="width-full"
                                            size='small'
                                            name='age'
                                            {...register("patientName")}
                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Age</p>
                                        <TextField
                                            type={'number'}
                                            className="width-full"
                                            size='small'
                                            name='age'
                                            {...register("age")}
                                        />
                                    </div>
                                    <div className="query-details">
                                        <p>Gender</p>
                                        <Select
                                            className="width-full text-capitalize"
                                            size={MODAL_INPUT_SIZE}
                                            {...register("gender")}
                                        >
                                            {
                                                Object.keys(gender).length > 0
                                                    ? Object.keys(gender).map(key => {
                                                        return (
                                                            <MenuItem
                                                                className='text-capitalize'
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {gender[key]}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    : <NoItemFound />
                                            }
                                        </Select>
                                    </div>

                                    {/* <div className="query-details">
                                        <p>Status</p>
                                        <Select
                                            className="width-full text-capitalize"
                                            size={MODAL_INPUT_SIZE}
                                            {...register("status")}
                                        >
                                            {
                                                Object.keys(appointment).length > 0
                                                    ? Object.keys(appointment).map(key => {
                                                        return (
                                                            <MenuItem
                                                                className='text-capitalize'
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {appointment[key].UI}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    : <NoItemFound />
                                            }
                                        </Select>
                                    </div> */}



                                </div>
                            </Typography>

                            {/*  */}
                            <div className="query-btn-content">
                                <div className="query-mark-btn">
                                    <button
                                        className='mark-query text-white'
                                        // onClick={handleFilter}
                                        type='submit'
                                        style={{
                                            backgroundColor: '#2f77b6'
                                        }}
                                    >
                                        Filter
                                    </button>

                                    <div
                                        className='mark-query text-white'
                                        style={{
                                            backgroundColor: 'var(--steel-blue)'
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

function NoItemFound() {
    return <MenuItem>No Items</MenuItem>
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height:450,
    borderRadius: "1rem",
    bgcolor: 'white',

    // boxShadow: 24,
    // paddingTop:"1rem"
};


export default FilterModal