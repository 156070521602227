import React, { useRef, useState } from "react";
import { styled } from '@mui/material/styles';

import FilterInput from "components/common/filterfield/FilterInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import sortarrow from 'assets/images/sort-arrow.svg'
import eye from 'assets/images/eye.png'
import edit from 'assets/images/edit-2.svg'
import Pagination from 'components/pagination/Pagination';
import { useNavigate } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import moment from 'moment';
import { getCaller } from "services/api";
import { fetchAWSObject, generateQuery } from "utils";


let orderStatus = false
const Teams = props => {
    /* ISOLATED STATES */
    const navigate = useNavigate();

    /* Working states */
    const [pagina, setPagina] = useState(1);
    const [porPagina, setPorPagina] = useState(5);
    const [list, setList] = React.useState({});
    const [inputValue, setInputValue] = useState("")
    const searchTextFieldRef = useRef(null);


    const maximo = list?.count / porPagina;

    const nextData = async page => {
        const payload = {
            page
        }
        fetchTeamListing(payload)
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        fetchTeamListing({ page: 1, searchText: value })
    }

    const filterSortOrder = () => {
        orderStatus = !orderStatus;
        fetchTeamListing({ page: pagina, orderStatus: orderStatus ? "ASC" : "DESC" })
    }

    /* HANDLING DATA FROM API CALL */
    const fetchTeamListing = async (payload) => {
        console.log('here');
        let URL = 'admin/teams/listing?';

        URL = generateQuery(URL, payload);
        const teams = await getCaller(URL);
        setList(teams?.data || {});
    }


    React.useEffect(() => {
        fetchTeamListing();
    }, [])

    React.useEffect(() => {
        nextData(pagina)
    }, [pagina])


    const handleRedirectPage = (index, disable) => navigate(`edit/${index}`, { state: { disabled: disable } })
    const handleRedirectAddPage = () => navigate(`add`)

    return (
        <>
            <>
                <div>
                    <FilterInput
                        heading="Teams"
                        searchRef={searchTextFieldRef}
                        searchHandler={handleTextFieldButton}
                        addOnClick={handleRedirectAddPage}
                        onKeyUp={event => event.key === 'Enter' && handleTextFieldButton()}
                    />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <Cell>Profile</Cell>
                                    <Cell>Name</Cell>
                                    <Cell>Designation</Cell>
                                    <Cell>About</Cell>
                                    <Cell>Created At</Cell>
                                    <Cell>Actions</Cell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    list?.rows?.length > 0 ? list.rows.map(item => {
                                        return (
                                            <StyledTableRow>
                                                <Cell>
                                                    <Avatar alt={item.name?.['en']} src={item.profile && fetchAWSObject(item.profile)} />
                                                </Cell>

                                                <Cell>
                                                    {item.name?.['en']}
                                                </Cell>

                                                <Cell>
                                                    {
                                                        item.designation?.['en']
                                                        && item.designation?.['en']?.length > 37
                                                        && `${item.designation?.['en'].slice(0, 37)}...`
                                                        || item.designation?.['en']
                                                    }
                                                </Cell>

                                                <Cell className="text-adj">
                                                    {
                                                        item.about?.['en']
                                                        && item.about?.['en']?.length > 37
                                                        && `${item.about?.['en'].slice(0, 37)}...`
                                                        || item.about?.['en']
                                                    }
                                                </Cell>

                                                <Cell style={{ color: "#13BC34", fontWeight: "bold" }} >
                                                    {moment(item.createdAt).format('DD-MM-YYYY')}
                                                </Cell>

                                                <Cell className='table-edit-one' >
                                                    <img
                                                        src={eye}
                                                        alt="View"
                                                        onClick={() => handleRedirectPage(item.teamId, true)}
                                                    />
                                                    <img
                                                        src={edit}
                                                        alt="Accept"
                                                        onClick={() => handleRedirectPage(item.teamId, false)}
                                                    />
                                                </Cell>
                                            </StyledTableRow>
                                        )
                                    }) : <>

                                        <Cell
                                            style={{
                                                color: "#13BC34",
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }}
                                        >
                                            No data found
                                        </Cell>
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
                </div>
            </>

        </>
    )
}

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: 'top'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        verticalAlign: 'top',
        userSelect: 'none'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default Teams;