import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '../../components/pagination/Pagination'
import '../../components/common/filterfield/filter.css'
import sortarrow from '../../assets/images/sort-arrow.svg'
import { dateFormatWW_TIME, destructureProfile } from 'utils';
import { useRef } from 'react';
import FilterModal from './FilterModal';
import { useTranslation } from 'react-i18next';


let orderStatus = false;
const PaymentTable = ({
    data,
    setData,
    refreshData
}) => {
    const [pagina, setPagina] = useState(1);
    const [porPagina, setPorPagina] = useState(5);
    const [modal, setModal] = useState(false);
    const [query, setQuery] = useState({})

    const searchTextFieldRef = useRef(null);

    const {t} = useTranslation()


    const maximo = data?.count / porPagina;

    const nextData = async page => {
        const payload = {
            ...query,
            page
        }
        refreshData(payload)
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        setQuery(prev => ({...prev, searchText: value}))
        refreshData({ page: 1, searchText: value })
    }

    const filterSortOrder = () => {
        orderStatus = !orderStatus;
        refreshData({ page: pagina, orderStatus: orderStatus ? "ASC" : "DESC" })
    }

    const handleModalOpen = () => { setModal(true) }
    const handleModalClose = () => { setModal(false) }

    useEffect(() => {
        nextData(pagina)
    }, [pagina])


    return (
        <>
            <FilterModal
                open={modal}
                setQuery={setQuery}
                setPagina={setPagina}
                handleClose={handleModalClose}
                refreshData={refreshData}
            />
            <div>
                <div className="filter-field">
                    <p> {data?.count ?? "0"} {t("Items")} </p>
                    <div className="filter-search">
                        {/* <div className="date" >
                            <input type="date" name="" id="" />
                        </div> */}
                        <div className="filter-search-field">
                            <input
                                type="text"
                                id="search-box"
                                placeholder={t("Search")}
                                ref={searchTextFieldRef}
                                onKeyUp={event => event.key === 'Enter' && handleTextFieldButton()}
                            />
                            <label
                                onClick={handleTextFieldButton}
                                htmlFor="search-box"
                                className="fas fa-search"
                            ></label>
                        </div>
                        <div className="filter-button">
                            <button onClick={handleModalOpen}> {t("Filter")} </button>
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700, minHeight: 500 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell> {t("Tranaction ID")} </StyledTableCell>
                                <StyledTableCell> {t("Username")} </StyledTableCell>
                                <StyledTableCell> {t("emailId")} </StyledTableCell>
                                <StyledTableCell> Date & Time </StyledTableCell>
                                <StyledTableCell>
                                    {t("Status")} <img onClick={filterSortOrder} src={sortarrow} alt="" />
                                </StyledTableCell>
                                <StyledTableCell> {t("Amount Paid")} </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data?.rows?.map((e, i) => (
                                    <StyledTableRow key={`Payment_Table_${i}`}>
                                        <StyledTableCell component="th" scope="row">
                                            {e?.transactionId ?? "n/a"}
                                        </StyledTableCell>
                                        <StyledTableCell> {destructureProfile(e)?.name ?? "n/a"} </StyledTableCell>
                                        <StyledTableCell> {destructureProfile(e)?.email ?? "n/a"} </StyledTableCell>
                                        <StyledTableCell> {dateFormatWW_TIME(e?.createdAt) ?? "n/a"} </StyledTableCell>
                                        {/* <StyledTableCell> {e?.method ?? "n/a"} </StyledTableCell> */}
                                        <StyledTableCell
                                            style={{
                                                color: "#13BC34",
                                                fontWeight: "bold",
                                                textTransform: "uppercase"
                                            }}
                                        > {e?.status ?? "n/a"} </StyledTableCell>
                                        <StyledTableCell className='table-edit-one' >
                                            {e?.amount ?? "n/a"}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    )
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: 'top'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        verticalAlign: 'top',
        userSelect: 'none'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default PaymentTable