import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getCaller } from 'services/api';
import { appointmentStatus } from 'utils/constants';
import AptTable from '../../../pages/appointment/AptTable';

const UpcomingAppointment = () => {
    const [appointment, setAppointment] = useState("")
    const { doctorId } = useParams();


    const appointmentsData = async payload => {
        const {
            page
        } = payload;

        let URL = `admin/users/doctor/appointments/${appointmentStatus.Upcoming}/${doctorId}?`;

        if (page) {
            URL += 'page=' + page;
        }
        const res = await getCaller(URL)

        setAppointment(res?.data || [])
    }

    useEffect(() => {
        appointmentsData({})
    }, [])

    return (
        <>
            <AptTable
                data={appointment}
                refreshData={appointmentsData}
            />
        </>
    )
}

export default UpcomingAppointment