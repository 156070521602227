import { Card, Typography } from "@mui/material"
import { Box } from "@mui/system";
import UsersAttachment from "./UsersAttachments";
import UserIcon from "assets/images/chat-icons/patient-avator.png"
import DoctorIcon from "assets/images/chat-icons/doctor-avator.png"

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AttachmentContainer = ({ data }) => {

    const usersAttachment = data.filter(d => {
        let postFix = d.Key.split('_')[3].split('.')[0];
        if (postFix == 'user') {
            return true;
        } else {
            return false;
        }
    });
    const doctorsAttachment = data.filter(d => {
        let postFix = d.Key.split('_')[3].split('.')[0];
        if (postFix == 'doctor') {
            return true;
        } else {
            return false;
        }
    });


    return (
        <>
            <div style={{
                display: 'grid',
                gridTemplateColumns: "1fr 5fr",

            }}>
                <span style={{
                    width: '2rem',
                    margin: '4px auto'
                }}>
                    <img src={UserIcon} alt="" style={{ width: '25px' }} />
                </span>
                <div
                    className='thin-scrollbar'
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        width: '100%',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        borderLeft: '1px solid #bbb4b4',
                        padding: '0 10px'

                    }}>
                    <UsersAttachment data={usersAttachment} />
                </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: "1fr 5fr" }}>
                <span style={{
                    width: '2rem',
                    margin: '4px auto'
                }}>
                    <img src={DoctorIcon} alt="" style={{ width: '25px' }} />
                </span>
                <div
                    className='thin-scrollbar'
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        width: '100%',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        borderLeft: '1px solid #bbb4b4',
                        padding: '0 10px'

                    }}>
                    {
                        <UsersAttachment data={doctorsAttachment} />
                    }
                </div>
            </div>

        </>
        // </Box>
    )
}

export default AttachmentContainer;