import React, { useState } from 'react'
import AptTable from 'pages/appointment/AptTable';
import { getCaller } from 'services/api';
import { appointmentStatus } from 'utils/constants';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';


const ReviewAppointment = () => {
    const [appointment, setAppointment] = useState("")
    const { doctorId } = useParams();


    const appointmentsData = async payload => {
        const {
            page
        } = payload;

        let URL = `admin/users/doctor/appointments/${appointmentStatus.inReview}/${doctorId}?`;

        if (page) {
            URL += 'page=' + page;
        }
        const res = await getCaller(URL)

        setAppointment(res?.data || [])
    }

    useEffect(() => {
        appointmentsData({})
    }, [])
    return (
        <>
            <AptTable
                data={appointment}
                refreshData={appointmentsData}
            />
            {/* <div>
                <FilterInput heading="reviews Items" />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell> Appointment ID </StyledTableCell>
                                <StyledTableCell > Appointment Date & Time </StyledTableCell>
                                <StyledTableCell > Specialisation Chosen </StyledTableCell>
                                <StyledTableCell > Patient Name </StyledTableCell>
                                <StyledTableCell > Gender, Age </StyledTableCell>
                                <StyledTableCell > Status <img src={sortarrow} alt="" /> </StyledTableCell>
                                <StyledTableCell > Action </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <StyledTableRow >
                                <StyledTableCell component="th" scope="row">
                                    appointmentId
                                </StyledTableCell>
                                <StyledTableCell>78 </StyledTableCell>
                                <StyledTableCell> 78 </StyledTableCell>
                                <StyledTableCell> 45</StyledTableCell>
                                <StyledTableCell> n/a </StyledTableCell>
                                <StyledTableCell style={{ color: "#F28519", fontWeight: "bold" }} > staus </StyledTableCell>
                                <StyledTableCell className='table-edit-one' >
                                    <img src={eye} alt="View" onClick={() => navigate('/inreviewAppointment')} />
                                    <img src={check} alt="Accept" />
                                    <img src={cross} alt="Reject" />
                                    <img src={reshc} alt="Reschedule" />
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination />
            </div> */}
        </>
    )
}

export default ReviewAppointment