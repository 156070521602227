import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import message from '../../assets/images/message_icon.png'
import logo from "../../assets/images/header-logo.png";
import translate from '../../assets/images/language-logo.png'
import chevronDown from '../../assets/images/chevronDown.png'
import bell from '../../assets/images/Group 5900.png'
import { useTranslation } from "react-i18next"
import './header.css'
import LocaleContext from "../../LocaleContext";
import i18next from "i18next";
import useGlobal from "hooks/useGlobal";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
const Header = () => {
    const navigate = useNavigate()

    const { tKey: [tKey, setTKey] } = useGlobal();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [lang, setLang] = useState('English')
    const [name, setName] = useState(null)
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext)
    const [open, setOpen] = useState(false);
    
    

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    

    // function changeLocale(l) {
    //     if (locale !== l) {
    //         i18next.changeLanguage(l)
    //     }
    // }


    function setNavName() {
        const type = localStorage.getItem('type')
        const name = localStorage.getItem('name')
        if (type === 'user' && !name) {
            setName("Patient")
        }
        else if (type === 'doctor' && !name) {
            setName("Doctor")
        }
        else {
            setName(name)
        }
    }



    /* Change the language */
    function changeLocale(l) {
        // console.log("change language")
        if (tKey === 'en') {
            setTKey('ar')
        } else {
            setTKey('en')
        }
        if (locale !== l) {
            i18next.changeLanguage(l)
        }

    }


    // const handleDropDown = (whatToDo) => {
    //     switch (whatToDo) {
    //         case 'en':
    //             setTKey('en')
    //             changeLocale('en');
    //             setLang("English");
    //             break;

    //         case 'ar':
    //             setTKey('ar')
    //             changeLocale('ar');
    //             setLang("Arabic");
    //         default:
    //             break;
    //     }
    // }

    const handleLogout = () => {
        localStorage.clear();
        navigate('/')
        handleClose()
    }



    useEffect(() => {
        setNavName()
    }, [])

    return (
        <>
            <header className="header">
                <div className="logo-header">
                    <Link to="/">
                        <img src={logo} alt="" className="logo" />
                    </Link>
                    <div className="dropdown">
                        <button className="dropbtn">
                            <img src={translate} alt="translate" className="translate" />
                            {lang}
                            <img src={chevronDown} className="chevronDown" alt="translate" />
                        </button>
                        <div className="dropdown-content">
                            <span
                                onClick={() => { changeLocale('en'); setLang("English") }}>
                                English
                            </span>
                            <span onClick={() => { changeLocale('ar'); setLang("Arabic") }}>
                                Arabic
                            </span>
                        </div>
                    </div>
                </div>
                <div className={`book-list-nav ? ${open ? "book-is-expended" : ""}`} >
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            columnGap: '.5rem'
                        }}>
                            {t('Admin')}
                            <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <img src={localStorage.getItem('profileImage') ? localStorage.getItem('profileImage') : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" style={{
                                    cursor: "pointer", width: '3rem', height: '3rem',
                                    borderRadius: '50%'
                                }} />
                                {/* <Acco{t('Admin')} untCircle /> */}
                            </IconButton>
                        </div>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            // anchorOrigin={{
                            //     vertical: 'top',
                            //     horizontal: 'right',
                            // }}
                            // keepMounted
                            // transformOrigin={{
                            //     vertical: 'top',
                            //     horizontal: 'right',
                            // }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </div>
                {/* {
                    open ? (
                        <div className="fas fa-close" id="close-btn" onClick={menuOpen} ></div>
                    ) : (
                        <div className="fas fa-bars" id="menu-btn" onClick={menuOpen}></div>
                    )
                } */}
            </header>
        </>
    );
};
export default Header;
