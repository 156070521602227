import React, { useEffect, useState } from 'react'
import '../../../websitecontent/homePage/edithome.css';
import Header from '../../../../layout/header/Header'
import '../HomePage'
import { checkAvailability } from '../../../../utils';
import { postCaller } from '../../../../services/api';
import { MdImage } from 'react-icons/md';
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';


const INITIAL__LANGUAGE = { en: '', ar: '' };
const IMAGES_INITIAL_VALUE = [INITIAL__LANGUAGE, INITIAL__LANGUAGE, INITIAL__LANGUAGE];

const SectionThree = ({ sectionThreeData, setSectionThreeData, disabled }) => {
    const [title, setTitle] = useState(INITIAL__LANGUAGE);
    const [subtitle, setSubtitle] = useState(INITIAL__LANGUAGE);
    const [images, setImages] = useState(IMAGES_INITIAL_VALUE)

    const { t } = useTranslation()



    const uploadAndDelete = async (file, old_image_url) => {
        /* Generating FormData for background image*/
        const formData = new FormData();
        formData.append('file', file);
        formData.append('old_file', old_image_url);

        /* Passing form to API */
        const response = await postCaller('homepage/uploads', formData, true);
        if (response.error == false) {
            return response?.data?.Key;
        }
        return false;
    }

    const handleImages = async (e, index) => {
        let _temp = images;
        const current = e.target;
        if (current.files && current.files.length !== 0) {
            let file = current.files[0];
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
            if (!checkAvailability(file.type)) { return alert("Unsupported File") }

            const newKey = await uploadAndDelete(file, _temp[index].image);

            _temp[index].image = newKey;
            setSectionThreeData(prev => {
                return {
                    ...prev,
                    images: [..._temp]
                }
            })
            // setBackgroundImage(newKey);
        }
    }

    useEffect(() => {
        setTitle(sectionThreeData?.title)
        setSubtitle(sectionThreeData?.subtitle)
        setImages(sectionThreeData?.images || IMAGES_INITIAL_VALUE)
    }, [sectionThreeData])

    return (
        <>
            <Header />
            {/* section 3 */}
            <div className="home-section-one">
                <p> Section 3 </p>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (EN)")} </label> <br />
                    <input
                        disabled={disabled}

                        value={title?.en || ""}
                        type="text"
                        className='home-input-field'
                        placeholder='Title (en)'
                        onChange={e => {
                            const temp = sectionThreeData;
                            temp.title = {
                                'en': e.target.value,
                                'ar': temp?.title?.ar
                            };
                            setSectionThreeData({ ...temp })
                            // setTitle()
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (AR)")} </label> <br />
                    <input
                        disabled={disabled}
                        value={title?.ar || ""}
                        type="text"
                        className='home-input-field'
                        placeholder='Title (ar)'
                        onChange={e => {
                            const temp = sectionThreeData;
                            temp.title = {
                                'ar': e.target.value,
                                'en': temp?.title?.en
                            };
                            setSectionThreeData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (EN) </label> <br />
                    <input
                        disabled={disabled}
                        value={subtitle?.en || ""}
                        type="text"
                        className='home-input-field'
                        placeholder='Sub-Title (en)'
                        onChange={e => {
                            const temp = sectionThreeData;
                            temp.subtitle = {
                                'en': e.target.value,
                                'ar': temp?.subtitle?.ar
                            };
                            setSectionThreeData({ ...temp })
                        }}
                    />
                </div>

                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (AR) </label> <br />
                    <input
                        disabled={disabled}
                        value={subtitle?.ar || ""}
                        type="text"
                        className='home-input-field'
                        placeholder='Sub-Title (ar)'
                        onChange={e => {
                            const temp = sectionThreeData;
                            temp.subtitle = {
                                'ar': e.target.value,
                                'en': temp?.subtitle?.en
                            };
                            setSectionThreeData({ ...temp })
                        }}
                    />
                </div>

                {
                    images.map((nodata, index) => {
                        return (
                            <>
                                <label htmlFor="home"> Image {index + 1}</label> <br />
                                <Grid container spacing={2} className="my-5 align-items-center">
                                    <Grid item xs={4}>
                                        <div>
                                            <input
                                                type="file"
                                                disabled={disabled}
                                                className='home-input-field-file'
                                                placeholder='Upload'
                                                onChange={e => handleImages(e, index)}
                                            />
                                        </div>
                                    </Grid>
                                    {/* <Grid item xs={4}></Grid> */}
                                    <Grid item xs={2}>
                                        {
                                            !(images[index]?.image) ?
                                                <></>
                                                : <>
                                                    <MdImage
                                                        className="delete-icon"
                                                        onClick={() => window.open(`http://localhost:8081/common/awsObject?key=${images[index]?.image}`)}
                                                    />
                                                </>
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}

export default SectionThree

