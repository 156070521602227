import React from 'react'
import { useTranslation } from 'react-i18next';
import '../../websitecontent/EditPolicy';

export const Policy = (props) => {
    let { index, policyData, setPoliciesData, disabled } = props;
    const { t } = useTranslation()
    return (
        <>
            <div className="policy-section-one">
                <p> Section {index + 1} </p>
                <div className='section-one-input'>
                    <label htmlFor="policy">{t("Title (EN)")}</label> <br />
                    <input
                        type="text"
                        disabled={disabled}

                        value={policyData[index]?.title?.en}
                        className='policy-input-field'
                        placeholder='Title in EN'
                        onChange={(e) => {
                            let temp = policyData;
                            if (temp[index]) {
                                temp[index].title = {
                                    'en': e.target.value,
                                    'ar': temp[index].title?.ar
                                }
                                setPoliciesData([...temp])
                            }
                        }}
                    />
                </div>

                <div className='section-one-input'>
                    <label htmlFor="policy">{t("Title (AR)")}</label> <br />
                    <input
                        type="text"
                        disabled={disabled}

                        value={policyData[index]?.title?.ar}
                        className='policy-input-field'
                        placeholder='Title in AR'
                        onChange={(e) => {
                            let temp = policyData;
                            if (temp[index]) {
                                temp[index].title = {
                                    'ar': e.target.value,
                                    'en': temp[index].title?.en
                                }
                                setPoliciesData([...temp])
                            }
                        }}
                    />
                </div>


                <div className='section-one-input'>
                    <label htmlFor="policy"> Sub-Title (EN)</label> <br />
                    <textarea
                        type="text"
                        disabled={disabled}

                        value={policyData[index]?.content?.en}
                        className='policy-input-field home-input-field-textarea'
                        placeholder='Enter Your Section'
                        onChange={({ target: { value } }) => {
                            const temp = policyData;
                            temp[index].content = {
                                'en': value,
                                'ar': policyData[index].content?.ar
                            }
                            setPoliciesData([...temp])
                        }}
                    />
                </div>

                <div className='section-one-input'>
                    <label htmlFor="policy"> Sub-Title (AR) </label> <br />
                    <textarea
                        type="text"
                        disabled={disabled}
                        value={policyData[index]?.content?.ar}
                        className='policy-input-field home-input-field-textarea'
                        placeholder='Enter Your Section'
                        onChange={({ target: { value } }) => {
                            const temp = policyData;
                            temp[index].content = {
                                'ar': value,
                                'en': policyData[index].content?.en
                            }
                            setPoliciesData([...temp])
                        }}
                    />
                </div>
            </div>
        </>
    )
}
export default Policy

