import { Card, Typography } from "@mui/material"
import { Box } from "@mui/system";
import PDFIcon from "assets/images/chat-icons/pdf-icon.png"
import ImageIcon from "assets/images/chat-icons/image-icon.png"

const BASE_URL = process.env.REACT_APP_BASE_URL;


const usersAttachments = ({ data }) => {
    // const formats = {
    //     png: () => ,
    //     jpg: () => <img src={PDFIcon} alt="" />,
    //     pdf: "pdf",
    //     docs: "document"
    // }


    const getCompo = (ext) => {
        if (ext == 'pdf') {
            return (<img src={PDFIcon} alt="" style={{ width: '25px' }} />)
        } else if (ext == 'jpg') {
            return (<img src={ImageIcon} alt="" style={{ width: '25px' }} />)
        } else {
            return (<img src={ImageIcon} alt="" style={{ width: '25px' }} />)
        }
    }
    const extName = url => url.split('.').pop();

    return (
        <>
            {
                Array.isArray(data) && data.length > 0 ?
                    data.map(
                        (item, index) => {
                            const extension = extName(item.Key);

                            return (
                                <>
                                    {/* <Card
                                        key={index}
                                        variant="outlined"
                                        sx={{
                                            gap: 2,
                                            '--Card-padding': (theme) => theme.spacing(2),
                                        }}
                                        style={{ minWidth: '50px' }}
                                    > */}


                                    <Box sx={{
                                        whiteSpace: 'nowrap',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <Typography fontWeight="md" style={{ width: '40% !important' }} onClick={() => {
                                            window.open(
                                                `${BASE_URL}/common/awsObject?key=${item.Key}`
                                            )
                                        }}>
                                            {
                                                // formats[extension]
                                                getCompo(extension)
                                            }
                                        </Typography>
                                    </Box>
                                    {/* </Card> */}
                                </>
                            )
                        }
                    ) :
                    <>No Data Found</>
            }
        </>

    )
}

export default usersAttachments;