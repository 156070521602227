import React, { useEffect, useState } from 'react'
import Header from '../../../../layout/header/Header'
import '../HomePage'
import { MdDelete, MdImage } from 'react-icons/md';
import { checkAvailability } from '../../../../utils';
import { postCaller } from '../../../../services/api';
import { useTranslation } from 'react-i18next';

const INITIAL__LANGUAGE = { en: '', ar: '' };
const IMAGES_INITIAL_VALUE = [INITIAL__LANGUAGE, INITIAL__LANGUAGE, INITIAL__LANGUAGE];

const SectionFive = ({
    sectionFiveData,
    setSectionFiveData,
    disabled
}) => {

    const [title, setTitle] = useState(INITIAL__LANGUAGE);
    const [subtitle, setSubtitle] = useState(INITIAL__LANGUAGE);
    const [backgroundImage, setBackgroundImage] = useState("");
    const [images, setImages] = useState(IMAGES_INITIAL_VALUE);

    const { t } = useTranslation()


    const uploadAndDelete = async (file, old_image_url) => {
        /* Generating FormData for background image*/
        const formData = new FormData();
        formData.append('file', file);
        formData.append('old_file', old_image_url);

        /* Passing form to API */
        const response = await postCaller('homepage/uploads', formData, true);
        if (response.error == false) {
            return response?.data?.Key;
        }
        return false;
    }

    const handleBackgroundImage = async (e) => {
        const current = e.target;
        const oldValue = current.dataset.value;
        try {
            if (current.files && current.files.length !== 0) {
                let file = current.files[0];
                // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
                if (!checkAvailability(file.type)) { return alert("Unsupported File") }

                const newKey = await uploadAndDelete(file, backgroundImage || oldValue);
                setSectionFiveData(prev => {
                    return {
                        ...prev, backgroundImage: newKey
                    }
                })
                // setBackgroundImage(newKey);
            }
        } catch (e) {
            return false;
        }
    }

    const handleImages = async (e, index) => {
        let _temp = images;
        const current = e.target;
        if (current.files && current.files.length !== 0) {
            let file = current.files[0];
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
            if (!checkAvailability(file.type)) { return alert("Unsupported File") }

            const newKey = await uploadAndDelete(file, _temp[index].image);

            _temp[index].image = newKey;
            setSectionFiveData(prev => {
                return {
                    ...prev,
                    images: [..._temp]
                }
            })
            // setBackgroundImage(newKey);
        }
    }

    const handleParentStates = e => {
        setSectionFiveData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        setTitle(sectionFiveData?.title)
        setSubtitle(sectionFiveData?.subtitle)
        setBackgroundImage(sectionFiveData?.backgroundImage)
        setImages(sectionFiveData?.images || IMAGES_INITIAL_VALUE)
    }, [sectionFiveData])

    return (
        <>
            <Header />
            {/* section 5 */}
            <div className="home-section-one">
                <p> Section 5 </p>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (EN)")}</label> <br />
                    <input
                        disabled={disabled}

                        value={title?.en}
                        type="text"
                        name='title.en'
                        className='home-input-field'
                        placeholder='Title (en)'
                        onChange={e => {
                            const temp = sectionFiveData;
                            temp.title = {
                                'en': e.target.value,
                                'ar': temp?.title?.ar
                            };
                            setSectionFiveData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (AR)")} </label> <br />
                    <input
                        disabled={disabled}
                        value={title?.ar}
                        type="text"
                        name='title.en'
                        className='home-input-field'
                        placeholder='Title (ar)'
                        onChange={e => {
                            const temp = sectionFiveData;
                            temp.title = {
                                'ar': e.target.value,
                                'en': temp?.title?.en
                            };
                            setSectionFiveData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (EN) </label> <br />
                    <input
                        disabled={disabled}
                        value={subtitle?.en}
                        type="text"
                        name='subtitle'
                        className='home-input-field'
                        placeholder='Sub-Title (en)'
                        onChange={e => {
                            const temp = sectionFiveData;
                            temp.subtitle = {
                                'en': e.target.value,
                                'ar': temp?.subtitle?.ar
                            };
                            setSectionFiveData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (AR) </label> <br />
                    <input
                        disabled={disabled}
                        value={subtitle?.ar}
                        type="text"
                        name='subtitle'
                        className='home-input-field'
                        placeholder='Sub-Title (ar)'
                        onChange={e => {
                            const temp = sectionFiveData;
                            temp.subtitle = {
                                'ar': e.target.value,
                                'en': temp?.subtitle?.en
                            };
                            setSectionFiveData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-bg-input'>
                    <label htmlFor="home"> Image </label> <br />
                    <input
                        disabled={disabled}
                        type="file"
                        className='home-input-field-file'
                        placeholder='Upload'
                        onChange={handleBackgroundImage}
                    />
                </div>
                {
                    images.map((nodata, index) => {
                        return (
                            <div
                                key={`IMAGES_${index}`}
                                className="title-input-container"
                            >
                                <div className='home-input-content'>
                                    <label htmlFor="home"> {t("Title (EN)")}</label> <br />
                                    <input type="text"
                                        disabled={disabled}
                                        value={images[index]?.title?.en || ""}
                                        className='home-input-field'
                                        placeholder='Title (en)'
                                        onChange={e => {
                                            let _images = images;
                                            _images[index].title = {
                                                'en': e.target.value,
                                                'ar': _images[index].title?.ar
                                            };
                                            setImages([..._images])
                                            setSectionFiveData(prev => {
                                                return {
                                                    ...prev,
                                                    images: [..._images]
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className='home-input-content'>
                                    <label htmlFor="home"> {t("Title (AR)")}</label> <br />
                                    <input type="text"
                                        disabled={disabled}
                                        value={images[index]?.title?.ar || ""}
                                        className='home-input-field'
                                        placeholder='Title (ar)'
                                        onChange={e => {
                                            let _images = images;
                                            _images[index].title = {
                                                'ar': e.target.value,
                                                'en': _images[index].title?.en
                                            };
                                            setImages([..._images])
                                            setSectionFiveData(prev => {
                                                return {
                                                    ...prev,
                                                    images: [..._images]
                                                }
                                            })
                                        }}
                                    />
                                </div>

                                <div className='home-input-content home-web-content'>
                                    <label htmlFor="home"> Upload Images </label> <br />
                                    <div className='title-input-container home-title-container'>
                                        <input
                                            disabled={disabled}
                                            type="file"
                                            className='home-input-field-file'
                                            placeholder='Upload'
                                            onChange={e => handleImages(e, index)}
                                        />
                                        {

                                            !(images[index]?.image) ?
                                                <></>
                                                : <>
                                                    <MdImage className="delete-icon"
                                                        onClick={() => window.open(`${''}/common/awsObject?key=${images[index]?.image}`)
                                                        }
                                                    />
                                                </>
                                        }
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )


}

export default SectionFive