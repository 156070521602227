import React, { useState } from 'react'
import { Backdrop, Modal, Fade, Box, TextField, Stack } from '@mui/material';
import { FaRegTimesCircle } from 'react-icons/fa';
import { DateRangePicker } from 'rsuite';
import { dateFormat } from 'utils';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const SubServiceFilterModal = ({
    data,
    openFilter,
    setOpenFilter,
    setQuery,
    refreshData

}) => {

    const formikIntialValue = {
        serviceName: ''
    }

    const filterValidationSchema = Yup.object().shape({
        serviceName: Yup.string(),
    })


    const formik = useFormik({
        initialValues: formikIntialValue,
        validationSchema: filterValidationSchema,
        onSubmit: data => {
            const [dateFrom, dateTo] = calenderValue;
            const _data = {
                name: data.serviceName,
                dateFrom: dateFrom && dateFormat(dateFrom),
                dateTo: dateTo && dateFormat(dateTo)
            }
            setQuery(_data)
            refreshData(_data)
            handleClose()
        }

    })


    const { errors } = formik;

    const [calenderValue, setCalenderValue] = useState([null, null])

    const handleRestFormAndClose = () => {
        formik.resetForm();
        setCalenderValue([null, null])
        refreshData({ page: 1 })

    }
    const handleClose = () => {
        setOpenFilter(false)
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openFilter}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openFilter}>
                    <Box sx={style}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="query-pop-up">
                                <h4> Filter Sub Services </h4>
                                <span onClick={handleClose}>
                                    <FaRegTimesCircle
                                        style={{
                                            width: "2rem",
                                            height: "1.5rem"
                                        }}
                                    />
                                </span>
                            </div>

                            {/* Content */}
                            <Stack direction='row' id="transition-modal-description" className='query-para' >
                                <div className="query-details">
                                    <p>Name</p>
                                    <TextField
                                        type={'text'}
                                        name='serviceName'
                                        className="width-full"
                                        size='small'
                                        value={formik.values.serviceName}
                                        onChange={formik.handleChange}
                                        error={errors?.serviceName}
                                        helperText={errors?.serviceName && ""}
                                    />
                                </div>
                                <div className="query-details">
                                    <p>Createad At</p>
                                    <DateRangePicker
                                        ranges={[]}
                                        className="width-full text-capitalize"
                                        showOneCalendar
                                        value={calenderValue}
                                        onChange={newValue => setCalenderValue(newValue || [null, null])}
                                        name="valid"
                                        format="dd-MM-yyyy"
                                        placeholder="Select Date"
                                        placement='auto'
                                    />
                                </div>
                            </Stack>

                            {/*  */}
                            <div className="query-btn-content">
                                <div className="query-mark-btn">
                                    <button
                                        className='mark-query text-white'
                                        type='submit'
                                        style={{
                                            backgroundColor: '#2f77b6'
                                        }}
                                    >
                                        Filter
                                    </button>

                                    <div
                                        className='mark-query text-white'
                                        style={{
                                            backgroundColor: '#2f77b6'
                                        }}
                                        onClick={handleRestFormAndClose}
                                    >
                                        Reset
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </>

    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    borderRadius: "1rem",
    bgcolor: 'white',
};

export default SubServiceFilterModal;
