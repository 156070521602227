import React, { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import NewList from "./List";
import chatSend from 'assets/images/chat-send.png'
import pdfbtn from 'assets/images/chat-btn.png'
import AttachmentContainer from "./Attatchment/AttatchmentContainer";
import ReloadIcon from "assets/images/chat-icons/reload-icon.png"
import { getCaller, postCaller } from "services/api";
import './video-chat.css'
import GlobalContext from "context/GlobalContext";
import { Box, CircularProgress } from "@mui/material";
const Chat = require("twilio-chat");

function ChatWindowCompo({
    chatData,
    userType,

    /* DIRECT PROPS */
    _email, // These two will only work if direct prop is set to true
    _roomId, // These two will only work if direct prop is set to true
    _userId, // These two will only work if direct prop is set to true
    /* DIRECT PROPS */

    popup = false,
    attatchment = false,
    direct = false
}) {
    const email = direct ? _email : (userType === 'user' ? chatData.user_profile.email : chatData.doctor_profile.email)
    const room = direct ? _roomId : (chatData.roomId)
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [channel, setChannel] = useState(null);
    const [text, setText] = useState("");
    const [chatAttachments, setChatAttachments] = useState([]);

    const context = useContext(GlobalContext);

    const socket = context.socket;


    let scrollDiv = useRef(null);

    const getData = async (obj) => {
        let token = await getTwilioCredentials();

        setLoading(true)

        try {
            token = await getTwilioCredentials(email);
        } catch {
            throw new Error("Unable to get token, please reload this page");
        }
        // debugger;
        const client = await Chat.Client.create(token);


        client.on("tokenAboutToExpire", async () => {
            const token = await getTwilioCredentials(email);
            client.updateToken(token);
        });

        client.on("tokenExpired", async () => {
            const token = await getTwilioCredentials(email);
            client.updateToken(token);
        });

        client.on("channelJoined", async (channel) => {
            let name = channel.friendlyName
            if (channel.friendlyName === room) {
                // getting list of all messages since this is an existing channel
                const newMessages = await channel.getMessages();
                console.log("🚀 ~ file: Chat.jsx:73 ~ client.on ~ newMessages", newMessages)

                if (newMessages?.items?.length === 0) {
                    socket.emit('wakeUserForSupportChat', { roomId: room, userId: _userId });
                    channel.sendMessage('Welcome to chat support');
                }
                // messages.push(newMessages.items || [])
                setMessages(newMessages.items || []);
                // scrollToBottom();
            }
        });
        client.on("error", async (err) => {
            console.log('ERROR WHILE CHAT :>> ', err);
        })
        try {
            console.log("🚀 ~ file: NewChat.jsx ~ line 76 ~ getData ~ room", room)
            const channel = await client.getChannelByUniqueName(room);
            console.log("🚀 ~ file: NewChat.jsx ~ line 78 ~ getData ~ channel", channel)
            joinChannel(channel)
            setChannel(channel)
            return;
        } catch (err) {
            try {
                // debugger;
                const channel = await client.createChannel({
                    uniqueName: room,
                    friendlyName: room,
                });

                joinChannel(channel);
            } catch {
                throw new Error("Unable to create channel, please reload this page");
            }
        }
    }
    const getTwilioCredentials = async (email) => {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/chat/${email}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
        });
        const data = await res.json();
        // debugger;
        return data?.jwt;
    }

    useEffect(() => {
        getData();
        attatchment && getALlChatAttachment();
    }, [])

    /* EFFECT FOR SCROLLING FIRST TIME */
    // useEffect(() => {
    //     if (messages.length > 0) {
    //         console.log("First Scroll Done.....");
    //         scrollToBottom();
    //     }
    // }, [messages])

    const updateText = e => setText(e);

    const joinChannel = async (channel) => {
        if (channel.channelState.status !== "joined") {
            await channel.join();
        }
        setChannel(channel);
        setLoading(false)

        channel.on('messageAdded', handleMessageAdded);
    };



    const handleMessageAdded = message => {
        setMessages(messages => [...messages, message]);
        scrollToBottom();
    };

    const scrollToBottom = () => {
        const scrollHeight = scrollDiv.current.scrollHeight;
        const height = scrollDiv.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    const sendMessage = () => {
        console.log("🚀 ~ file: Chat.jsx ~ line 159 ~ sendMessage")
        if (text && text?.trim() !== '') {
            setLoading(true)
            channel.sendMessage(String(text).trim());
            setText('');
        }
        setLoading(false)
    };

    /* Handler for attatching chat file */
    const handleAttatchChatFile = () => {
        /* Creating dynamic File Input */
        const fileElement = document.createElement('input');

        /* Making it input type file */
        fileElement.setAttribute('type', 'file');

        fileElement.onchange = whenChatFileChanged /* Attaching onChange to dymanic Input */;

        /* clicking dynamically */
        fileElement.click();
    }

    const whenChatFileChanged = async e => {
        const formData = new FormData();

        formData.append('media', e.target.files[0])
        // formData.append('patientId', chatData.loginId)
        // formData.append('byWhome', localStorage.getItem('type'));
        // formData.append('appointmentId', chatData.appointmentId);

        const message = {
            contentType: 'attachment',
            media: e.target.files[0]
        }
        channel.sendMessage(formData);
        // const response = await postCaller('appointmentChatFile', formData, true);
        // if (response?.status == "success") {
        //     setChatAttachments(prev => [...prev, response.data])
        //     alert("File attached successfully")
        // }
    }

    const getALlChatAttachment = async () => {
        const response = await getCaller(`appointmentChatFiles?patientId=${chatData.loginId}`);
        if (response.data) {
            const data = response?.data?.Contents;
            setChatAttachments(data)
        }
    }
    return (
        <>

            {
                !loading ?
                    <div className='flex chat-window'
                        style={{ height: popup ? '100vh' : '100%' }}
                    >
                        <div className='message-list' style={{ height: '200px' }} ref={scrollDiv}>
                            {
                                messages.length > 0 &&
                                <div style={styles.timestamp()}>
                                    Appointment Started {new Date(messages[0].dateCreated.toISOString()).toLocaleString()}
                                </div>
                            }
                            {(messages && room !== "chat") &&
                                messages.map((message) =>
                                    <NewList
                                        data={chatData}
                                        key={`CHAT_MSG-${message.index}`}
                                        message={message}
                                        email={email} />
                                )}

                        </div>

                        {
                            attatchment &&
                            <div style={{
                                display: "grid",
                                width: '100%',
                                boxShadow: '0 0 3px 1px #bbb4b4',
                                borderRadius: '5px',
                                position: 'relative'
                            }}>
                                <AttachmentContainer data={chatAttachments} />
                                <div
                                    onClick={getALlChatAttachment}
                                >
                                    <img src={ReloadIcon} alt="" />
                                </div>
                            </div>

                        }
                        <div className='chat-btn-container'>
                            <input
                                required
                                placeholder="Type here…"
                                variant="outlined"
                                onKeyDown = {event =>{
                                    if(event.key === 'Enter'){
                                        sendMessage(event)
                                    }
                                }}
                                onChange={(e) => updateText(e.target.value)} value={text} />
                            <button
                                className='chat-send-btn'
                                style={{ padding: 0 }}
                                onClick={sendMessage}
                            >
                                <img src={chatSend} alt="" />
                            </button>
                            <button
                                className='chat-send-btn'
                                style={{ padding: 0 }}
                                onClick={handleAttatchChatFile}
                            >
                                <img src={pdfbtn} alt="" />
                            </button>
                        </div>
                    </div>


                    : <>
                        <Box sx={{
                            height: '100%',
                            display: 'flex'
                        }}>
                            <CircularProgress />
                        </Box>
                    </>
            }
        </>

    )
}

const styles = {
    listItem: (isOwnMessage) => ({
        display: "flex",
    }),
    container: (isOwnMessage) => ({
        maxWidth: "75%",
        width: "100%",
        borderTopRightRadius: isOwnMessage ? "0rem" : ".5rem",
        borderBottomRightRadius: isOwnMessage ? ".5rem" : ".5rem",
        borderBottomLeftRadius: isOwnMessage ? ".5rem" : ".5rem",
        borderTopLeftRadius: isOwnMessage ? ".5rem" : "0rem",
        marginLeft: isOwnMessage ? "0rem" : "0rem",
        padding: 16,
        border: "1px solid #CBCBCB",
        color: "black",
        fontSize: 14,
        backgroundColor: isOwnMessage ? "#FFFFFF" : "#FFFFFF",
    }),
    author: (isOwnMessage) => ({
        fontSize: 10,
        color: "gray",
        display: "flex",
        justifyContent: isOwnMessage ? "flex-end" : "flex-start",
        width: "4rem",
        alignItems: "center",
        height: "4rem",
        borderRadius: "50%",
        border: "none",
        margin: "0.5rem 0.5rem 0 0rem"
    }),

    timestamp: (isOwnMessage) => (
        {
            fontSize: 10,
            color: "black",
            textAlign: "center",
            padding: ".5rem 2rem .5rem 2rem",
            backgroundColor: "#F0F0F0",
            borderRadius: "2rem",
            margin: " .5rem 0rem .5rem 4.5rem",

            maxWidth: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }),
};



export default ChatWindowCompo;