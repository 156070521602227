import React, { useState, useEffect, useTransition } from 'react'
import '../appointmenCard/card.css'
import { users } from '../../../services/api'
import { constants } from 'utils/constants'
import { useTranslation } from 'react-i18next'
const AppointmentOverCard = ({ appointments }) => {

    const {t} = useTranslation()

    return (
        <>
            <div className="apt-body-content">
                <div className='apt-para1'>
                    <p>{t("Total Appointments")}</p>
                    <h3> {appointments?.allAppointments?.count || '0'} </h3>
                </div>
                <div className='apt-para'>
                    <div className='apt-para-head'>
                        <p> {t("Completed")} </p>
                        <h3 style={{ color: "#13BC34" }}> {appointments?.completedAppointments || '0'}  </h3>
                    </div>
                </div>
                <div className='apt-para'>
                    <div className='apt-para-head'>
                        <p> {t("In Review")} </p>
                        <h3 style={{ color: "#EFD511" }}> {appointments?.inReviewAppointments || '0'}  </h3>
                    </div>
                </div>
                <div className='apt-para'>
                    <div className='apt-para-head'>
                        <p>{t("Rescheduled")}</p>
                        <h3 style={{ color: "#E80707" }}> {appointments?.rescheduleAppointments || '0'} </h3>
                    </div>
                </div>
                <div className='apt-para'>
                    <div className='apt-para-head'>
                        <p>{t("Cancelled")}</p>
                        <h3 style={{ color: "#989898" }}> {appointments?.cancelledAppointments || '0'}  </h3>
                    </div>
                </div>
                <div className='apt-para'>
                    <div className='apt-para-head'>
                        <p>Rejected</p>
                        <h3 style={{ color: "#989898" }}> {appointments?.rejectedAppointments || '0'}  </h3>
                    </div>
                </div>
                {/* <div className='apt-para'>
                    <div className='apt-para-head'>
                        <p>Payment Pending</p>
                        <h3 style={{ color: "#989898" }}> {appointments?.paymentPendingAppointments || '0'}  </h3>
                    </div>
                </div> */}
            </div>

        </>
    )
}

export default AppointmentOverCard