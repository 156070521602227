import React, { useState, useEffect } from 'react'
import Header from '../../layout/header/Header'
import '../appointment/aptoverview.css'
import { getCaller } from '../../services/api';
import { useLocation } from 'react-router-dom';
import { constants } from 'utils/constants';
import useGlobal from 'hooks/useGlobal';

const ReviewAppointmentView = () => {
    const [data, setData] = useState('');
    const location = useLocation();
    useEffect(() => {
        getReview()
    }, [])

    const {tKey: [tKey]} = useGlobal();

    const getReview = async () => {
        const response = await getCaller(`getAppointment/${location.state.id}`);
        setData(response);
        console.log("data", data)
    }



    return (
        <>
            <Header />
            <div className='review-apt-container'>
                <div className='border'>
                    <p className='review-para'>Appointment Details</p>
                    <div className="review-body-content">
                        <div>
                            <p>Appointment ID</p>
                            <h4> {data.appointment_appointmentId} </h4>
                        </div>
                        <div>
                            <p> Appointment Date & Time </p>
                            <h4> {data.appointment_bookingDate} </h4>
                        </div>
                        <div>
                            <p> Booking Date </p>
                            <h4> {data.appointment_createdAt}</h4>
                        </div>
                        <div>
                            <p>Payment Amount</p>
                            <h4> {data.appointment_fees}</h4>
                        </div>
                        <div>
                            <p> Payment Method </p>
                            <h4>{data.appointment_payMethod}</h4>
                        </div>
                        <div>
                            <p> Appointment Status </p>
                            <h4 style={{ color: "orange", fontWeight: "bold" }}> {data.appointment_status}</h4>
                        </div>
                    </div>


                    <p className='review-para'>Doctor Details</p>
                    <div className="review-body-content">
                        <div className='doc-review-details'>
                            <p>Name</p>
                            <h4> {data.doctor_name} </h4>
                        </div>
                        <div className='doc-review-details'>
                            <p> Years of Experience</p>
                            <h4> {data.doctor_experience} </h4>
                        </div>
                        <div className='doc-review-details'>
                            <p> Specialisation </p>
                            <h4> {data.doctor_service} </h4>
                        </div>
                    </div>
                    <p className='review-para'>Patient Details</p>
                    <div className="review-body-content">
                        <div className='doc-review-details'>
                            <p>Patient ID</p>
                            <h4> {data.user_loginId} </h4>
                        </div>
                        <div className='doc-review-details'>
                            <p> Patients Name </p>
                            <h4>{data.user_name} </h4>
                        </div>
                        <div className='doc-review-details'>
                            <p> Gender </p>
                            <h4> {data.user_gender} </h4>
                        </div>
                        <div className='doc-review-details'>
                            <p> Age </p>
                            <h4> {data.user_age} </h4>
                        </div>
                        <div className='doc-review-details'>
                            <p> Booking Date </p>
                            <h4> ID00005612 </h4>
                        </div>
                    </div>

                    <div className="review-body-content">
                        <div className='doc-review-details'>
                            <p>Specialisation Chosen</p>
                            <h4> {data.appointment_serviceName?.name?.[tKey] ?? constants.DOTS } </h4>
                        </div>
                    </div>
                    <div className="review-body-content">
                        <div className='doc-review-details'>
                            <p>Problem Description</p>
                            <h4> {data.appointment_description} </h4>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ReviewAppointmentView