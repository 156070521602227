import React, { useEffect, useState, useRef } from "react";
import Header from "../../../layout/header/Header";
import "../couponCodes/coupon.css";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { getCaller, updateCaller } from "../../../services/api";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
// import { DateRangePicker } from "react-date-range";
import { DateRangePicker } from 'rsuite';

import format from "date-fns/format";
// import { handleChange } from "date-fns";

// import "react-date-range/dist/styles.css";
import "rsuite/dist/rsuite.css";
import { toast } from 'react-toastify';
import TOAST from 'components/common/toast/Success'
//@import "rsuite/dist/rsuite.css";


const EditCoupon = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [couponDetails, setCouponDetails] = useState({});
    const [value, setValue] = useState();

    const { t } = useTranslation();
    const editCouponSchema = Yup.object().shape({
        couponTitle: Yup.string()
            .min(2, "Coupon Title is too small")
            .max(10, "Title is too long")
            .required("Title is required"),
        code: Yup.string().required('Code is required'),
        perUser: Yup.number().required('Per User is required'),
        discount: Yup.number().min(2, "Discount can not be less then 1")
            .max(99, "Discount can not be greater then 99").required('Disscount is required'),

        // email: Yup.string().email('Invalid email').required('Email is Required'),
        // mobile: Yup.string().min(10, 'Incorrect mobile number').required('Mobile number is required'),
        // location: Yup.string().min(2, "Location is too small").required('Location is required'),
        // dob: Yup.date().required('Date of birth is required'),
        // description: Yup.string().required('About is required'),
        // experience: Yup.string().required('Experience is required'),
        // license_no: Yup.string().required('License number is required'),
        // fees: Yup.string().required('Appointment Fees is Required'),
    });

    const getCouponId = async () => {
        const response = await getCaller(`getOneCoupon/${id}`).then(
            (couponData) => {
                console.log("couponData", couponData);
                let valid_from = couponData.data.valid_from;
                let valid_to = couponData.data.valid_to;
                console.log("valid_to", valid_to);
                // let validdateArray=validDate.split('-');
                let va1 = new Date(valid_from);
                let va2 = new Date(valid_to);
                console.log("---", va1);
                // console.log("---values",values);

                setValue([va1, va2])

                formik.setValues((values) => ({
                    ...values,
                    couponTitle: couponData?.data?.couponTitle ?? "",
                    code: couponData?.data?.code ?? "",
                    //   valid: couponData?.data?.valid ?? [],
                    valid_from: couponData?.data?.valid_from ?? "",
                    valid_to: couponData?.data?.valid_to ?? "",
                    perUser: couponData?.data?.perUser ?? "",
                    discount: couponData?.data?.discount ?? 1,
                    description: couponData?.data?.description ?? "",
                    status: couponData?.data?.status ?? "Active",
                }));
                console.log("in");
                setCouponDetails(couponData?.data);
                setValue([new Date(couponData?.data?.valid_from), new Date(couponData?.data?.valid_to)])
            }
        );
    };
    console.log("response coupon Details", couponDetails);
    const formik = useFormik({
        initialValues: {
            couponTitle: "",
            code: "",
            //   valid: "",
            valid_from: "",
            valid_to: "",
            perUser: "",
            description: "",
            status: "",
            discount: 1,

        },
        validationSchema: editCouponSchema,
        onSubmit: async (values, { resetForm }) => {
            const resp = await updateCaller(`updateCoupon/${id}`, values);
            console.log(resp);
            if(resp){
                toast.success(<TOAST message={'Create Coupon Successfully'} />)
                navigate('/dashboard/configurations/settings-coupon');
            }
        },
    });

    console.log("value>>", value);

    function changeSetValue(event) {
        console.log(">>>>>>>>", format(event[0], 'M/dd/yyyy') + '-' + format(event[1], 'M/dd/yyyy'), ">>>>>>>>")
        setValue(event) //update your value here
        formik.setFieldValue('valid_from', format(event[0], 'yyyy/MM/dd'))
        formik.setFieldValue('valid_to', format(event[1], 'yyyy/MM/dd'))
    }

    React.useEffect(() => {
        getCouponId();
    }, []);

    let errors = formik.errors;
    console.log("🚀 ~ file: EditCoupon.jsx ~ line 123 ~ EditCoupon ~ errors", errors)

    return (
        <>
            <Header />
            <div className="edit-home-container">
                <p> Edit Coupon </p>
                <div className="home-wrapper">
                    {/* {({ handleSubmit, handleChange, values }) => ( */}
                    <form action="" onSubmit={formik.handleSubmit}>
                        <div className="coupon-container">
                            <div className="create-coupon-form">
                                <div className="create-input-content">
                                    <label htmlFor="home"> Coupon Title </label> <br />
                                    <input
                                        type="text"
                                        className="coupon-input-field"
                                        name="couponTitle"
                                        placeholder="Homepage"
                                        onChange={formik.handleChange}
                                        value={formik.values.couponTitle}
                                    />
                                    {console.log("jgskjh====>>>", formik.values)}
                                    {formik.touched.couponTitle && formik.errors.couponTitle && (
                                        <span className="error">{formik.errors.couponTitle}</span>
                                    )}
                                </div>

                                <div
                                    className="create-input-content"
                                    style={{ marginLeft: "2rem" }}
                                >
                                    <label htmlFor="home"> Coupon Code </label> <br />
                                    <input
                                        type="text"
                                        className="coupon-generate-field"
                                        name="code"
                                        placeholder="23UFHY46"
                                        onChange={formik.handleChange}
                                        value={formik.values.code}
                                    />
                                    {formik.touched.code && formik.errors.code && (
                                        <span className="error">{formik.errors.code}</span>
                                    )}
                                </div>
                                <div className="create-input-content">
                                    <label htmlFor="home"> Valid From - TO </label> <br />
                                    <DateRangePicker
                                        value={value}
                                        // onChange={setValue,e => formik.setFieldValue('valid', value)}  
                                        onChange={changeSetValue}

                                        name="valid"
                                        format="yyyy-MM-dd"
                                        placeholder="Select Date Range"
                                    />
                                    {formik.touched.valid && formik.errors.valid && (
                                        <span className="error">{formik.errors.valid}</span>
                                    )}

                                </div>
                                {/* <div className="create-input-content">
                                    <label htmlFor="home"> Valid From - TO </label> <br />
                                    <div className="calendarWrap">
                                        <input
                                            value={`${format(
                                                range[0].startDate,
                                                "dd/MM/yyyy"
                                            )} - ${format(range[0].endDate, "dd/MM/yyyy")}`}
                                            readOnly
                                            className="inputBox"
                                            onClick={() => setOpen((open) => !open)}
                                            name="valid"
                                        />

                                        <div ref={refOne}>
                                            {open && (
                                                <DateRangePicker
                                                    onChange={(item) => setRange([item.selection])}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={range}
                                                    months={2}
                                                    direction="horizontal"
                                                    className="calendarElement"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <input
                                        type="date"
                                        className="coupon-generate-field"
                                        name="valid"
                                        placeholder="23/05/1985 — 23/05/1985"
                                        onChange={formik.handleChange}
                                        value={formik.values.valid}
                                    />
                                    {formik.touched.valid && formik.errors.valid && (
                                        <span className="error">{formik.errors.valid}</span>
                                    )}
                                </div> */}
                                <div
                                    className="create-input-content"
                                    style={{ marginLeft: "2rem" }}
                                >
                                    <label htmlFor="home"> Usage Per User </label> <br />
                                    <input
                                        type="text"
                                        className="coupon-input-field"
                                        name="perUser"
                                        placeholder="Enter"
                                        onChange={formik.handleChange}
                                        value={formik.values.perUser}
                                    />
                                    {formik.touched.perUser && formik.errors.perUser && (
                                        <span className="error">{formik.errors.perUser}</span>
                                    )}
                                </div>
                            </div>

                            <div className="desc-input-content">
                                <label htmlFor="home"> Description </label> <br />
                                <input
                                    type="text"
                                    className="coupon-input-field"
                                    name="description"
                                    placeholder="Describe Your Bio Here"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                />
                                {formik.touched.description && formik.errors.description && (
                                    <span className="error">{formik.errors.description}</span>
                                )}

                            </div>
                            <div className="create-input-content">
                                <label htmlFor="home"> Status </label> <br />
                                <select
                                    name="status"
                                    id=""
                                    onChange={formik.handleChange}
                                    value={formik.values.status}
                                >
                                    <option value=" "> choose status</option>
                                    <option value="Active"> Active </option>
                                    <option vale="Inactive"> Inactive </option>
                                </select>
                            </div>



                            <div className="create-input-content">
                                <label htmlFor="home"> Discount Percent(%) </label> <br />
                                <input
                                    className="coupon-input-field"
                                    type="number"
                                    name="discount"
                                    id=""
                                    onChange={formik.handleChange}
                                    value={formik.values.discount}
                                />
                                {formik.touched.discount && formik.errors.discount && (
                                    <span className="error">{formik.errors.discount}</span>
                                )}

                            </div>


                        </div>

                        <div className="coupon-btn-content">
                            <button onClick={() => navigate('/dashboard/configurations/settings-coupon')} className="home-cancel">
                                Cancel
                            </button>
                            <button type="submit" className="home-save">
                                {" "}
                                Save{" "}
                            </button>
                        </div>
                    </form>
                    {/* // )} */}
                </div>
            </div>
        </>
    );
};

export default EditCoupon;
