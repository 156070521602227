import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UnstyledButton } from '../../../components/common/button/Button';
import Header from '../../../layout/header/Header';
import { useForm } from "react-hook-form";
import { getCaller, updateCaller } from '../../../services/api';
import One from './sections/One';
import { toast } from 'react-toastify';
import { responseMessages } from 'utils/responseMessages';
import { useTranslation } from 'react-i18next';
import Top from './sections/Top';

const ContactUs = props => {
    /* ISOLATEDS STATES */

    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const { state: { disabled } } = useLocation();


    /* Button controlling states */
    const [disable, setDisable] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    /* DESTRUCTRING Form States */
    const { register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        getFieldState,
    } = useForm();

    const onSubmit = async (d) => {
        try {
            setLoader(true);
            /* Parsing content for section 2 */

            const response = await updateCaller(`admin/website-content/${id}`, d)

            if (response?.error === false) {
                toast.success(response?.msg)
                return navigate('/dashboard/webiste-contents')
                // return navigate('/dashboard')
            } else {
                toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
            }
            setLoader(false);
        } catch {
            setLoader(false);
        }
    }

    /* Form Submittion handler */
    const getAboutId = async (data) => {
        const response = await getCaller(`admin/website-content/${id}`);
        if (response?.error === false && response?.data) {
            const {
                page_name,
                page_url,
                content
            } = response?.data;

            /* TOP SECTION */

            /* setting page name languages specified */
            page_name?.en && setValue('page_name.en', page_name?.en)
            page_name?.ar && setValue('page_name.ar', page_name?.ar)


            /* setting page url */
            page_url && setValue('page_url', page_url)

            Array.isArray(content) && content.map(((c, i) => {
                setValue(`content[${i}].title.en`, c.title.en);
                setValue(`content[${i}].title.ar`, c.title.ar);
                setValue(`content[${i}].address`, c.address);
            }))
        }
    }

    useEffect(() => {
        getAboutId()
    }, [])

    return (

        <>
            <Header />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='edit-policy-container'>
                    <h3>Edit Contact Us</h3>

                    <div className="home-wrapper">
                        <Top
                            disabled={disabled}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                        />

                        <One
                            disabled={disabled}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                        />
                    </div>
                </div>


                <div className="home-btn-content">
                    <UnstyledButton
                        sx={{
                            width: '10rem',
                        }}
                        text="Back"
                        onClick={() => navigate('/dashboard/webiste-contents')}
                    />
                    {/* <button onClick={() => navigate('/dashboard')}> Cancel </button> */}

                    {
                        !disabled
                        && <div className="home-mark">
                            <div
                                className='theme-button home-save'
                            >
                                <UnstyledButton
                                    sx={{
                                        color: disable ? 'white' : 'white'
                                    }}
                                    text="Save"
                                    loading={loader}
                                />
                            </div>
                        </div>
                    }
                </div>
            </form>

        </>
    )

}


export default ContactUs;