const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const registerUser = async (payload) => {
    const url = `${API_BASE_URL}/registeruser`;
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*"
        }, body: JSON.stringify(payload)
    });
    return res.json();
}
export const loginUser = async (payload) => {
    const url = `${API_BASE_URL}/loginAdmin`;
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*"
        }, body: JSON.stringify(payload)
    });
    return res.json();
}

export const getCaller = async (url) => {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(response => response.json()).catch(error => console.log(error))
    return response
}

export const updateCaller = async (url, data, formData = false) => {
    let headers = {};
    /* If user does not want to send with form data */
    if (!formData) {
        headers = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(`${API_BASE_URL}/${url}`, {
        method: 'PUT',
        headers: {
            ...headers,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: !formData ? JSON.stringify(data) : data

    }).then(response => response.json()).catch(error => console.log(error))
    return response
}

export const postCaller = async (url, data, formData = false) => {
    let headers = {};
    /* If user does not want to send with form data */
    if (!formData) {
        headers = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(`${API_BASE_URL}/${url}`, {
        method: 'POST',
        headers: {
            ...headers,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: !formData ? JSON.stringify(data) : data
    }).then(response => response.json()).catch(error => console.log(error))
    return response
}


export const deleteCaller = async (url, data, formData = false) => {
    let headers = {};

    if(!formData){
        headers ={
            'Content-Type': 'application/json'
        }
        const response = await fetch(`${API_BASE_URL}/${url}`,{
            method : 'DELETE',
            headers: {
                ...headers,
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            },
            body: !formData ? JSON.stringify(data) : data
        }).then(response => response.json()).catch(error => console.log(error))
        return response
    }

}


