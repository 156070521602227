


import React, { useState } from 'react';
import '../pagination/page.css'
const Pagination = ({ pagina, setPagina, maximo }) => {
    const [input, setInput] = useState(1);

    const nextPage = () => {
        setInput(parseInt(input) + 1);
        setPagina(parseInt(pagina) + 1);
    };

    const previousPage = () => {
        setInput(parseInt(input) - 1);
        setPagina(parseInt(pagina) - 1);
    };

    const onKeyDown = e => {
        if (e.keyCode == 13) {
            setPagina(parseInt(e.target.value));
            if (
                parseInt(e.target.value < 1) ||
                parseInt(e.target.value) > Math.ceil(maximo) ||
                isNaN(parseInt(e.target.value))
            ) {
                setPagina(1);
                setInput(1);
            } else {
                setPagina(parseInt(e.target.value));
            }
        }
    };

    const onChange = e => {
        setInput(e.target.value);
    };

    return (
        <div className='custom-page'>
            <div className="">
                {/* {maximo} */}
            </div>
            <div className='page-container' >
                <button
                    className='page-btn'
                    to="#"
                    disabled={pagina === 1 || pagina < 1}
                    onClick={previousPage}
                >
                    Prev
                </button>
                <div
                    // onChange={e => onChange(e)}
                    // onKeyDown={e => onKeyDown(e)}
                    // name="page"
                    // autoComplete="off"
                    value={input}
                    className="custom-input"
                >
                {input}
                </div>
                <button to="#"
                    className='page-btn'
                    disabled={pagina === Math.ceil(maximo) || pagina > Math.ceil(maximo)}
                    onClick={nextPage}
                >
                    Next
                </button>
            </div>
        </div>
    );
};
export default Pagination