import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchUserSettingsRQ, updateDoctoreSettingsRQ } from "../Calls";
import { Link, useNavigate } from 'react-router-dom';
import TableHeading from '../../../components/common/table/TableHeading';
import ProfileSettings_M from '../modals/ProfileSettings_M';
import { Switch } from '@mui/material';
import edit from '../../../assets/images/edit-2.svg'


const ProfileSettings = () => {
    /* ISOLATED STATES */
    const navigate = useNavigate();
    const [list, setList] = useState([]);


    const [profileModal, setProfileModal] = useState(false);
    const [modalData, setModalData] = useState({});


    /* HANDLERS */
    const fetchUserSettings = async () => {
        const response = await fetchUserSettingsRQ()
        setList(response?.data?.rows || []);
    }

    /* Patient Setting Enable Or Disable */
    const disabledOrEnabled = async (id, status) => {
        let data = {}
        console.log(status)
        if (status === 'disable') {
            data.status = 0;
        } else if (status === 'enable') {
            data.status = 1;
        }
        const response = await updateDoctoreSettingsRQ(id, data);
        console.log('response', response)
        fetchUserSettings()

    }

    /* EFFECTS */
    useEffect(() => {
        fetchUserSettings()
    }, [])
    return (
        <>
            {/* MODAL */}
            <ProfileSettings_M
                openDialog={profileModal}
                setOpenDialog={setProfileModal}
                parentData={modalData}
                setParentData={setModalData}
                refreshData={fetchUserSettings}
            />
            <div className="">
                <TableHeading name='Patient Settings' />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell > Field Name </Cell>
                                <Cell align='center' > Field Type </Cell>
                                <Cell align='center'> Action </Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                Array.isArray(list) && list.map(item => {
                                    return (
                                        <Row >
                                            <Cell component="th" scope="row" >
                                                {item.field_label?.['en']}
                                            </Cell>
                                            <Cell align='center'> {item.field_type}  </Cell>
                                            <Cell align='center'>
                                                <Link
                                                    onClick={() => {
                                                        setProfileModal(true);
                                                        setModalData(item);
                                                    }}
                                                    style={{ marginRight: "1rem" }}
                                                >
                                                    <img src={edit} alt="Accept" />
                                                </Link>
                                                <Switch
                                                    defaultChecked={item?.status || false}
                                                    style={{ marginleft: "1rem" }}
                                                    onClick={() => {
                                                        disabledOrEnabled(
                                                            item.id,
                                                            item.status === 1 ? "disable" : "enable"
                                                        )
                                                    }}
                                                />
                                                {/* {item.status === 1 ? "Disable" : "Enable"}
                                                </Link> */}
                                            </Cell>
                                        </Row>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
    },
}));

const Row = styled(TableRow)(({ theme }) => ({
    borderBottom: '1px solid #F0F0F0',
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default ProfileSettings