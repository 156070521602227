import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

export default function AvatarChip(
    {
        label,
        onDelete
    }) {
    return (
        <>
            <Chip
                avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg"/>}
                label={label}
                variant="outlined"
                onDelete={onDelete}
            />
        </>
    );
}