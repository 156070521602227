import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getCaller } from 'services/api';
import AptTable from 'pages/appointment/AptTable';
import { appointmentStatus } from 'utils/constants';

const UserPastAppointments = () => {
    const [appointment, setAppointment] = useState("")
    const { patientId } = useParams();


    const appointmentsData = async payload => {
        const {
            page
        } = payload;

        let URL = `admin/users/patient/appointments/${appointmentStatus.Completed}/${patientId}?`;

        if (page) {
            URL += 'page=' + page;
        }
        const res = await getCaller(URL)

        setAppointment(res?.data || [])
    }

    useEffect(() => {
        appointmentsData({})
    }, [])
    return (
        <>
            <AptTable
                tableSize='sm'
                data={appointment}
                refreshData={appointmentsData}
            />
        </>
    )
}

export default UserPastAppointments