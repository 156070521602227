import React, { useState, useEffect } from 'react';
import { Slide } from "@mui/material";
import { useForm } from 'react-hook-form';
import { Avatar, Dialog, Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";
import { checkAvailability, fetchAWSObject } from '../../../../utils';
import { fetchGeneralFooterLogoRQ} from './Calls';
import { ThemeButton } from '../../../../components/common/button/Button';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

function LogoDialogBox({
    openDialog,
    setOpenDialog,
    parentData,
    fetchGeneralFooter

}) {

    /* Image Perview State */
    const [preview, setPreview] = React.useState(null);

    /* Button State */
    const [loader, setLoader] = useState();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const handleClose = () => {
        setOpenDialog(false);
    }

    const handleImageRender = async e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setValue('file', file)
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }

            if (!checkAvailability(file.type)) { return alert("Unsupported File") }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setPreview(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }
    const handleServiceSubmit = async data => {
        setLoader(true);
        /* It Means Update */
        /* UPDATE */
        const _data = new FormData();
        _data.append('file', data.file)

        const response = await fetchGeneralFooterLogoRQ(parentData?.id, _data);
        if (response.errors === false) {
            fetchGeneralFooter()
            setOpenDialog(true);
            setLoader(false);
        } else {
            setLoader(false);
            alert(response.msg)
        }

    }

    useEffect(() => {
        if (parentData?.id) {
            setPreview(fetchAWSObject(parentData?.content))
        }
        // setPreview(null);
    }, [parentData])


    return (
        <div>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <Container>
                    <Header>
                        Edit Logo
                        <IconButton onClick={handleClose}>
                            <CloseIcon className='pointer' />
                        </IconButton>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit(handleServiceSubmit)} >
                            <div className="home-wrapper">
                                <div className="home-form">
                                    <div className='home-input-content home-input-content' style={{ marginLeft: "2rem" }}>
                                        <label> Image </label> <br />
                                        <input
                                            {
                                            ...register("file",
                                                {
                                                    required: (parentData?.id) ? false : true
                                                }
                                            )}
                                            type="file"
                                            name="file"
                                            id="image"
                                            className='hidden'
                                            onChange={handleImageRender}

                                        />
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <label
                                                    htmlFor='image'
                                                    className='home-input-field-file'
                                                    placeholder='Upload'
                                                    style={{ border: 'none', display: 'block' }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div
                                                    // style={{
                                                    //     margin: "auto",
                                                    //     display: "flex",
                                                    //     alignItems: "center",
                                                    //     justifyContent: "center",
                                                    //     height: "100%"
                                                    // }}
                                                >
                                                    <Avatar alt="Remy Sharp" src={preview} style={{width:"100px",  height:"82px"}} />
                                                    <ThemeButton
                                                        style={{
                                                            color: loader ? 'grey' : '#2f77b6'
                                                        }}
                                                        loading={loader}
                                                        text={'Update'}
                                                    />

                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className={'input-invalid-message'}>
                                            {errors.file && 'Image is required'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Body>
                </Container>
            </Dialog>
        </div>
    );
}

const Container = styled("div")`
    padding: 1rem;
    min-width: 400px;
    width: 40vw;
`;

const Header = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Body = styled("div")`
    width: 97%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 0.2rem;
    padding-left: 1rem;
    border-radius: 5px;
    input{
        border: none;
        outline: none;
        border: 1px solid #d2d2d2;
        width: 100%;
        color: var(--text-light);
    }
`;










export default LogoDialogBox