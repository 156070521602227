import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import sortarrow from '../../assets/images/sort-arrow.svg'
import eye from '../../assets/images/eye.png'
import edit from '../../assets/images/edit-2.svg'
import lock from '../../assets/images/lock.svg'
import FilterInput from '../../components/common/filterfield/FilterInput'
import Pagination from '../../components/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { deleteCaller, getCaller, postCaller } from '../../services/api';
import { constants, profiles } from 'utils/constants';
import { dateFormatWW, destructureProfile } from 'utils';
import { Tooltip } from '@mui/material';
import FilterModal from './FilterModal'
import useMasters from 'hooks/useMasters';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import TOAST from 'components/common/toast/Success'
import { responseMessages } from 'utils/responseMessages';
import { useTranslation } from 'react-i18next';

let orderStatus = false;

const UserTable = ({
    data,
    refreshData
}) => {
    const navigate = useNavigate()
    const { user } = useMasters();

    const { t } = useTranslation()

    const [pagina, setPagina] = useState(1);
    const [porPagina, setPorPagina] = useState(5);
    const searchTextFieldRef = useRef(null);
    const [modal, setModal] = useState(false);
    const maximo = data?.count / porPagina;
    const [query, setQuery] = useState("");


    const handleModalClose = () => {
        setModal(false);
    }

    const handleModalOpen = () => setModal(true);

    const handleView = async payload => {
        const {
            type, loginId
        } = payload;

        if (type === 'user') {
            navigate(`/view/profile/patient/${loginId}`)
        }
        else {
            navigate(`/view/profile/doctor/${loginId}`)
        }
    }

    const handleEdit = async payload => {
        if (
            window.confirm('Are you sure you want to edit ?')
        ) {
            console.log("🚀 ~ file: UserTable.jsx:59 ~ handleEdit ~ payload", payload)
            const {
                type,
                loginId
            } = payload;

            if (type === profiles.user) {
                return navigate(
                    '/addNewPatient',
                    {
                        state: {
                            patientId: loginId
                        }
                    }
                )
            } else {
                return navigate(
                    '/addNewDoctor',
                    { state: { doctorId: loginId } }
                )
            }
        }

    }

    const nextData = async page => {
        const payload = {
            ...query,
            page
        }
        refreshData(payload)
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;

        refreshData({ page: 1, searchText: value })
    }

    const filterSortOrder = () => {
        orderStatus = !orderStatus;
        refreshData({ page: pagina, orderStatus: orderStatus ? "ASC" : "DESC" })
    }


    /* DELETE Function */

    const handleDelete = async (e) => {
        if (
            window.confirm('Are you sure you want to delete ?')
        ) {
            const loginId = e?.loginId
            const response = await deleteCaller(`admin/user/${loginId}`)
            if (response?.error === true) {
                toast.info(response?.msg)
            } else {
                toast.success(response?.msg)
            }
            refreshData({ page: pagina })
            return;
        }
    }


    /* Lock Functions */

    const handleLock = async (e) => {
        if (
            window.confirm('Are you sure you want to Rest this')
        ) {
            const mobile = e?.mobile
            const data = {
                "mobile": mobile
            }
            const response = await postCaller(`forgot-password`, data)
            if (response?.error === false) {
                toast.success(<TOAST message={response?.msg} />)
            } else {
                toast.info(<TOAST message={response?.msg || responseMessages.UNKNOWN_ERROR} />)
            }
        }
    }

    useEffect(() => {
        nextData(pagina)
    }, [pagina])

    return (
        <>
            <FilterModal
                open={modal}
                handleClose={handleModalClose}
                refreshData={refreshData}
                setQuery={setQuery}
            />
            <div>
                <div className="filter-field">
                    <p> {data?.count ?? "0"} {t("Items")} </p>
                    <div className="filter-search">
                        <div className="filter-search-field">
                            <input
                                type="text"
                                id="search-box"
                                placeholder={t("Search")}
                                ref={searchTextFieldRef}
                                onKeyUp={event => event.key === 'Enter' && handleTextFieldButton()}
                            />
                            <label
                                htmlFor="search-box"
                                className="fas fa-search"
                                onClick={handleTextFieldButton}
                            ></label>
                        </div>
                        <div className="filter-button">
                            <button
                                onClick={handleModalOpen}
                            > {t("Filter")} </button>
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700, minHeight: 400 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell> {t("Username")} </StyledTableCell>
                                <StyledTableCell> {t("Mobile Number")} </StyledTableCell>
                                <StyledTableCell> {t("Email Id")}</StyledTableCell>
                                <StyledTableCell> {t("Date of Joining")} </StyledTableCell>
                                <StyledTableCell> {t("User Type")} </StyledTableCell>
                                <StyledTableCell>
                                    {t("Status")}
                                    <img
                                        src={sortarrow}
                                        alt=""
                                        onClick={filterSortOrder}
                                    />
                                </StyledTableCell>
                                <StyledTableCell> {t("Action")} </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {
                                data && data?.rows?.map((e, i) => {
                                    return (
                                        <StyledTableRow key={'User_Table_${i}'} >
                                            <StyledTableCell component="th" scope="row"> {destructureProfile(e)?.name ?? "n/a"}  </StyledTableCell>
                                            <StyledTableCell> {destructureProfile(e)?.mobile ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell> {destructureProfile(e)?.email ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell> {dateFormatWW(e?.createdAt) ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell style={{ textTransform: 'capitalize' }}> {user?.[e?.type]?.UI ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell>
                                                <p
                                                    style={{
                                                        fontWeight: "bold",
                                                        color: (!e?.isVerify) ? '#E80707' : '#13BC34'
                                                    }}
                                                >
                                                    {(e?.isVerify) ? "Verified" : "Not-Verified"}
                                                </p>
                                            </StyledTableCell>
                                            <StyledTableCell className='table-edit-one' >
                                                <Tooltip title="View">
                                                    <div className="tooltip">
                                                        <img src={eye} alt="View" onClick={() => handleView(e)} />
                                                        {/* <p className="tooltiptext"> View </p> */}
                                                    </div>
                                                </Tooltip>

                                                <Tooltip title="Edit">
                                                    <div className="tooltip">
                                                        <img src={edit} alt="Edit" onClick={() => handleEdit(e)} />
                                                        {/* <p className="tooltiptext"> Edit </p> */}
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Rest">
                                                    <div className="tooltip">
                                                        <img src={lock} alt="Reject"
                                                            onClick={() => handleLock(e)}
                                                        />

                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <div className="tooltip">
                                                        <DeleteIcon
                                                            onClick={() => handleDelete(e)}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: "top",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        verticalAlign: "top",
        userSelect: "none"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default UserTable


// { props?.data?.upcoming && props?.data?.upcoming?.map((e,i)=>{  })}