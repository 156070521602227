import React, { useState } from 'react'
import '../configuration/configur.css'

/* LAZY IMPORTS */
// const Teams = React.lazy(() => import("./teams"));
// const DoctorSettings = React.lazy(() => import("./doctorSettings/DoctorSettings"));
import DoctorSettings from './doctorSettings/DoctorSettings';
import Teams from './teams';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const setActiveInactiveClass = ({ isActive }) => { return isActive ? 'conf-tab conf-active-tab' : 'conf-tab' }


const Configuration = () => {

    const {t} = useTranslation()

    const { pathname } = useLocation();

    const [tabIndex, setTabIndex] = useState(0);
    return (
        <>
            <div className='configure-container'>
                <ul>

                    <NavLink
                        // className={pathname === '/' ? 'tablink active-side' : 'tablink'}
                        className={setActiveInactiveClass}
                        to={''}
                    >
                        <li>
                            {t("General")}
                        </li>
                    </NavLink>


                    <NavLink
                        className={setActiveInactiveClass}
                        to={'settings-doctor'}
                    >
                        <li>
                            {t("Doctor Settings")}
                        </li>
                    </NavLink>


                    <NavLink
                        className={setActiveInactiveClass}
                        to={'settings-patient'}
                    >
                        <li>
                            {t("Patient Settings")}
                        </li>
                    </NavLink>

                    <NavLink
                        className={setActiveInactiveClass}
                        to={'settings-appointment'}
                    >
                        <li>
                            {t("Appointment")}
                        </li>
                    </NavLink>

                    <NavLink
                        className={setActiveInactiveClass}
                        to={'settings-coupon'}
                    >
                        <li>
                            {t("Coupon Codes")}
                        </li>
                    </NavLink>

                    <NavLink
                        className={setActiveInactiveClass}
                        to={'settings-teams'}
                    >
                        <li>
                            {t("Teams")}
                        </li>
                    </NavLink>
                </ul>
                
                {/* where will pipe flow */}
                <Outlet />
            </div>
        </>
    )
}

export default Configuration