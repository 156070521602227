import { MdDelete } from 'react-icons/md'
import { getAllCategories } from '../Calls';
import React, { useEffect, useState } from 'react'
import Header from '../../../../layout/header/Header'
import '../../../websitecontent/homePage/edithome.css';
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import useGlobal from 'hooks/useGlobal';
import { useTranslation } from 'react-i18next';

const INITIAL__LANGUAGE = { en: '', ar: '' };
const SectionTwo = ({
    sectionTwoData,
    setSectionTwoData,
    disabled
}) => {
    const { tKey: [tKey] } = useGlobal();

    const { t } = useTranslation();


    const [title, setTitle] = useState(INITIAL__LANGUAGE);
    const [subtitle, setSubtitle] = useState(INITIAL__LANGUAGE);
    const [cats, setCats] = useState([]);


    const handleInitialCat = async () => {
        const cats = await getAllCategories();

        setSectionTwoData(prev => {
            return {
                ...prev,
                categories: cats
            }
        })
        setCats([...cats]);
    }

    const handleCats = index => {
        const _cats = cats;
        /* 'homepageVisibility' is a database column*/
        _cats[index].homepageVisibility = !(_cats[index].homepageVisibility);
        setSectionTwoData(prev => {
            return {
                ...prev,
                categories: _cats
            }
        })
        setCats([..._cats]);
    }


    useEffect(() => {
        setTitle(sectionTwoData?.title);
        setSubtitle(sectionTwoData?.subtitle);
        // setCats(sectionTwoData?.categories);
    }, [sectionTwoData]);

    useEffect(() => {
        handleInitialCat();
    }, [])

    return (
        <>
            <Header />
            {/* section two  */}
            <div className="home-section-one">
                <p> Section 2 </p>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (EN)")} </label> <br />
                    <input
                        disabled={disabled}

                        type="text"
                        name='title'
                        value={title?.en}
                        className='home-input-field'
                        placeholder='Title (en)'
                        onChange={e => {
                            const temp = sectionTwoData;
                            temp.title = {
                                'en': e.target.value,
                                'ar': temp?.subtitle?.ar
                            };
                            setSectionTwoData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (AR)")} </label> <br />
                    <input
                        type="text"
                        disabled={disabled}
                        name='title'
                        value={title?.ar}
                        className='home-input-field'
                        placeholder='Title (ar)'
                        onChange={e => {
                            const temp = sectionTwoData;
                            temp.title = {
                                'ar': e.target.value,
                                'en': temp?.subtitle?.en
                            };
                            setSectionTwoData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (EN) </label> <br />
                    <input
                        disabled={disabled}
                        type="text"
                        name='subtitle'
                        value={subtitle?.en}
                        className='home-input-field'
                        placeholder='Sub-Title (en)'
                        onChange={e => {
                            const temp = sectionTwoData;
                            temp.subtitle = {
                                'en': e.target.value,
                                'ar': temp?.subtitle?.ar
                            };
                            setSectionTwoData({ ...temp })
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (AR)</label> <br />
                    <input
                        disabled={disabled}
                        type="text"
                        name='subtitle'
                        value={subtitle?.ar}
                        className='home-input-field'
                        placeholder='Sub-Title (ar)'
                        onChange={e => {
                            const temp = sectionTwoData;
                            temp.subtitle = {
                                'ar': e.target.value,
                                'en': temp?.subtitle?.en
                            };
                            setSectionTwoData({ ...temp })
                        }}
                    />
                </div>

                {
                    cats && cats.map((cat, index) => {
                        return (
                            <div
                                className='section-bg-input'
                                key={`CAT-${index}`}
                            >
                                <label htmlFor="home"> Category {index + 1} </label> <br />
                                <div className='category-name'>
                                    <input
                                        type="text"
                                        disabled
                                        className={`home-input-field ${!(cat.homepageVisibility) && 'input-disabled'}`}
                                        placeholder='Body Imaging Clinic'
                                        defaultValue={cat?.sub_services?.[tKey]}
                                    />
                                    {

                                        !disabled
                                        && <span
                                            onClick={() => handleCats(index)}
                                        >
                                            {
                                                cat.homepageVisibility ?
                                                    <BsFillEyeFill
                                                        className='delete-icon'
                                                    /> :
                                                    <BsFillEyeSlashFill
                                                        className='delete-icon'
                                                    />
                                            }
                                        </span>
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </>
    )

}

export default SectionTwo