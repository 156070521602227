import { Typography } from "@mui/material";
const TableHeading = ({ name }) => {
    return (
        <Typography
            sx={{
                marginTop: 2.5,
                marginBottom: 1,
                fontWeight: 'bold'
            }}
        >
            {name}
        </Typography>
    )
}
export default TableHeading;