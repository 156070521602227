import React, { useEffect, useState } from 'react';
import { Avatar, Dialog, Grid, IconButton, Slide } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { addSubServiceRQ, fetchSubServiceByIdRQ, updateSubServiceRQ } from './Calls';
import { checkAvailability, fetchAWSObject } from '../../../../../utils';
import { ThemeButton, UnstyledButton } from '../../../../../components/common/button/Button';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogBox({
    openDialog,
    setOpenDialog,
    parentData,
    fetchTeamListing
}) {
    /* ISOLATED STATES */
    const [preview, setPreview] = React.useState(null);

    /* Button States */
    const [loader, setLoader] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();



    const fetchSubService = async (id) => {
        const response = await fetchSubServiceByIdRQ(id)
        setValue('name', response?.data?.sub_services);
        setPreview(fetchAWSObject(response?.data?.image))
    }


    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleImageRender = async e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setValue('file', file)
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }

            if (!checkAvailability(file.type)) { return alert("Unsupported File") }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setPreview(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }


    const handleServiceSubmit = async data => {
        setLoader(true);
        if (parentData?.id) {
            /* It means Update */
            /* UPDATE */
            const _data = new FormData();
            _data.append('sub_services', JSON.stringify(data.name))
            _data.append('service_id', data.id)
            _data.append('file', data.file)

            const response = await updateSubServiceRQ(parentData.id, _data);
            if (response.error === false) {
                fetchTeamListing({})
                setOpenDialog(false);
                setLoader(false);
            } else {
                setLoader(false)
                alert(response.msg);
            }

        } else {
            /* It means Insert */
            /* INSERT */
            const _data = new FormData();
            _data.append('serviceMasterId', parentData?.masterId)
            _data.append('sub_services', JSON.stringify(data.name))
            _data.append('file', data.file)

            const response = await addSubServiceRQ(_data);
            if (response.error === false) {
                fetchTeamListing({})
                setOpenDialog(false);
                setLoader(false)
            } else {
                setLoader(false)
                alert(response.msg);
            }
        }
    }

    // useEffect(() => {
    //     if (parentData?.id) {
    //         fetchSubService(parentData.id)
    //     }
    //     setValue('field_name', '');
    //     setPreview(null);
    //     reset();
    // }, [parentData])


    /* Modify UseEffects */
    useEffect(() => {
        setValue('name.en', '');
        setValue('name.ar', '');
        setPreview(null);
        reset();
        console.log("🚀 ~ file: Dialog_Modal.jsx:125 ~ useEffect ~ parentData", parentData)
        if (parentData) {
            setValue('name.en', parentData.name?.en);
            setValue('name.ar', parentData.name?.ar);
            setPreview(parentData?.image)
        }
    }, [parentData])

    return (
        <div>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Container>
                    <Header>
                        Edit Service
                        <IconButton onClick={handleClose}>
                            <CloseIcon className='pointer' />
                        </IconButton>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit(handleServiceSubmit)} >
                            <div className="home-wrapper">
                                <div className="home-form">
                                    <div className='home-input-content-full'>
                                        <label htmlFor="home">  Field Name (EN) </label> <br />
                                        <input
                                            {...register("name.en", { required: true })}
                                            type="text"
                                            className='home-input-field'
                                            placeholder='Enter Sub Category Name'
                                        />
                                        <div className={'input-invalid-message'}>
                                            {errors.field_label && 'Name is required'}
                                        </div>
                                    </div>
                                </div>
                                <div className="home-wrapper">
                                    <div className="home-form">
                                        <div className='home-input-content-full'>
                                            <label htmlFor="home"> Field Name (AR) </label> <br />
                                            <input
                                                {...register("name.ar", { required: true })}
                                                type="text"
                                                className='home-input-field'
                                                placeholder='Enter Label Name'
                                            />
                                            <div className={'input-invalid-message'}>
                                                {errors.field_label && 'Field Name is required'}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='home-input-content home-input-content' style={{ marginLeft: "2rem" }}>
                                    <label> Image </label> <br />
                                    <input
                                        {
                                        ...register("file",
                                            {
                                                // required: (parentData?.id) ? false : true
                                            }
                                        )}
                                        type="file"
                                        name="file"
                                        id="image"
                                        className='hidden'
                                        onChange={handleImageRender}

                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <label
                                                htmlFor='image'
                                                className='home-input-field-file'
                                                placeholder='Upload'
                                                style={{ border: 'none', display: 'block' }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "100%"
                                                }}
                                            >
                                                <Avatar alt="Remy Sharp" src={preview} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className={'input-invalid-message'}>
                                        {errors.file && 'Image is required'}
                                    </div>
                                </div>
                            </div>

                            <Grid
                                container
                                spacing={2}
                                style={{
                                    marginTop: '1rem'
                                }}
                            >
                                <Grid item xs={8}></Grid>
                                <Grid item xs={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end'
                                    }}>
                                    <ThemeButton
                                        style={{
                                            color: loader ? 'grey' : '#2f77b6'
                                        }}
                                        loading={loader}
                                        text={parentData?.id ? "Update" : "Add"}
                                    />
                                </Grid>
                            </Grid>

                        </form>
                    </Body>
                </Container>
            </Dialog>
        </div>
    );
}



const Container = styled("div")`
    padding: 1rem;
    min-width: 400px;
    width: 40vw;
`;

const Header = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Body = styled("div")`
    width: 97%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 0.2rem;
    padding-left: 1rem;
    border-radius: 5px;
    input{
        border: none;
        outline: none;
        border: 1px solid #d2d2d2;
        width: 100%;
        color: var(--text-light);
    }
`;



export default DialogBox;