import React, { useState, useEffect } from 'react'
import '../serviceOverview/service.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import arrowleft from "../../../assets/images/Path -33.png";
import arrowright from "../../../assets/images/Path -34.png";
import { getCaller } from '../../../services/api';
import Appointments from './Appointments';
import Doctors from './Doctors';

const ServiceOver = props => {
    // const [serviceIndex, setServiceIndex] = useState(0);
    const [btnIndex, setBtnIndex] = useState(0);
    const [sliderRef, setSliderRef] = useState(null)
    const [service, setService] = useState()
    useEffect(() => {
        serviceOverview()
    }, [])

    const serviceOverview = async () => {
        const resp = await getCaller('appoinmentServiceOverview')
        setService(resp)
    }

    console.log(service, "service")
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },

        ]
    };
    return (
        <>
            <div className="service-container">
                <div className="service-head">
                    <div>
                        <p>Service Overview</p>
                    </div>
                    <div className="service-btn">
                        <button className={`apt-btn ${btnIndex === 0 ? 'btn-active' : null} `} onClick={() => { setBtnIndex(0) }} >Appointments</button>
                        <button className={`apt-btn ${btnIndex === 1 ? 'btn-active' : null} `} onClick={() => { setBtnIndex(1) }}> Doctors </button>
                    </div>
                </div>
                <div className="service-arrow">
                    <img
                        src={arrowleft}
                        alt=""
                        style={{ marginRight: "1rem", cursor: "pointer" }}
                        onClick={sliderRef?.slickPrev}
                    />
                    <img src={arrowright} alt="" style={{ cursor: "pointer" }} onClick={sliderRef?.slickNext} />
                </div>
                {
                    btnIndex === 0 &&
                    <Appointments
                        {...props}
                    />
                }
                {
                    btnIndex === 1 &&
                    <Doctors
                        {...props}
                    />
                }

            </div>
        </>
    )
}

export default ServiceOver