import React, { useEffect, useState } from 'react'
import './aptoverview.css'
import AptTable from '../appointment/AptTable'
import AppointmentOverCard from '../../components/common/appointmenCard/AppointmentOverCard'
import { getCaller, users } from '../../services/api'
import { generateQuery } from 'utils'
import { useTranslation } from 'react-i18next'
const AptOverView = () => {
    /* Appointments State */
    const {t} = useTranslation()
    const [appointment, setAppointment] = useState("")

    const appointmentsData = async payload => {
        let URL = 'admin/dashboard/appointments?';

        URL = generateQuery(URL, payload);

        const res = await getCaller(URL)
        setAppointment(res?.data || [])
    }
    useEffect(() => {
        console.log("HERE HERE");
        appointmentsData();
    }, [])

    // const [data, setData] = useState("");

    // const getAllAppointmentData = async () => {
    //     const res = await getCaller('getAppointmentDashboard')
    //     setData(res.data)
    //     console.log(res)
    // }
    // useEffect(() => {
    //     getAllAppointmentData()
    // }, [])

    return (
        <>
            <div className='apt-container'>
                <p>{t("Appointments Overview")}</p>
                <div className='border'>
                    <AppointmentOverCard
                        appointments={appointment}
                    />
                </div>
                <div className='apt-table'>
                    <AptTable
                        data={appointment?.allAppointments}
                        setData={setAppointment}
                        refreshData={appointmentsData}
                    />
                </div>
            </div>
        </>
    )
}

export default AptOverView