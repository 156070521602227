import React from 'react';
import socketio from "socket.io-client";
import { URLS } from 'utils/constants';
import GlobalContext from './GlobalContext';

const socket = socketio.connect(URLS.API_BASE_URL);


const GlobalState = (props) => {

    /* .IMPORTANT. */
    /* Don't Add states unless you want to use in whole react. */
    const userDetails = React.useState();
    const tKey = React.useState('en');

    return (
        <GlobalContext.Provider value={{
            userDetails,

            tKey,

            /* Socket */
            socket: socket
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalState
