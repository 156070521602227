export const theme = {
    regular: "#4facfe",
    dark: "#2f77b6"
}


export const __status_color = {
    success: '#13BC34',
    error: '#E80707',
    warning: '#EFD511'
}