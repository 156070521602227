import React from 'react'
import { useEffect, useState } from 'react'
import { json, useLocation, useNavigate, useParams } from 'react-router-dom'
import Header from '../../../layout/header/Header'
import { getCaller, postCaller, updateCaller } from '../../../services/api';
import '../TermsConditions/Sections/TermsCondition.css'
import Section from '../TermsConditions/Sections/Section';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { responseMessages } from 'utils/responseMessages';
import { ThemeButton, UnstyledButton } from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';

const INITIAL__LANGUAGE = { en: '', ar: '' };
const INITIAL_SECTIONS = [
    {
        title: INITIAL__LANGUAGE,
        content: INITIAL__LANGUAGE
    },
    {
        title: INITIAL__LANGUAGE,
        content: INITIAL__LANGUAGE
    },
];

const EditTermsConditions = () => {
    const { id } = useParams();
    const { state: { disabled } } = useLocation();

    const { t } = useTranslation();

    /* All inputs states */
    const [pageName, setPageName] = useState(INITIAL__LANGUAGE);
    const [pageUrl, setPageUrl] = useState('');

    const [sectionsData, setSectionsData] = useState({})

    const navigate = useNavigate();

    const handleSaveTerm = async () => {
        try {
            const data = {
                id: id,
                page_name: pageName,
                // page_name_en: pageName?.en,
                // page_name_ar: pageName?.ar,
                page_url: pageUrl,
                content: sectionsData
            }

            const response = await updateCaller(`admin/website-content/${id}`, data)

            if (response?.error === false) {
                toast.success(response?.msg || responseMessages.UNKNOWN_ERROR)
                return navigate('/dashboard/webiste-contents')

            }
            toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
            // navigate('/dashboard/webiste-contents')
            return;
        } catch {
            return toast.info(responseMessages.UNKNOWN_ERROR)
        }
    }

    const getTermCondById = async () => {
        try {
            const response = await getCaller(`admin/website-content/${id}`)
            if (response?.error === false && response?.data) {
                const {
                    // page_name_en,
                    // page_name_ar,
                    page_name,
                    page_url,
                    content
                } = response?.data;

                page_name && setPageName(page_name)

                setPageUrl(page_url)
                if (Array.isArray(content) && content.length > 0) {
                    setSectionsData(content)
                } else { setSectionsData(INITIAL_SECTIONS) }
            }
            else {
                throw true;
            }
        } catch { }
    }

    useEffect(() => {
        getTermCondById()
    }, [])
    return (
        <>
            <Header />
            <div className='edit-terms-container'>
                <Typography
                    variant='h5'
                    component={'h1'}
                    sx={{
                        fontWeight: 'bold',
                        mb: 2
                    }}
                >
                    Edit Terms & Condition
                </Typography>
                <div className="terms-wrapper">
                    <div className="terms-form">
                        {/* PAGE NAME */}
                        <div className='terms-input-content'>
                            <label htmlFor="terms"> Page Name (EN) </label> <br />
                            <input
                                disabled={disabled}
                                value={pageName?.en}
                                type="text"
                                className='terms-input-field'
                                placeholder='Page name in er'
                                onChange={({ target: { value } }) => {
                                    setPageName(
                                        _prev => ({
                                            ..._prev,
                                            en: value
                                        })
                                    )
                                }}
                            />
                        </div>
                        <div className='terms-input-content' style={{ marginLeft: "2rem" }}>
                            <label htmlFor="terms"> Page Name (AR) </label> <br />
                            <input type="text"
                                disabled={disabled}

                                value={pageName?.ar}
                                className='terms-input-field'
                                placeholder='Page name in ar'
                                onChange={({ target: { value } }) => {
                                    setPageName(
                                        _prev => ({
                                            ..._prev,
                                            ar: value
                                        })
                                    )
                                }}
                            />
                        </div>

                        {/* PAGE CONTENT */}
                        <div className='terms-input-content' style={{ marginLeft: "2rem" }}>
                            <label htmlFor="terms"> {t("Page URL")} </label> <br />
                            <input
                                type="text"
                                disabled={disabled}

                                value={pageUrl}
                                className='terms-input-field'
                                placeholder='url where terms and conditions page will appear'
                                onChange={(e) => {
                                    setPageUrl(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    {
                        Array(sectionsData?.length || INITIAL_SECTIONS.length).fill(0).map((nodata, index) => {
                            return (
                                <Section
                                    disable={disabled}
                                    sectionsData={sectionsData}
                                    setSectionsData={setSectionsData}
                                    key={index}
                                    index={index}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className="terms-btn-content">
                <UnstyledButton
                    sx={{
                        width: '10rem',
                    }}
                    text="Back"
                    onClick={() => navigate('/dashboard/webiste-contents')}
                />
                {
                    !disabled
                    && <div className="terms-mark">
                        {/* <button className='terms-preview'> Preview </button> */}
                        <ThemeButton
                            text='Save'
                            className='terms-save'
                            onClick={handleSaveTerm}
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default EditTermsConditions