import { getCaller, postCaller, updateCaller } from "../../../../../services/api";


const LISTING = id => `admin/subServices/${id}`;
const BYID = id => `admin/getSubService/${id}`;
const ADD = 'admin/addSubService';
const UPDATE = id => `admin/updateSubService/${id}`;


export const fetchSubServicesByServiceIdRQ = async (id) => {
    const response = await getCaller(LISTING(id));
    return (response?.error == false) ? response : false;
}

export const fetchSubServiceByIdRQ = async id => {
    const response = await getCaller(BYID(id));
    return (response?.error == false) ? response : false;
}

export const addSubServiceRQ = async formData => {
    const response = await postCaller(ADD, formData, true);
    return (response?.error == false) ? response : false;
}

export const updateSubServiceRQ = async (id, formData) => {
    const response = await updateCaller(UPDATE(id), formData, true);
    return (response?.error == false) ? response : false;
} 

