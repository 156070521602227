import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../generalSettings/general.css';
import { fetchFooterContentRQ, fetchMasterServicesListingRQ } from './Call';
import LogoModal from '.././generalSettings/Modals/LogoModal'
import LinkModal from './Modals/LinksModal'
import HyperLinkModal from './Modals/Hyperlink';
import { fix_pages } from '../../../utils/constants';
import { updateGeneralFooterRQ } from './Modals/Calls';
import { updateCaller } from 'services/api';
import useGlobal from 'hooks/useGlobal';
import { Switch } from '@mui/material';
import edit from '../../../assets/images/edit-2.svg'
import { useTranslation } from 'react-i18next';
import { ThemeButton } from 'components/common/button/Button';
import { toast } from 'react-toastify';
import { responseMessages } from 'utils/responseMessages';

const GeneralSettings = () => {
    const { t } = useTranslation()

    /* custom hook */
    const { tKey: [tKey] } = useGlobal();

    /* Refs */
    const websiteLogoRef = useRef(null)

    /* ISOLATED STATES */
    const [modalType, setModalType] = useState('');
    const [footer, setFooter] = React.useState([]);
    const [masterServices, setMasterServices] = useState([]);
    const [logoLoader, setLogoLoader] = useState(false)

    /* HyperLink modal states */
    const [hyperModal, setHyperModal] = useState(false);
    const [hyperState, setHyperState] = useState({});

    /* Logo Model */
    const [open, setOpen] = React.useState(false);
    const [logoState, setLogoState] = useState({});


    /* Links Modal */
    const [openLink, setOpenLink] = useState(false);
    const [linkState, setLinkState] = useState({});

    /* HANDLERS */
    const fetchMasterServicesListing = async () => {
        const response = await fetchMasterServicesListingRQ();
        setMasterServices(response?.data?.rows || [])
    }

    const fetchFooterContent = async () => {
        const response = await fetchFooterContentRQ();
        setFooter(response?.data?.rows || [])
    }

    const disabledOrEnable = async (id, status) => {
        console.log("🚀 ~ file: GeneralSettings.jsx:56 ~ disabledOrEnable ~ status", status)
        let data = {};
        console.log(status);
        if (status === 'disable') {
            data.status = 0;
        } else if (status === 'enable') {
            data.status = 1;
        }
        const response = await updateGeneralFooterRQ(id, data);
        console.log('response', response)
        fetchFooterContent();
    }

    const disabledOrEnableServices = async (id, status) => {
        let data = {};
        if (status === 'disable') {
            data.status = 0;
        } else if (status === 'enable') {
            data.status = 1;
        }
        await updateCaller(`admin/services-masters/${id}`, data);
        fetchMasterServicesListing();
    }

    const handleUploadWebsiteImage = async () => {
        const [onlyLogo] = footer.filter(foot => foot.page_type === fix_pages.logo)
        if (onlyLogo) {
            const [file] = websiteLogoRef.current.files;
            if (file) {
                const formData = new FormData();
                formData.append('file', file)
                try {
                    setLogoLoader(true)
                    const response = await updateCaller(`admin/config/general/footer/logo/${onlyLogo.id}`, formData, true)
                    if (response?.error === false) {
                        websiteLogoRef.current.value = null;
                        toast.success(response?.msg || responseMessages.UNKNOWN_ERROR)
                    } else {
                        toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
                    }
                    setLogoLoader(false)
                } catch (error) {
                    console.log("🚀 ~ file: GeneralSettings.jsx:92 ~ handleUploadWebsiteImage ~ error", error)
                }
            }
        }

    }


    React.useEffect(() => {
        fetchMasterServicesListing();
        fetchFooterContent();
    }, [])

    const getWhatToRender = foot => {
        switch (foot.page_type) {
            case fix_pages.contact:
                return <></>

            case fix_pages.logo:
                return (
                    <div className='service-general-body'>
                        <p>{foot?.page_name?.['en']}</p>
                        <p> Logo </p>
                        <div>
                            <Link
                                onClick={() => {
                                    setOpen(true);
                                    setLogoState(foot)
                                    setModalType(foot?.page_name?.[tKey])
                                }}
                            > <img src={edit} alt="Accept" />
                            </Link>

                            <Switch
                                defaultChecked={foot?.status || false}
                                onClick={() => {
                                    disabledOrEnable(
                                        foot.id,
                                        foot.status == true ? 'disable' : 'enable'
                                    )
                                }}
                            />
                            {/* {foot.status == true ? "Disabled" : "Enabled"} </Link> */}
                        </div>
                    </div>
                )

            case fix_pages.socialmeadilink:
                return (
                    <div className='service-general-body'>
                        <p>{foot?.page_name?.['en']}</p>
                        <p> Social Media Links </p>
                        <div>
                            <Link
                                onClick={() => {
                                    setModalType(fix_pages.socialmeadilink)
                                    setOpenLink(true);
                                    setLinkState(foot)
                                }}

                            > <img src={edit} alt="Accept" /> </Link>
                            <Switch
                                defaultChecked={foot?.status || false}
                                onClick={() => {
                                    disabledOrEnable(
                                        foot.id,
                                        foot.status == true ? 'disable' : 'enable'
                                    )
                                }}
                            />
                        </div>
                    </div>
                )

            case fix_pages.email:
                return (
                    <div className='service-general-body'>
                        <p>{foot?.page_name?.[tKey]}</p>
                        {/* <p> {foot.page_type.toLowerCase()} </p> */}
                        <p> Email </p>
                        <div>
                            <Link
                                onClick={() => {
                                    setModalType(foot?.page_name?.[tKey])
                                    setHyperModal(true);
                                    setHyperState(foot)
                                }}
                            >
                                <img src={edit} alt="Accept" /></Link>
                            {/* <Link to="#"> Disabled </Link> */}
                            <Switch
                                defaultChecked={foot?.status || false}
                                onClick={() => {
                                    disabledOrEnable(
                                        foot.id,
                                        foot.status == true ? 'disable' : 'enable'
                                    )
                                }}
                            />
                            {/* {foot.status == true ? "Disabled" : "Enabled"}  */}
                        </div>
                    </div>
                )

            default:
                return (
                    <div className='service-general-body'>
                        <p>{foot?.page_name?.[tKey]}</p>
                        {/* <p> {foot.page_type.toLowerCase()} </p> */}
                        <p> Hyperlink </p>
                        <div>
                            <Link
                                onClick={() => {
                                    setModalType(foot?.page_name?.[tKey])
                                    setHyperModal(true);
                                    setHyperState(foot)
                                }}
                            >
                                <img src={edit} alt="Accept" /></Link>
                            {/* <Link to="#"> Disabled </Link> */}
                            <Switch
                                defaultChecked={foot?.status || false}
                                onClick={() => {
                                    disabledOrEnable(
                                        foot.id,
                                        foot.status == true ? 'disable' : 'enable'
                                    )
                                }}
                            />
                            {/* {foot.status == true ? "Disabled" : "Enabled"}  */}
                        </div>
                    </div>
                )
        }
    }

    return (
        <>
            {/* MODALS */}
            <HyperLinkModal
                type={modalType}
                openDialog={hyperModal}
                setOpenDialog={setHyperModal}
                parentData={hyperState}
                setParentData={setHyperState}
                refreshData={fetchFooterContent}
            />

            <LogoModal
                openDialog={open}
                setOpenDialog={setOpen}
                parentData={logoState}
                setParentData={setLogoState}
            />

            <LinkModal
                openLinkDialog={openLink}
                setOpenLink={setOpenLink}
                parentData={linkState}
                setParentData={setLinkState}
            />

            <div className="general-container">
                <div className="general-input-body">
                    <div className="general-input">
                        <label htmlFor="web"> {t("Website URL")}</label>
                        <input type="text" id="web" placeholder='https:\\www.varclinc.ksa' />
                    </div>
                    <div className="general-input" style={{ marginLeft: "3rem" }}>
                        <label htmlFor="weblogo"> {t("Website Logo")}</label>
                        <div style={{
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center',
                            columnGap: '1rem'
                        }}>
                            <input ref={websiteLogoRef} type="file" id='weblogo' placeholder='Upload' className='home-input-field-file' />
                            <ThemeButton
                                onClick={handleUploadWebsiteImage}
                                sx={{ height: 'auto' }}
                                text={'Upload'}
                                loading={logoLoader}
                            />
                        </div>
                    </div>
                </div>


                <div className='general-send-noti'>
                    <p>{t("Send notification to VAR clinic users")}</p>
                    <Link to="/push-notifications">  {t("Push notification")} </Link>
                </div>
                <div className="s-general-cat">
                    <p className='common-para'> {t("Service Categories")} </p>
                    <div className='border-service'>
                        {

                            Array.isArray(masterServices) && masterServices.map(
                                masterSer => {
                                    return (
                                        <>
                                            <div className='service-general-body'>
                                                <p>{masterSer?.services?.[tKey] || ''}</p>
                                                <p>{masterSer?.service?.length} {t("Sub-categories")}</p>
                                                <div className='edit-link'>
                                                    <Link to={`settings-services/sub/${masterSer?.serviceMasterId}`} style={{ marginRight: "2rem" }}> <img src={edit} alt="Accept" /> </Link>
                                                    <Switch
                                                        defaultChecked={masterSer?.status || false}
                                                        onClick={() => {
                                                            disabledOrEnableServices(
                                                                masterSer.serviceMasterId,
                                                                masterSer.status == true ? 'disable' : 'enable'
                                                            )
                                                        }}
                                                    />
                                                    {/* {masterSer.status == true ? "Enabled" : "Disabled"}
                                                </Link> */}
                                                </div>
                                            </div>
                                            <div style={{ borderTop: "1px solid #F0F0F0" }}></div>
                                        </>
                                    )
                                })
                        }

                    </div>
                </div>


                <div className="s-general-cat">
                    <p className='common-para'>{t("Footer Categories")}</p>
                    <div className='border-service'>

                        {/* <div className='service-general-body'>
                            <p>Logo</p>
                            <p> Image Upload</p>
                            <div>

                                <Link style={{ marginRight: "2rem" }}
                                    onClick={() => {
                                        setOpen(true);
                                        setLogoState(footer.find(foot => foot.page_type === fix_pages.logo))
                                    }}

                                > Edit </Link>
                                <Link to="#"> Disabled </Link>
                            </div>
                        </div> */}

                        {
                            Array.isArray(footer) && footer.map(foot => {
                                return (
                                    getWhatToRender(foot)
                                )
                            })
                        }

                        <div style={{ borderTop: "1px solid #F0F0F0" }}></div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default GeneralSettings