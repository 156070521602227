import React, { useEffect, useState } from 'react'
import '../../../websitecontent/homePage/edithome.css';
import Header from '../../../../layout/header/Header'
import '../HomePage'
import { MdDelete, MdImage } from 'react-icons/md'
import { checkAvailability } from '../../../../utils';
import { toast } from 'react-toastify';
import { postCaller } from '../../../../services/api';
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

const INITIAL__LANGUAGE = { en: '', ar: '' };
const IMAGES_INITIAL_VALUE = [INITIAL__LANGUAGE, INITIAL__LANGUAGE, INITIAL__LANGUAGE, INITIAL__LANGUAGE];

const SectionOne = ({ sectionOneData, setSectionOneData, disabled }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = data => console.log(data);

    const { t } = useTranslation()

    const [title, setTitle] = useState(INITIAL__LANGUAGE);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [images, setImages] = useState(IMAGES_INITIAL_VALUE);
    const [subtitle, setSubtitle] = useState(INITIAL__LANGUAGE);


    /* HANDLERS */
    const uploadAndDelete = async (file, old_image_url) => {
        /* Generating FormData for background image*/
        const formData = new FormData();
        formData.append('file', file);
        formData.append('old_file', old_image_url);

        /* Passing form to API */
        const response = await postCaller('homepage/uploads', formData, true);
        if (response.error == false) {
            return response?.data?.Key;
        }
        return false;
    };

    const handleBackgroundImage = async (e) => {
        const current = e.target;
        try {
            if (current.files && current.files.length !== 0) {
                let file = current.files[0];
                // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
                if (!checkAvailability(file.type)) {
                    return alert("Unsupported File")
                }

                const newKey = await uploadAndDelete(file, backgroundImage);
                setSectionOneData(prev => {
                    return {
                        ...prev,
                        backgroundImage: newKey
                    }
                });
                setBackgroundImage(newKey);
            }
        } catch (e) {
            console.log("ERROR");
            return false;
        }
    };

    const handleImages = async (e, index) => {
        let _temp = images;
        const current = e.target;
        if (current.files && current.files.length !== 0) {
            let file = current.files[0];
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
            if (!checkAvailability(file.type)) {
                return alert("Unsupported File")
            }

            const newKey = await uploadAndDelete(file, _temp[index].image);

            _temp[index].image = newKey;
            setSectionOneData(prev => {
                return {
                    ...prev,
                    images: [..._temp]
                }
            })
        }
    };

    /* HANDLERS */
    useEffect(() => {
        setTitle(sectionOneData?.title);
        setSubtitle(sectionOneData?.subtitle);
        setBackgroundImage(sectionOneData?.backgroundImage);
        setImages(sectionOneData?.images || IMAGES_INITIAL_VALUE);
    }, [sectionOneData]);
    return (
        <>

            {/* section one  */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="home-section-one">
                    <p> Section 1 </p>
                    <div className='section-one-input'>
                        <label htmlFor="home"> {t("Title (EN)")}</label> <br />
                        <input
                            disabled={disabled}
                            // {...register("section_title_en", { required: true })}
                            value={title?.en}
                            type="text"
                            className='home-input-field'
                            placeholder='Title in English'
                            onChange={e => {
                                const temp = sectionOneData;
                                temp.title = {
                                    'en': e.target.value,
                                    'ar': temp?.title?.ar
                                };
                                setSectionOneData({ ...temp })

                            }}
                        />
                        {/* {errors.section_title_en &&
                            <div className={'input-invalid-message'}>
                                This field is required
                            </div>
                        } */}

                    </div>

                    <div className='section-one-input'>
                        <label htmlFor="home"> {t("Title (AR)")}</label> <br />
                        <input
                            disabled={disabled}
                            // {...register("section_title_ar", { required: true })}
                            value={title?.ar}
                            type="text"
                            className='home-input-field'
                            placeholder='Title in Arabic'
                            onChange={e => {
                                const temp = sectionOneData;
                                temp.title = {
                                    'ar': e.target.value,
                                    'en': temp?.title?.en
                                };
                                setSectionOneData({ ...temp })
                            }}
                        />
                        {/* {
                            errors.section_title_ar &&
                            <div className={'input-invalid-message'}>
                                This field is required
                            </div>
                        } */}

                    </div>

                    {/* SUBTITLE */}
                    <div className='section-one-input'>
                        <label htmlFor="home"> Subtitle (EN) </label> <br />
                        <input
                            disabled={disabled}
                            value={subtitle?.en}
                            type="text"
                            className='home-input-field'
                            placeholder='Subtitle in English'
                            onChange={e => {
                                const temp = sectionOneData;
                                temp.subtitle = {
                                    'en': e.target.value,
                                    'ar': temp?.subtitle?.ar
                                };
                                setSectionOneData({ ...temp })
                            }}
                        />
                    </div>

                    <div className='section-one-input'>
                        <label htmlFor="home"> Subtitle (AR) </label> <br />
                        <input
                            disabled={disabled}
                            value={subtitle?.ar}
                            type="text"
                            className='home-input-field'
                            placeholder='Subtitle in Arabic'
                            onChange={e => {
                                const temp = sectionOneData;
                                temp.subtitle = {
                                    'ar': e.target.value,
                                    'en': temp?.subtitle?.en
                                };
                                setSectionOneData({ ...temp })
                            }}
                        />
                    </div>
                    <div className='section-bg-input'>
                        <label htmlFor="home"> Background Image </label> <br />
                        <Grid container spacing={2} className="my-5 align-items-center">
                            <Grid item xs={8}>
                                <div>
                                    <input
                                        disabled={disabled}
                                        type="file"
                                        className='home-input-field-file'
                                        placeholder='Upload'
                                        onChange={handleBackgroundImage}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                {
                                    (sectionOneData?.backgroundImage) ?
                                        <>
                                            <MdImage className="delete-icon"
                                                onClick={
                                                    () =>
                                                        window.open(`http://localhost:8081/common/awsObject?key=${sectionOneData?.backgroundImage}`)}
                                            />
                                        </>
                                        : <></>
                                }
                            </Grid>
                        </Grid>


                    </div>

                    {
                        images.map((nodata, index) => {
                            return (
                                <div className="title-input-container">
                                    {/* FOR ENGLISH */}
                                    <div className='home-input-content'>
                                        <label htmlFor="home"> {t("Title (EN)")}</label> <br />
                                        <input type="text"
                                            disabled={disabled}
                                            value={images[index]?.title?.en || ""}
                                            className='home-input-field'
                                            placeholder='Image title (en)'
                                            onChange={e => {
                                                let _images = images;
                                                _images[index].title = {
                                                    'en': e.target.value,
                                                    'ar': _images[index].title?.ar
                                                };
                                                setImages([..._images]);
                                                setSectionOneData({
                                                    ...sectionOneData,
                                                    images: _images
                                                })
                                            }}
                                        />
                                    </div>

                                    {/* FOR ARABIC */}
                                    <div className='home-input-content'>
                                        <label htmlFor="home"> {t("Title (AR)")}</label> <br />
                                        <input type="text"
                                            disabled={disabled}
                                            value={images[index]?.title?.ar || ""}
                                            className='home-input-field'
                                            placeholder='Image title (ar)'
                                            onChange={e => {
                                                let _images = images;
                                                _images[index].title = {
                                                    'ar': e.target.value,
                                                    'en': _images[index].title?.en
                                                };
                                                setImages([..._images]);
                                                setSectionOneData({
                                                    ...sectionOneData,
                                                    images: _images
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='home-input-content home-web-content'>
                                        <label htmlFor="home"> Upload Images </label> <br />
                                        <div className='title-input-container home-title-container'>
                                            <input
                                                disabled={disabled}
                                                type="file"
                                                className='home-input-field-file'
                                                placeholder='Upload'
                                                onChange={(e) => handleImages(e, index)}
                                            />
                                            {
                                                !(images[index]?.image) ?
                                                    <></>
                                                    : <>
                                                        <MdImage className="delete-icon"
                                                            onClick={() => window.open(`http://localhost:8081/common/awsObject?key=${images[index]?.image}`)
                                                            }
                                                        />
                                                        <MdDelete className="delete-icon" />
                                                    </>
                                            }
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>

        </>
    );
};
export default SectionOne
