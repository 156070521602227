import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const arr = [0, 0, 0]

const One = ({ register, errors, disabled }) => {

    const { t } = useTranslation()

    return (
        <>
            {
                arr.map(
                    (noData, index) => {
                        return <>
                            <div className="home-section-one">
                                <p> Address {index + 1} </p>
                                <div className="home-section-one d-flex" style={{ columnGap: '1rem' }}>
                                    {/* <p> Section 1 </p> */}

                                    {/* Title */}
                                    <div className='section-one-input'>
                                        <label htmlFor="home"> {t("Title (EN)")} </label> <br />
                                        <input
                                            disabled={disabled}
                                            {...register(`content[${index}].title.en`, { required: true })}
                                            type="text"
                                            className='home-input-field'
                                            placeholder='Enter Section One TItle'

                                        />
                                        <div className={'input-invalid-message'}>
                                            {errors.title?.en && 'This field is required'}
                                        </div>
                                    </div>
                                    <div className='section-one-input'>
                                        <label htmlFor="home"> {t("Title (AR)")} </label> <br />
                                        <input
                                            disabled={disabled}
                                            {...register(`content[${index}].title.ar`, { required: true })}
                                            type="text"
                                            className='home-input-field'
                                            placeholder='Enter Section One TItle'

                                        />
                                        <div className={'input-invalid-message'}>
                                            {errors.title?.ar && 'This field is required'}
                                        </div>
                                    </div>

                                </div>

                                <div className="home-section-one d-flex" style={{ columnGap: '1rem' }}>
                                    {/* Sub Title */}
                                    <div className='section-one-input width-full'>
                                        <label htmlFor="home"> Address </label> <br />
                                        <textarea
                                            disabled={disabled}
                                            {...register(`content[${index}].address`, { required: true })}
                                            type="text"
                                            className='home-input-field home-input-field-textarea '
                                            placeholder='Enter Your Address'

                                        />

                                        <div className={'input-invalid-message'}>
                                            {errors.address && 'Address is required'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                )
            }

        </>
    )
}

export default One;