import React, { useState, useEffect } from 'react'
import UserTable from './UserTable'
import '../user/usertotal.css'
import { BiPlus } from 'react-icons/bi'
import { FaAngleDown } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { getCaller } from '../../services/api'
import { generateQuery } from 'utils'
import { useTranslation } from 'react-i18next'
const UserTotal = () => {
    const {t} = useTranslation()
    const [editOpen, setEditOpen] = useState(false);
    const [data, setdata] = useState("");
    const navigate = useNavigate();


    const getDashboardUsers = async payload => {
        let URL = 'admin/dashboard/users?';

        URL = generateQuery(URL, payload);
        const res = await getCaller(URL)
        setdata(res?.data || [])
    }

    useEffect(() => {
        getDashboardUsers({});
    }, []);

    if (!data) {
        return <div> Data Not Found </div>
    }


    return (
        <>

            <div className='user-total'>
                <p>{t("USERS OVERVIEW")}</p>
                <div className='border'>
                    <div className="user-total-body">
                        <div className='user-para1'>
                            <p>{t("Total Users")}</p>
                            <h3> {data.allUsers?.count} </h3>
                        </div>
                        <div className='user-para'>
                            <div className='user-para-head'>
                                <p> {t("Doctors")} </p>
                                <h3 > {data.doctorCount} </h3>
                            </div>
                        </div>
                        <div className='user-para'>
                            <div className='user-para-head'>
                                <p> {t("Patients")} </p>
                                <h3> {data.patientCount} </h3>
                            </div>
                        </div>
                        <div className='user-btn-content'>
                            <div className='user-para-btn'>
                                <button
                                    className='theme-button add-users-btn'
                                    onClick={() => setEditOpen(!editOpen)}
                                > <BiPlus />
                                    Add New User
                                    <FaAngleDown />
                                </button>
                            </div>
                            <div className={`btn-drop ${editOpen ? "active-popup" : ""}`}>
                                <p onClick={() => navigate("/addNewPatient")} >New Patient</p>
                                <p onClick={() => navigate("/addNewDoctor")} >New Doctor</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='user-table'>
                    <UserTable
                        data={data?.allUsers}
                        refreshData={getDashboardUsers}
                    />
                </div>
            </div>
        </>
    )
}

export default UserTotal