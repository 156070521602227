import React, { useState, useEffect } from 'react'
import '../appointmenCard/card.css'
import { getCaller } from '../../../services/api'
const AptCard = (props) => {
    //   const [state, setstate] = useState('')
    //   useEffect (()=>{
    // getReview()
    //   },[])
    //  async function getReview(){
    //    const resp= await getCaller('dashboard')
    //    setstate(resp)
    //    console.log(resp,"hello")
    //  }
    /* Appointments State */
    // const [appointment, setAppointment] = useState([])

    // const appointmentsData = async () =>{
    //   const res = await getCaller('admin/dashboard/appointments')
    //   console.log('response', res)
    //   setAppointment(res?.data || [])
    // }
    /* 
    "appointments": {
          "allAppointments": 30,
          "newAppointments": 10,
          "rescheduledAppointments": 10,
          "approvalAppointments": 10
      }, */

    return (
        <>
            {props.type === "APPOINTMENTS"
                ? <Appointments {...props} />
                : <MobileApp {...props} />}
        </>
    )
}

const Appointments = props => {
    return (
        <>
            <div className="container">
                <div className="card-body">
                    <p style={{ color: "#1A1919" }}> {props.all} </p>
                    <h4 style={{ color: "#1A1919" }}>{props?.dashboardData?.appointments?.allAppointments || '0'}</h4>
                </div>
                <div className="card-body">
                    <p style={{ color: "#13BC34" }}> {props.new} </p>
                    <h4 style={{ color: "#13BC34" }}>{props?.dashboardData?.appointments?.newAppointments || '0'}</h4>
                </div>
                <div className="card-body">
                    <p style={{ color: "#E80707" }}> {props.res} </p>
                    <h4 style={{ color: "#E80707" }}> {props?.dashboardData?.appointments?.rescheduledAppointments || '0'} </h4>
                </div>
                <div className="card-body">
                    <p style={{ color: "#EFD511" }}> {props.doc} </p>
                    <h4 style={{ color: "#EFD511" }}> {props?.dashboardData?.appointments?.doctorsForApprovel || '0'} </h4>
                </div>
            </div>
        </>
    )
}

const MobileApp = props => {
    return (
        <>
            <div className="container">
                <div className="card-body">
                    <p style={{ color: "#1A1919" }}> {props.all} </p>
                    <h4 style={{ color: "#1A1919" }}>{props?.dashboardData?.mobileApp?.androidUsers}</h4>
                </div>
                <div className="card-body">
                    <p style={{ color: "#13BC34" }}> {props.new} </p>
                    <h4 style={{ color: "#13BC34" }}>{props?.dashboardData?.mobileApp?.iosUsers}</h4>
                </div>
                <div className="card-body">
                    <p style={{ color: "#E80707" }}> {props.res} </p>
                    <h4 style={{ color: "#E80707" }}> {props?.dashboardData?.mobileApp?.activeUsers} </h4>
                </div>
                <div className="card-body">
                    <p style={{ color: "#EFD511" }}> {props.doc} </p>
                    <h4 style={{ color: "#EFD511" }}> {props?.dashboardData?.mobileApp?.inactiveUsers} </h4>
                </div>
            </div>
        </>
    )
}
export default AptCard