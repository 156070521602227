import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCaller } from 'services/api';
import { dateFormatWW } from 'utils';
import { constants } from 'utils/constants';
import Pagination from '../../../components/pagination/Pagination';
import './review.css'
const ReviewShowScreen = () => {
    const { t } = useTranslation()
    const [reviews, setReviews] = useState([])
    const { doctorId } = useParams();
    const [pagina, setPagina] = useState(1);
    const [porPagina] = useState(5);
    const maximo = reviews?.count / porPagina;

    const appointmentsData = async payload => {
        const {
            page,
            fixed_rating
        } = payload;

        let URL = `admin/users/doctor/reviews/${doctorId}?`;

        if (page) {
            URL += '&page=' + page;
        }

        if (fixed_rating !== undefined) {
            URL += '&fixed_rating=' + fixed_rating;
        }

        const res = await getCaller(URL)
        setReviews(res?.data || [])
    }

    const nextData = async page => {
        const payload = {
            page
        }
        appointmentsData(payload)
    }

    const handleStars = event => {
        const { value } = event.target;
        const payload = {
            page: 1,
            fixed_rating: value == 0 ? undefined : value
        }
        appointmentsData(payload)
        console.log("🚀 ~ file: ReviewShowScreen.jsx:41 ~ handleStars ~ value", value)
    }

    useEffect(() => {
        nextData(pagina)
    }, [pagina])

    useEffect(() => {
        appointmentsData({})
    }, [])
    return (
        <>
            <div className="review-container">
                <div className="review-head">
                    <h2> Reviews <span> {reviews?.count} Items </span> </h2>
                </div>
                <div className="review">
                    <div className="review-btn-container">
                        <span>Rating</span>
                        <select onChange={handleStars} >
                            <option value="0"> All </option>
                            <option value="1"> 1 Star </option>
                            <option value="2"> 2 Stars </option>
                            <option value="3"> 3 Stars </option>
                            <option value="4"> 4 Stars </option>
                            <option value="5"> 5 Stars </option>
                        </select>
                    </div>
                    <div className='review-date'>
                        <p style={{ marginRight: "1rem" }}>FROM </p> <input type="date" name="" id="" /> <p style={{ marginLeft: "1rem", marginRight: "1rem" }}> - TO </p> <input type="date" />
                    </div>
                </div>
                <div className="all-reviews">
                    {
                        Array.isArray(reviews?.rows)
                            && reviews?.rows?.length > 0
                            ? reviews?.rows?.map(review => {
                                return (
                                    <div className="reviewcard">
                                        <div className="reviewcard-content">
                                            <div className="review-rating">
                                                <div className="review-list">
                                                    <p>{t('rating')} </p>
                                                    <h4>{review?.rating || constants.three_dots} {review?.rating && Number(review?.rating) > 1 ? 'Stars' : 'Star'}</h4>
                                                </div>
                                                <div className="review-list">
                                                    <p> {t('reviewBy')} </p>
                                                    <h4>{review?.user_profile?.name || constants.three_dots}</h4>
                                                </div>
                                                <div className="review-list">
                                                    <p> {t('dateGiven')} </p>
                                                    <h4>{dateFormatWW(review?.createdAt) || constants.three_dots}</h4>
                                                </div>
                                            </div>
                                            <div className='review-text'> <h5> Review Text </h5>
                                                <p>{review?.message || constants.three_dots}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : <>
                                <div className='no-reviews-content'>
                                    <div>
                                        No reviews Found
                                    </div>
                                </div>
                            </>
                    }
                </div>

                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    )
}

export default ReviewShowScreen