import React, { useState } from 'react'
import '../DoctorProfile/ProfileProgress.css'
import Header from '../../layout/header/Header'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import CircularStatic from 'components/common/appointmenCard/progress/CircularProgressWithLabel'
const UserProfileTab = () => {
    const { state } = useLocation();
    const [profilePer, setProfilePec] = useState(0);

    const getPercentageColor = percentage => {
        const per = Number(percentage);

        if (per >= 0 && per <= 10) {
            return "#ff0300"
        } else if (per >= 11 && per <= 30) {
            return "#ceeb18"
        } else if (per >= 31 && per <= 60) {
            return '#ceeb18'
        } else if (per >= 61 && per <= 100) {
            return "#02b150"
        }

    }

    const setActiveInactiveClass = ({ isActive }) => { return isActive ? 'doctor-list-tab doctor-list-active-tab' : 'doctor-list-tab' }

    return (
        <>
            <Header />
            Back
            <div className="d-container">
                <div className="doctor-approvel-container">
                    <div className="approvel-body">
                        <CircularStatic
                            size={100}
                            style={{
                                color: getPercentageColor(profilePer)
                            }}
                            value={parseInt(profilePer)}
                        />
                        <div className='approvel-para'>
                            <h4> {parseInt(profilePer)}% Profile complete </h4>
                            <p>Due to incomplete profile the patient will not be able to perform any actions on the website.</p>
                        </div>
                    </div>
                </div>
                <div className="doctor-tab-container">
                    <ul>
                        <NavLink
                            className={setActiveInactiveClass}
                            to={''}
                        >
                            <li>
                                Profile
                            </li>
                        </NavLink>
                        <NavLink
                            className={setActiveInactiveClass}
                            to={'appointments'}
                        >
                            <li>
                                Appointment
                            </li>
                        </NavLink>
                        <NavLink
                            className={setActiveInactiveClass}
                            to={'medical'}
                        >
                            <li>
                                Medical Records
                            </li>
                        </NavLink>
                        <NavLink
                            className={setActiveInactiveClass}
                            to={'wallet'}
                        >
                            <li>
                                Wallet
                            </li>
                        </NavLink>

                    </ul>

                    <div>
                        <Outlet
                            context={{ setProfilePec }}
                        />
                    </div>
                    {/* <div className="" hidden={tabIndex !== 0}>
                        <UserAccount />
                    </div>
                    <div className="" hidden={tabIndex !== 1}>
                        <UserAppointment
                            upcomingAppointments={upcomingAppointments}
                            inReviewAppointments={inReviewAppointments}
                            pastAppointments={pastAppointments}
                            setPastAppointment={setPastAppointment}
                            setUpcomingAppointments={setUpcomingAppointments}
                            setinReviewAppointments={setinReviewAppointments}
                        />
                    </div>
                    <div className="" hidden={tabIndex !== 2}>
                        <UserMedical />
                    </div>
                    <div className="" hidden={tabIndex !== 3}>
                        <UserWallet />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default UserProfileTab