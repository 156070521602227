import React, { useState, useEffect } from 'react'
import PaymentTable from './PaymentTable'
import '../payment/payment.css'
import { getCaller } from '../../services/api'
import { generateQuery } from 'utils'
import { useTranslation } from 'react-i18next'

const PaymentOverView = () => {
    const [data, setData] = useState()

    const {t} = useTranslation()


    const getPaymentData = async payload => {
        let URL = 'admin/dashboard/payments?';
        URL = generateQuery(URL, payload)
        const response = await getCaller(URL)
        setData(response?.data || {})
    }

    useEffect(() => {
        getPaymentData({})
    }, [])
    return (
        <>
            <div className='payment-total'>
                <p>{t("PAYMENTS OVERVIEW")}</p>
                <div className='border'>
                    <div className="payment-total-body">
                        <div className='payment-para1'>
                            <p> {t("Total Revenue(in SAR)")} </p>
                            <h3> {(Number(data?.userRevenue) || 0) + (Number(data?.doctorRevenue) || 0)} </h3>
                        </div>
                        <div className='payment-para'>
                            <div className='payment-para-head'>
                                <p> {t("Revenue from Doctors(in SAR)")} </p>
                                <h3 > {Number(data?.doctorRevenue) || 0} </h3>
                            </div>
                        </div>
                        <div className='payment-para'>
                            <div className='payment-para-head'>
                                <p> {t("Revenue from Patients(in SAR)")} </p>
                                <h3> {Number(data?.userRevenue) || 0} </h3>
                            </div>
                        </div>


                    </div>
                </div>
                <div className='payment-table'>
                    <PaymentTable
                        data={data?.allTransactions}
                        setData={setData}
                        refreshData={getPaymentData}
                    />
                </div>
            </div>
        </>
    )
}

export default PaymentOverView