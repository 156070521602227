import UserProfileTab from "profile/UserProfile/UserProfileTab";

const PatientView = () => {
    return (
        <>
            <UserProfileTab />
        </>
    )
}

export default PatientView;