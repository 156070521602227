import useGlobal from 'hooks/useGlobal';
import React from 'react';
import '../view.css';
import { constants } from 'utils/constants';



const PatientDetails = (props) => {


    const {tKey: [tKey]} = useGlobal();

    return (
        <>
            <div className="report-view-child">
                <div className="report-view-section-parent">
                    <div className="section-parent-header">
                        <h5 className="appointment-heading">Patient Details</h5>
                    </div>
                    <div className="section-childrens">
                        <div className="section-parent-app-dtails">
                            Patient ID
                            <p className="section-app">{props.View.data?.user_profile?.loginId}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                        Patient Name
                            <p className="section-app">{props.View.data?.patientName}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                        Gender
                            <p className="section-app">{props.View.data?.gender}</p>
                        </div>
                        <div className="section-parent-app-dtails">
                        Age
                            <p className="section-app">{props.View.data?.age}</p>
                        </div>
                        
                        <div className="section-parent-app-dtails">
                        Specialisation
                            <p className="section-app">{props.View.data?.service?.service_master?.name?.[tKey]  }</p>
                        </div>
                        <div className="section-parent-app-dtails">
                        About
                            <p className="section-app">{props.View.data?.description}</p>
                        </div>
                    </div>
                    <div className="section-parent-childs">

                    </div>
                </div>
            </div>
        </>
    )
}
export default PatientDetails