import React, { useEffect, useState } from 'react'
import '../../../websitecontent/homePage/edithome.css';
import Header from '../../../../layout/header/Header'
import '../HomePage'
import { MdDelete, MdImage } from 'react-icons/md';
import { checkAvailability } from '../../../../utils';
import { postCaller } from '../../../../services/api';
import { useTranslation } from 'react-i18next';



const INITIAL__LANGUAGE = { en: '', ar: '' };
const IMAGES_INITIAL_VALUE = [INITIAL__LANGUAGE, INITIAL__LANGUAGE, INITIAL__LANGUAGE];

const SectionFour = ({
    sectionFourData,
    setSectionFourData,
    disabled
}) => {
    const [title, setTitle] = useState(INITIAL__LANGUAGE);
    const [subtitle, setSubtitle] = useState(INITIAL__LANGUAGE);
    const [images, setImages] = useState(IMAGES_INITIAL_VALUE);

    const { t } = useTranslation()

    const uploadAndDelete = async (file, old_image_url) => {
        /* Generating FormData for background image*/
        const formData = new FormData();
        formData.append('file', file);
        formData.append('old_file', old_image_url);

        /* Passing form to API */
        const response = await postCaller('homepage/uploads', formData, true);
        if (response.error == false) {
            return response?.data?.Key;
        }
        return false;
    }

    const handleImages = async (e, index) => {
        let _temp = images;
        const current = e.target;
        if (current.files && current.files.length !== 0) {
            let file = current.files[0];
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
            if (!checkAvailability(file.type)) { return alert("Unsupported File") }

            const newKey = await uploadAndDelete(file, _temp[index].image);

            _temp[index].image = newKey;
            setSectionFourData(prev => {
                return {
                    ...prev,
                    images: [..._temp]
                }
            })
        }
    }

    const handleParentStates = e => {
        setSectionFourData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        setTitle(sectionFourData?.title)
        setSubtitle(sectionFourData?.subtitle)
        setImages(sectionFourData?.images || IMAGES_INITIAL_VALUE)
    }, [sectionFourData])


    return (
        <>
            <Header />
            {/* section 4 */}
            <div className="home-section-one">
                <p> Section 4 </p>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (EN)")} </label> <br />
                    <input
                        type="text"
                        disabled={disabled}

                        value={title?.en}
                        className='home-input-field'
                        placeholder='Title (en)'
                        name="title" /* Important as it is handling state */
                        onChange={e => {
                            const temp = sectionFourData;
                            temp.title = {
                                'en': e.target.value,
                                'ar': temp?.title?.ar
                            };
                            setSectionFourData({ ...temp })
                            // setTitle()
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (AR)")} </label> <br />
                    <input
                        type="text"
                        disabled={disabled}
                        value={title?.ar}
                        className='home-input-field'
                        placeholder='Title (ar)'
                        name="title" /* Important as it is handling state */
                        onChange={e => {
                            const temp = sectionFourData;
                            temp.title = {
                                'ar': e.target.value,
                                'en': temp?.title?.en
                            };
                            setSectionFourData({ ...temp })
                            // setTitle()
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (EN) </label> <br />
                    <input
                        disabled={disabled}
                        value={subtitle?.en}
                        type="text"
                        className='home-input-field'
                        placeholder='Sub-Title (en)'
                        name="subtitle" /* Important as it is handling state */
                        onChange={e => {
                            const temp = sectionFourData;
                            temp.subtitle = {
                                'en': e.target.value,
                                'ar': temp?.subtitle?.ar
                            };
                            setSectionFourData({ ...temp })
                            // setTitle()
                        }}
                    />
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (AR) </label> <br />
                    <input
                        disabled={disabled}
                        value={subtitle?.ar}
                        type="text"
                        className='home-input-field'
                        placeholder='Sub-Title (ar)'
                        name="subtitle" /* Important as it is handling state */
                        onChange={e => {
                            const temp = sectionFourData;
                            temp.subtitle = {
                                'ar': e.target.value,
                                'en': temp?.subtitle?.en
                            };
                            setSectionFourData({ ...temp })
                            // setTitle()
                        }}
                    />
                </div>
                {
                    images.map((nodata, index) => {
                        return (
                            <div className="title-input-container">
                                <div className='home-input-content'>
                                    <label htmlFor="home"> {t("Title (EN)")} </label> <br />
                                    <input type="text"
                                        disabled={disabled}
                                        value={images[index]?.title?.en || ""}
                                        className='home-input-field'
                                        placeholder='Title (en)'
                                        onChange={e => {
                                            let _images = images;
                                            _images[index].title = {
                                                'en': e.target.value,
                                                'ar': _images[index].title?.ar
                                            };
                                            setImages([..._images])
                                            setSectionFourData({
                                                ...sectionFourData,
                                                images: _images
                                            })
                                        }}
                                    />
                                </div>

                                <div className='home-input-content'>
                                    <label htmlFor="home"> {t("Title (AR)")} </label> <br />
                                    <input type="text"
                                        disabled={disabled}
                                        value={images[index]?.title?.ar || ""}
                                        className='home-input-field'
                                        placeholder='Title (ar)'
                                        onChange={e => {
                                            let _images = images;
                                            _images[index].title = {
                                                'ar': e.target.value,
                                                'en': _images[index].title?.en
                                            };
                                            setImages([..._images])
                                            setSectionFourData({
                                                ...sectionFourData,
                                                images: _images
                                            })
                                        }}
                                    />
                                </div>


                                <div className='home-input-content home-web-content'>
                                    <label htmlFor="home"> Upload Images </label> <br />
                                    <div className='title-input-container home-title-container'>
                                        <input
                                            disabled={disabled}
                                            type="file"
                                            className='home-input-field-file'
                                            placeholder='Upload'
                                            onChange={e => handleImages(e, index)}
                                        />
                                        {

                                            !(images[index]?.image) ?
                                                <></>
                                                : <>
                                                    <MdImage className="delete-icon"
                                                        onClick={() => window.open(`http://localhost:8081/common/awsObject?key=${images[index]?.image}`)
                                                        }
                                                    />
                                                </>
                                        }
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )

}
export default SectionFour 