import React, { useEffect, useState } from 'react'
import Header from 'layout/header/Header'
import './view.css'
import AppointmentDetails from './Sections/AppointmentDetails'
import DoctorDetails from './Sections/DoctorDetails'
import PatientDetails from './Sections/PatientDetails'
import { getCaller } from 'services/api'
import { useParams } from 'react-router-dom'
import Attachment from './Sections/Attachment'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';



const UpcomingReportView = () => {
    const { id } = useParams();
    

    /* Appointment Details State */
    const [appointmentView, setView] = useState({})
    const [appointmentFiles, setAppointmentFiles] = useState([])
    console.log(appointmentView);



    const viewAppointmentDetailsById = async () => {
        const response = await getCaller(`getOneAppointment/${id}`)
        console.log('response' ,response)
        setView(response)
    }

    const fetchAppointmentFiles = async () => {
        const response = await getCaller(`appointments-files/${id}`);
        setAppointmentFiles(response?.data?.rows || [])
    }

    const handleBack = () => {
        window.history.back()
    }
    useEffect(() => {
        fetchAppointmentFiles()
        viewAppointmentDetailsById()
    }, [])
    return (
        <>
            <Header />
            <div className='report-view-parent-back-button ' style={{color:'#3A9CD8'}} onClick={handleBack}>
                <ArrowBackIosIcon sx={{ color:'#3A9CD8' ,fontSize: 15 }}/>
                back</div>
            <div className="report-view-parent">
                <AppointmentDetails
                    View={appointmentView}
                />
                <DoctorDetails
                    View={appointmentView}
                />
                <div style={{ display: "flex", columnGap: "1rem" }}>
                    <div style={{ width: "50%" }}>
                        <PatientDetails
                            View={appointmentView}
                        />
                    </div>
                    <div style={{ width: "50%" }}>
                        <Attachment
                            files={appointmentFiles}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpcomingReportView