import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


import sortarrow from "../../assets/images/sort-arrow.svg";
import eye from '../../assets/images/eye.png'
import check from '../../assets/images/check-circle.png'
import cross from '../../assets/images/x-circle-1.png'
import { useNavigate } from "react-router-dom";
import { updateCaller } from "../../services/api";
import rotate from '../../assets/images/rotate-cw.png'
import { useTranslation } from "react-i18next";
import Pagination from "../../components/pagination/Pagination";
import { appointmentStatusColor, dateFormatWW, formatTimeSlot } from "utils";


import { appointmentStatus } from "utils/constants";
import { Tooltip } from "@mui/material";
import AppointmentFilterModal from "./AppointmentFilterModal";
import useGlobal from "hooks/useGlobal";
import useMasters from "hooks/useMasters";


let orderStatus = false

const AptTable = ({
    data,
    setData,
    tableSize = "xl",
    refreshData
}) => {
    const sizes = {
        "sm": 200,
        "xl": 500,
        "xxl": 600
    }

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { tKey: [tKey] } = useGlobal();
    const { appointment } = useMasters();


    const [porPagina] = useState(5);
    const [query, setQuery] = useState({});
    const searchTextFieldRef = useRef(null);
    const [pagina, setPagina] = useState(1);
    const [modal, setModal] = useState(false);


    const maximo = data?.count / porPagina;


    const onSubmit = async (id, event) => {
        const resp = await updateCaller(`updateappointment/${id}?data=upcoming`);
        if (resp.data[0] > 0) {
            toast.success("Appointment is accepted. Appointment status is Upcoming")
            refreshData({ page: pagina })
        } else {
            toast.warning("Appointment is already accepted");
        }

    };

    const onSubmit1 = async (id) => {
        if (
            window.confirm('Do you want to cancel this appointment ?')
        ) {

            const resp = await updateCaller(`updateappointment/${id}?data=Cancelled`);
            if (resp.data[0] > 0) {
                toast.info("Appointment is cancelled");
                refreshData({ page: pagina })
            } else {
                toast.warning("Appointment is already cancelled");
            }
        }
    };

    const onSubmit2 = async (id) => {
        const resp = await updateCaller(`updateappointment/${id}?data=rescheduled`);
        if (resp.data[0] > 0) {
            toast.success("Appointment Rescheduled!");
            refreshData({ page: pagina })
        } else {
            toast.warning("Appointment was already rescheduled.");
        }
    };

    const formateTimeSlotAndDate = e => {
        if (Array.isArray(e.slot)) {
            const [_slot] = e.slot;
            const { start, end } = _slot.time;
            return `${dateFormatWW(e?.slotDate)}, ${formatTimeSlot(start)} - ${formatTimeSlot(end)}`
        } else {
            return "n/a"
        }
    }

    const nextData = async page => {
        const payload = {
            ...query,
            page
        }
        refreshData(payload)
    }

    const viewAppointment = async payload => {
        const {
            appointmentId,
            status
        } = payload.data;
        return navigate(`/upcomingReportView/${appointmentId}`)
    }


    const OptionsToShow = props => {
        const {
            status,
            appointmentId
        } = props.data;
        switch (status) {
            case appointmentStatus.Completed:
                return (
                    <>
                        <Tooltip title='View'>
                            <div className="tooltip">
                                <img src={eye} alt=""
                                    onClick={() => viewAppointment(props)}
                                />
                            </div>
                        </Tooltip>
                    </>
                )

            case appointmentStatus.Upcoming:
                return <>
                    <Tooltip title='View'>
                        <div className="tooltip">
                            <img src={eye} alt=""
                                onClick={() => viewAppointment(props)}
                            />
                        </div>
                    </Tooltip>

                    <Tooltip title="Cancel">
                        <div className="tooltip">
                            <img src={cross} onClick={() => onSubmit1(appointmentId)} alt="Reject"
                            // style={{ display: e?.status === 'payment_pending' ? 'none' : e?.status === 'Rescheduled' ? 'none' : '' }}
                            />
                            {/* <p className="tooltiptext"> Reject </p> */}
                        </div>
                    </Tooltip>

                    {/* <Tooltip title="Re-Scheduled">
                        <div className="tooltip">
                            <img src={rotate} onClick={() => onSubmit2(appointmentId)} alt="Rotate"
                            style={{ display: e.status === 'payment_pending' ? 'none' : e.status === 'Rescheduled' ? 'none' : '' }}
                            />
                            <p className="tooltiptext"> Reschedule </p>
                        </div>
                    </Tooltip> */}
                </>

            case appointmentStatus.payment_pending:
                return <>
                    <Tooltip title='View'>
                        <div className="tooltip">
                            <img src={eye} alt=""
                                onClick={() => viewAppointment(props)}
                            />
                        </div>
                    </Tooltip>

                </>

            case appointmentStatus.Cancelled:
                return <>
                    <Tooltip title='View'>
                        <div className="tooltip">
                            <img src={eye} alt=""
                                onClick={() => viewAppointment(props)}
                            />
                        </div>
                    </Tooltip>

                </>

            default:
                return <>
                    <Tooltip title='View'>
                        <div className="tooltip">
                            <img src={eye} alt=""
                                onClick={() => viewAppointment(props)}
                            />
                        </div>
                    </Tooltip>

                    <Tooltip title="Accept">
                        <div className="tooltip">
                            {/* <img src={check} onClick={() => onSubmit(appointmentId)} alt="Accept"
                            // style={{ display: e?.status === 'payment_pending' ? 'none' : e.status === 'Rescheduled' ? 'none' : '' }}
                            /> */}
                            {/* <p className="tooltiptext"> Accept </p> */}
                        </div>
                    </Tooltip>

                    <Tooltip title="Cancel">
                        <div className="tooltip">
                            <img src={cross} onClick={() => onSubmit1(appointmentId)} alt="Reject"
                            // style={{ display: e?.status === 'payment_pending' ? 'none' : e?.status === 'Rescheduled' ? 'none' : '' }}
                            />
                            {/* <p className="tooltiptext"> Reject </p> */}
                        </div>
                    </Tooltip>

                    {/* <Tooltip title="Re-Scheduled">
                        <div className="tooltip">
                            <img src={rotate} onClick={() => onSubmit2(appointmentId)} alt="Rotate"
                                style={{ display: e.status === 'payment_pending' ? 'none' : e.status === 'Rescheduled' ? 'none' : '' }}
                            />
                            <p className="tooltiptext"> Reschedule </p>
                        </div>
                    </Tooltip> */}
                </>
        }
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        setQuery(prev => ({ ...prev, searchText: value }))
        refreshData({ page: 1, searchText: value })
    }

    const filterSortOrder = () => {
        orderStatus = !orderStatus;
        refreshData({ page: pagina, orderStatus: orderStatus ? "ASC" : "DESC" })
    }

    const handleModalOpen = () => {
        setModal(true)
    }

    const handleModalClose = () => {
        setModal(false)
    }

    useEffect(() => {
        nextData(pagina)
    }, [pagina])
    return (
        <>
            <AppointmentFilterModal
                open={modal}
                handleClose={handleModalClose}
                refreshData={refreshData}
                setQuery={setQuery}
            />
            <div>
                <div className="user-past">
                    <h4> {t("Past Appointments")} </h4>
                </div>
                <div className="filter-field">
                    <p> {data?.count} {t("Items")} </p>
                    <div className="filter-search">
                        <div className="filter-search-field">
                            <input
                                type="text"
                                id="search-box"
                                placeholder={t("Search")}
                                ref={searchTextFieldRef}
                                onKeyUp={event => event.key === 'Enter' && handleTextFieldButton()}
                            />
                            <label
                                htmlFor="search-box"
                                className="fas fa-search"
                                onClick={handleTextFieldButton}
                            ></label>
                        </div>
                        <div className="filter-button">
                            <button onClick={handleModalOpen}> {t("Filter")} </button>
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {/* <StyledTableCell> Appointment ID </StyledTableCell> */}
                                <StyledTableCell> {t("Appointment Date & Time")} </StyledTableCell>
                                <StyledTableCell> {t("Specialisation Chosen")} </StyledTableCell>
                                <StyledTableCell> {t("Patient Name")} </StyledTableCell>
                                <StyledTableCell> {t("Gender, Age")} </StyledTableCell>
                                <StyledTableCell>
                                    {t("Status")} <img onClick={filterSortOrder} src={sortarrow} alt="" />
                                </StyledTableCell>
                                <StyledTableCell> {t("Action")} </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data.rows && data.rows.map((e, i) => {
                                    return (
                                        <StyledTableRow key={`APT_Table_${i}`} >
                                            <StyledTableCell> {formateTimeSlotAndDate(e)} </StyledTableCell>
                                            <StyledTableCell> {e?.service?.name?.[tKey] ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell> {e?.patientName ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell> {e?.gender ?? "n/a"}, {e?.age ?? "n/a"} </StyledTableCell>
                                            <StyledTableCell
                                                style={{
                                                    color: appointmentStatusColor(e?.status)
                                                }}
                                            >
                                                <b className='text-capitalize'>{appointment[e?.status]?.UI || e?.status}</b>
                                            </StyledTableCell>
                                            <StyledTableCell className='table-edit-one' >
                                                <OptionsToShow data={e} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: "top",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        height: '50px',
        verticalAlign: "top",
        userSelect: "none"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default AptTable;
