import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import sortarrow from '../../../assets/images/sort-arrow.svg'
import '../../../components/common/filterfield/filter.css'
import edit from '../../../assets/images/edit-2.svg'
import '../couponCodes/coupon.css'
import Pagination from '../../../components/pagination/Pagination'
import { BiPlus } from 'react-icons/bi'
import { useNavigate, Link } from 'react-router-dom';
import CreateCouponModal from '../../../components/modal/CreateCouponModal';
import { getCaller } from '../../../services/api';
import moment from 'moment';
import { generateQuery } from 'utils';

const CouponCode = () => {
    const navigate = useNavigate();

    const [data, setData] = useState()
    const [pagina, setPagina] = useState(1);
    const searchTextFieldRef = useRef(null);
    const maximo = data?.count / 5;

    const nextData = async page => {
        const payload = {
            page
        }
        fetchCouponListing(payload)
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;

        fetchCouponListing({ page: 1, searchText: value })

    }



    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#D5F1FF",
            color: "black",
            border: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: 0,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const fetchCouponListing = async (payload = {}) => {
        let URL = `showAllCoupon?`;
        URL = generateQuery(URL, payload)
        const res = await getCaller(URL)
        setData(res?.data || {})
    }

    useEffect(() => {
        fetchCouponListing()
    }, [])

    useEffect(() => {
        nextData(pagina)
    }, [pagina])
    return (
        <>
            <div>
                <div className="filter-field">

                    <p> {data?.count ?? "0"} Coupon Code</p>
                    <div className="filter-search">
                        <div className="filter-search-field">
                            <input
                                type="text"
                                id="search-box"
                                placeholder="Search"
                                ref={searchTextFieldRef}
                                onKeyUp = {event => event.key === "Enter" && handleTextFieldButton()}

                            />
                            <label
                                htmlFor="search-box"
                                className="fas fa-search"
                                onClick={handleTextFieldButton}
                            ></label>
                        </div>
                        <div className="filter-button">
                            <button className='filter-button' onClick={() => navigate('/createcoupon')}>  Add Coupon </button>
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell> Coupon Title </StyledTableCell>
                                <StyledTableCell> Coupon Code </StyledTableCell>
                                <StyledTableCell> Validity From - To </StyledTableCell>
                                <StyledTableCell> Created on </StyledTableCell>
                                <StyledTableCell> Usage Per User </StyledTableCell>
                                <StyledTableCell> Status <img src={sortarrow} alt="" /> </StyledTableCell>
                                <StyledTableCell> Action </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {
                            data?.rows?.length > 0
                                ? data?.rows?.map(
                                    e => {
                                        return (
                                            <TableBody>

                                                <StyledTableRow >
                                                    <StyledTableCell component="th" scope="row">
                                                        {e?.couponTitle ?? "n/a"}
                                                    </StyledTableCell>
                                                    <StyledTableCell> {e?.code ?? "n/a"} </StyledTableCell>
                                                    <StyledTableCell> {moment(e?.valid_from).format("MM-DD-YYYY")} - {moment(e?.valid_to).format("MM-DD-YYYY")}</StyledTableCell>
                                                    <StyledTableCell>{moment(e?.createdAt).format("MM-DD-YYYY") ?? "N/A"}</StyledTableCell>
                                                    <StyledTableCell> {e?.perUser ?? "a/n"} </StyledTableCell>
                                                    <StyledTableCell style={{ color: "#13BC34", fontWeight: "bold" }} > {e?.status ?? "a/n"} </StyledTableCell>
                                                    <StyledTableCell className='table-edit-one' >
                                                        <CreateCouponModal
                                                            currentCoupon={e}
                                                        refreshData={fetchCouponListing}
                                                        />
                                                        <Link to={`/editCoupon/${e?.couponId}`} style={{ marginRight: "2rem" }}>
                                                            <img src={edit} alt="Accept" />
                                                        </Link>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        )
                                    }
                                )
                                : (<></>)
                        }

                    </Table>
                </TableContainer>
                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    )
}

export default CouponCode