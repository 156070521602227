import React, {useEffect, useRef, useState} from 'react';

import login_logo from '../assets/images/login-logo.png';
import chevron_down_white from '../assets/images/chevron-down-white.png';
import {Link, useNavigate} from 'react-router-dom';
import "../loginRegister/login.css";
import {getCaller, loginUser} from '../services/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {checkIfLogin, handleResponse} from 'utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useMasters from 'hooks/useMasters';
import {ThemeButton} from 'components/common/button/Button';


const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required('Email is required'),
    password: Yup.string().min(6, 'Password is too small').max(20, 'Password is too large').required('Password is required')
});

const Login = () => {
    const navigate = useNavigate();
    const {_initialize} = useMasters()
    const [eyeVisibility, setEyeVisibility] = useState(false);
    const passwordRef = useRef(null);

    const checkValidityAndRedirect = async () => {
        const _ifLogin = await checkIfLogin();
        if (_ifLogin) {
            _initialize()
            navigate('/dashboard', {replace: true})
        }
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            let req = await loginUser(values);
            const response = handleResponse(req, true, true);
            if (response) {
                localStorage.setItem('token', response)
                _initialize()
                navigate('/dashboard', {replace: true})
            }
        }
    })

    const toggleEyeIcon = () => {
        setEyeVisibility(!eyeVisibility);
        passwordRef.current.type = eyeVisibility ? 'password' : 'text';
    }

    useEffect(() => {
        checkValidityAndRedirect();
    }, [])
    return (
        <>
            <div className='login'>
                <div className='login-box'>
                    <div className="login-container">
                        <div className='login-img'>
                            <img src={login_logo} alt="Logo"/>
                        </div>
                        <div className="login-form">
                            <h2 className='login-form-heading'> Admin Portal Login </h2>
                            <form action="" className='form-c' onSubmit={formik.handleSubmit}>
                                <div className='login-input-field'>
                                    <input type="email" name="email" placeholder='Email' value={formik.values.email}
                                           onChange={formik.handleChange}
                                    /> <br/>

                                    {/* <div className='error'>{formik.errors.mobile ? formik.errors.mobile : null}</div> */}
                                    <div className='error'>{formik.errors.email ? formik.errors.email : null}</div>

                                    <div className="password-input-parent">
                                        <input
                                            type="password"
                                            name="password"
                                            id=""
                                            className=''
                                            placeholder='Password'
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            ref={passwordRef}
                                        />
                                        <div className='password-input-child' onClick={toggleEyeIcon}>
                                            {eyeVisibility ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </div>
                                    </div>
                                    <div
                                        className='error'
                                    >
                                        {formik.errors.password ? formik.errors.password : null}
                                    </div>
                                </div>
                                <div className='login-btn-comp' style={{display: "flex"}}>
                                    <ThemeButton
                                        className='login-btn'
                                        type='submit'
                                        text='LOGIN'
                                    />
                                </div>
                            </form>

                            <div className='forgot-pass'>
                                <Link className='forgot-pass-link' to="/login"><p> Forgot Password? </p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login
