import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import sortarrow from '../../assets/images/sort-arrow.svg'
import eye from '../../assets/images/eye.png'
import edit from '../../assets/images/edit-2.svg'
import FilterInput from '../../components/common/filterfield/FilterInput'
import Pagination from '../../components/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { getCaller } from '../../services/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { dateFormatWW } from 'utils';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { fix_pages, URLS } from 'utils/constants';
import useGlobal from 'hooks/useGlobal';

// import ViewWebsiteContent from './TermsConditions/ViewWebsiteContent';
const WebsiteContent = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const {tKey: [tKey]} = useGlobal();

    const [list, setList] = useState([]);


    const getDataTermsCondition = async () => {
        const response = await getCaller('admin/getWebsiteContent?page_type=website-content-admin');
        setList(response?.data?.rows || [])
    }

    const handleRedirectPage = (item, disabled = false) => {
        switch (item.page_type) {
            case 'HOMEPAGE':
                navigate(`/edithomepage/${item.id}`, { state: { disabled } })
                return;

            case 'ABOUT':
                navigate(`/about/${item.id}`, { state: { disabled } })
                return;

            case 'PRIVACY':
                navigate(`/editPrivacyPolicy/${item.id}`, { state: { disabled } })
                return;

            case 'TERMS':
                navigate(`/editTermsCondition/${item.id}`, { state: { disabled } })
                return
            case fix_pages.contact:
                navigate(`/edit/contact-us/${item.id}`, { state: { disabled } })
            default:
                return;
        }
    }


    /* EFFECTS */
    useEffect(() => {
        getDataTermsCondition();
    }, [])

    return (
        <>
            <div>
                <FilterInput heading={t("All Pages")} filter={false} search={false} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("Page Name")} <img src={sortarrow} alt="" /> </StyledTableCell>
                                <StyledTableCell> {t("Page URL")} </StyledTableCell>
                                {/* <StyledTableCell > Page Title </StyledTableCell> */}
                                {/* <StyledTableCell > Content </StyledTableCell> */}
                                <StyledTableCell>{t("Date Created")}   </StyledTableCell>
                                <StyledTableCell> {t("Last Updated")}  </StyledTableCell>
                                <StyledTableCell> {t("Action")} </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                list && list.map(item => {
                                    console.log(list)
                                    return (
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                {item?.page_name && item.page_name[tKey]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {URLS.API_BASE_URL}{item.page_url}
                                            </StyledTableCell>

                                            <StyledTableCell style={{ color: "#13BC34", fontWeight: "bold" }} >
                                                {dateFormatWW(item?.createdAt)}
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "#13BC34", fontWeight: "bold" }} >
                                                {dateFormatWW(item?.createdAt)}
                                            </StyledTableCell>
                                            <StyledTableCell className='table-edit-one'>
                                                <Tooltip title='View'>
                                                    <img
                                                        src={eye}
                                                        alt="View"
                                                        onClick={() => handleRedirectPage(item, true)}
                                                    />
                                                </Tooltip>

                                                <Tooltip title='Edit'>
                                                    <img
                                                        src={edit}
                                                        alt="Accept"
                                                        onClick={() => handleRedirectPage(item)}
                                                    />
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <Pagination /> */}
            </div>
        </>
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: 'top'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        verticalAlign: 'top',
        userSelect: 'none'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default WebsiteContent
