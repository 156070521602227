import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../addPatient/addpatient.css'
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../../layout/header/Header'
import * as Yup from 'yup';
import { getCaller, postCaller, updateCaller } from '../../../../src/services/api'
import { useEffect } from 'react';
import { calculateAgeFromDate, checkAvailability, fetchAWSObject } from 'utils';
import { toast } from 'react-toastify';
import { constants, profiles } from 'utils/constants';
import { responseMessages } from 'utils/responseMessages';
import { ThemeButton, UnstyledButton } from 'components/common/button/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';


const AddPatient = () => {

    /* One Liner  code */
    const getBackToDashboard = () => navigate('/dashboard/users');





    let patientId = null;
    const { state } = useLocation();
    if (state && state?.patientId) {
        patientId = state.patientId;
    }

    // const resp = state?.userData;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setdata] = useState()
    const [patientData, setPatientData] = useState();


    const [profileImg, setProfileImg] = useState(null);
    const [profilePreview, setProfilePreview] = useState(null)


    const handleImageRender = e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setProfileImg(file)

            if (!checkAvailability(file.type)) { return alert("Unsupported File") }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setProfilePreview(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }


    const editPatientSchema = Yup.object().shape({
        name: Yup.string().min(2, "Name is too small").max(30, "Name is too long").required('Name is required'),
        email: Yup.string().required('Email ID is required').email('Invalid Email ID').max(60, 'Invalid Email Id'),
        gender: Yup.string().required('Gender is required'),
        age: Yup.number().min(18, 'Invalid Age').max(100, 'Must be below 2').required('Age is required'),
        mobile: Yup.string().min(10, 'Incorrect mobile number').max(10, 'Incorrect mobile number').required('Mobile number is required'),
        location: Yup.string().min(2, "Location is too small").required('Location is required'),
        dob: Yup.string(),
        about: Yup.string().max(500, 'Please shorten your about').required('About is required'),
        condition: Yup.string().required('Health Condition is required'),
        password: Yup.string().required('Password is required'),
        members: Yup.array()
        // .of(
        //     Yup.object().shape(
        //         {
        //             name: Yup.string().min(2, "Name is too small").max(30, "Name is too long").required('Name is required'),
        //             age: Yup.number().min(10, 'Invalid Age').max(100, 'Must be below 100').required('Age is required'),
        //             gender: Yup.string().required('Gender is required'),
        //         }
        //     )
        // )
    })

    const getUserData = async () => {
        const resp = await getCaller(`doctorAndPatient/?id=${patientId}&type=${profiles.user}`);

        formik.setValues(values => ({
            ...values,
            name: resp?.name ?? "",
            gender: resp?.gender ?? "",
            age: resp?.age ?? "",
            mobile: resp?.mobile ?? "",
            email: resp?.email ?? "",
            location: resp?.location ?? "",
            dob: resp?.dob ?? "",
            about: resp?.about ?? "",
            condition: resp?.condition ?? "",
            members: resp?.members ?? [
                {
                    name: "",
                    gender: "",
                    age: ''
                }
            ],
        }))
        setProfilePreview(fetchAWSObject(resp?.url) || profiles.default_picture)
        setPatientData(resp);
        console.log(resp)
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            gender: "",
            age: "",
            mobile: "",
            email: "",
            location: "",
            dob: undefined,
            about: "",
            condition: "",
            password: "",
            members: [
                {
                    name: "",
                    gender: "",
                    age: ''
                }
            ],
        },

        validationSchema: editPatientSchema,
        onSubmit: async (values) => {
            try {
                if (patientData) {
                    const updateResponse = await postCaller(`updateProfile?id=${patientData.loginId}`, values)
                    await uploadProfileImage({ loginId: patientData.loginId })
                    if (updateResponse?.error === false) {
                        toast.success(updateResponse?.msg)
                        navigate('/dashboard/users')
                    } else {
                        toast.info(updateResponse?.msg || responseMessages.UNKNOWN_ERROR)
                    }
                }
                else {
                    const response = await postCaller('admin/createPatientProfile', values)
                    await uploadProfileImage(response?.data)
                    if (response?.error === false) {
                        toast.success(response?.msg)
                        navigate('/dashboard/users')
                    } else {
                        toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
                    }
                }
            } catch {
                toast.info(responseMessages.UNKNOWN_ERROR)
            }
        },
    })


    const uploadProfileImage = async (data) => {
        if (data) {
            let formData = new FormData();
            formData.append("doc", profileImg);
            await updateCaller(`profile/image?userId=${data?.loginId}&type=user`, formData, true)
        }
    }


    // let erros = formik.touched;
    // console.log("🚀 ~ file: AddPatient.jsx:170 ~ AddPatient ~ erros", erros)



    function deleteMembers(i) {
        formik.values.members.splice(i, 1);
        formik.setFieldValue('members', [...formik.values.members])
    }

    /* Add Maximum Member function */

    const MAX_FAMILY_MEMBERS = 5;

    const handleAddMember = () => {
        const currentMembers = formik.values.members;

        if (currentMembers.length >= MAX_FAMILY_MEMBERS) {
            return;
        }

        formik.setFieldValue(
            'members',
            [
                ...currentMembers,
                {
                    name: "",
                    age: "",
                    gender: "",
                }
            ], false

        )
    }

    /* Back Button Handle */
    const handleBack = () => {
        window.history.back()
    }

    const handleAgeCalculation = (value) => {
        if (value?.isValid()) {
            const _value = value.format(constants.DATABASE_DATE_FORMAT);
            const age = calculateAgeFromDate(_value) || 0

            if (age > 18 && age < 110) {
                formik.setFieldValue('dob', _value)
                formik.setFieldValue('age', age)
                formik.setFieldError('dob', '', false)
                return;
            } else {
                formik.setFieldValue('dob', _value, false)
                formik.setFieldError('dob', 'Date of birth mush be 18 year of old', false)
                return;
            }
        } else {
            formik.setFieldValue('dob', '', false)
            formik.setFieldError('dob', 'Date of birth is required')
        }
    }

    useEffect(() => {
        patientId && getUserData()
    }, [])

    console.log('formik.values.dob', formik.values.dob)
    console.log('formik.errors.dob :>> ', formik.errors.dob);
    return (
        <>
            <Header />
            <div className='report-view-parent-back-button ' style={{ color: '#3A9CD8' }} onClick={handleBack}>
                <ArrowBackIosIcon sx={{ color: '#3A9CD8', fontSize: 15 }} />
                {t("back")}
            </div>
            <div>
                {/* <button onClick={() => navigate('/dashboard/users')}>Back</button> */}
                <form onSubmit={formik.handleSubmit} >
                    <div className="edit-container">
                        <div className="edit-content">
                            <div className="edit-body">
                                <h3>{t("Edit Profile Details")}</h3>
                                <div className="edit-doctor-img">
                                    <img
                                        src={profilePreview}
                                        height="175"
                                        style={{ borderRadius: '8px' }}
                                    />
                                    <input type="file" id="uploadImage" hidden onChange={handleImageRender} accept="image/*" />
                                    <label style={{
                                        cursor: 'pointer',
                                    }} htmlFor="uploadImage">{t('changePhoto')}</label>
                                </div>
                            </div>
                            <div className="edit-body-right">
                                <div className="edit-form">
                                    <div className="edit-name">
                                        <label htmlFor="name">{t("Name")}</label>
                                        <input type="text" name="name" placeholder="Enter name"
                                            onChange={formik.handleChange}
                                            // value={patientData?.name ? patientData.name : formik?.values?.name}
                                            value={formik?.values?.name}
                                        />
                                        <div className='error'>
                                            {formik.touched.name && formik.errors.name && (
                                                <span >{formik.errors.name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-select">
                                        <label htmlFor="gender">{t("Gender")}</label>
                                        <select
                                            // value={patientData?.gender ? patientData.gender : formik?.values?.gender}
                                            value={formik?.values?.gender}
                                            name="gender"
                                            className='edit-select-field'
                                            onChange={formik.handleChange}
                                        >
                                            <option value="" disabled>{t("Select gender")}</option>
                                            <option value="male">{t("Male")}</option>
                                            <option value="female">{t("Female")}</option>
                                        </select>
                                        <div className='error'>
                                            {formik.touched.gender && formik.errors.gender && (
                                                <span >{formik.errors.gender}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-age">
                                        <label htmlFor="age"> {t("Age")} </label>
                                        <Tooltip title='Age will be calculated automatically through Date of Birth'>
                                            <input
                                                type="number"
                                                name="age"
                                                placeholder="Enter age"
                                                disabled
                                                // onChange={handleAgeCalculation}
                                                // value={patientData?.age ? patientData.age : formik?.values?.age}
                                                value={formik?.values?.age}
                                            />
                                        </Tooltip>
                                        {/* <div className='error'>
                                            {formik.touched.age && formik.errors.age && (
                                                <span >{formik.errors.age}</span>
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                                <div className="edit-form">
                                    <div className="edit-mobile">
                                        <label htmlFor="mnumber"> {t("Mobile Number")} </label>
                                        <input
                                            disabled={patientId}
                                            type="number"
                                            name="mobile"
                                            placeholder="Enter Mobile Number"
                                            onChange={formik.handleChange}
                                            // value={patientData?.mobile ? patientData.mobile : formik?.values?.mobile}
                                            value={formik?.values?.mobile}
                                        />
                                        <div className='error'>
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <span >{formik.errors.mobile}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-email">
                                        <label htmlFor="email"> {t("Email Id")} </label>
                                        <input
                                            type="text"
                                            // value={patientData?.email ? patientData.email : formik?.values?.email} name="email" placeholder="Enter Email"
                                            value={formik?.values?.email} name="email" placeholder="Enter Email"
                                            onChange={formik.handleChange}
                                        />
                                        <div className='error'>
                                            {formik.touched.email && formik.errors.email && (
                                                <span >{formik.errors.email}</span>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="edit-form">
                                    <div className="edit-mobile">
                                        <label htmlFor="location"> {t("Location")} </label>
                                        <input
                                            type="text"
                                            name="location"
                                            placeholder="Enter location"
                                            // value={patientData?.location ? patientData.location : formik?.values?.location}
                                            value={formik?.values?.location}
                                            onChange={formik.handleChange}
                                        />
                                        <div className='error'>
                                            {formik.touched.location && formik.errors.location && (
                                                <span >{formik.errors.location}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-email">
                                        <label htmlFor="date" style={{
                                            marginBottom: '10px'

                                        }}> {t("Date of Birth")} </label>
                                        <DatePicker
                                            value={formik?.values?.dob}
                                            onChange={handleAgeCalculation}
                                            renderInput={
                                                (params) => (
                                                    <TextField
                                                        name='dob'
                                                        size='small'
                                                        helperText={formik?.errors?.dob !== undefined && formik.errors.dob}
                                                        {...params}
                                                    />
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="edit-textarea">
                                    <label htmlFor=""> {t("About")} </label> <br />
                                    <textarea
                                        name="about"
                                        placeholder='Enter the description'
                                        // value={patientData?.about ? patientData.about : formik?.values?.about}
                                        value={formik?.values?.about}
                                        onChange={formik.handleChange}
                                    ></textarea>
                                    <div className='error'>
                                        {formik.touched.about && formik.errors.about && (
                                            <span >{formik.errors.about}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="edit-form">
                                    <div className="edit-mobile">
                                        <label htmlFor="special"> {t("Health Condition")} </label>
                                        <select
                                            name="condition" className='edit-select-field'
                                            onChange={formik.handleChange}
                                            // value={patientData?.condition ? patientData.condition : formik?.values?.condition}
                                            value={formik?.values?.condition}
                                        >
                                            <option value="" disabled>{t("Select Health Condition")}</option>
                                            <option value="Daiabetic, Backbone issues"> {t("Daiabetic, Backbone issues")} </option>
                                            <option value="radiology issues"> {t("Radiology issues")} </option>
                                        </select>
                                        <div className='error'>
                                            {formik.touched.condition && formik.errors.condition && (
                                                <span >{formik.errors.condition}</span>
                                            )}
                                        </div>
                                    </div>


                                    <div className="edit-email">
                                        <label htmlFor="date"> {t("password")} </label>
                                        <input
                                            type="text"
                                            name="password"
                                            placeholder="Enter password"
                                            className='password'
                                            onChange={formik.handleChange}
                                            // value={formik?.values?.password}
                                            value={formik?.values?.password}
                                        />
                                        <div className='error'>
                                            {formik.touched.password && formik.errors.password && (
                                                <span >{formik.errors.password}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="edit-table">
                                    <div style={{
                                        display: 'flex', justifyContent: 'space-between',
                                        padding: '0.5rem  0rem'
                                    }}>
                                        <p> {t("Family Members")} </p>
                                        <ThemeButton
                                            // className='save-btn'
                                            sx={{ width: 'auto' }}
                                            onClick={handleAddMember}
                                            disabled={formik.values.members?.length >= MAX_FAMILY_MEMBERS}
                                            text='Add Member'
                                        />

                                        {/* <button type='button' className='save-btn' onClick={e => { formik.setFieldValue('members', [...formik.values.members, { name: "", age: "", gender: "" }], false) }}>Add Member</button> */}
                                    </div>
                                    <div style={{ overflowX: "auto" }}>
                                        <table className='family-table'>
                                            <thead>
                                                <tr>
                                                    <th> {t("Name")} </th>
                                                    <th> {t("Gender")} </th>
                                                    <th> {t("Age")} </th>
                                                    <th> {t("Actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(formik?.values?.members) && formik?.values?.members && formik?.values?.members?.map((e, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                name={`members.${i}.name`}
                                                                id={`member-{i}`}
                                                                className='family-input'
                                                                onChange={formik.handleChange}
                                                                // value={patientData?.members[i]?.name ? patientData.members[i].name : formik.values.members[i].name}
                                                                value={formik.values.members[i].name}
                                                                placeholder="Enter name"
                                                            />
                                                            <div className='error'>
                                                                {
                                                                    Array.isArray(formik.touched.members)
                                                                    && formik.touched.members.length > 0
                                                                    && Array.isArray(formik.errors.members)
                                                                    && formik.errors.members.length > 0
                                                                    && (
                                                                        <span>{formik?.errors?.members[i]?.name}</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <select
                                                                name={`members.${i}.gender`}
                                                                id=""
                                                                className='family-input-select'
                                                                onChange={formik.handleChange}
                                                                value={formik.values.members[i].gender}
                                                            >
                                                                <option value={""}>{t("Select gender")} </option>
                                                                <option value="male"> {t("Male")} </option>
                                                                <option value="female"> {t("Female")} </option>
                                                            </select>
                                                            <div className='error'>
                                                                {
                                                                    Array.isArray(formik.touched.members)
                                                                    && formik.touched.members.length > 0
                                                                    && Array.isArray(formik.errors.members)
                                                                    && formik.errors.members.length > 0
                                                                    && (
                                                                        <span>{formik?.errors?.members[i]?.gender}</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <input
                                                                type="number"
                                                                name={`members.${i}.age`}
                                                                id=""
                                                                className='family-input'
                                                                onChange={formik.handleChange}
                                                                value={formik.values.members[i].age}
                                                                placeholder={"Enter age"}
                                                            />
                                                            <div className='error'>
                                                                {
                                                                    Array.isArray(formik.touched.members)
                                                                    && formik.touched.members.length > 0
                                                                    && Array.isArray(formik.errors.members)
                                                                    && formik.errors.members.length > 0

                                                                    && (
                                                                        <span>{formik?.errors?.members[i]?.age}</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div
                                                                onClick={e => {
                                                                    deleteMembers(i)
                                                                }}
                                                                className="save-btn" style={{
                                                                    background: 'crimson'
                                                                }}
                                                            >
                                                                {t("Delete")}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='edit-footer'>
                            <div className='edit-button-content'>
                                <UnstyledButton
                                    onClick={getBackToDashboard}
                                    className='discard-btn'
                                    text='Discard'
                                />
                                <ThemeButton
                                    sx={{ width: 'auto' }}
                                    className='save-btn'
                                    type='submit'
                                    text='Save Changes'
                                // disabled={Object.keys(formik.errors).length > 0}
                                />
                                {/* <button className='discard-btn' onClick={() => navigate('/dashboard/users')}>Discard</button>
                                <button className='save-btn' type='submit' > Save Changes </button> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddPatient