import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { checkAvailability, fetchAWSObject, uploadAndDelete } from "../../../../utils";
import { MdImage } from "react-icons/md";
import { useTranslation } from "react-i18next";

const One = ({ register, errors, setValue, getValues, getFieldState, setDisable, disabled }) => {

    const [backgroundImage, setBackgroundImage] = React.useState(null);

    const { t } = useTranslation()

    const handleBackgroundImage = async (e) => {
        const current = e.target;
        try {
            if (current.files && current.files.length !== 0) {
                let file = current.files[0];
                // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }
                if (!checkAvailability(file.type)) {
                    console.log('current.files :>> ', current.files);
                    current.value = null;
                    console.log('current.files :>> ', current.files);

                    setValue('one_backround_image_hidden', null)
                    return alert("Unsupported File")
                }

                setDisable(true);
                const newKey = await uploadAndDelete(file, backgroundImage);
                setValue('one_backround_image_hidden', newKey)
                setBackgroundImage(newKey);
                setDisable(false);
            }
        } catch (e) {
            console.log("ERROR");
            return false;
        }
    };
    return (
        <>

            <div className="home-section-one">
                <p> Section 1 </p>

                {/* Title */}
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (EN)")} </label> <br />
                    <input
                        disabled={disabled}
                        {...register("one_title.en", { required: true })}
                        type="text"
                        className='home-input-field'
                        placeholder='Enter Section One TItle'

                    />
                    <div className={'input-invalid-message'}>
                        {errors.one_title?.en && 'This field is required'}
                    </div>
                </div>
                <div className='section-one-input'>
                    <label htmlFor="home"> {t("Title (AR)")} </label> <br />
                    <input
                        disabled={disabled}
                        {...register("one_title.ar", { required: true })}
                        type="text"
                        className='home-input-field'
                        placeholder='Enter Section One TItle'

                    />
                    <div className={'input-invalid-message'}>
                        {errors.one_title?.ar && 'This field is required'}
                    </div>
                </div>

                {/* Sub Title */}
                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (EN) </label> <br />
                    <textarea
                        disabled={disabled}
                        {...register("one_subtitle.en", { required: true })}
                        type="text"
                        className='home-input-field home-input-field-textarea'
                        placeholder='Enter Your SubTitle'

                    />

                    <div className={'input-invalid-message'}>
                        {errors.one_subtitle?.en && 'Subtitle in (en) is required'}
                    </div>
                </div>

                <div className='section-one-input'>
                    <label htmlFor="home"> Subtitle (AR)</label> <br />
                    <textarea
                        disabled={disabled}
                        {...register("one_subtitle.ar", { required: true })}
                        type="text"
                        className='home-input-field home-input-field-textarea'
                        placeholder='Enter Your SubTitle'

                    />

                    <div className={'input-invalid-message'}>
                        {errors.one_subtitle?.ar && 'Subtitle in (ar) is required'}
                    </div>
                </div>


                <div className='section-bg-input'>
                    <label htmlFor="home"> Background Image </label> <br />
                    <Grid container spacing={5} className="my-5 align-items-center">
                        <Grid item xs={10}>
                            <div>
                                <input
                                    type="hidden"
                                    {...register('one_backround_image_hidden')}
                                />

                                <input
                                    disabled={disabled}
                                    {
                                    ...register("one_background_image_file",
                                        {
                                            // required: true,
                                            onChange: handleBackgroundImage
                                        }
                                    )}
                                    type="file"
                                    name='one_background_image_file'
                                    className='home-input-field-file'
                                    placeholder='Eter Your BackgroundImag'


                                />
                                {errors.one_background_image_file &&
                                    <div className={'input-invalid-message'}>
                                        Please select a background image
                                    </div>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            {

                                (getFieldState('one_backround_image_hidden')) ?
                                    <>
                                        <MdImage className="delete-icon"
                                            onClick={
                                                () =>
                                                    window.open(`${fetchAWSObject(getValues('one_backround_image_hidden'))}`)}
                                        />
                                    </>
                                    : <></>

                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default One;