import { useTranslation } from "react-i18next"

export const constants = {
    USER_DATE_FORMAT: "DD-MM-YYYY",
    USER_DATE_FORMAT_WW: "DD MMM YYYY", /* with words */
    USER_DATE_FORMAT_WW_TIME: "DD MMM YYYY, hh:mm A", /* with words */
    DATABASE_DATE_FORMAT: "YYYY-MM-DD",
    N_A: 'N/A',
    three_dots: "..."
}

export const profiles = {
    'user': "user",
    'doctor': "doctor",
    'default_picture': "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
}

export const fix_pages = {
    'about': 'ABOUT',
    'privacy': 'PRIVACY',
    'contact': 'CONTACT',
    'terms': 'TERMS',
    'homepage': 'HOMEPAGE',
    'logo': 'LOGO',
    'socialmeadilink': 'SOCIAL_LINK',
    'email': "EMAIL",
    'address': "ADDRESS"
}

export const URLS = {
    API_BASE_URL: process.env.REACT_APP_BASE_URL
}


export const userProfileSettings = {
    'Name': 'Name',
    'Gender': 'Gender',
    'Age': 'Age',
    'Mobile number': 'Mobile number',
    'Email id': 'Email id',
    'Location': 'Location',
    'Date of Birth': 'Date of Birth',
    'Health Condition': 'Health Condition',
    'About': 'About',
    'Family Members': 'Family Members',
}

export const doctorApprovalStatus = {
    'accepted': 'accepted',
    'rejected': 'rejected',
    'pending': 'pending',
}



/* doing this is just for admin side */
export const useAvailability = () => {
    const { t } = useTranslation()
    return [
        {
            "day": t("monday"),
            "day_index": 1,
            "start": "",
            "end": "",
            "status": false
        },
        {
            "day": "tuesday",
            "day_index": 2,
            "start": "",
            "end": "",
            "status": false
        },
        {
            "day": "wednesday",
            "day_index": 3,
            "start": "",
            "end": "",
            "status": false
        },
        {
            "day": "thursday",
            "day_index": 4,
            "start": "",
            "end": "",
            "status": false
        },
        {
            "day": "friday",
            "day_index": 5,
            "start": "",
            "end": "",
            "status": false
        }
    ]
}


/* -------------------------------------------------------STATUE------------------------------------------------------- */
export const queryStatus = {
    'pending': 'Pending',
    'process': 'In-Process',
    'resolved': 'Resolved'
}

export const appointmentStatus = {
    'Upcoming': 'Upcoming',
    'Completed': 'Completed',
    'inReview': 'inReview',
    'Rescheduled': 'Rescheduled',
    'Cancelled': 'Cancelled',
    'payment_pending': 'payment_pending',
    'reject': 'reject',
}

export const reviewsStatus = {
    'approved': 'approved',
    'rejected': 'rejected'
}

export const _approvalStatus = {
    'accepted': 'accepted',
    'rejected': 'rejected',
    'pending': 'pending'
}
/* -------------------------------------------------------STATUE------------------------------------------------------- */
