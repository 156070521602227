import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import Dashboard from './pages/dashboard/Dashboard'
import LocaleContext from './LocaleContext'
import Helmet from 'react-helmet'
import i18next from 'i18next';
import AddPatient from './pages/user/addPatient/AddPatient'
import AddNewDoctor from './pages/user/addDoctor/AddNewDoctor'
import PushNotification from './pages/configuration/generalSettings/pushNotifications/PushNotification';

import Login from './loginRegister/Login';
import ProtectedRoute from './routes/ProtectedRoute';
import HomePage from './pages/websitecontent/homePage/HomePage';
import EditTermsCondition from './pages/websitecontent/TermsConditions/Edit';
import EditPolicy from './pages/websitecontent/EditPolicy';
import CreateCoupon from './pages/configuration/couponCodes/CreateCoupon';
import EditCoupon from './pages/configuration/couponCodes/EditCoupon';

import InvoiceDownloadScreen from './profile/DoctorProfile/DoctorAccount/InvoiceDownloadScreen';
import ProfileInProgress from './profile/DoctorProfile/ProfileInProgress';
import UserProfileTab from './profile/UserProfile/UserProfileTab';

import { useForm } from "react-hook-form";
import About from './pages/websitecontent/aboutPage';
/* TEAMS CRUD */
import TeamsAdd from './pages/Teams/Add';
import TeamsEdit from './pages/Teams/Edit';
/* Services Routes */
import SubSerListing from "./pages/configuration/generalSettings/Services/Sub/";


import ReviewReportView from './pages/appointmentReportView/ReviewReportView';
import DownloadReportView from './pages/appointmentReportView/DownloadReportView';
import UpcomingReportView from './pages/appointmentReportView/UpcomingReportView/View';
import ReviewAppointmentView from './pages/appointmentReportView/ReviewAppointmentView';
import ChatWindow from "./pages/chatWindow/ChatWindow";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AptOverView from 'pages/appointment/AptOverView';
import UseroverView from 'pages/dashboard/useroverview/UseroverView';
import UserTotal from 'pages/user/UserTotal';
import DoctorReview from 'pages/doctorReview/DoctorReview';
import PaymentOverView from 'pages/payment/PaymentOverView';
import WebsiteContent from 'pages/websitecontent/WebsiteContent';
import CustomerSupportQuery from 'pages/customerSupport/CustomerSupportQuery';
import Configuration from 'pages/configuration/Configuration';
import ViewWebsiteContent from 'pages/websitecontent/TermsConditions/ViewWebsiteContent';
import View from 'pages/appointmentReportView/UpcomingReportView/View'
import PatientView from 'pages/user/view/Patient';
import DoctorView from 'pages/user/view/Doctor';
import DoctorProfileAccount from 'profile/DoctorProfile/DoctorAccount/DoctorAccount';
import DoctorAppointment from 'profile/DoctorProfile/DoctorAppointment/DoctorAppointment';
import ReviewShowScreen from 'profile/DoctorProfile/reviews/ReviewShowScreen';
import ReviewAppointment from 'profile/DoctorProfile/DoctorAppointment/ReviewAppointment';
import UpcomingAppointment from 'profile/DoctorProfile/DoctorAppointment/UpcomingAppointment';
import PastAppointment from 'profile/DoctorProfile/DoctorAppointment/PastAppointment';
import UserAccount from 'profile/UserProfile/userAccount/UserAccount';
import UserAppointment from 'profile/UserProfile/userAppointment/UserAppointment';
import UserMedical from 'profile/UserProfile/usermedical/UserMedical';
import UserWallet from 'profile/UserProfile/userWallet/UserWallet';
import useMasters from 'hooks/useMasters';
import { checkIfLogin } from 'utils';
import GeneralSettings from 'pages/configuration/generalSettings/GeneralSettings';
import DoctorSettings from 'pages/configuration/doctorSettings/DoctorSettings';
import ProfileSettings from 'pages/configuration/patientSettings/ProfileSettings';
import AppointmentSettings from 'pages/configuration/appointmentSettings/AppointmentSettings';
import CouponCode from 'pages/configuration/couponCodes/CouponCode';
import TeamsLayout from 'pages/configuration/teams';


/* importing Team components */
import ListTeam from 'pages/Teams/List'
import AddTeam from 'pages/Teams/Add/index'
import EditTeam from 'pages/Teams/Edit/index'
import { ContactUsPage } from 'pages/websitecontent/contactUs';


function App() {
    const { _initialize } = useMasters();
    const [locale, setLocale] = useState(i18next.language);
    i18next.on('languageChanged', (lng) => setLocale(i18next.language));


    useEffect(() => {
        checkIfLogin() && _initialize()
    }, [])
    return (
        <>
            {/* TOAST CONTAINNE */}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                limit={1}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="light"
            />
            <LocaleContext.Provider value={{ locale, setLocale }}>
                <Helmet htmlAttributes={{
                    lang: locale,
                    dir: locale === 'en' ? 'ltr' : 'rtl'
                }}>
                </Helmet>
                <BrowserRouter>
                    <Wrapper>



                        <Routes>
                            <Route exact path='/' element={<Login />} />

                            <Route exact path="/dashboard" element={<ProtectedRoute> {<Dashboard />} </ProtectedRoute>}>
                                <Route index element={<ProtectedRoute> {<UseroverView />} </ProtectedRoute>} />
                                <Route path={'appointments'} element={<AptOverView />} />
                                <Route path={'users'} element={<UserTotal />} />
                                <Route path={'doctors'} element={<DoctorReview />} />
                                <Route path={'payments'} element={<PaymentOverView />} />
                                <Route path={'webiste-contents'} element={<WebsiteContent />} />
                                <Route path={'contact-support'} element={<CustomerSupportQuery />} />
                                {/* <Route path={'configurations'} element={<Configuration />} /> */}
                                <Route path={'configurations'} >
                                    <Route element={<Configuration />} >
                                        <Route index element={<GeneralSettings />} />
                                        <Route path='settings-doctor' element={<DoctorSettings />} />
                                        <Route path='settings-patient' element={<ProfileSettings />} />
                                        <Route path='settings-appointment' element={<AppointmentSettings />} />
                                        <Route path='settings-coupon' element={<CouponCode />} />
                                        <Route path='settings-teams' element={<TeamsLayout />}>
                                            <Route path='' element={<ListTeam />} />
                                            <Route path='add' element={<AddTeam />} />
                                            <Route path='edit/:id' element={<EditTeam />} />
                                        </Route>
                                        <Route path='settings-services/sub/:subMasterId' element={<SubSerListing />} />
                                    </Route>
                                </Route>
                            </Route>

                            <Route exact path="/upcomingReportView/:id"
                                element={<ProtectedRoute> {<UpcomingReportView />} </ProtectedRoute>} />


                            <Route exact path="/addNewPatient"
                                element={<ProtectedRoute> {<AddPatient />} </ProtectedRoute>} />
                            <Route exact path="/addNewDoctor"
                                element={<ProtectedRoute> {<AddNewDoctor />} </ProtectedRoute>} />
                            <Route exact path="/inreviewAppointment"
                                element={<ProtectedRoute> {<ReviewAppointmentView />} </ProtectedRoute>} />
                            <Route exact path="/push-notifications"
                                element={<ProtectedRoute>  {<PushNotification />} </ProtectedRoute>} />
                            <Route exact path="/invoicedownload/:doctorId"
                                element={<ProtectedRoute>  {<InvoiceDownloadScreen />} </ProtectedRoute>} />

                            {/* USER Profile viewing */}
                            <Route
                                path="/view/profile/patient/:patientId"
                                element={<ProtectedRoute>  {<PatientView />} </ProtectedRoute>}
                            >
                                <Route index element={<UserAccount />} />
                                <Route path={"appointments"} element={<UserAppointment />} />
                                <Route path={"medical"} element={<UserMedical />} />
                                <Route path={"wallet"} element={<UserWallet />} />
                            </Route>

                            {/* DOCTOR Profile viewing */}
                            <Route
                                path="/view/profile/doctor/:doctorId"
                                element={<ProtectedRoute>  {<DoctorView />} </ProtectedRoute>}
                            >
                                <Route index element={<DoctorProfileAccount />} />
                                <Route path={'appointments'} element={<DoctorAppointment />} >
                                    <Route index element={<ReviewAppointment />} />
                                    <Route path={'past'} element={<PastAppointment />} />
                                    <Route path={'upcoming'} element={<UpcomingAppointment />} />
                                </Route>
                                <Route path={'reviews'} element={<ReviewShowScreen />} />
                            </Route>

                            {/* WEBSITE CONTENT ROUTES */}
                            <Route exact path="/edithomepage/:id"
                                element={<ProtectedRoute>  {<HomePage />} </ProtectedRoute>} />
                            <Route exact path="/editTermsCondition/:id"
                                element={<ProtectedRoute>  {<EditTermsCondition />} </ProtectedRoute>} />
                            <Route exact path="/editPrivacyPolicy/:id"
                                element={<ProtectedRoute>  {<EditPolicy />} </ProtectedRoute>} />
                            <Route exact path="/about/:id" element={<ProtectedRoute>  {<About />} </ProtectedRoute>} />
                            <Route exact path="/edit/contact-us/:id" element={<ProtectedRoute>  {<ContactUsPage />} </ProtectedRoute>} />

                            {/* TEAMS */}
                            <Route exact path="/createcoupon" element={<ProtectedRoute>  {<CreateCoupon />} </ProtectedRoute>} />
                            <Route exact path="/editCoupon/:id" element={<ProtectedRoute>  {<EditCoupon />} </ProtectedRoute>} />
                            {/* <Route exact path="/teams/add" element={<ProtectedRoute>  {<TeamsAdd />} </ProtectedRoute>} />
                        <Route exact path="/teams/edit/:id" element={<ProtectedRoute>  {<TeamsEdit />} </ProtectedRoute>} /> */}

                            {/* Sub Categories Routes  */}
                            <Route exact path="/services/sub/:subMasterId"
                                element={<ProtectedRoute>  {<SubSerListing />} </ProtectedRoute>} />

                            {/* Chat Window Popup Routes */}
                            <Route exact path={'/window/popup/:roomId'}
                                element={<ProtectedRoute> <ChatWindow /> </ProtectedRoute>} />


                            {/* Terms & Condition View ROutes */}
                            <Route exact path={"/admin/getTermsCondition/:id"} element={<ViewWebsiteContent />} />

                        </Routes>
                    </Wrapper>
                </BrowserRouter>
            </LocaleContext.Provider>
        </>
    );
}

const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}


export default App;
