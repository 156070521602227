import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Avatar, Dialog, Grid, IconButton, Slide } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";
import { useForm } from 'react-hook-form';
import { ThemeButton } from '../../../../components/common/button/Button';
import { fetchAWSObject } from '../../../../utils';
import { updateGeneralFooterRQ } from './Calls';
import { fix_pages } from 'utils/constants';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HyperLinkModal({
    type,
    openDialog,
    setOpenDialog,
    parentData,
    setParentData,
    refreshData,
}) {
    /* ISOLATED STATES */

    /* Button States */
    const [loader, setLoader] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();


    const handleClose = () => {
        setOpenDialog(false);
        setParentData(null);
    };

    const handleServiceSubmit = async data => {
        setLoader(true);
        const payload = {};

        if (
            parentData?.page_type === fix_pages.address
            || parentData?.page_type === fix_pages.email
        ) {
            payload['content'] = data.url
        } else {
            payload['url'] = data.url
        }
        const response = await updateGeneralFooterRQ(parentData.id, payload);
        if (response.error === false) {
            refreshData();
            handleClose();
        }
        setLoader(false);

    }

    useEffect(() => {
        setValue('url', '');
        reset();
        if (parentData) {
            setValue('url', parentData.page_url);
        }
    }, [parentData])

    return (
        <div>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Container>
                    <Header>
                        Edit {type}
                        <IconButton onClick={handleClose}>
                            <CloseIcon className='pointer' />
                        </IconButton>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit(handleServiceSubmit)} >
                            <div className="home-wrapper">
                                <div className="home-form">
                                    <div className='home-input-content-full'>
                                        <label htmlFor="home"> Name </label> <br />
                                        {
                                            [fix_pages.address].includes(parentData?.page_type)
                                                ? <textarea
                                                    {...register("url", { required: true })}
                                                    type="text"
                                                    className='home-input-field not-resizable-x'
                                                    placeholder='Enter Sub Category Name'
                                                ></textarea>
                                                : <input
                                                    {...register("url", { required: true })}
                                                    type="text"
                                                    className='home-input-field not-resizable-x'
                                                    placeholder='Enter Sub Category Name'
                                                />
                                        }
                                        <div className={'input-invalid-message'}>
                                            {errors.name && 'Name is required'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    marginTop: '1rem'
                                }}
                            >
                                <Grid item xs={8}></Grid>
                                <Grid item xs={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end'
                                    }}>
                                    <ThemeButton
                                        style={{
                                            color: loader ? 'grey' : '#2f77b6'
                                        }}
                                        loading={loader}
                                        text={'Update'}
                                    />
                                </Grid>
                            </Grid>

                        </form>
                    </Body>
                </Container>
            </Dialog>
        </div>
    );
}



const Container = styled("div")`
    padding: 1rem;
    min-width: 400px;
    width: 40vw;
`;

const Header = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Body = styled("div")`
    width: 97%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 0.2rem;
    padding-left: 1rem;
    border-radius: 5px;
    input{
        border: none;
        outline: none;
        border: 1px solid #d2d2d2;
        width: 100%;
        color: var(--text-light);
    }
`;



export default HyperLinkModal;