import React, { useEffect, useState } from 'react'
import { responsiveFontSizes, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate, Link } from 'react-router-dom';
import { getCaller } from './../../../services/api'
import { updateDoctoreSettingsRQ } from "../Calls";
import ProfileSettings_M from './../modals/ProfileSettings_M';
import { Switch } from '@mui/material';
import edit from '../../../assets/images/edit-2.svg'
 <img src={edit} alt="Accept" />

const AppointmentSettings = () => {

    const [appointmentSettings, setAppointmentSettings] = useState([])
    const [modalData, setModalData] = useState({})
    const [openModal, setOpenModal] = useState(false)


    const navigate = useNavigate();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#D5F1FF",
            color: "black",
            border: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: 0,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: '1px solid #F0F0F0',
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const fatchAppoimentSettingsMaster = async () => {
        const res = await getCaller('admin/config/settings/appointment')
        console.log('response aa gya', res);
        setAppointmentSettings(res?.data?.rows || [])
    }

    /* AppointmentEnableOrDisable */
    const disabledOrEnabled = async (id, status) => {
        let data = {}
        console.log(status)
        if (status === 'disable') {
            data.status = 0;
        } else if (status === 'enable') {
            data.status = 1;
        }
        const response = await updateDoctoreSettingsRQ(id, data)
        console.log('response', response)
        fatchAppoimentSettingsMaster()
    }


    useEffect(() => {
        fatchAppoimentSettingsMaster()
    }, [])
    return (
        <>
            <ProfileSettings_M
                openDialog={openModal}
                setOpenDialog={setOpenModal}
                parentData={modalData}
                setParentData={setModalData}
                refreshData={fatchAppoimentSettingsMaster}
            />
            <div style={{ marginTop: "3rem" }}>
                <p>Appointment Settings </p>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell > Field Name </StyledTableCell>
                                <StyledTableCell align='center' > Field Type </StyledTableCell>
                                <StyledTableCell align='center'> Action </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                appointmentSettings.map(item => {
                                    return (
                                        <StyledTableRow >
                                            <StyledTableCell StyledTableCell component="th" scope="row" >
                                                {item.field_label?.['en']}
                                            </StyledTableCell>
                                            <StyledTableCell align='center'> {item.field_type}  </StyledTableCell>

                                            <StyledTableCell align='center'>
                                                <Link
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                        setModalData(item)
                                                    }}
                                                    style={{ marginRight: "1rem" }}>  <img src={edit} alt="Accept" />  </Link>
                                                <Switch
                                                    defaultChecked={item?.status || false}
                                                    style={{ marginleft: "1rem" }}
                                                    onClick={() => {
                                                        disabledOrEnabled(
                                                            item.id,
                                                            item.status === 1 ? 'disable' : 'enable'
                                                        )
                                                    }}
                                                /> 
                                                {/* {item.status === 1 ? "Disable" : "Enable"}  </Link> */}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }







                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default AppointmentSettings