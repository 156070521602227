import React, { useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom'

const DoctorAllAppointment = () => {
    const [tabIndex, setTabIndex] = useState();
    const setActiveInactiveClass = ({ isActive }) => { return isActive ? 'doctor-list-tab doctor-list-active-tab' : 'doctor-list-tab' }

    const {t} = useTranslation();

    return (
        <>
            <div className="doctor-tab-container">
                <ul>
                    <NavLink
                        className={setActiveInactiveClass}
                        to={''}
                    >
                        <li>
                            {t("Review Appointments")}
                        </li>
                    </NavLink>
                    <NavLink
                        className={setActiveInactiveClass}
                        to={'upcoming'}
                    >
                        <li>
                            {t("Upcoming Appointments")}
                        </li>
                    </NavLink>
                    <NavLink
                        className={setActiveInactiveClass}
                        to={'past'}
                    >
                        <li>
                            {t("Past Appointments")}
                        </li>
                    </NavLink>
                </ul>
                <div>
                    <Outlet />
                    {/* <div className="" hidden={tabIndex !== 0}>
                        <ReviewAppointment />
                    </div>
                    <div className="" hidden={tabIndex !== 1}>
                        <UpcomingAppointment />
                    </div>
                    <div className="" hidden={tabIndex !== 2}>
                        <PastAppointment />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default DoctorAllAppointment