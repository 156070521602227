import React, { useEffect } from 'react'
import '../revenue/revenue.css'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import useMasters from 'hooks/useMasters';
import { getCaller } from 'services/api';
import { useState } from 'react';
import moment from 'moment';
import useGlobal from 'hooks/useGlobal';

const YEAR_FORMAT = 'YYYY'
const currentYear = moment().format(YEAR_FORMAT);

const inital_graph_values = Array(12).fill(0).map((nodata, index) => {
    return {
        month: index,
        total_amount: 0
    }
})

const calculation = response => {
    const { data } = response;

    let merged_array = [
        ...inital_graph_values /* declared globally */,
        ...data /* data comming from response */
    ]


    const merged_filtered_array = [
        ...new Map(
            merged_array.map(
                item => [item['month'], item]
            )
        ).values()
    ].map( /* running map again for setting 'month' index to 'MMM' name */
        item => ({
            Amount: item['total_amount'],
            month: moment().set(
                'M',
                item['month']
            ).format("MMM")
        })
    )
    return merged_filtered_array;
}

const last_three_years = () => {
    const current_date = moment(); /* initiating moment */
    return [
        current_date.format(YEAR_FORMAT),
        current_date.subtract(1, 'year').format(YEAR_FORMAT),
        current_date.subtract(2, 'year').format(YEAR_FORMAT)
    ]
}

/* COMPONENT */
const Revenue = ({ state, setState }) => {
    const { masterServices } = useMasters();
    const { tKey: [tKey] } = useGlobal();

    const [graphData, setGraphData] = useState([])
    const [totalR, setTotalR] = useState(0);
    const [yearS, setYearS] = useState(currentYear) // yearS -> year selected
    const [masterSS, setMasterSS] = useState(currentYear) // masterSS -> master service selected

    const renderGraphData = async payload => {
        try {
            const {
                year,
                serviceMasterId
            } = payload;

            const response = await getCaller(`admin/dashboard/graph?serviceMasterId=${serviceMasterId}&year=${year}`)
            if (response?.error === false) {
                calculateAndSetRevenue(response)
                setGraphData(calculation(response))
            } else {
                setGraphData(inital_graph_values)
            }
        } catch { }
    }

    const calculateAndSetRevenue = response => {
        const { data } = response;
        const revenue = data.reduce((acc, next) => {
            return acc += Number(next.total_amount);
        }, 0)
        setTotalR(revenue)
    }

    const handleYearDropDown = ({ target: { value } }) => setYearS(value)
    const handleServiceDropDown = ({ target: { value } }) => setMasterSS(value)

    useEffect(() => {
        const payload = {
            year: yearS,
            serviceMasterId: masterSS
        }
        renderGraphData(payload)
    }, [masterSS, yearS])


    useEffect(() => {
        const payload = {
            year: moment().format(YEAR_FORMAT),
            serviceMasterId: 1
        }
        renderGraphData(payload)
    }, [])
    return (
        <>
            <div className="revenue-container">
                <p>Revenue</p>
                <div className="border">
                    <div className="revenue-total">
                        <div className="revenue-para">
                            <p>Total Revenue(SAR)</p>
                            <h3>{totalR}</h3>
                        </div>
                        <div className="revenue-drop">
                            <div className="revenue-service">
                                <select
                                    className='text-capitalize'
                                    onChange={handleServiceDropDown}
                                    value={masterSS}
                                >
                                    {
                                        masterServices.length > 0
                                            ? masterServices.map(service => {
                                                return <option className="text-capitalize" value={service?.id}>{service?.name?.[tKey]}</option>
                                            }) : <></>
                                    }
                                </select>
                            </div>
                            <div className="calender">
                                <select
                                    onChange={handleYearDropDown}
                                    value={yearS}
                                >
                                    {
                                        last_three_years().map(year => {
                                            return <option value={year} key={year}>Year {year}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="revenue-graph">
                    <ResponsiveContainer aspect={3}  >
                        <AreaChart
                            width={500}
                            height={400}
                            data={graphData}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="Amount" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    )
}

export default Revenue