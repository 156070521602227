import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/locales/i18n'
import GlobalState from 'context/GlobalState';
import { MastersProvider } from 'context/MastersContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-in'}>
            <GlobalState>
                <MastersProvider>
                    <App />
                </MastersProvider>
            </GlobalState>
        </LocalizationProvider>
    </React.StrictMode>
);

reportWebVitals();
