import React from 'react';
import Header from '../../../layout/header/Header';
import { useForm } from "react-hook-form";
import { ThemeButton, UnstyledButton } from '../../../components/common/button/Button';
import { Avatar, Grid, IconButton } from '@mui/material';
import { checkAvailability, fetchAWSObject } from '../../../utils';
import { fetchTeamMemberByIdRQ, updateTeamMember } from '../Call';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const Edit = () => {
    /* ISOLATED STATES */
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const { id } = useParams();
    const { state: { disabled } } = useLocation();

    const [loader, setLoader] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [proiflePreview, setProfileView] = React.useState(null);


    const handleImageRender = async e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setValue('file', file)

            if (!checkAvailability(file.type)) { return alert("Unsupported File") }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setProfileView(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }

    const onSubmit = async data => {
        setLoader(true);
        const {
            name,
            designation,
            about,
            file
        } = data;
        console.log("🚀 ~ file: index.jsx:50 ~ onSubmit ~ data", data?.file?.length)

        const formData = new FormData();

        formData.append('name', JSON.stringify(name))

        formData.append('designation', JSON.stringify(designation))

        formData.append('about', JSON.stringify(about))

        if(file?.length !== 0){
            formData.append('file', file)
        }

        const response = await updateTeamMember(formData, id);
        if (response) {
            navigate('/dashboard/configurations/settings-teams');
        } else {
            toast.info(response?.msg)
        }
        setLoader(false);
    }

    const fetchById = async () => {
        const response = await fetchTeamMemberByIdRQ(id);
        console.log('response :>> ', response);

        if (response?.data) {
            const {
                name,
                about,
                designation,
                profile
            } = response.data;

            name?.en && setValue('name.en', name?.en)
            name?.ar && setValue('name.ar', name?.ar)

            about?.en && setValue('about.en', about?.en)
            about?.ar && setValue('about.ar', about?.ar)

            designation?.en && setValue('designation.en', designation?.en)
            designation?.ar && setValue('designation.ar', designation?.ar)

            setProfileView(profile && fetchAWSObject(profile))
        } else {
            navigate('/dasbboard');
        }
    }

    React.useEffect(() => {
        fetchById();
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* FILLING SECTION */}
                <div
                    className='edit-home-container'
                    style={{
                        margin: '1rem',
                        width: '100%'
                    }}
                >
                    <h3>Edit Team Member</h3>
                    <div className="home-wrapper">
                        <div className="home-form">
                            <div className='home-input-content'>
                                <label htmlFor="home"> Name (EN)</label> <br />
                                <input
                                    disabled={disabled}
                                    {...register("name.en", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member name (en)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.name?.en && 'Name (en) is required'}
                                </div>
                            </div>
                            <div className='home-input-content'>
                                <label htmlFor="home"> Name (AR)</label> <br />
                                <input
                                    disabled={disabled}
                                    {...register("name.ar", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member name (ar)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.name?.ar && 'Name (ar) is required'}
                                </div>
                            </div>

                            <div className='home-input-content' style={{ marginLeft: "2rem" }}>
                                <label htmlFor="home"> Designation (EN)</label> <br />
                                <input
                                    disabled={disabled}
                                    {...register("designation.en", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member designation (en)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.designation?.en && 'Designation (en) is required'}
                                </div>
                            </div>
                            <div className='home-input-content' style={{ marginLeft: "2rem" }}>
                                <label htmlFor="home"> Designation (AR)</label> <br />
                                <input
                                    disabled={disabled}
                                    {...register("designation.ar", { required: true })}
                                    type="text"
                                    className='home-input-field'
                                    placeholder='Member designation (ar)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.designation?.ar && 'Designation (ar) is required'}
                                </div>
                            </div>

                            <div className='home-input-content home-input-content-20' style={{ marginLeft: "2rem" }}>
                                <label > Profile </label> <br />
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <label
                                            htmlFor='profile_photo'
                                            // className='home-input-field-file'
                                            placeholder='Upload'
                                        // style={{ border: 'none', display: 'block' }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                margin: "auto",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "100%"
                                            }}
                                        >
                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                <input
                                                    disabled={disabled}
                                                    hidden
                                                    {...register("file")}
                                                    type="file"
                                                    name="file"
                                                    id="profile_photo"
                                                    className='hidden'
                                                    onChange={handleImageRender}
                                                    accept="image/*"
                                                />
                                                <Avatar id='profile_photo' alt="P" src={proiflePreview} />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className={'input-invalid-message'}>
                                </div>
                            </div>
                        </div>

                        <div className="home-form">
                            <div className='home-input-content home-input-content-full'>
                                <label htmlFor="home"> About (EN)</label> <br />
                                <textarea
                                    disabled={disabled}
                                    {...register("about.en", { required: true })}
                                    type="text"
                                    className='home-input-field home-input-content-full home-input-field-textarea'
                                    placeholder='Member about (en)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.about?.en && 'About is required'}
                                </div>
                            </div>
                            <div className='home-input-content home-input-content-full'>
                                <label htmlFor="home"> About (AR)</label> <br />
                                <textarea
                                    disabled={disabled}
                                    {...register("about.ar", { required: true })}
                                    type="text"
                                    className='home-input-field home-input-content-full home-input-field-textarea'
                                    placeholder='Member about (ar)'
                                />
                                <div className={'input-invalid-message'}>
                                    {errors.about?.ar && 'About is required'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* SUBMIT SECTION */}
                <div
                    className="home-btn-content"
                    style={{
                        position: 'unset',
                        backgroundColor: 'unset',
                        boxShadow: 'unset'
                    }}
                >
                    <UnstyledButton
                        onClick={() => navigate('/dashboard/configurations/settings-teams')}
                        text={disabled ? 'Back' : 'Cancel'}
                    />
                    {
                        !disabled
                        && <div className="home-mark">
                            <div
                                className=''
                                style={{
                                    color: disable ? 'black' : 'white'
                                }}
                            >
                                <ThemeButton
                                    text="Save"
                                    loading={loader}
                                />
                            </div>
                        </div>
                    }
                </div>

            </form>
        </>
    )
}


export default Edit;