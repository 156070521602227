import { useParams, useSearchParams } from "react-router-dom";
import ChatWindowCompo from 'components/ChatWindow/Chat';
import { useEffect } from "react";

const ChatWindow = () => {
    const { roomId } = useParams();
    const [params] = useSearchParams();

    const email = params.get('email');
    const userId = params.get('user_id');
    const name = params.get('name');

    useEffect(() => {
        window.document.title = name;
    }, [])
    return (
        <>
            <ChatWindowCompo
                userType={'user'}

                /* DIRECT PROPS */
                _email={email}
                _roomId={roomId}
                _userId={userId}
                /* DIRECT PROPS */

                direct={true}
                attatchment={false}
                popup={true}
            />
        </>
    )
};

export default ChatWindow;
