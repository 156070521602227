import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import check from '../../assets/images/check-circle.png';
import cross from '../../assets/images/x-circle-1.png';
import message from '../../assets/images/message-square.svg'
import sortarrow from '../../assets/images/sort-arrow.svg'
import FilterInput from '../../components/common/filterfield/FilterInput'
import Pagination from '../../components/pagination/Pagination';
import { getCaller, updateCaller } from '../../services/api';
import { dateFormatWW, destructureProfile, generateQuery } from 'utils';
import { constants, profiles, reviewsStatus } from 'utils/constants';
import { __status_color } from 'utils/colors';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import FilterModal from './FilterModal';
import { useTranslation } from 'react-i18next';



let orderStatus = false;
const DoctorReview = () => {


    const { t } = useTranslation()

    const [list, setList] = useState('')
    const [pagina, setPagina] = useState(1);
    const [porPagina, setPorPagina] = useState(5);
    const [modal, setModal] = useState(false);
    const [query, setQuery] = useState({})

    const searchTextFieldRef = useRef(null);

    const maximo = list?.count / porPagina;
    const { N_A } = constants;


    async function getReview(payload) {
        let URL = 'admin/dashboard/reviews?';
        URL = generateQuery(URL, payload)
        const response = await getCaller(URL)
        setList(response?.data || {})
    }

    const handleModalClose = () => setModal(false);
    const handleModalOpen = () => setModal(true)

    const onSubmit = async (id, event) => {
        const resp = await updateCaller(`updateReview/${id}?data=true`)
        getReview({ page: 1 })
        resp ? toast.info('Status - Accepted') : toast.info('Some error occurred')
    }

    const onSubmit1 = async (id) => {
        const resp = await updateCaller(`updateReview/${id}`)
        getReview({ page: 1 })
        resp ? toast.info('Status - Rejected') : toast.info('Some error occurred')
    }

    const nextData = async page => {
        const payload = {
            page
        }
        getReview(payload)
    }

    const filterSortOrder = () => {
        orderStatus = !orderStatus;
        getReview({ page: pagina, orderStatus: orderStatus ? "ASC" : "DESC" })
    }
    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        setQuery(prev => ({ ...prev, searchText: value }))
        getReview({ page: 1, searchText: value })
    }

    const ActionsButtons = ({ payload }) => {
        const {
            status,
            reviewId
        } = payload;
        console.log("🚀 ~ file: DoctorReview.jsx:80 ~ ActionsButtons ~ status", status)

        switch (status) {
            case reviewsStatus['rejected']:
                return <>
                    <Tooltip title="Accept">
                        <div className="tooltip">
                            <img src={check} onClick={() => onSubmit(reviewId)} alt="Accept" />
                        </div>
                    </Tooltip>
                    <Tooltip title="Coming Soon">
                        <div className="tooltip">
                            {/* <img src={message} alt="Chat" /> */}
                        </div>
                    </Tooltip>
                </>


            case reviewsStatus['approved']:
                return <>
                    <Tooltip title="Reject">
                        <div className="tooltip">
                            <img src={cross} onClick={() => onSubmit1(reviewId)} alt="Reject" />
                        </div>
                    </Tooltip>
                    <Tooltip title="Coming Soon">
                        <div className="tooltip">
                            {/* <img src={message} alt="Chat" /> */}
                        </div>
                    </Tooltip>
                </>
                break;

            default:
                return <>
                    <Tooltip title="Accept">
                        <div className="tooltip">
                            <img src={check} onClick={() => onSubmit(reviewId)} alt="Accept" />
                        </div>
                    </Tooltip>
                    <Tooltip title="Reject">
                        <div className="tooltip">
                            <img src={cross} onClick={() => onSubmit1(reviewId)} alt="Reject" />
                        </div>
                    </Tooltip>
                    <Tooltip title="Coming Soon">
                        <div className="tooltip">
                            {/* <img src={message} alt="Chat" /> */}
                        </div>
                    </Tooltip>
                </>
                break;
        }

    }

    useEffect(() => {
        nextData(pagina)
    }, [pagina])

    useEffect(() => {
        getReview({})
    }, [])

    return (
        <>
            <div>
                <FilterModal
                    open={modal}
                    handleClose={handleModalClose}
                    refreshData={getReview}
                    setQuery={setQuery}
                />
                <div className="filter-field">
                    <p> {list?.count ?? "0"} {t("Items")} </p>
                    <div className="filter-search">

                        <div className="filter-search-field">
                            <input
                                type="text"
                                id="search-box"
                                placeholder={t("Search")} ref={searchTextFieldRef}
                                onKeyUp={event => event.key === "Enter" && handleTextFieldButton()}
                            />
                            <label onClick={handleTextFieldButton} htmlFor="search-box" className="fas fa-search"></label>
                        </div>
                        <div className="filter-button">

                            <button
                                onClick={handleModalOpen}
                            >
                                {t("Filter")}
                            </button>
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        {/* <Table sx={{ minWidth: 700, minHeight: 500 }} aria-label="customized table"> */}
                        <TableHead>
                            <TableRow>
                                <StyledTableCell> {t("Review Given By")} </StyledTableCell>
                                <StyledTableCell > {t("Date Given")} </StyledTableCell>
                                <StyledTableCell > {t("Review Given to")} </StyledTableCell>
                                <StyledTableCell > {t("Ratings")} </StyledTableCell>
                                <StyledTableCell > {t("Review Content")} </StyledTableCell>
                                <StyledTableCell > {t("Status")} <img onClick={filterSortOrder} src={sortarrow} alt="" /> </StyledTableCell>
                                <StyledTableCell > {t("Action")} </StyledTableCell>
                                {/* dataReviews */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                list && list?.rows?.map((e, i) => {
                                    return (
                                        <StyledTableRow key={`Doctor_Review_${i}`} >
                                            <StyledTableCell component="th" scope="row">
                                                {
                                                    destructureProfile({
                                                        'user_profile': e.user_profile,
                                                        type: profiles.user
                                                    }).name
                                                    ?? N_A
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell>{dateFormatWW(e?.createdAt) ?? N_A}</StyledTableCell>
                                            <StyledTableCell>
                                                {
                                                    destructureProfile({
                                                        'doctor_profile': e.doctor_profile,
                                                        type: profiles.doctor
                                                    }).name
                                                    ?? N_A
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell> {Number(e?.rating).toPrecision(2) ?? N_A}</StyledTableCell>
                                            <StyledTableCell>
                                                <Tooltip title={e?.message || ""}>
                                                    <span>
                                                        {`${e?.message?.slice(0, 30)}...` ?? N_A}
                                                    </span>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <p
                                                    style={{
                                                        fontWeight: 'bold',
                                                        textTransform: 'uppercase',
                                                        color: reviewsStatus[e.status] === reviewsStatus.approved ? __status_color.success : __status_color.error
                                                    }}
                                                >
                                                    {reviewsStatus[e.status]}
                                                </p>
                                            </StyledTableCell>

                                            <StyledTableCell className='table-edit-one'>
                                                <ActionsButtons payload={e} />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination pagina={pagina} setPagina={setPagina} maximo={maximo} />
            </div>
        </>
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: 'top'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        verticalAlign: "top",
        userSelect: 'none'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default DoctorReview