import * as React from 'react';
import { styled } from '@mui/material/styles';
import { theme } from "../../../utils/colors";
import { LoadingButton } from '@mui/lab';


export const ThemeButton = ({ text, onClick, loading, disabled, sx }) => {
    return (
        <CustomStyledThemeButton
            type="submit"
            variant="contained"
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            sx={{
                width: 'auto',
                backgroundColor: 'var(--steel-blue)',
                color: 'white',
                ...sx
            }}
        >
            {text}
        </CustomStyledThemeButton>
    );
}

export const UnstyledButton = ({ text, onClick, loading, disabled, sx }) => {
    return (
        <UnstyledThemeButton
            type="submit"
            variant="contained"
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            sx={{ width: 'auto', ...sx }}
        >
            {text}
        </UnstyledThemeButton>
    );
}

const CustomStyledThemeButton = styled(LoadingButton)(
    ({ theme }) => ({
        height: '100%',
        width: '100%',
        color: theme.palette.text.primary,
        borderRadius: 'inherit',
        backgroundColor: theme.dark,
        padding: 'inherit',
        fontFamily: "inherit",
        fontSize: "inherit",
        display: 'inherit',
        // '&:hover': {
        //     backgroundColor: theme.palette.text.sec,
        //     color: 'black'
        // },
        textTransform: "inherit",
    })
);

const UnstyledThemeButton = styled(LoadingButton)(
    ({ theme }) => ({
        height: '100%',
        width: '100%',
        color: theme.palette.text.primary,
        borderRadius: 'inherit',
        backgroundColor: 'inherit',
        fontFamily: "inherit",
        fontSize: "inherit",
        '&:hover': {
            backgroundColor: theme.dark,
            color: "white",
        },
        textTransform: "inherit",
    })
);