import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { generateQuery } from 'utils';
import { constants } from 'utils/constants';
import { getCaller } from '../../services/api';
import '../customerSupport/customerquery.css'
import CustomerQueryTable from './CustomerQueryTable'
const CustomerSupportQuery = () => {

    const {t} = useTranslation()
    const [queries, setQueries] = useState("");
    const [customerData, setCustomerData] = useState();


    const { N_A } = constants;

    const fetchQueriesRQ = async (payload = {}) => {
        let URL = 'admin/dashboard/queries?';
        URL = generateQuery(URL, payload)
        const res = await getCaller(URL)
        setQueries(res?.data || {})
    }

    useEffect(() => {
        fetchQueriesRQ();
    }, [])
    return (
        <>
            <div className='query-total'>
                <p> {t("QUERIES OVERVIEW")} </p>
                <div className='border'>
                    <div className="query-total-body">
                        <div className='query-para1'>
                            <p> {t("Total Queries")} </p>
                            <h3 >{queries?.allQueries?.count ?? N_A}  </h3>
                        </div>
                        <div className='query-para'>
                            <div className='query-para-head'>
                                <p> {t("Pending Queries")} </p>
                                <h3 style={{ color: "#EFD511" }}> {queries?.pendingQueries ?? "n/c"}  </h3>
                            </div>
                        </div>
                        <div className='query-para'>
                            <div className='query-para-head'>
                                <p>{t("In-Process")} </p>
                                <h3 style={{ color: "#43AFE9" }}>{queries?.inProcess ?? "n/c"} </h3>
                            </div>
                        </div>
                        <div className='query-para'>
                            <div className='query-para-head'>
                                <p>{t("Resolved")}</p>
                                <h3 style={{ color: "#13BC34" }}> {queries?.resolved ?? "n/c"} </h3>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='query-table'>
                    <CustomerQueryTable
                        customerData={queries?.allQueries}
                        setCustomerData={setCustomerData}
                        refreshData={fetchQueriesRQ}
                    />
                </div>
            </div>
        </>
    )
}

export default CustomerSupportQuery