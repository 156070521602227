import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { getCaller } from "services/api";
import { useTranslation } from "react-i18next";
import star from '../../../assets/images/Group 5279.png';
import veriuser from '../../../assets/images/Group 5276.png';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import AppointmentOverCard from "../../../components/common/appointmenCard/AppointmentOverCard";
import { constants } from "../../../utils/constants";
import './doctoraccount.css'
import { dateFormatWW, fetchAWSObject, formatTimeSlot } from "utils";
import useGlobal from "hooks/useGlobal";

const DoctorProfileAccount = () => {
    const { doctorId } = useParams();
    const { isPC } = useOutletContext();
    const [, setProfileCompleted] = isPC;
    const navigate = useNavigate();

    const { tKey : [tKey]} = useGlobal();

    const [dp, setDP] = useState({}) // DP --> Doctor profile
    const fetchdp = async () => {
        const response = await getCaller(`admin/users/doctor/profile/${doctorId}`);
        response?.error === false && setDP(response?.data)
        if (response?.error === false) {
            setDP(response?.data);
            const { profile } = response?.data;
            setProfileCompleted(profile?.approval_status)
        }
    }


    const { t } = useTranslation();

    const editData = [
        {
            title: 'Mobile Number',
            desc: dp?.profile?.mobile ?? constants.three_dots,
        },
        {
            title: 'EmailId',
            desc: dp?.profile?.email ?? constants.three_dots,
        },
        {
            title: 'Gender',
            desc: dp?.profile?.gender ?? constants.three_dots,
        },
        {
            title: 'Location',
            desc: dp?.profile?.location ?? constants.three_dots,
        },
        {
            title: 'Date of Birth',
            desc: dp?.profile?.dob ? (dateFormatWW(dp?.profile?.dob)) : constants.three_dots,
        },
        {
            title: 'Reviews',
            desc: Array(dp?.profile?.reviews) && dp?.profile?.reviews || constants.three_dots
        },
        {
            title: 'Year Of Experience',
            desc: dp?.profile?.experience ?? constants.three_dots
        },
        {
            title: t('saudiCLicence'),
            desc: dp?.profile?.license_no ?? constants.three_dots
        },

    ];

    const navigateToInvoice = () => {
        navigate(
            `/invoicedownload/${doctorId}`,
            {
                state: {
                    totalAppointments: dp?.appointments?.total_appointments,
                    totalEarnings: dp?.total_earnings,
                    name: dp?.profile?.name
                }
            }
        )
    }

    useEffect(() => {
        fetchdp();
        setProfileCompleted(true)
    }, [])

    return (
        <>

            <div className="profile-attend">
                <div className="profile-attend-body">
                    <div>
                        <p> Patients Attended </p>
                        <h3> {dp?.appointments?.total_appointments || '0'} </h3>
                    </div>

                    <div>
                        <p> Total Earnings(SAR) </p>
                        <h3> {dp?.total_earnings || '0'} </h3>
                    </div>
                </div>
                <div className="profile-attend-body-1">
                    <AppointmentOverCard
                        counts={dp?.appointments}
                    />
                </div>
            </div>

            <Box sx={{ flexGrow: 1, marginTop: 5, border: "1px solid #F0F0F0", backgroundColor: "white", borderRadius: ".625rem" }}>

                <Grid item xs={12} md={12} className="">
                    <div className="profile-head">
                        <p> Doctor Profile </p>
                        <div className="" style={{
                            display: 'flex',
                            color: '#2F77B6'
                        }}>
                            <div
                                className='cursor-pointer'
                                onClick={navigateToInvoice}
                                style={{ marginRight: "2rem" }}
                            >
                                Generate Invoice
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={
                                    () => {
                                        navigate('/addNewDoctor', {
                                            state: { doctorId }
                                        })
                                    }
                                }> Edit Profile </div>
                        </div>
                    </div>

                    <Grid item xs={12} md={12}>
                        <div className="profile">
                            <div className="profile-img">
                                <img src={fetchAWSObject(dp?.profile?.url)} alt="" width="175"
                                    height="175"
                                    style={{ borderRadius: '8px' }}
                                />
                                <img src={star} alt="" className="star-img" />
                                <br />
                                <input type="file" id="uploadImage" hidden accept="image/*" />
                                <label style={{
                                    cursor: 'pointer',
                                    padding: '0.5rem 0rem'
                                }} for="uploadImage">{t('changePhoto')}</label>
                            </div>
                            <div className="profile-content">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* Username */}
                                    <h3> {dp?.profile?.name ?? constants.three_dots} </h3>
                                    <img src={veriuser} alt="" style={{ marginLeft: "1rem" }} />
                                </div>
                                <h4> {`${dp?.profile?.subService?.service_master?.name?.[tKey] || constants.three_dots}, ${dp?.profile?.subService?.name?.[tKey] ??   constants.DoTS}`} </h4>
                                <Box sx={{ flexGrow: 1, marginTop: 1 }}>
                                    <Grid
                                        container
                                        spacing={{ xs: 2, md: 2 }}
                                        columns={{ xs: 4, sm: 8, md: 12 }}
                                    >
                                        {editData.map((e, i) => {
                                            return (
                                                <>
                                                    <Grid item xs={12} sm={4} md={4} key={i}>
                                                        <p> {e.title} </p>
                                                        <h5> {e.desc} </h5>
                                                    </Grid>
                                                </>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                                <div className="profile-about">
                                    <p> About Us </p>
                                    <h5>
                                        {dp?.profile?.description ?? constants.three_dots}
                                    </h5>
                                </div>
                                <p style={{ marginTop: "2rem" }}> {t('timingSlots')} </p>
                                <div className="profile-time">
                                    {
                                        Array.isArray(dp?.profile?.availability) && dp.profile.availability.map(
                                            item => {
                                                if (item?.start && item?.end) {
                                                        return (
                                                        <>
                                                            <div>
                                                                <h5 className="time-m"> <div className="m"> {item?.day[0]?.toUpperCase()} </div>
                                                                    <p className="ten">
                                                                        {formatTimeSlot(item?.start)} to {formatTimeSlot(item?.end)}
                                                                    </p>
                                                                </h5>
                                                            </div>
                                                        </>
                                                    )
                                                } else {
                                                    return <></>
                                                }
                                            })
                                    }

                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </Box>
        </>
    );
};

export default DoctorProfileAccount;
