import { useTranslation } from "react-i18next";
import { URLS } from "utils/constants";

const Top = ({ register, errors, disabled }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="home-form">
                <div className='home-input-content'>
                    <label htmlFor="home"> {t("Page Name(EN)")}</label> <br />
                    <input
                        disabled={disabled}
                        {...register("page_name.en", { required: true })}
                        type="text"
                        className='home-input-field'
                        placeholder='Page Name (en)'
                    />
                    <div className={'input-invalid-message'}>
                        {errors.page_name?.en && 'Page Name (EN) is required'}
                    </div>
                </div>

                <div className='home-input-content'>
                    <label htmlFor="home"> {t("Page Name(AR)")}</label> <br />
                    <input
                        disabled={disabled}
                        {...register("page_name.ar", { required: true })}
                        type="text"
                        className='home-input-field'
                        placeholder='Page Name(ar)'
                    />
                    <div className={'input-invalid-message'}>
                        {errors.page_name?.ar && 'Page Name(AR) is required'}
                    </div>
                </div>

                <div className='home-input-content' style={{ marginLeft: "2rem" }}>
                    <label htmlFor="home"> {t("Page URL")} </label> <br />
                    <input
                        disabled={disabled}
                        {...register("page_url", { required: true })}
                        type="text"
                        className='home-input-field'
                        placeholder={`/about-us`}
                    />
                    <div className={'input-invalid-message'}>
                        {errors.page_url && 'Page URL is required'}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Top;