import React from 'react';
import '../view.css';



const Attachment = (props) => {

    return (
        <>
        {/* <Breadcrumb head="" /> */}
            <div className="report-view-child">
            <h5 className="appointment-heading">Attachments</h5>
                {
                    props?.files?.map((e,i)=>{  
                        return(
                            <>
                            {
                                e?.fileName.split('.').pop()==="pdf"?
                                    <PdfView 
                                        fileName = {e?.fileName}
                                        pathUrl = {e?.filePath}
                                    />:
                                    <ImageView 
                                        fileName = {e?.fileName}
                                        pathUrl = {e?.filePath}
                                    />
                            }
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}

const ImageView = ({fileName,pathUrl}) => {

    return (
        <>
        <div className='fileName'>
            {fileName}
        </div>
            <img className='attachments' src={pathUrl} alt="" />
        </>
    )
}

const PdfView = ({fileName,pathUrl}) => {
    return (
        <>
        pdf : {fileName}
        </>
    )
}

export default Attachment;