import React, { useEffect, useState } from "react";
import { Grid, ListItem } from "@mui/material";
import UserIcon from "assets/images/chat-icons/patient-avator.png"
import DoctorIcon from "assets/images/chat-icons/doctor-avator.png";
import ImageIcon from "assets/images/chat-icons/image-icon.png";

/* CSS */
import './video-chat.css';

function MessagesList(props) {
    // const newChatData = props.data.chatimg;

    const [profileImage, setProfileImage] = useState()
    const message = props.message;
    const email = props.email;
    const isOwnMessage = message.author === email;

    const getTempURL = async (message) => {

        const file = message.media.contentType;
        console.log("🚀 ~ file: NewList.jsx ~ line 24 ~ getTempURL ~ file", file)

        const tempURL = await message.media.getContentTemporaryUrl();
        const link = document.createElement('a');
        link.href = tempURL;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log("🚀 ~ file: NewList.jsx ~ line 22 ~ getTempURL ~ tempURL", tempURL)
    }

    useEffect(() => {
        console.log("🚀 ~ file: NewList.jsx ~ line 30 ~ useEffect ~ message", message.media)
    }, [])
    return (
        <>
            {
                isOwnMessage ?
                    <Grid container spacing={2} className="my-5">
                        <Grid item xs={2}>
                            <div> <img
                                style={styles.author(isOwnMessage)}
                                src={DoctorIcon}
                                alt=""
                            /></div>
                        </Grid>
                        <Grid item xs={10}>
                            <div>
                                <div class="talk-bubble triangle left-top">
                                    <div class="talktext">
                                        <p>
                                            {
                                                message.media ?
                                                    <>
                                                        <img
                                                            src={ImageIcon}
                                                            onClick={() => getTempURL(message)}
                                                        />
                                                    </>

                                                    : message.body
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2} className="my-5">
                        <Grid item xs={10}>
                            <div>
                                <div class="talk-bubble triangle right-top ">
                                    <div class="talktext talktext2">
                                        <p>
                                            {
                                                message.media ?
                                                    <>
                                                        <img
                                                            src={ImageIcon}
                                                            onClick={() => getTempURL(message)}
                                                        />
                                                    </>

                                                    : message.body
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div> <img
                                style={styles.author(isOwnMessage)}
                                src={UserIcon}
                                alt=""
                            /></div>
                        </Grid>

                    </Grid>
            }

        </>

    );
}


const styles = {
    listItem: (isOwnMessage) => ({
        display: "flex",
    }),
    container: (isOwnMessage) => ({
        maxWidth: "75%",
        width: "100%",
        borderTopRightRadius: isOwnMessage ? "0rem" : ".5rem",
        borderBottomRightRadius: isOwnMessage ? ".5rem" : ".5rem",
        borderBottomLeftRadius: isOwnMessage ? ".5rem" : ".5rem",
        borderTopLeftRadius: isOwnMessage ? ".5rem" : "0rem",
        marginLeft: isOwnMessage ? "0rem" : "0rem",
        padding: 16,
        border: "1px solid #CBCBCB",
        color: "black",
        fontSize: 14,
        backgroundColor: isOwnMessage ? "#FFFFFF" : "#FFFFFF",
    }),
    author: (isOwnMessage) => ({
        fontSize: 10,
        color: "gray",
        display: "flex",
        justifyContent: isOwnMessage ? "flex-end" : "flex-start",
        width: "4rem",
        alignItems: "center",
        height: "4rem",
        borderRadius: "50%",
        border: "none",
        margin: "0.5rem 0.5rem 0 0rem"
    }),

    timestamp: (isOwnMessage) => (
        {
            fontSize: 11,
            color: "black",
            textAlign: "center",
            padding: ".5rem 2rem .5rem 2rem",
            backgroundColor: "#F0F0F0",
            borderRadius: "2rem",
            margin: " .5rem 0rem .5rem 4.5rem",

            maxWidth: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }),
};

export default MessagesList;