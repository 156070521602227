import { getCaller } from "../../../services/api";

export const fetchMasterServicesListingRQ = async () => {
    const response = await getCaller(`admin/services-masters/listing`);
    return (response?.error == false) ? response : false;
}

export const fetchFooterContentRQ = async () => {
    const response = await getCaller(`admin/config/general/footer`);
    return (response?.error == false) ? response : false;
}
