import ProfileInProgress from "profile/DoctorProfile/ProfileInProgress";

const DoctorView = () => {
    return (
        <>
            <ProfileInProgress />
        </>
    )
}

export default DoctorView;